<template>
  <div style="width:100%;height:100%;">
    <pull-to :id="'message-' + mod + '-container'" :ref="'message-' + mod + '-container'" :style="listStyle" class="my-list" :top-load-method="(loaded) => loadData(loaded, 'down')">
      <div v-if="!loading[mod] && itemNums && !data.noDownPage" class="tip load" @click="getData('down')"><i class="iconfont my-dot-more" /></div>
      <div class="flow-container">
        <div class="column" :style="{'width': '100%'}">
          <div v-for="(item, i) in data.chatItems" :key="i" class="item">
            <div :style="{ 'width': cardWidth + 'px' }">
              <div v-if="i > 1 && item.time !== data.chatItems[i - 1].time" class="time-row">{{ item.time }}</div>
              <div class="user">
                <div :style="{'float': item.user.userid === loginUserId ? 'right' : 'left'}" style="width: 60px;padding-right: 5px;" @click="goto('user', { userid: item.user.userid, type: 'thread', typeid: 0 })">
                  <el-badge :value="formateNums(item.unreadCount)" :hidden="!item.unreadCount" :style="{'float': item.user.userid === loginUserId ? 'right' : ''}">
                    <span v-if="mod !=='system' && !item.user.avatar" class="el-avatar el-avatar--circle noavatar large">
                      <i class="iconfont my-man" />
                    </span>
                    <el-avatar v-if="mod !=='system' && item.user.avatar" :size="42" :src="getAvatar(item.user)" />
                    <el-avatar v-if="mod ==='system'" :size="42" :src="icon" />
                  </el-badge>
                </div>
                <div class="summary chat" :class="{'lchat': item.user.userid !== loginUserId, 'bg-green rchat': item.user.userid === loginUserId}" :style="{'float': item.user.userid === loginUserId ? 'right' : 'left'}">
                  <div :class="{'ltriangle': item.user.userid !== loginUserId, 'rtriangle': item.user.userid === loginUserId}" />
                  <div :style="{'float': item.user.userid === loginUserId ? 'right' : 'left'}" v-html="getContent(item)" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </pull-to>
    <div class="input" :class="{ 'small' : data.rows > 1}" :style="{'position': isDesktop ? 'absolute' : ''}" style="border-top:1px solid var(--color-28);">
      <div class="left">
        <el-input ref="input" v-model="data.content" type="textarea" class="my-from-item" name="name" :rows="data.rows" :placeholder="data.placeholder" clearable @focus="moreRows" @blur="() => moreRows()" />
      </div>
      <div class="right">
        <el-button class="tap-btn margin-top8" @click="send">
          <i class="iconfont my-fly" :class="{ 'blue': data.content }" />
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mixins } from '@/mixins/message'
export default {
  mixins: [mixins]
}
</script>
