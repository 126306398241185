<template>
  <div v-show="config.show" v-finger:swipe="onSwipe" class="my-container">
    <div v-show="!isDesktop" class="header">
      <div class="left">
        <el-button class="tap-btn" @click="goto()">
          <i class="iconfont my-back" />
        </el-button>
      </div>
      <div class="middle">
        数字藏品
      </div>
      <div class="right">
        <el-button class="tap-btn" @click="goto('nftorder')">
          <i class="iconfont my-orders" />
        </el-button>
      </div>
    </div>
    <div class="tabs" :style="isDesktop ? 'top: 4px;' : ''">
      <el-tabs v-model="config.type">
        <el-tab-pane v-for="(type, idx) in config.types" :key="idx" :label="type[1]" :name="type[0]" />
      </el-tabs>
    </div>
    <nft-list
      v-show="config.show && config.type === 'publish'"
      ref="nft-publish"
      mod="publish"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <nft-list
      v-show="config.show && config.type === 'preorder'"
      ref="nft-preorder"
      mod="preorder"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <nft-list
      v-show="config.show && config.type === 'paradrop'"
      ref="nft-paradrop"
      mod="paradrop"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <nft-list
      v-show="config.show && config.type === 'compound'"
      ref="nft-compound"
      mod="compound"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <nft-list
      v-show="config.show && config.type === 'calendar'"
      ref="nft-calendar"
      mod="calendar"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
  </div>
</template>
<script>
import nftList from '@/components/web/nftList'
import { mixins } from '@/mixins/common'
export default {
  components: {
    nftList
  },
  mixins: [mixins],
  computed: {
    config() {
      return this.view.nft
    }
  },
  methods: {
    handle(act, data) {
      this.emit(act, data)
    },
    onSwipe(evt) {
      this.switchTab(this.config, evt.direction)
    },
    renewView() {
      const el = this.$refs['nft-' + this.config.type]
      if (el) el.renewView()
    }
  }
}
</script>
