<template>
  <div style="width:100%;height:100%;">
    <pull-to :id="'flow-' + mod + '-container'" :style="listStyle" class="my-list" :top-load-method="(loaded) => topPull ? loadData(loaded, showUp ? 'up' : 'renew') : {}" :bottom-load-method="(loaded) => bottomPull ? loadData(loaded, 'down') : {}" @infinite-scroll="getData('down')">
      <div class="flow-container">
        <div v-for="(column, index) in columns" :key="index" class="column" :style="{'width': columnWidth + 'px' }">
          <div v-for="(item, i) in column.items" :key="i" class="item">
            <div class="card size14" :style="{ 'width': cardWidth + 'px', 'height': (item._height - 5) + 'px' }">
              <div v-if="mod === 'flow'" class="padding10">
                <div class="float-left">
                  {{ item.change_desc }}<br>
                  <div class="padding-top4 color-99">{{ getDate(item.created_at) }}</div>
                </div>
                <div class="float-right size16 padding-top4" :class="{'red': item.change_available_amount < 0}">
                  {{ formateMoney(item.change_available_amount * 100) }}
                </div>
              </div>
              <div v-if="mod === 'cash'" class="padding10">
                <div class="float-left">
                  提现 <span v-html="getCashStatus(item.cash_status)" /><br>
                  <div class="padding-top4 color-99">{{ getDate(item.created_at) }}</div>
                </div>
                <div class="float-right size16 padding-top8">
                  {{ formateMoney(item.cash_apply_amount * 100) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="itemNums && !data.noDownPage" class="tip load" @click="getData('down')"><i class="iconfont my-dot-more" /></div>
      <div v-if="flowHeight > height && data.noDownPage" class="tip">已显示全部内容</div>
      <el-empty v-if="(!itemNums && !loading[mod])" :image="emptyImage" :description="mod === 'invite' ? '快邀请好友吧' : '这里空空的'" />
    </pull-to>
    <div v-if="data.scrollTop > 200" class="go-top">
      <el-button class="tap-btn" @click="backTop()">
        <i class="iconfont my-top" />
      </el-button>
    </div>
  </div>
</template>
<script>
import { mixins } from '@/mixins/flow'
export default {
  mixins: [mixins]
}
</script>
