<template>
  <el-dialog
    ref="replyDialog"
    :visible.sync="config.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="!isDesktop"
    :show-close="false"
    :top="marginTop"
    custom-class="pop_page noscroll reply replyDialog"
  >
    <div slot="title" class="header">
      <div class="left">
        &nbsp;
      </div>
      <div class="middle margin-left8">
        {{ (total >= 0 ? total + '条' : '') + '回复' }}
      </div>
      <div class="float-right">
        <el-button class="tap-btn iconfont my-close size20" @click="closePop(config)" />
      </div>
    </div>
    <div>
      <comment-list
        v-show="config.show"
        mod="reply"
        :view="view"
        :state="state"
        :file="work"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :is-pop="true"
        @receive="handle"
      />
    </div>
  </el-dialog>
</template>
<script>
import commentList from '@/components/web/commentList'
import { mixins } from '@/mixins/common'
export default {
  components: {
    commentList
  },
  mixins: [mixins],
  data() {
    const data = {}
    data.total = 0
    return data
  },
  computed: {
    config() {
      return this.view.reply
    },
    work() {
      return this.config.work
    }
  },
  methods: {
    handle(act, data) {
      if (act === 'setTotal') {
        this.setTotal(...data)
      } else {
        this.emit(act, data)
      }
    },
    setTotal(total) {
      this.total = total || 0
    }
  }
}
</script>
