<template>
  <el-dialog
    ref="itemsDialog"
    :visible.sync="config.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="!isDesktop"
    :show-close="false"
    :top="marginTop"
    custom-class="pop_page noscroll itemsDialog"
  >
    <div slot="title" class="header">
      <div class="left">
        <el-button v-if="!isDesktop" class="tap-btn iconfont my-back size20" @click="closePop(config)" />
        &nbsp;
      </div>
      <div class="middle">
        我的物品
      </div>
      <div class="float-right align-right">
        <el-button v-if="isDesktop" class="tap-btn iconfont my-close size20" @click="closePop(config)" />
        &nbsp;
      </div>
    </div>
    <div v-show="config.show" class="my-container">
      <div class="container-header">
        <div ref="header-card" class="padding8">
          <div class="my-row round8" style="height: 80px;">
            <div class="float-left padding-top4">
              <span v-if="!(user.avatar && !config.loading)" class="el-avatar el-avatar--circle noavatar large">
                <i class="iconfont my-man" />
              </span>
              <el-avatar v-if="user.avatar && !config.loading" :size="48" :src="getAvatar()" />
            </div>
            <div class="info padding-top4">
              <span class="name margin-left4">{{ user.nickname || view.anonymity }}</span>
              <span v-if="false" class="addition opacity-bg">Lv.3</span>
              <br>
              <el-button class="txt-btn white">{{ (source[10008] && source[10008].quantity) ? source[10008].quantity : 0 }} <span class="white">成长值</span></el-button>
              <el-button class="txt-btn white">{{ (source[10006] && source[10006].quantity) ? source[10006].quantity : 0 }} <span class="white">晶石</span></el-button>
              <el-button class="txt-btn white">{{ (source[10007] && source[10007].quantity) ? source[10007].quantity : 0 }} <span class="white">晶钻</span></el-button>
            </div>
          </div>
        </div>
        <div class="tabs user-tabs margin-top4" :class="{'headless': !config.showHead}">
          <el-tabs v-model="config.type">
            <el-tab-pane v-for="(type, idx) in config.types" :key="idx" :label="type[1]" :name="type[0]" />
          </el-tabs>
        </div>
      </div>
      <items-list
        v-show="config.show && config.type === 'all'"
        ref="items-all"
        mod="all"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :subtract-height="subtractHeight"
        @receive="handle"
      />
      <items-list
        v-show="config.show && config.type === 'bag'"
        ref="items-bag"
        mod="bag"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :subtract-height="subtractHeight"
        @receive="handle"
      />
      <items-list
        v-show="config.show && config.type === 'store'"
        ref="items-store"
        mod="store"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :subtract-height="subtractHeight"
        @receive="handle"
      />
      <items-list
        v-show="config.show && config.type === 'hall'"
        ref="items-hall"
        mod="hall"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :subtract-height="subtractHeight"
        @receive="handle"
      />
    </div>
  </el-dialog>
</template>
<script>
import itemsList from '@/components/web/itemsList'
import { mixins } from '@/mixins/common'
import utils from '@/js/utils'
import service from '@/js/service'
export default {
  components: {
    itemsList
  },
  mixins: [mixins],
  data() {
    return {
      swipeLock: false,
      scrollTop: 0,
      subtractHeight: 125,
      user: {}
    }
  },
  computed: {
    config() {
      return this.view.items
    },
    source() {
      return this.state.source
    }
  },
  watch: {
    // 'view.items.show': {
    //   handler() {
    //     if (this.view.items.show) {
    //       this.update()
    //       this.view.items.loading = false
    //       if (this.loginUserId !== this.user.userid) {
    //         this.getUser(true)
    //       }
    //     }
    //   }
    // },
    'view.items.renew': {
      handler() {
        this.getSource()
      }
    }
  },
  mounted() {
    this.view.items.loading = false
    if (!this.loginUserId) {
      this.emit('login')
      return
    }
    this.getUser()
  },
  methods: {
    handle(act, data) {
      this.emit(act, data)
    },
    update() {
      this.$forceUpdate()
      setTimeout(() => {
        this.subtractHeight = this.getElementHeight('header-card') + 40
      }, 10)
    },
    getAvatar() {
      const ver = Math.round(new Date(this.user.avatar_at) / 1000)
      if (this.user.avatar) return service.getCosUrl(this.user.avatar, 'avatar', ver) || ''
    },
    formateTime: utils.formateTime,
    formateNums: utils.formateNums,
    getElementHeight(refid) {
      return this.$refs[refid] ? parseInt(window.getComputedStyle(this.$refs[refid]).height) : 0
    },
    renewView() {
      this.callSub('items-all', 'renewView')
    },
    callSub(ref, fn) {
      const el = this.$refs[ref]
      if (el && el[fn]) {
        el[fn]()
      } else {
        setTimeout(() => {
          this.callSub(ref, fn)
        }, 100)
      }
    },
    getUser(renew) {
      if (this.view.items.loading) return
      this.view.items.loading = true
      this.emit('getUser', [this.loginUserId, (user) => {
        if (user) this.user = user
        this.view.items.loading = false
        this.update()
        this.getSource()
      }])
      if (renew) this.renewView()
    },
    // 获取成长值、晶石、晶钻
    getSource() {
      this.emit('getSource', [(dt, type) => {
        this.update()
      }])
    }
  }
}
</script>
