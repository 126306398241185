<template>
  <div :id="'topics-' + mod + '-container'" class="flow-container my-list">
    <div v-for="(column, index) in columns" :key="index" class="column" :style="{'width': columnWidth + 'px' }">
      <div v-for="(item, i) in column.items" :key="i" class="item">
        <div class="card ellipsis size14 hover-bg-ee" :style="{ 'width': cardWidth + 'px', 'height': (item._height - 5) + 'px', 'padding-left': '18px' }" @click="emit('goto', ['topicDetail', { topicid: item.id } ])">
          #{{ item.content }}#
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mixins } from '@/mixins/topics'
export default {
  mixins: [mixins]
}
</script>
