<template>
  <el-dialog
    ref="signDialog"
    :visible.sync="config.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="!isDesktop"
    :show-close="false"
    :top="marginTop"
    custom-class="pop_page noscroll signDialog"
  >
    <div slot="title" class="header">
      <div class="left">
        <el-button v-if="!isDesktop" class="tap-btn iconfont my-back size20" @click="closePop(config)" />
        &nbsp;
      </div>
      <div class="middle">
        签到日历
      </div>
      <div class="float-right align-right">
        <el-button class="tap-btn iconfont my-question size20" @click="showRule" />
        <el-button v-if="isDesktop" class="tap-btn iconfont my-close size20" @click="closePop(config)" />
        &nbsp;
      </div>
    </div>
    <div v-show="config.show" class="my-container">
      <div class="my-body">
        <el-calendar v-model="selectDate" class="mine-calendar">
          <template slot="dateCell" slot-scope="{date, data}">
            <div v-html="getContent(date, data)" />
          </template>
        </el-calendar>
        <div class="sign-bar">
          <div class="bg-28 padding8 round8 margin-bottom12 align-left"> &nbsp; {{ continue_days > 1 ? '已连续签到 ' + continue_days + ' 天，' : '' }} 今日{{ lastSignDate === today ? '已' : '未' }}签到</div>
          <el-row>
            <el-col v-for="(item, i) in signConf" :key="i" :span="3">
              <div :title="item.info" @click="alert(item.info)">
                <span class="size12" :class="{'green': item.continue_days <= continue_days}">第{{ item.continue_days }}天</span> <br>
                <span v-if="item.continue_days <= continue_days">
                  <i class="iconfont my-prize green" />
                </span>
                <span v-if="item.continue_days > continue_days">
                  <i class="iconfont my-prize color-99" />
                </span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="footer padding-top8" style="position:absolute;">
      <el-button v-if="lastSignDate === today" type="primary" class="btn-cancel" style="max-width:200px;">
        <span class="color-66">已签到</span>
      </el-button>
      <el-button v-if="lastSignDate !== today" type="light" class="btn-ok" style="max-width:200px;" @click="signIt()">
        立即签到
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { mixins } from '@/mixins/common'
import utils from '@/js/utils'
import service from '@/js/service'
export default {
  mixins: [mixins],
  data() {
    const today = utils.date('date')
    const yesterday = utils.date('date', -1)
    return {
      signConf: [],
      signDays: [],
      continue_days: 0,
      lastSignDate: '',
      today: today,
      yesterday: yesterday,
      selectDate: ''
    }
  },
  computed: {
    config() {
      return this.view.sign
    }
  },
  watch: {
    'selectDate': {
      handler() {
        if (utils.date('date', 0, this.selectDate) === this.yesterday && this.signDays.indexOf(this.yesterday) === -1) {
          this.confirm('是否补签？', (action) => {
            if (action === 'confirm') this.signIt('yesterday')
          })
        } else if (utils.date('date', 0, this.selectDate) === this.today && this.signDays.indexOf(this.today) === -1) {
          this.signIt()
        }
      }
    }
  },
  mounted() {
    if (!this.loginUserId) {
      this.emit('login')
      return
    }
    this.getSignConf()
    this.getSignLog()
  },
  methods: {
    showRule() {
      this.$alert('<div class="align-left margin-left12 margin-right12 line-height-20 size12">1、连续签到奖励每天递增，第7天后，可获得与第7天相同的奖励；<br>2、若签到中断，可补昨日断签。否则，将从第一天重新开始；<br>3、长期坚持签到，还有额外神秘奖励。</div>', '签到规则', {
        dangerouslyUseHTMLString: true
      })
    },
    signIt(mod) {
      const params = {}
      if (mod === 'yesterday') params.type = mod
      service.post('sign_log', params, (dt, type) => {
        if (type === 'success' && dt.data && dt.data.items && dt.data.items.length) {
          this.getSignLog()
          const gets = []
          for (const i in dt.data.items) {
            gets.push(dt.data.items[i].name + ' x' + dt.data.items[i].nums)
          }
          service.incCount(this.loginUserId, 'sign')
          this.emit('alert', [gets.join('\n'), () => {}, (mod === 'yesterday' ? '补签' : '签到') + '获得'])
          this.emit('traceEvent', ['sign' + (mod === 'yesterday' ? '_yesterday' : '')])
        } else {
          this.message(dt, type)
        }
      })
    },
    getSignConf() {
      const params = {
        between_field: 'continue_days',
        between_value: '0,7',
        sort: 'asc',
        simple: 1
      }
      service.listGet('sign', params, (res, type) => {
        if (type === 'success' && res.data && res.data.count) {
          this.signConf = res.data.items
        }
      }, false, true)
    },
    getSignLog() {
      const params = {
        between_field: 'sign_date',
        between_value: 'month,now',
        sort_field: 'sign_date',
        sort: 'desc',
        simple: 1
      }
      service.listGet('sign_log', params, (res, type) => {
        const signDays = []
        this.continue_days = 0
        this.lastSignDate = ''
        if (type === 'success' && res.data && res.data.count) {
          this.lastSignDate = utils.date('date', 0, new Date(res.data.items[0].sign_date * 1000))
          if (this.lastSignDate === this.today || this.lastSignDate === this.yesterday) {
            this.continue_days = res.data.items[0].continue_days || 0
          }
          for (const i in res.data.items) {
            signDays.push(utils.date('date', 0, new Date(res.data.items[i].sign_date * 1000)))
          }
        }
        this.signDays = signDays
      })
    },
    getContent(date, data) {
      let cls = ''
      if (date.getTime() > new Date().getTime() || data.type !== 'current-month') {
        cls = cls + 'color-44'
      }
      let signed = false
      if (this.signDays.indexOf(data.day) >= 0) {
        cls = cls + 'green signed'
        signed = true
      }
      let ret = '<p class="' + cls + '">' + data.day.split('-')[2] + (signed ? '<i class="iconfont my-check green size16 margin-left4">' : '')
      if (!signed && data.day === this.yesterday) {
        ret = ret + '<i class="iconfont my-fail yellow size16 margin-left4">'
      }
      return ret + '</p>'
    },
    handle(act, data) {
      this.emit(act, data)
    }
  }
}
</script>
