<template>
  <div style="width:100%;height:100%;">
    <pull-to :id="'resource-' + mod + '-container'" :style="listStyle" class="my-list" :top-load-method="(loaded) => loadData(loaded, showUp ? 'up' : 'renew')" :bottom-load-method="(loaded) => loadData(loaded, 'down')" @infinite-scroll="getData('down')">
      <div class="flow-container">
        <div v-for="(column, index) in columns" :key="index" class="column" :style="{'width': columnWidth + 'px' }">
          <div v-for="(item, i) in column.items" :key="i" class="item">
            <div v-finger:long-tap="() => longTap(item[keyfield])" class="card" :style="{ 'width': cardWidth + 'px' }">
              <div class="float-left margin10 line-height-15" :style="{ 'width': cardWidth - 60 + 'px' }" @click="() => mod !== 'selecter' && viewResource(item[keyfield], item)">
                <span v-if="table === 'bankcard'" @click="(data.selectMod || mod === 'selecter') && select(item[keyfield])">
                  <el-row>
                    <span class="size16">{{ item.bank }}</span> <span class="color-99 margin-left4">{{ item.name }}</span> <span v-if="item.isdefault" class="tag bg-blue white margin-left4">默认</span>
                  </el-row>
                  <el-row>
                    <span class="color-dd size24">{{ hintBankAccount(item.account) }}</span>
                  </el-row>
                </span>
                <span v-if="table === 'address'" @click="(data.selectMod || mod === 'selecter') && select(item[keyfield])">
                  <el-row>
                    <span class="size16">{{ item.name }}</span> <span class="color-99 margin-left4">{{ item.phone }}</span> <span v-if="item.isdefault" class="tag bg-blue white margin-left4">默认</span>
                  </el-row>
                  <el-row class="color-bb line-height-20">
                    {{ item.province }} {{ item.city }} {{ item.address }}
                  </el-row>
                </span>
                <span v-if="table === 'topics'">
                  <el-row>
                    <span class="size16">#{{ item.content }}#</span> <span class="color-99 margin-left4 size12">{{ item.view_count ? item.view_count + '热度' : '' }}</span>
                  </el-row>
                </span>
                <span v-if="table === 'user_follow'">
                  <el-row>
                    <div class="float-left" style="height:42px;">
                      <span v-if="!item.avatar" class="el-avatar el-avatar--circle noavatar large">
                        <i class="iconfont my-man" />
                      </span>
                      <el-avatar v-if="item.avatar" :size="42" :src="getAvatar(item)" />
                    </div>
                    <div class="float-left size16 padding-top12 padding-left8">{{ item.nickname || view.anonymity }}</div>
                  </el-row>
                </span>
                <span v-if="table === 'work'">
                  <el-row>
                    <div class="float-left" style="height:80px;">
                      <div style="width:90px;height:80px;">
                        <img :src="worksHost + 'work/action/download/gridy/size/8/workid/' + item.workid + '/' + item.workid + '.png?ver=' + item.update_at" style="margin:0 auto;" :style="imgStyle(item)">
                      </div>
                    </div>
                    <div class="float-left padding-left8 padding-top4" style="width:calc(100% - 90px);line-height: 1.8;">
                      <div class="ellipsis" style="width:100%;">{{ item.name }}</div>
                      <span v-if="!item.check" class="tag bg-red white margin-right4">封</span>
                      <span v-if="!item.public" class="tag bg-yellow white margin-right4">私密</span>
                      <span v-if="item.on_chain && !item.copyright_state && !item.publish_state" class="tag bg-green white margin-right4">上链</span>
                      <span v-if="item.copyright_state" class="tag bg-purple white margin-right4">版权</span>
                      <span v-if="item.publish_state" class="tag bg-blue white margin-right4">藏品</span>
                      <span v-if="item.origin" class="tag bg-blue white margin-right4">二创</span>
                      <span v-if="item.original" class="tag bg-green white margin-right4">原创</span>
                      <span v-if="item.best" class="tag bg-gray">荐</span> <br>
                      <span class="color-99 size12">{{ formateTime(item.update_at) }}</span>
                    </div>
                  </el-row>
                </span>
                <span v-if="table === 'paint'">
                  <div class="padding-bottom8">
                    <div style="width:100%;">
                      <span v-if="!item.check" class="tag bg-red white margin-right4">封</span>
                      <span class="size14">{{ item.name }}</span>
                    </div>
                    <span v-if="!item.public" class="tag bg-yellow white margin-right4">私密</span> <span v-for="tag in item.tags" :key="tag" class="tag bg-gray white">{{ tag }}</span> <span class="color-99 size12">{{ formateTime(item.add_at) }}</span>
                    <!-- <span v-if="item.ai_params.style" class="tag bg-blue white margin-right4">{{ item.ai_params.style }}</span> -->
                  </div>
                  <div :style="{'width': (cardWidth - 20) + 'px'}">
                    <img v-if="item.images[0] && item.ai_status === 2" :src="getPaint(item)" class="round4" style="margin:0 auto;" :style="paintStyle(item, cardWidth * 0.8)">
                    <div v-if="!item.images[0] || item.ai_status !== 2" style="width: 100%;" class="align-center">
                      <span class="size14 color-99" :style="{'line-height': (cardWidth - 40) + 'px'}">{{ getPaintTip(item) }}</span>
                    </div>
                  </div>
                </span>
                <span v-if="table === 'draft'">
                  <el-row>
                    <div class="float-left" style="height:80px;">
                      <div style="width:90px;height:80px;">
                        <scenes-preview
                          :key="'scenes-preview:0'"
                          :idx="0"
                          :file="item"
                          :size="80"
                        />
                      </div>
                    </div>
                    <div class="float-left padding-left8 padding-top4" style="width:calc(100% - 95px);line-height: 1.8;">
                      <div class="ellipsis" style="width:100%;">{{ item.name }}</div>
                      <span class="color-cc size12">{{ item.canvas.cols + ' x ' + item.canvas.rows }}</span><br>
                      <span v-if="item.origin" class="tag bg-blue white margin-right8">二创</span> <span v-if="item.publish" class="tag bg-green white margin-right8">已发布</span>
                      <span class="color-99 size12">{{ formateTime(item.publish ? item.publishTime : item.createTime) }}</span>
                    </div>
                  </el-row>
                </span>
                <span v-if="table === 'nft'">
                  <el-row>
                    <div class="float-left" style="height:80px;">
                      <div style="width:90px;height:80px;">
                        <img :src="worksHost + 'work/action/download/gridy/size/8/workid/' + item.workid + '/' + item.workid + '.png?ver=' + item.update_at" style="margin:0 auto;" :style="imgStyle(works[item.workid])">
                      </div>
                    </div>
                    <div class="float-left padding-left8 padding-top4" style="width:calc(100% - 95px);line-height: 1.8;">
                      <div class="ellipsis" style="width:100%;">{{ item.name }} <span v-if="nfts[item.publishid]"> #{{ padding(nfts[item.publishid].sn, 4) }}</span></div>
                      <span v-if="!item.check" class="tag bg-red white">封</span> <span v-for="(tag, idx) in item.tags" :key="idx" class="tag bg-gray white margin-right8">{{ tag }}</span> <br>
                      <span class="color-99 size12">{{ formateTime(item.update_at) }}</span>
                    </div>
                  </el-row>
                </span>
                <span v-if="table.slice(0, -2) === 'attachments'">
                  <el-row>
                    <div v-if="table === 'attachments_1'" class="float-left" style="height:80px;">
                      <div style="width:90px;height:80px;">
                        <el-image class="round4" :style="{'width':'80px', 'height': '80px'}" fit="cover" :src="getAttachment(item)" />
                        <!-- <img :src="getAttachment(item)" style="margin:0 auto;" :style="imgStyle({'width': item.file_width, 'height': item.file_height})"> -->
                      </div>
                    </div>
                    <div class="float-left padding-left8 padding-top4" style="width:calc(100% - 95px);line-height: 1.8;">
                      <div class="ellipsis" style="width:100%;">{{ item.file_name }}</div>
                      <span class="color-99 size12">{{ formateBytes(item.file_size) }}<br>{{ formateTime(item.created_at) }} 上传</span>
                    </div>
                  </el-row>
                </span>
              </div>
              <div v-if="data.selectMod || mod === 'selecter'" class="select-btn padding-top4 padding-left2" @click="select(item[keyfield])">
                <i class="iconfont my-ok" :class="{ gray: !data.selectIds[item[keyfield]], blue: data.selectIds[item[keyfield]]}" />
              </div>
              <div class="select-btn padding-top4">
                <el-button class="iconfont my-dot-more-v size14 box24 padding4" @click="showActionSheet(item[keyfield], item)" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!loading[mod] && itemNums && !data.noDownPage" class="tip load" @click="getData('down')"><i class="iconfont my-dot-more" /></div>
      <div v-if="flowHeight > height && data.noDownPage" class="tip">已显示全部内容</div>
      <el-empty v-if="(!itemNums && !loading[mod])" :image="emptyImage" description="这里空空的" />
    </pull-to>
    <div v-if="data.scrollTop > 200" class="go-top">
      <el-button class="tap-btn" @click="backTop()">
        <i class="iconfont my-top" />
      </el-button>
    </div>
    <div v-if="table === 'bankcard' || table === 'address'" class="footer padding-top8" :style="{'position' : isDesktop ? 'absolute' : ''}">
      <el-button type="light" class="btn-ok" style="max-width:200px;" @click="addResource">
        {{ view.resourceActs['add'] + view.resourceNames[table] }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { mixins } from '@/mixins/resource'
import scenesPreview from '@/components/scenesPreview'
export default {
  components: {
    scenesPreview
  },
  mixins: [mixins]
}
</script>
