/**
 * @author funfly
 * @mail 389193@qq.com
 * @date 2023-2-20
 * @copyright Gridy.Art
 */
const ALL_COLORS = [
  {
    'name': '白色',
    'hex': '#FEFBF5',
    'id': '1'
  },
  {
    'name': '灰白',
    'hex': '#E6E6E4',
    'id': '2'
  },
  {
    'name': '浅蓝灰',
    'hex': '#C0C4D2',
    'id': '7'
  },
  {
    'name': '沙蓝',
    'hex': '#828EA4',
    'id': '43'
  },
  {
    'name': '浅灰',
    'hex': '#ADADB0',
    'id': '3'
  },
  {
    'name': '中灰',
    'hex': '#838588',
    'id': '4'
  },
  {
    'name': '深灰',
    'hex': '#616160',
    'id': '5'
  },
  {
    'name': '深蓝灰',
    'hex': '#49484E',
    'id': '8'
  },
  {
    'name': '黑色',
    'hex': '#111111',
    // 'hex': '#212121',
    'id': '6'
  },
  {
    'name': '薰衣草',
    'hex': '#B0A1CD',
    'id': '33'
  },
  {
    'name': '蛋壳色',
    'hex': '#FDEDE4',
    'id': '9'
  },
  {
    'name': '淡肉色',
    'hex': '#FCE3CD',
    'id': '10'
  },
  {
    'name': '肌色',
    'hex': '#FACE9D',
    'id': '12'
  },
  {
    'name': '浅肉色',
    'hex': '#F8C5A3',
    'id': '11'
  },
  {
    'name': '藕色',
    'hex': '#EDAD87',
    'id': '13'
  },
  {
    'name': '粉葵彩',
    'hex': '#DA8755',
    'id': '14'
  },
  {
    'name': '法布兰棕',
    // 'hex': '#C45C1B',
    'hex': '#B15218',
    'id': '16'
  },
  {
    'name': '棕色',
    'hex': '#743620',
    'id': '17'
  },
  {
    'name': '深棕色',
    'hex': '#482413',
    'id': '18'
  },
  {
    'name': '浅紫',
    'hex': '#9B68A9',
    'id': '34'
  },
  {
    'name': '沙色',
    'hex': '#E3C17F',
    'id': '19'
  },
  {
    'name': '浅黄',
    // 'hex': '#FFEE7D',
    'hex': '#FFE155',
    'id': '21'
  },
  {
    'name': '黄色',
    // 'hex': '#FDD100',
    'hex': '#FFCC00',
    'id': '22'
  },
  {
    'name': '深黄色',
    // 'hex': '#D98700',
    'hex': '#FFAA00',
    'id': '23'
  },
  {
    'name': '中橙',
    'hex': '#F39800',
    'id': '24'
  },
  {
    'name': '橙色',
    'hex': '#FF7E14',
    'id': '25'
  },
  {
    'name': '淡鲑鱼',
    'hex': '#FADBDA',
    'id': '26'
  },
  {
    'name': '亮粉',
    'hex': '#F3A9C9',
    'id': '27'
  },
  {
    'name': '深粉',
    'hex': '#DC6AA0',
    'id': '28'
  },
  {
    'name': '玫红',
    'hex': '#9C0A67',
    'id': '29'
  },
  {
    'name': '黄绿',
    'hex': '#D8E698',
    'id': '44'
  },
  {
    'name': '果绿',
    'hex': '#B7C500',
    'id': '45'
  },
  {
    'name': '草绿',
    'hex': '#00A73C',
    'id': '49'
  },
  {
    'name': '绿色',
    'hex': '#00833B',
    'id': '50'
  },
  {
    'name': '深绿',
    'hex': '#004719',
    'id': '51'
  },
  {
    'name': '军绿',
    'hex': '#15340E',
    'id': '52'
  },
  {
    'name': '肉色',
    'hex': '#C46652',
    'id': '15'
  },
  {
    'name': '红色',
    'hex': '#D92817',
    'id': '30'
  },
  {
    'name': '深红',
    'hex': '#9F0014',
    'id': '31'
  },
  {
    'name': '紫红色',
    'hex': '#663651',
    'id': '83'
  },
  {
    'name': '糖果蓝',
    'hex': '#91CDF1',
    'id': '36'
  },
  {
    'name': '天蓝',
    'hex': '#579ED7',
    'id': '37'
  },
  {
    'name': '深空蓝',
    'hex': '#2980C4',
    'id': '39'
  },
  {
    'name': '浅蓝',
    'hex': '#0086C1',
    'id': '40'
  },
  {
    'name': '蓝色',
    'hex': '#005FAD',
    'id': '41'
  },
  {
    'name': '藏蓝',
    // 'hex': '#003760',
    'hex': '#174664',
    'id': '42'
  },
  // 定制颜色
  {
    'name': '淡古灰',
    'hex': '#DACBBE',
    'id': '80'
  },
  // 定制颜色
  {
    'name': '浅古灰',
    'hex': '#CAB5A2',
    'id': '81'
  },
  // 定制颜色
  {
    'name': '复古灰',
    'hex': '#BA9E85',
    'id': '82'
  },
  {
    'name': '沙红',
    'hex': '#A87570',
    'id': '32'
  },
  {
    'name': '淡蓝',
    'hex': '#1BB8CE',
    'id': '38'
  },
  {
    'name': '青色',
    'hex': '#009E9F',
    'id': '48'
  },
  {
    'name': '沙绿',
    'hex': '#739F80',
    'id': '47'
  },
  {
    'name': '橄榄绿',
    'hex': '#878444',
    'id': '46'
  },
  {
    'name': '褐色',
    'hex': '#907445',
    'id': '20'
  },
  {
    'name': '深紫',
    'hex': '#584C9D',
    'id': '35'
  }
]
// 获取深化颜色
// eslint-disable-next-line
const getDarkenedColor = function(color) {
  // eslint-disable-next-line
  const arr = hexToRgb(color)
  const rgb = arr.map((color) => Math.round((color * Math.PI) / 4))
  // eslint-disable-next-line
  return rgbToHex(...rgb).toUpperCase()
}
const whiteBrickNum = [
  '4',
  '5',
  '6',
  '8',
  '17',
  '18',
  '20',
  '29',
  '30',
  '31',
  '34',
  '35',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '46',
  '47',
  '48',
  '49',
  '50',
  '51',
  '52'
]
// 缺失颜色：
const brickMap = {
  '#AC5017': '17',
  '#FFE840': '80',
  '#FFEE7D': '21',
  '#F9CD00': '22',
  '#E9E1DA': '81'
}
const brickfyGrayIds = ['1', '2', '3', '4', '5', '6']
// eslint-disable-next-line
const brickfyColorsIds = ['11', '12', '13', '14', '4', '5', '6', '35', '42', '52']
const classicReplace = {
  '#FEFBF5': '#DACBBE',
  '#E6E6E4': '#CAB5A2',
  '#ADADB0': '#BA9E85',
  '#838588': '#663651',
  '#616160': '#174664',
  '#212121': '#111111'
}
const goldenReplace = {
  '#FEFBF5': '#FFE155',
  '#E6E6E4': '#FFCC00',
  '#ADADB0': '#FFAA00',
  '#838588': '#B15218',
  '#616160': '#482413',
  '#212121': '#111111'
}
// eslint-disable-next-line
const colorsReplace = {
  '#FEFBF5': '#FEFBF5',
  '#D92817': '#D92817',
  '#FFCC00': '#FFCC00',
  '#00A73C': '#00A73C',
  '#0086C1': '#0086C1',
  '#111111': '#111111'
}
// eslint-disable-next-line
const pinkReplace = {
  '#FEFBF5': '#FEFBF5',
  '#E6E6E4': '#FFE155',
  '#ADADB0': '#FFCC00',
  '#838588': '#DC6AA0',
  '#616160': '#9C0A67',
  '#212121': '#111111'
}
// eslint-disable-next-line
const danceReplace = {
  '#FEFBF5': '#FEFBF5',
  '#E6E6E4': '#FFE155',
  '#ADADB0': '#FFCC00',
  '#838588': '#DC6AA0',
  '#616160': '#005FAD',
  '#212121': '#111111'
}
const brickfyGray = {}
const brickfyColors = {}
const brickfy = {}
const brickfyArr = []
const darkenedBrickfy = {}
Object.values(ALL_COLORS).map((color) => {
  color.hex = color.hex.toUpperCase()
  brickfyArr.push(color.hex)
  brickfy[color.id] = color.hex
  darkenedBrickfy[color.id] = getDarkenedColor(color.hex)
  if (brickfyGrayIds.indexOf(color.id) > -1) brickfyGray[color.id] = color.hex
  brickfyColors[color.id] = color.hex
})
export default {
  names: {
    base: '主题色',
    brickfy: '拼图色',
    // brickfyColors: '多彩',
    skin: '肤色',
    gray: '灰色'
    // metals: '金属'
  },
  colorfy: {
    // colors: { name: '多彩', palette: colorsReplace },
    dance: { name: '跳动', palette: danceReplace },
    classic: { name: '经典', palette: classicReplace },
    // pink: { name: '粉红', palette: pinkReplace },
    golden: { name: '金黄', palette: goldenReplace }
  },
  frames: frames,
  base: ['#000000', '#ffffff', '#eeece1', '#e2534d', '#f9974c', '#ffc12a', '#9aba60', '#47acc5', '#1e497b', '#956FE7', '#808080', '#f2f2f2', '#ddd8c3', '#f2dbdb', '#fee9da', '#fff2cc', '#eaf1de', '#d9eef3', '#c6d9f0', '#f0edf6', '#696969', '#dbdbdb', '#b6b29f', '#d5b6b6', '#e9c9b1', '#e5d4a3', '#cad4ba', '#b3d0d7', '#a1b4cc', '#d0c3dc', '#525252', '#c4c4c4', '#8f8c7b', '#b89291', '#d5a988', '#cbb77a', '#abb796', '#8eb2bb', '#7c90a8', '#b099c3', '#3b3b3b', '#adadad', '#696657', '#9c6e6c', '#c18960', '#b29a51', '#8b9a73', '#68949f', '#576c84', '#906faa', '#242424', '#969696', '#424033', '#7f4a47', '#ad6937', '#987d28', '#6c7d4f', '#437683', '#324860', '#704591'],
  brickfy: brickfy,
  brickfyArr: brickfyArr,
  darkenedBrickfy: darkenedBrickfy,
  brickfyGray: brickfyGray,
  // brickfyColors: brickfyColors,
  skin: ['#ffe0bd', '#ffdbac', '#ffcd94', '#eac086', '#e0ac69', '#f1c27d', '#ffad60', '#c68642', '#8d5524', '#765339', '#613D24', '#4C2D17', '#391E0B', '#2D1304', '#180A01', '#090300'],
  gray: ['#F0F0F0', '#E0E0E0', '#D0D0D0', '#C0C0C0', '#B0B0B0', '#A0A0A0', '#909090', '#808080', '#707070', '#606060', '#505050', '#404040', '#303030', '#202020', '#101010', '#000000'],
  metals: ['#000000', '#996614', '#946D0A', '#A5790B', '#B8870C', '#BF9325', '#C69E3B', '#784F11', '#848A99', '#848588', '#939598', '#A4A6AA', '#ADAFB2', '#B5B7B9', '#595D66', '#6B3805', '#643A00', '#704100', '#7D4900', '#8A5B1A', '#956B31', '#4D2804', '#4D4D4D', '#6A6A68', '#767674', '#848482', '#90908E', '#9B9B99', '#363636', '#B36452', '#924F39', '#A3583F', '#B56246', '#BD7259', '#C4806A'],
  colors: ['#D5B69F', '#E3D3C5', '#FFFBF5', '#653350', '#144463', '#151515'],
  brickMap: brickMap,
  whiteBrickNum: whiteBrickNum
}
