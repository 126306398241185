import conf from '@/js/conf/conf'
import PullTo from 'vue-pull-to'
import emptyImage from '@/assets/empty.png'
import lockImage from '@/assets/lock.png'
import utils from '@/js/utils'
import service from '@/js/service'
export const mixins = {
  components: {
    PullTo
  },
  props: {
    view: {
      type: Object,
      default() {
        return {}
      }
    },
    state: {
      type: Object,
      default() {
        return {}
      }
    },
    db: {
      type: Object,
      default() {
        return {}
      }
    },
    file: {
      type: Object,
      default() {
        return {}
      }
    },
    mod: {
      type: String,
      default() {
        return 'new'
      }
    },
    params: {
      type: Object,
      default() {
        return {}
      }
    },
    thumbSize: {
      type: Number,
      default: 120
    },
    pageSize: {
      type: Number,
      default: 24
    },
    loginStatus: {
      type: Boolean,
      default: false
    },
    loginUserId: {
      type: Number,
      default: 0
    },
    subtractHeight: {
      type: Number,
      default: 0
    }
  },
  data() {
    const catalogs = utils.deepClone(conf.catalogs)
    const schema = {
      scrollTop: 0,
      // 瀑布流各列数据
      columns: [],
      item_types: [],
      items: [],
      active: 0,
      currentPage: 0,
      cursorValue: 0,
      cursorValueUp: 0,
      cursorValueDown: 0,
      cursorSkipUp: 0,
      cursorSkipDown: 0,
      skip: 0,
      noUpPage: false,
      noDownPage: false
    }
    let container_width, container_height
    if (this.state.platform.type === 'desktop') {
      container_width = 632
      container_height = 632
    } else {
      container_width = utils.width()
      container_height = utils.height()
    }
    const data = {
      tradeState: {},
      worksHost: conf.hosts().worksHost,
      container_width: container_width,
      container_height: container_height,
      width: container_width - 3,
      height: container_height - 95,
      renew: false,
      fixHeight: 40,
      // 达到保留页数时，重新渲染列表
      remainPages: 100,
      showUp: false,
      userid: 0,
      albumid: 0,
      emptyImage: emptyImage,
      lockImage: lockImage,
      catalogs: catalogs,
      schema: schema,
      dt: {
        'all': utils.deepClone(schema),
        'bag': utils.deepClone(schema),
        'store': utils.deepClone(schema),
        'hall': utils.deepClone(schema)
      },
      loading: {
        'all': false,
        'bag': false,
        'store': false,
        'hall': false
      }
    }
    return data
  },
  computed: {
    isMobile() {
      return this.state.platform.type === 'mobile'
    },
    reqId() {
      return this.state.reqId
    },
    maxColumns() {
      return Math.max(Math.floor(this.width / (this.thumbSize + 10)), 1)
    },
    columnWidth() {
      return Math.round(this.width / this.maxColumns)
    },
    cardWidth() {
      return this.columnWidth - 10
    },
    // 瀑布流高度
    flowHeight() {
      let height = 0
      for (let i = 0; i < this.columns.length; i++) {
        const its = this.columns[i].items
        const colHeight = its[its.length - 1]._height + its[its.length - 1]._top
        if (colHeight > height) height = colHeight
      }
      return height
    },
    headHeight() {
      return this.config.showHead ? 40 : 0
    },
    listStyle() {
      if (this.subtractHeight) return { 'height': 'calc(100% - ' + this.subtractHeight + 'px)', 'margin-top': '5px' }
      return { 'height': 'calc(100% - ' + (45 + this.headHeight) + 'px)', 'margin-top': (45 + this.headHeight) + 'px' }
    },
    config() {
      return this.view.items
    },
    users() {
      return this.state.users
    },
    data() {
      return this.dt[this.mod]
    },
    itemNums() {
      return this.data.items.length
    },
    columns() {
      return this.data ? this.data.columns : []
    },
    limit() {
      let limit = this.pageSize
      if (!this.isMobile) limit = limit * 2
      return limit
    }
  },
  watch: {
    // 'view.items.show': {
    //   handler(show) {
    //     if (this.config.type === this.mod && show) {
    //       this.getTradeState()
    //       if (!this.itemNums) this.getData('down')
    //       this.update()
    //     }
    //   }
    // },
    'view.items.type': {
      handler() {
        if (this.config.type === this.mod && this.config.show) {
          if (!this.config.pop) this.emit('setLocationHash')
          if (!this.itemNums) this.getData('down')
        }
      }
    },
    'view.items.renew': {
      handler() {
        if (this.config.type === this.mod && this.config.show) this.syncQuantity()
      }
    }
    // ,
    // 'data.scrollTop': {
    //   handler() {
    //     if (this.view.items.show && this.mod === 'items') {
    //       this.emit('setScrollTop', [this.data.scrollTop])
    //     }
    //   }
    // }
  },
  mounted() {
    this.calcSize()
    const container = this.scrollContainer()
    if (container) container.onscroll = () => { this.data.scrollTop = container.scrollTop }
    window.addEventListener('resize', this.onResize)
    if (this.config.type === this.mod && this.config.show && !this.itemNums) {
      this.getData('down')
      this.getTradeState()
    }
  },
  methods: {
    // 同步数量
    syncQuantity() {
      const decItems = this.state.decItems
      const ids = []
      for (var id in decItems) {
        ids.push(parseInt(id))
      }
      if (ids.length) {
        for (var i in this.data.items) {
          if (ids.indexOf(this.data.items[i].itemid) >= 0) {
            this.data.items[i].quantity = this.data.items[i].quantity - decItems[this.data.items[i].itemid]
            if (this.data.items[i].quantity <= 0) {
              this.data.items.splice(i, 1)
            }
          }
        }
        this.renderItems(this.data.items, true)
      }
    },
    // 移除作品
    removeItem(id) {
      for (var i in this.data.items) {
        if (this.data.items[i].itemid === id) {
          this.data.items.splice(i, 1)
        }
      }
      this.renderItems(this.data.items, true)
    },
    // 设置属性
    setItem(itemid, key, value) {
      for (var i in this.data.items) {
        if (this.data.items[i].itemid === itemid) {
          this.data.items[i][key] = value
          return this.data.items[i]
        }
      }
    },
    scrollContainer() {
      const main = document.getElementById('items-' + this.mod + '-container')
      if (main) {
        const scroll = main.getElementsByClassName('scroll-container')
        if (scroll.length) return scroll[0]
      }
    },
    onResize() {
      if (!this.config.show) return
      this.calcSize()
      // this.backTop()
      // this.data.scrollTop = 0
      this.renderItems(this.data.items, true)
    },
    backTop() {
      const container = this.scrollContainer()
      if (container) {
        setTimeout(() => {
          container.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
        }, 100)
      }
    },
    renewView() {
      this.backTop()
      this.loadData(() => {}, 'renew')
    },
    showItem(item) {
      if (item._top <= this.data.scrollTop - this.height && item._top + item._height >= this.data.scrollTop - this.height) return true
      if (item._top >= this.data.scrollTop - this.height && item._top <= this.data.scrollTop + this.height + this.height) return true
      return false
    },
    formateTime: utils.formateTime,
    formateNums: utils.formateNums,
    // 调用父组件方法
    emit(fn, params) {
      if (!fn) return
      this.$emit('receive', fn, params || [])
    },
    // 提示信息
    message(msg, type) {
      this.emit('message', [msg, type])
    },
    // 确认操作
    confirm(msg, fn, title) {
      this.emit('confirm', [msg, fn, title])
    },
    getMinHeight(arr) {
      const a = []
      for (let i = 0; i < arr.length; i++) {
        a.push(parseInt(arr[i]._height) + parseInt(arr[i]._top))
      }
      return Math.min.apply(null, a)
    },
    getMinIndex(val) {
      const arrIndex = []
      for (let i = 0; i < this.columns.length; i++) {
        const its = this.columns[i].items
        const height = its[its.length - 1]._height
        const top = its[its.length - 1]._top
        if (parseInt(height) + parseInt(top) === val) {
          arrIndex.push(i)
        }
      }
      return arrIndex
    },
    typeDescription() {
      for (var i in this.config.types) {
        if (this.config.types[i][0] === this.mod) return '你还没有' + this.config.types[i][1] + '呢'
      }
      return ''
    },
    renderItems(items, refresh = false) {
      if (refresh) this.data.columns = []
      if (!items.length) return
      // 初始化列、列的第一个元素
      let idx = 0
      if (this.columns.length < this.maxColumns || refresh) {
        for (let i = 0; i < this.maxColumns; i++) {
          if (!this.columns[i] && items[idx]) {
            items[idx]._top = 0
            items[idx]._raw_width = this.cardWidth
            items[idx]._raw_height = this.cardWidth
            items[idx]._height = items[idx]._raw_height + this.fixHeight
            this.columns.push({ items: [items[idx]] })
            idx++
          }
        }
      }
      // 对剩余元素的判断，应该放到哪一列
      for (var index = idx; index < items.length; index++) {
        // 找到高度最小的一列，可能有多个
        const tmp = []
        for (let i = 0; i < this.columns.length; i++) {
          const its = this.columns[i].items
          tmp.push({
            _height: its[its.length - 1]._height,
            _top: its[its.length - 1]._top
          })
        }
        // 获取最矮的列高度
        const minHeight = this.getMinHeight(tmp)
        // 获取最矮列的索引
        let minHeightIdx = 0
        const arrIndex = this.getMinIndex(minHeight)
        // 出现高度一样的，取索引最小的
        if (arrIndex.length > 0) {
          minHeightIdx = Math.min.apply(null, arrIndex)
        }
        // 设置属性
        items[index]._top = minHeight
        items[index]._raw_width = this.cardWidth
        items[index]._raw_height = this.cardWidth
        items[index]._height = items[index]._raw_height + this.fixHeight
        this.columns[minHeightIdx].items.push(items[index])
      }
    },
    calcSize() {
      if (this.state.platform.type === 'desktop') {
        this.container_width = 632
        this.container_height = 632
      } else {
        this.container_width = utils.width()
        this.container_height = utils.height()
      }
      this.width = this.container_width - 3
      this.height = this.container_height - 95 - this.headHeight
    },
    init(backTop) {
      if (backTop) this.backTop()
      this.dt[this.mod] = utils.deepClone(this.schema)
    },
    update() {
      this.$forceUpdate()
    },
    updateData(updateHash) {
      if (updateHash && !this.config.pop) this.emit('setLocationHash')
      if (!this.itemNums) {
        this.init()
        this.getData('down')
      } else {
        this.update()
      }
    },
    showActionSheet(item) {
      if (!item) return
      const actions = {
        show: true,
        title: this.getName(item),
        btns: []
      }
      // TODO: 转移
      const item_type = this.data.item_types[item.item_typeid] || {}
      if (item_type.break) {
        actions.btns.push({ title: '打开', cb: () => { this.actionOperate('open', item) } })
      }
      if (item_type.discard) {
        actions.btns.push({ title: '丢弃', cb: () => { this.actionOperate('discard', item) } })
      }
      if (item.donate && item_type.donate) {
        const time = utils.time('time')
        const donateLockSeconds = item.donate_open_at - time
        if (donateLockSeconds > 0) {
          actions.btns.push({ title: '转赠(' + utils.getCountDown(donateLockSeconds) + '后)', cb: () => {
            this.message(utils.getCountDown(donateLockSeconds) + '后可转赠', 'error')
          } })
        } else {
          actions.btns.push({ title: '转赠', cb: () => { this.itemOperate('donate', item, item_type) } })
        }
      }
      actions.btns.push({ title: '查看', cb: () => { this.itemOperate('view', item, item_type) } })
      this.view.actionSheet = actions
    },
    // 查看物品
    viewItem(item) {
      if (!item) return
      const item_type = this.data.item_types[item.item_typeid] || {}
      this.itemOperate('view', item, item_type)
    },
    // 物品操作
    itemOperate(act, item, item_type) {
      if (!act) return
      if (act === 'donate' && this.tradeState.status !== 4) {
        if (this.tradeState.status === 3) {
          this.emit('alert', ['请先设置交易密码', () => { this.emit('goto', ['account']) }])
        } else if (this.tradeState.status === 1 || this.tradeState.status === 0 || this.tradeState.status === -1) {
          this.emit('alert', ['请先通过实名认证', () => { this.emit('goto', ['account']) }])
        } else {
          this.message('请重新登录', 'login')
        }
        return
      }
      this.view.itemOperate.data = { itemid: item.itemid, item: item, item_type: item_type }
      this.view.itemOperate.type = act
      this.view.itemOperate.show = true
      if (act === 'view') {
        this.view.itemOperate.fn = () => {
          this.actionOperate('open', item)
        }
      } else {
        this.view.itemOperate.fn = null
      }
    },
    // 数据操作
    actionOperate(act, item, nums = 1) {
      if (!act || !item || !nums) return
      const params = {
        type: act,
        id: item.itemid,
        nums: nums
      }
      const acts = {
        'open': '打开',
        'discard': '丢弃'
      }
      service.actionGet('item', params, (dt, type) => {
        if (type === 'success') {
          if (act === 'open') {
            const gets = []
            for (const i in dt.data) {
              gets.push(dt.data[i].name + ' x' + dt.data[i].nums)
            }
            const msg = gets.length ? gets.join('\n') : '已打开'
            this.emit('alert', [msg, () => {
              this.renewView()
              this.view.items.renew = utils.time('time')
            }, gets.length ? '获得' : ''])
            this.emit('traceEvent', ['openItem'])
          } else {
            this.message(acts[act] ? (acts[act] + this.getName(item) + ' x' + nums) : '操作成功', type)
            item.quantity--
            this.update()
          }
        } else {
          this.message(dt, type)
        }
      }, true)
    },
    loadData(loaded, direction) {
      if (this.config.show) {
        this.renew = direction === 'renew'
        if (direction === 'renew') {
          direction = 'down'
          this.data.currentPage = 0
          this.data.cursorValue = 0
          this.data.cursorValueUp = 0
          this.data.cursorValueDown = 0
          this.data.cursorSkipUp = 0
          this.data.cursorSkipDown = 0
          this.data.skip = 0
          this.data.noUpPage = false
          this.data.noDownPage = false
        }
        this.getData(direction, () => {
          this.renew = false
          loaded('done')
        })
      }
    },
    getClass(level) {
      level = parseInt(level)
      if (level > 6) level = 6
      const colors = ['gray', 'white', 'green', 'blue', 'purple', 'yellow', 'red']
      const cls = {}
      cls[colors[level] || 'gray'] = true
      return cls
    },
    getName(item) {
      if (item.name) return item.name
      if (this.data.item_types[item.item_typeid].name) return this.data.item_types[item.item_typeid].name
    },
    getThumbName(item) {
      if (item.name) return item.name.slice(0, 1)
      if (this.data.item_types[item.item_typeid].name) return this.data.item_types[item.item_typeid].name.slice(0, 1)
    },
    getThumb(item_type) {
      const ver = Math.round(new Date(item_type.update_at) / 1000)
      const url = item_type.thumb ? item_type.thumb : 'cos://' + item_type.item_typeid + '.png'
      return service.getCosUrl(url, 'item_type', ver) || ''
    },
    // 分页读取数据
    async getData(direction, cb) {
      direction = direction || 'down'
      if (!this.config.show) return cb && cb()
      if (direction === 'up' && this.data.noUpPage) return cb && cb()
      if (direction === 'down' && this.data.noDownPage) return cb && cb()
      if (this.loading[this.mod]) return cb && cb()
      this.loading[this.mod] = true
      const params = {
        'sort_field': 'itemid',
        'sort': 'desc',
        'limit': this.limit,
        'skip': direction === 'up' ? this.data.cursorSkipUp : this.data.cursorSkipDown,
        'cursor_value': direction === 'up' ? this.data.cursorValueUp : this.data.cursorValueDown,
        'direction': direction,
        'count_total': 1
      }
      if (this.mod) {
        params.mod = this.mod
      } else {
        params.parentid = 0
      }
      params.userid = this.loginUserId
      // console.error('params', this.mod, params, direction, this.data.currentPage, this.data, direction === 'up', this.data.cursorValueUp, this.data.cursorValueDown)
      service.listGet('item', params, (res, type) => {
        if (type === 'success') {
          if (res.data.active) {
            let refresh = false
            if (direction === 'up') {
              refresh = true
              this.data.cursorValueUp = res.data.cursor_value_up
              this.data.cursorSkipUp = res.data.cursor_skip_up
              if (!this.data.cursorValueDown && !utils.isEmpty(res.data.cursor_value_down)) this.data.cursorValueDown = res.data.cursor_value_down
              this.data.noUpPage = utils.isEmpty(this.data.cursorValueUp)
              this.data.items = res.data.items.concat(this.data.items)
            } else if (direction === 'down') {
              if ((res.data.count === this.limit && this.data.currentPage >= this.remainPages) || this.renew) {
                if (res.data.count === this.limit && this.data.currentPage >= this.remainPages) {
                  this.init(true)
                  this.showUp = true
                } else {
                  this.init()
                }
                refresh = true
              }
              this.data.currentPage++
              if (!this.data.cursorValueUp && !utils.isEmpty(res.data.cursor_value_up)) this.data.cursorValueUp = res.data.cursor_value_up
              this.data.cursorValueDown = res.data.cursor_value_down
              this.data.cursorSkipDown = res.data.cursor_skip_down
              this.data.noDownPage = utils.isEmpty(this.data.cursorValueDown)
              this.data.items = this.data.items.concat(res.data.items)
            }
            if (res.data.item_types) {
              for (var item_typeid in res.data.item_types) {
                this.data.item_types[item_typeid] = res.data.item_types[item_typeid]
              }
            }
            if (res.data.users) {
              for (var userid in res.data.users) {
                this.users[userid] = res.data.users[userid]
              }
            }
            this.data.skip = utils.getInt(res.data.skip)
            this.data.active = res.data.active || 0
            // this.emit('setTotal', [res.data.total])
            if (direction === 'up') {
              this.renderItems(this.data.items, true)
            } else {
              this.renderItems(res.data.items, refresh)
            }
          }
        } else {
          this.message(res, type)
        }
        cb && cb()
        this.loading[this.mod] = false
      }, false, false)
    },
    getTradeState() {
      if (this.tradeState.userid === this.loginUserId) return
      this.emit('getTradeState', [this.loginUserId, '', (ret) => {
        this.tradeState = ret || {}
      }])
    }
  }
}
