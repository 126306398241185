/**
 * @author funfly
 * @mail 389193@qq.com
 * @date 2022-1-29
 * @copyright Gridy.Art
 */
import Dexie from 'dexie'
export class Db extends Dexie {
  constructor() {
    super('database')
    this.version(22).stores({
      cache: '++id,key,expireTime',
      count: '++id,[userid+key],updateTime',
      files: '++id,fileId,type,name,del,delTime,updateTime,openTime',
      acts: '++id,fileId'
    })
  }
  // 分页获取数据
  async pageGet(table, where, order, page, pageSize) {
    table = table || 'files'
    order = order || 'id'
    page = page || 1
    pageSize = pageSize || 20
    const offset = (parseInt(page) - 1) * parseInt(pageSize)
    const whereArr = []
    if (where) {
      Object.keys(where).map((k) => {
        whereArr.push('item.' + k + ' === "' + where[k] + '"')
      })
    }
    if (whereArr.length) {
      return await this[table].orderBy(order).filter((item) => {
        // eslint-disable-next-line
        return eval(whereArr.join(' && '))
      }).reverse().offset(offset).limit(pageSize).toArray()
    } else {
      return await this[table].orderBy(order).reverse().offset(offset).limit(pageSize).toArray()
    }
  }
  // 统计条数
  async totalCount(table, where) {
    table = table || 'files'
    if (where && Object.keys(where).length) {
      return await this[table].where(where).count()
    } else {
      return await this[table].count()
    }
  }
  // 批量获取数据
  async batchGet(table, where, order) {
    table = table || 'files'
    order = order || 'id'
    const whereArr = []
    if (where) {
      Object.keys(where).map((k) => {
        whereArr.push('item.' + k + ' === "' + where[k] + '"')
      })
    }
    if (whereArr.length) {
      return await this[table].orderBy(order).filter(function(item) {
        // eslint-disable-next-line
        return eval(whereArr.join(' && '))
      }).toArray()
    } else {
      return await this[table].orderBy(order).toArray()
    }
  }
  // 获取上一条
  async getPreview(table, where, id) {
    table = table || 'files'
    const whereArr = []
    if (where) {
      Object.keys(where).map((k) => {
        whereArr.push('item.' + k + ' === "' + where[k] + '"')
      })
    }
    if (id) {
      whereArr.push('item.id <= ' + id)
    }
    if (whereArr.length) {
      return await this[table].orderBy('id').filter((item) => {
        // eslint-disable-next-line
        return eval(whereArr.join(' && '))
      }).reverse().limit(2).toArray()
    } else {
      return await this[table].orderBy('id').reverse().limit(2).toArray()
    }
  }
  // 获取下一条
  async getNext(table, where, id) {
    table = table || 'files'
    const whereArr = []
    if (where) {
      Object.keys(where).map((k) => {
        whereArr.push('item.' + k + ' === "' + where[k] + '"')
      })
    }
    if (id) {
      whereArr.push('item.id >= ' + id)
    }
    if (whereArr.length) {
      return await this[table].orderBy('id').filter((item) => {
        // eslint-disable-next-line
        return eval(whereArr.join(' && '))
      }).limit(2).toArray()
    } else {
      return await this[table].orderBy('id').limit(2).toArray()
    }
  }
  // 获取下N条
  async getNextAll(table, where, id) {
    table = table || 'files'
    const whereArr = []
    if (where) {
      Object.keys(where).map((k) => {
        whereArr.push('item.' + k + ' === "' + where[k] + '"')
      })
    }
    if (id) {
      whereArr.push('item.id > ' + id)
    }
    if (whereArr.length) {
      return await this[table].orderBy('id').filter((item) => {
        // eslint-disable-next-line
        return eval(whereArr.join(' && '))
      }).toArray()
    } else {
      return await this[table].orderBy('id').toArray()
    }
  }
  // 获取数据
  async get(table, where) {
    table = table || 'files'
    if (where && Object.keys(where).length) {
      return await this[table].get(where)
    }
  }
  // 保存数据（已创建的可直接更新）
  async save(table, data, where) {
    if (where && Object.keys(where).length) {
      if (await this[table].where(where).count()) {
        return this[table].where(where).modify(data)
      }
    }
    return this[table].add(data)
  }
  // 添加数据
  add(table, data) {
    return this[table].add(data)
  }
  // 逻辑删除
  del(table, where) {
    if (where && Object.keys(where).length) {
      return this[table].where(where).modify({ del: '1', delTime: new Date() })
    }
  }
  // 恢复
  restore(table, where) {
    if (where && Object.keys(where).length) {
      return this[table].where(where).modify({ del: '0' })
    }
  }
  // 物理删除
  delete(table, where) {
    if (where && Object.keys(where).length) {
      return this[table].where(where).delete()
    }
  }
  // 全部物理删除
  clean(table, id) {
    id = id || 0
    return this[table].where('id').above(id).delete()
  }
  // 清空回收站
  clearRecycle(table) {
    return this[table].where({ del: '1' }).delete()
  }
  // 更新数据
  update(table, id, data) {
    return this[table].update(id, data)
  }
}
