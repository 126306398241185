import conf from '@/js/conf/conf'
import PullTo from 'vue-pull-to'
import emptyImage from '@/assets/empty.png'
import blankImage from '@/assets/blank.png'
import utils from '@/js/utils'
import service from '@/js/service'
export const mixins = {
  components: {
    PullTo
  },
  props: {
    view: {
      type: Object,
      default() {
        return {}
      }
    },
    state: {
      type: Object,
      default() {
        return {}
      }
    },
    db: {
      type: Object,
      default() {
        return {}
      }
    },
    file: {
      type: Object,
      default() {
        return {}
      }
    },
    mod: {
      type: String,
      default() {
        return 'new'
      }
    },
    params: {
      type: Object,
      default() {
        return {}
      }
    },
    thumbSize: {
      type: Number,
      default: 380
    },
    pageSize: {
      type: Number,
      default: 10
    },
    maxPage: {
      type: Number,
      default: 0
    },
    loginStatus: {
      type: Boolean,
      default: false
    },
    loginUserId: {
      type: Number,
      default: 0
    },
    subtractHeight: {
      type: Number,
      default: 0
    },
    topPull: {
      type: Boolean,
      default: true
    },
    bottomPull: {
      type: Boolean,
      default: true
    },
    isPop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const catalogs = utils.deepClone(conf.catalogs)
    const schema = {
      scrollTop: 0,
      threadid: 0,
      // 瀑布流各列数据
      columns: [],
      items: [],
      currentPage: 0,
      cursorValue: 0,
      cursorValueUp: 0,
      cursorValueDown: 0,
      cursorSkipUp: 0,
      cursorSkipDown: 0,
      skip: 0,
      noUpPage: false,
      noDownPage: false
    }
    let container_width, container_height
    if (this.state.platform.type === 'desktop' && this.isPop) {
      container_width = 632
      container_height = 632
    } else {
      container_width = utils.width()
      container_height = utils.height()
    }
    const data = {
      blankImage: blankImage,
      worksHost: conf.hosts().worksHost,
      mainHost: conf.hosts().mainHost,
      posts: {},
      works: {},
      toms: {},
      attachments: {},
      // 可用宽度
      container_width: container_width,
      container_height: container_height,
      width: container_width - 3,
      height: container_height - 95,
      fixHeight: 120,
      docsHeight: 40,
      maxThumbHeight: 356,
      // 达到保留页数时，重新渲染列表
      remainPages: 100,
      showUp: false,
      userid: 0,
      emptyImage: emptyImage,
      catalogs: catalogs,
      schema: schema,
      dt: {
        'new': utils.deepClone(schema),
        'best': utils.deepClone(schema),
        'thread': utils.deepClone(schema),
        'user': utils.deepClone(schema),
        'praise': utils.deepClone(schema),
        'favor': utils.deepClone(schema),
        'footprint': utils.deepClone(schema),
        'discover': utils.deepClone(schema),
        'index': utils.deepClone(schema),
        'topic': utils.deepClone(schema)
      },
      loading: {
        'new': false,
        'best': false,
        'thread': false,
        'user': false,
        'praise': false,
        'favor': false,
        'footprint': false,
        'discover': false,
        'index': false,
        'topic': false
      }
    }
    return data
  },
  computed: {
    isDesktop() {
      return this.state.platform.type === 'desktop'
    },
    isMobile() {
      return this.state.platform.type === 'mobile'
    },
    reqId() {
      return this.state.reqId
    },
    maxColumns() {
      return Math.max(Math.floor(this.width / (this.thumbSize + 10)), 1)
    },
    columnWidth() {
      return Math.round(this.width / this.maxColumns)
    },
    cardWidth() {
      return this.columnWidth - 10
    },
    imageSize() {
      return (this.cardWidth - 20) / 3
    },
    // 瀑布流高度
    flowHeight() {
      let height = 0
      for (let i = 0; i < this.columns.length; i++) {
        const its = this.columns[i].items
        const colHeight = its[its.length - 1]._height + its[its.length - 1]._top
        if (colHeight > height) height = colHeight
      }
      return height
    },
    headHeight() {
      return (!this.isDesktop && this.config.showHead) ? 40 : 0
    },
    listStyle() {
      if (this.subtractHeight) return { 'height': 'calc(100% - ' + this.subtractHeight + 'px)', 'margin-top': '5px' }
      return { 'height': 'calc(100% - ' + (95 + this.headHeight) + 'px)', 'margin-top': (45 + this.headHeight) + 'px' }
    },
    config() {
      if (this.mod === 'thread') return this.view.thread
      return this.view.zone
    },
    viewMod() {
      return this.view.mod
    },
    users() {
      return this.state.users || {}
    },
    data() {
      return this.dt[this.mod]
    },
    itemNums() {
      return this.data.items.length
    },
    columns() {
      return this.data ? this.data.columns : []
    },
    threadCount() {
      return this.state.threadCount || {}
    },
    followState() {
      return this.state.followState || {}
    },
    limit() {
      let limit = this.pageSize
      if (!this.isMobile) limit = limit * 2
      return limit
    }
  },
  watch: {
    'view.praise.show': {
      handler() {
        if (this.view.praise.show && this.mod === 'praise' && this.view.praise.type === 'thread') this.updateData()
      }
    },
    'view.praise.type': {
      handler() {
        if (this.view.praise.show && this.mod === 'praise' && this.view.praise.type === 'thread') this.updateData(true)
      }
    },
    'view.favor.show': {
      handler() {
        if (this.view.favor.show && this.mod === 'favor' && this.view.favor.type === 'thread') this.updateData()
      }
    },
    'view.favor.type': {
      handler() {
        if (this.view.favor.show && this.mod === 'favor' && this.view.favor.type === 'thread') this.updateData(true)
      }
    },
    'view.footprint.show': {
      handler() {
        if (this.view.footprint.show && this.mod === 'footprint' && this.view.footprint.type === 'thread') this.updateData()
      }
    },
    'view.footprint.type': {
      handler() {
        if (this.view.footprint.show && this.mod === 'footprint' && this.view.footprint.type === 'thread') this.updateData(true)
      }
    },
    'view.topicDetail.show': {
      handler() {
        if (this.view.topicDetail.show && this.mod === 'topic') this.updateData(true)
      }
    },
    'view.topicDetail.topicid': {
      handler() {
        if (this.mod === 'topic') this.init()
      }
    },
    'view.index.show': {
      handler() {
        if (this.view.index.show && this.mod === 'index') this.updateData()
      }
    },
    'view.discover.show': {
      handler() {
        if (this.view.discover.show && this.mod === 'discover') this.updateData()
      }
    },
    'view.user.show': {
      handler() {
        if (this.view.user.show && this.mod === 'user' && this.view.user.type === 'thread') {
          if ((this.view.user.userid && this.userid !== this.view.user.userid) || !this.itemNums) {
            if (this.userid !== this.view.user.userid) this.init()
            this.getData('down')
          }
          this.update()
          this.deleteItems()
        }
      }
    },
    'view.user.type': {
      handler() {
        if (this.view.user.show && this.mod === 'user' && this.view.user.type === 'thread') {
          if (!this.view.user.pop) this.emit('setLocationHash')
          if ((this.view.user.userid && this.userid !== this.view.user.userid) || !this.itemNums) {
            if (this.userid !== this.view.user.userid) this.init()
            this.getData('down')
          }
          this.update()
        }
      }
    },
    'view.thread.show': {
      handler(show) {
        if (this.view.thread.type === this.mod && show) {
          this.update()
          this.deleteItems()
        }
        if (this.view.thread.type === this.mod && show && (this.data.threadid !== this.view.thread.threadid || !this.itemNums)) {
          if (this.data.threadid !== this.view.thread.threadid) this.init()
          this.getData('down')
        }
      }
    },
    'view.zone.show': {
      handler(show) {
        if (this.view.zone.type === this.mod && show) {
          this.update()
          if (!this.itemNums) this.getData('down')
          this.deleteItems()
        }
      }
    },
    'view.zone.type': {
      handler() {
        if (this.view.zone.type === this.mod && this.view.zone.show) {
          if (!this.view.zone.pop) this.emit('setLocationHash')
          if (!this.itemNums) this.getData('down')
        }
      }
    },
    'view.zone.sort': {
      handler() {
        if (this.view.zone.type === this.mod && this.view.zone.show) {
          this.init()
          if (!this.view.zone.pop) this.emit('setLocationHash')
          if (!this.itemNums) this.getData('down')
        }
      }
    },
    'view.zone.sortType': {
      handler() {
        if (this.view.zone.type === this.mod && this.view.zone.show) {
          this.init()
          if (!this.view.zone.pop) this.emit('setLocationHash')
          if (!this.itemNums) this.getData('down')
        }
      }
    },
    'view.zone.catalogid': {
      handler() {
        if (this.view.zone.type === this.mod && this.view.zone.show) {
          this.init()
          if (!this.view.zone.pop) this.emit('setLocationHash')
          if (!this.itemNums) this.getData('down')
        }
      }
    },
    'view.zone.renew': {
      handler() {
        if (this.view.zone.type === this.mod && this.view.zone.show) {
          this.renewView()
        }
      }
    },
    'data.scrollTop': {
      handler() {
        if (this.view.user.show && this.mod === 'user' && this.view.user.type === 'thread') {
          this.emit('setScrollTop', [this.data.scrollTop])
        }
      }
    }
  },
  mounted() {
    this.calcSize()
    const container = this.scrollContainer()
    if (container) container.onscroll = () => { this.data.scrollTop = container.scrollTop }
    window.addEventListener('resize', this.onResize)
    if (this.config.show && !this.itemNums) return this.getData('down')
  },
  methods: {
    viewImages(list, i, item) {
      let name = item.title || this.getContent(item, ' ', true)
      name = utils.removeEmoji(name)
      this.emit('openGridyViewer', [{ data: { images: list, name: name, idx: i, type: 'imagesViewer' }}])
    },
    downDoc(item) {
      const url = this.getAttachment(item)
      utils.open(url)
    },
    getAttachment(item) {
      if (!item || !item.file_path) return ''
      const ver = item.updated_at ? Math.round(new Date(item.updated_at) / 1000) : ''
      let type = 'attachments'
      if (item.file_path.split('/paint/').length > 1) type = 'paint'
      return service.getCosUrl(item.file_path.replace('public/' + type + '/', 'cos://') + item.attachment, type, ver) || ''
    },
    imageStyle(aid, imageSize) {
      if (!this.attachments[aid]) return {}
      const img = this.getAttachment(this.attachments[aid])
      return { 'width': imageSize + 'px', 'height': imageSize + 'px', 'background-color': 'var(--color-1b)', 'background-size': imageSize + 'px', 'background-image': 'url(' + img + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center' }
    },
    scrollContainer() {
      const main = document.getElementById('thread-' + this.mod + '-container')
      if (main) {
        const scroll = main.getElementsByClassName('scroll-container')
        if (scroll.length) return scroll[0]
      }
    },
    onResize() {
      if (!this.config.show && !this.view.user.show && !this.view.praise.show && !this.view.favor.show && !this.view.footprint.show && !this.view.topicDetail.show && !this.view.index.show && !this.view.discover.show) return
      this.calcSize()
      // this.backTop()
      // this.data.scrollTop = 0
      this.renderItems(this.data.items, true)
    },
    // 删除主题
    deleteItems() {
      if (this.state.deleteThreads.length) {
        const items = []
        for (var i in this.data.items) {
          if (this.state.deleteThreads.indexOf(this.data.items[i].id) < 0) items.push(this.data.items[i])
        }
        this.data.items = items
        this.state.deleteThreads = []
        this.renderItems(this.data.items, true)
      }
    },
    // 移除主题
    removeItem(id) {
      for (var i in this.data.items) {
        if (this.data.items[i].id === id) {
          this.data.items.splice(i, 1)
        }
      }
      this.renderItems(this.data.items, true)
    },
    // 设置主题属性
    setItem(thread_id, key, value) {
      for (var i in this.data.items) {
        if (this.data.items[i].id === thread_id) {
          this.data.items[i][key] = value
          return this.data.items[i]
        }
      }
    },
    // 获取主题
    getItem(thread_id) {
      for (var i in this.data.items) {
        if (this.data.items[i].id === thread_id) {
          return this.data.items[i]
        }
      }
    },
    backTop() {
      const container = this.scrollContainer()
      if (container) {
        setTimeout(() => {
          container.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
        }, 100)
      }
    },
    renewView() {
      this.backTop()
      this.loadData(() => {}, 'renew')
    },
    // 判断是否显示
    showItem(item) {
      if (item._top <= this.data.scrollTop - this.height && item._top + item._height >= this.data.scrollTop - this.height) return true
      if (item._top >= this.data.scrollTop - this.height && item._top <= this.data.scrollTop + this.height + this.height) return true
      return false
    },
    formateTime: utils.formateTime,
    formateNums: utils.formateNums,
    formateBytes: utils.formateBytes,
    // 调用父组件方法
    emit(fn, params) {
      if (!fn) return
      this.$emit('receive', fn, params || [])
    },
    // 提示信息
    message(msg, type) {
      this.emit('message', [msg, type])
    },
    // 确认操作
    confirm(msg, fn, title) {
      this.emit('confirm', [msg, fn, title])
    },
    getMinHeight(arr) {
      const a = []
      for (let i = 0; i < arr.length; i++) {
        a.push(parseInt(arr[i]._height) + parseInt(arr[i]._top))
      }
      return Math.min.apply(null, a)
    },
    getMinIndex(val) {
      const arrIndex = []
      for (let i = 0; i < this.columns.length; i++) {
        const its = this.columns[i].items
        const height = its[its.length - 1]._height
        const top = its[its.length - 1]._top
        if (parseInt(height) + parseInt(top) === val) {
          arrIndex.push(i)
        }
      }
      return arrIndex
    },
    getAttachments(item) {
      const images = []
      if (this.toms[item.id] && this.toms[item.id].imageIds) {
        for (var i in this.toms[item.id].imageIds) {
          const attachment = this.attachments[this.toms[item.id].imageIds[i]]
          if (attachment) {
            images.push(this.getAttachment(attachment))
          }
        }
      }
      const docs = []
      if (this.toms[item.id] && this.toms[item.id].docIds) {
        for (var x in this.toms[item.id].docIds) {
          const doc = this.attachments[this.toms[item.id].docIds[x]]
          if (doc) {
            docs.push(doc)
          }
        }
      }
      return { images: images, docs: docs }
    },
    calcHeight(item, imagesCount = 0, docsCount = 0) {
      const fontSize = 14
      const txt = this.getContent(item, '', true)
      let lines = 0
      if (txt) {
        const txtArr = txt.split('<br>')
        for (const i in txtArr) {
          if (txtArr[i]) lines = lines + Math.ceil(utils.getLen(txtArr[i]) * fontSize / 2 / (this.cardWidth - 20))
        }
      }
      if (item.is_essence) lines++
      const height = lines * 1.5 * fontSize + 25
      const maxHeight = 1.5 * fontSize * 5
      let imageSize = this.imageSize
      let imagesHeight = 0
      if (imagesCount) {
        if (imagesCount === 1) {
          imageSize = this.cardWidth - 20
        } else if (imagesCount === 2 || imagesCount === 4) {
          imageSize = (this.cardWidth - 20) / 2
        }
        if (imagesCount === 2) {
          imagesHeight = 2 * imageSize + 10
        } else if (imagesCount === 3) {
          imageSize = (this.cardWidth - 20) / 2
          imagesHeight = 2 * imageSize + 10
        } else {
          imagesHeight = Math.ceil(imagesCount / 3) * imageSize + 10
        }
      }
      let docsHeight = 0
      if (docsCount) docsHeight = docsCount * 60
      if (height > maxHeight) {
        return { 'more': true, height: maxHeight, imagesHeight: imagesHeight, docsHeight: docsHeight, imageSize: imageSize }
      } else {
        return { 'more': false, height: height, imagesHeight: imagesHeight, docsHeight: docsHeight, imageSize: imageSize }
      }
    },
    calcThumbHeight(realm, refid) {
      if (realm !== 1 || !refid || !this.works[refid]) return 0
      const width = this.works[refid].width
      const height = this.works[refid].height
      if (height >= width) return this.maxThumbHeight
      return Math.min(this.cardWidth * height / width, this.maxThumbHeight)
    },
    renderItems(items, refresh = false) {
      if (refresh) this.data.columns = []
      if (!items.length) return
      // 初始化列、列的第一个元素
      let idx = 0
      if (this.columns.length < this.maxColumns || refresh) {
        for (let i = 0; i < this.maxColumns; i++) {
          if (!this.columns[i] && items[idx]) {
            items[idx]._top = 0
            const attachments = this.getAttachments(items[idx])
            const height = this.calcHeight(items[idx], attachments.images.length, attachments.docs.length)
            items[idx]._full = false
            items[idx]._more = height.more
            items[idx]._raw_height = height.height
            items[idx]._images = attachments.images
            items[idx]._docs = attachments.docs
            items[idx]._imageSize = height.imageSize
            items[idx]._imagesHeight = height.imagesHeight
            items[idx]._docsHeight = height.docsHeight
            items[idx]._thumb_height = this.calcThumbHeight(items[idx].realm, items[idx].refid)
            items[idx]._height = height.height + height.imagesHeight + height.docsHeight + this.fixHeight + items[idx]._thumb_height
            this.columns.push({ items: [items[idx]] })
            idx++
          }
        }
      }
      // 对剩余元素的判断，应该放到哪一列
      for (var index = idx; index < items.length; index++) {
        // 找到高度最小的一列，可能有多个
        const tmp = []
        for (let i = 0; i < this.columns.length; i++) {
          const its = this.columns[i].items
          tmp.push({
            _height: its[its.length - 1]._height,
            _top: its[its.length - 1]._top
          })
        }
        // 获取最矮的列高度
        const minHeight = this.getMinHeight(tmp)
        // 获取最矮列的索引
        let minHeightIdx = 0
        const arrIndex = this.getMinIndex(minHeight)
        // 出现高度一样的，取索引最小的
        if (arrIndex.length > 0) {
          minHeightIdx = Math.min.apply(null, arrIndex)
        }
        // 设置属性
        items[index]._top = minHeight
        const attachments = this.getAttachments(items[index])
        const height = this.calcHeight(items[index], attachments.images.length, attachments.docs.length)
        items[index]._full = false
        items[index]._more = height.more
        items[index]._raw_height = height.height
        items[index]._images = attachments.images
        items[index]._docs = attachments.docs
        items[index]._imageSize = height.imageSize
        items[index]._imagesHeight = height.imagesHeight
        items[index]._docsHeight = height.docsHeight
        items[index]._thumb_height = this.calcThumbHeight(items[index].realm, items[index].refid)
        items[index]._height = height.height + height.imagesHeight + height.docsHeight + this.fixHeight + items[index]._thumb_height
        this.columns[minHeightIdx].items.push(items[index])
      }
    },
    calcSize() {
      if (this.state.platform.type === 'desktop' && this.isPop) {
        this.container_width = 632
        this.container_height = 632
      } else {
        this.container_width = utils.width()
        this.container_height = utils.height()
      }
      this.width = this.container_width - 3
      this.height = this.container_height - 95 - this.headHeight
    },
    init(backTop) {
      if (backTop) this.backTop()
      this.loading[this.mod] = false
      this.posts = {}
      this.works = {}
      this.dt[this.mod] = utils.deepClone(this.schema)
    },
    update() {
      this.$forceUpdate()
    },
    updateData(updateHash) {
      if (updateHash && !this.config.pop) this.emit('setLocationHash')
      if (!this.itemNums) {
        this.init()
        this.getData('down')
      } else {
        this.update()
      }
    },
    getAvatar(item) {
      const ver = Math.round(new Date(this.users[item.user_id].avatar_at) / 1000)
      if (this.users[item.user_id] && this.users[item.user_id].avatar) return service.getCosUrl(this.users[item.user_id].avatar, 'avatar', ver) || ''
    },
    showFull(item) {
      item._full = !item._full
      this.update()
    },
    getCreateTime(item) {
      if (!item || !item.id) return ''
      let time = ''
      if (item.posted_at && item.updated_at && item.posted_at >= item.updated_at) {
        time = this.formateTime(item.posted_at)
      } else if (item.updated_at && item.created_at && item.updated_at >= item.created_at && new Date(item.updated_at) + 86400000 * 3 > new Date()) {
        time = this.formateTime(item.updated_at) + '有更新'
      } else if (item.updated_at || item.created_at) {
        time = this.formateTime(item.updated_at || item.created_at)
      } else if (this.posts[item.id] && this.posts[item.id].created_at) {
        time = this.formateTime(this.posts[item.id].created_at)
      }
      return time
    },
    getContent(item, split, removeHtml) {
      if (!item || !item.id) return ''
      let content = item.title + '<br>'
      if (this.posts[item.id] && this.posts[item.id].content) content = content + this.posts[item.id].content
      if (item.is_essence) content = content + '<br><span class="tag">推荐</span>'
      return utils.fomateContent(content, split, removeHtml)
    },
    loadData(loaded, direction) {
      if (this.config.show || this.view.user.show || this.view.praise.show || this.view.favor.show || this.view.footprint.show || this.view.topicDetail.show || this.view.index.show || this.view.discover.show) {
        this.renew = direction === 'renew'
        if (direction === 'renew') {
          direction = 'down'
          this.data.currentPage = 0
          this.data.cursorValue = 0
          this.data.cursorValueUp = 0
          this.data.cursorValueDown = 0
          this.data.cursorSkipUp = 0
          this.data.cursorSkipDown = 0
          this.data.skip = 0
          this.data.noUpPage = false
          this.data.noDownPage = false
        }
        this.getData(direction, () => {
          this.renew = false
          loaded('done')
        })
      }
    },
    viewThread(threadid) {
      this.emit('goto', ['thread', { threadid: threadid }, true])
    },
    openViewer(type, workid) {
      const data = {
        userid: 0,
        workid: workid,
        publishid: 0
      }
      if (type === 1) {
        this.emit('openBrickyViewer', [{ data: data }])
      } else {
        this.emit('openWorkViewer', [{ data: data }])
      }
    },
    // 分页读取数据
    async getData(direction, cb) {
      direction = direction || 'down'
      if (!this.config.show && !this.view.user.show && !this.view.praise.show && !this.view.favor.show && !this.view.footprint.show && !this.view.topicDetail.show && !this.view.index.show && !this.view.discover.show) return cb && cb()
      if (direction === 'up' && this.data.noUpPage) return cb && cb()
      if (direction === 'down' && this.data.noDownPage) return cb && cb()
      if (this.loading[this.mod]) return cb && cb()
      if (this.maxPage && this.data.items.length >= this.maxPage * this.limit) return cb && cb()
      this.loading[this.mod] = true
      const params = {
        'sort_field': 'updated_at',
        'sort': 'desc',
        'limit': this.limit,
        'skip': direction === 'up' ? this.data.cursorSkipUp : this.data.cursorSkipDown,
        'cursor_value': direction === 'up' ? this.data.cursorValueUp : this.data.cursorValueDown,
        'direction': direction
      }
      if (this.config.sortType === 'create') {
        params.sort_field = 'created_at'
      } else {
        params.sort_field = 'updated_at'
      }
      if (this.config.sort === 'asc') {
        params.sort = 'asc'
      } else {
        params.sort = 'desc'
      }
      const catalogid = utils.getInt(this.config.catalogid)
      if (catalogid) params.category_id = catalogid
      if (this.mod === 'best' || this.mod === 'discover' || this.mod === 'index') params.is_essence = 1
      if (this.mod === 'thread') {
        this.data.threadid = this.view.thread.threadid
        params.threadid = this.view.thread.threadid
      }
      if (this.mod === 'user') {
        this.userid = this.view.user.userid
        params.userid = this.view.user.userid
      }
      if (this.mod === 'topic') {
        params.topicid = this.view.topicDetail.topicid
      }
      if (this.mod === 'praise' || this.mod === 'favor' || this.mod === 'footprint' || this.mod === 'topic') {
        params.mod = this.mod
      }
      // console.error('params', this.mod, params, direction, this.data.currentPage, this.data, this.view.thread)
      service.listGet('threads', params, (res, type) => {
        if (type === 'success') {
          let refresh = false
          if (direction === 'up') {
            this.data.cursorValueUp = res.data.cursor_value_up
            this.data.cursorSkipUp = res.data.cursor_skip_up
            if (!this.data.cursorValueDown && !utils.isEmpty(res.data.cursor_value_down)) this.data.cursorValueDown = res.data.cursor_value_down
            this.data.noUpPage = utils.isEmpty(this.data.cursorValueUp)
            this.data.items = res.data.items.concat(this.data.items)
          } else if (direction === 'down') {
            if ((res.data.count === this.limit && this.data.currentPage >= this.remainPages) || this.renew) {
              if (res.data.count === this.limit && this.data.currentPage >= this.remainPages) {
                this.init(true)
                this.showUp = true
              } else {
                this.init()
              }
              refresh = true
            }
            this.data.currentPage++
            if (!this.data.cursorValueUp && !utils.isEmpty(res.data.cursor_value_up)) this.data.cursorValueUp = res.data.cursor_value_up
            this.data.cursorValueDown = res.data.cursor_value_down
            this.data.cursorSkipDown = res.data.cursor_skip_down
            this.data.noDownPage = utils.isEmpty(this.data.cursorValueDown)
            this.data.items = this.data.items.concat(res.data.items)
          }
          if (res.data.users) {
            for (var userid in res.data.users) {
              this.users[userid] = res.data.users[userid]
            }
          }
          if (res.data.posts) {
            for (var postid in res.data.posts) {
              this.posts[postid] = res.data.posts[postid]
            }
          }
          if (res.data.works) {
            for (var workid in res.data.works) {
              this.works[workid] = res.data.works[workid]
            }
          }
          if (res.data.toms) {
            for (var threadid in res.data.toms) {
              this.toms[threadid] = res.data.toms[threadid]
            }
          }
          if (res.data.attachments) {
            for (var tid in res.data.attachments) {
              this.attachments[tid] = res.data.attachments[tid]
            }
          }
          this.data.skip = utils.getInt(res.data.skip)
          if (this.mod === 'thread' && res.data.items.length) {
            this.view.thread.threadid = res.data.items[0].id
            this.emit('update')
          }
          this.getThreadCount(res.data)
          if (direction === 'up') {
            this.renderItems(this.data.items, true)
          } else {
            this.renderItems(res.data.items, refresh)
          }
        } else {
          this.message(res, type)
        }
        this.loading[this.mod] = false
        if (this.view.user.show) this.view.user.loading = false
        if (this.view.praise.show) this.view.praise.loading = false
        if (this.view.favor.show) this.view.favor.loading = false
        if (this.view.footprint.show) this.view.footprint.loading = false
        if (this.view.topicDetail.show) this.view.topicDetail.loading = false
        if (this.view.index.show) this.view.index.loading = false
        if (this.view.discover.show) this.view.discover.loading = false
        cb && cb()
      }, false, false)
    },
    // 关注
    follow(userid) {
      this.followState[userid] = 1
      this.update()
      if (this.mod === 'thread' || this.mod === 'user') this.emit('update')
      this.emit('follow', [userid, (res, type) => {
        if (type === 'success') {
          this.followState[userid] = res.isMutual
        } else {
          // 回滚
          this.followState[userid] = -1
          this.message(res, type)
        }
        this.update()
        if (this.mod === 'thread' || this.mod === 'user') this.emit('update')
      }])
    },
    // 取消关注
    unfollow(userid) {
      this.followState[userid] = -1
      this.update()
      if (this.mod === 'thread' || this.mod === 'user') this.emit('update')
      this.emit('unfollow', [userid, (res, type) => {
        if (type === 'success') {
          this.followState[userid] = -1
        } else {
          // 回滚
          this.followState[userid] = 0
          this.message(res, type)
        }
        this.update()
        if (this.mod === 'thread' || this.mod === 'user') this.emit('update')
      }])
    },
    praiseIt(thread_id) {
      if (!thread_id) return
      this.praisePost(this.posts[thread_id].id, thread_id)
    },
    // 赞
    praisePost(post_id, thread_id) {
      let count = this.threadCount[thread_id]
      if (!count) count = {}
      let cmd
      if (count.praise_state) {
        cmd = 'unpraise'
        count.praise_state = 0
        count.praise_count--
        service.decCount(this.loginUserId, 'thread_praise')
      } else {
        cmd = 'praise'
        count.praise_state = 1
        count.praise_count++
        service.incCount(this.loginUserId, 'thread_praise')
      }
      count.praise_count = Math.max(count.praise_count, 0)
      thread_id = thread_id || post_id
      this.update()
      if (this.mod === 'thread') this.emit('update')
      if (this.mod === 'praise' && cmd === 'unpraise') this.removeItem(thread_id)
      service[cmd](thread_id, post_id || 0, (dt, type) => {
        if (type === 'success') {
          count.praise_state = dt.isLiked ? 1 : 0
          if (dt.likeCount) count.praise_count = dt.likeCount
          if (typeof dt.isFavorite !== 'undefined') count.collect_state = dt.isFavorite ? 1 : 0
        } else {
          // 失败回滚
          if (count.praise_state) {
            count.praise_state = 0
            count.praise_count--
            service.decCount(this.loginUserId, 'thread_praise')
          } else {
            count.praise_state = 1
            count.praise_count++
            service.incCount(this.loginUserId, 'thread_praise')
          }
          this.message(dt, type)
        }
        this.update()
        if (this.mod === 'thread') this.emit('update')
        if (this.mod === 'praise' && cmd === 'unpraise') this.removeItem(thread_id)
      }, true)
    },
    // 收藏
    collectIt(thread_id) {
      if (!thread_id) return
      let count = this.threadCount[thread_id]
      if (!count) count = {}
      if (this.mod === 'favor' && count.collect_state) this.removeItem(thread_id)
      let isFavorite
      if (count.collect_state) {
        isFavorite = false
        count.collect_state = 0
        count.collect_count--
        service.decCount(this.loginUserId, 'thread_favor')
      } else {
        isFavorite = true
        count.collect_state = 1
        count.collect_count++
        service.incCount(this.loginUserId, 'thread_favor')
      }
      count.collect_count = Math.max(count.collect_count, 0)
      this.update()
      if (this.mod === 'thread') this.emit('update')
      service.collect(thread_id, isFavorite, (dt, type) => {
        if (type === 'success') {
          count.collect_state = isFavorite ? 1 : 0
          if (dt.paidCount) count.paid_count = dt.paidCount
          if (dt.postCount) count.post_count = dt.postCount
          if (dt.shareCount) count.share_count = dt.shareCount
          if (dt.viewCount) count.view_count = dt.viewCount
        } else {
          // 失败回滚
          if (count.collect_state) {
            count.collect_state = 0
            count.collect_count--
            service.decCount(this.loginUserId, 'thread_favor')
          } else {
            count.collect_state = 1
            count.collect_count++
            service.incCount(this.loginUserId, 'thread_favor')
          }
          this.message(dt, type)
        }
        this.update()
        if (this.mod === 'thread') this.emit('update')
      }, true)
    },
    getShareContent(thread_id) {
      const item = this.getItem(thread_id)
      if (item) return this.getContent(item, '；') + ' ' + this.mainHost + '#/?mod=thread&threadid=' + thread_id
    },
    // 分享
    shareIt(id) {
      if (!id) return
      this.threadCount[id].share_count++
      this.update()
      if (this.mod === 'thread') this.emit('update')
      // eslint-disable-next-line
      const clipboard = new ClipboardJS('.clipboard-thread')
      clipboard.on('success', (e) => {
        e.clearSelection()
        this.message('链接复制成功，请粘贴分享', 'success')
        clipboard.destroy()
      })
      service.get('thread_share', id)
      service.incCount(this.loginUserId, 'thread_share')
    },
    showActionSheet(userid, threadid, is_sticky, is_essence) {
      const actions = {
        show: true,
        title: '请选择',
        btns: []
      }
      // if ((userid && userid === this.loginUserId) || (this.loginUserId && this.loginUserId < 10000)) {
      //   actions.btns.push({ title: '编辑', cb: () => { console.log('编辑') } })
      // }
      if (this.loginUserId && this.loginUserId < 10000) {
        actions.btns.push({ title: is_sticky ? '取消置顶' : '置顶', cb: () => { this.threadsOperate(threadid, { isSticky: !is_sticky }) } })
        actions.btns.push({ title: is_essence ? '取消推荐' : '推荐', cb: () => { this.threadsOperate(threadid, { isEssence: !is_essence }) } })
      }
      if ((userid && userid === this.loginUserId) || (this.loginUserId && this.loginUserId < 10000)) {
        actions.btns.push({ title: '删除', cb: () => { this.threadDelete(threadid) } })
      }
      actions.btns = actions.btns.concat([
        { title: '广告垃圾', cb: () => { this.emit('reports', [threadid, 1, '广告垃圾', this.loginUserId]) } },
        { title: '违规内容', cb: () => { this.emit('reports', [threadid, 1, '违规内容', this.loginUserId]) } },
        { title: '恶意灌水', cb: () => { this.emit('reports', [threadid, 1, '恶意灌水', this.loginUserId]) } },
        { title: '重复发帖', cb: () => { this.emit('reports', [threadid, 1, '重复发帖', this.loginUserId]) } },
        { title: '其他理由', cb: () => { this.emit('setReportReason', [threadid, 1, this.loginUserId]) } }
      ])
      this.view.actionSheet = actions
    },
    // 主题操作：置顶、推荐
    threadsOperate(id, data) {
      service.threadsOperate(id, data, (dt, type) => {
        if (type === 'success') {
          if (typeof data.isSticky !== 'undefined') {
            this.message('已' + (data.isSticky ? '' : '取消') + '置顶', type)
            this.setItem(id, 'is_sticky', data.isSticky)
          } else if (typeof data.isEssence !== 'undefined') {
            this.message('已' + (data.isEssence ? '' : '取消') + '推荐', type)
            if (data.isEssence === false && this.mod === 'best') {
              this.removeItem(id)
            } else {
              this.setItem(id, 'is_essence', data.isEssence)
            }
          }
        } else {
          this.message(dt, type)
        }
      })
    },
    // 删除主题
    threadDelete(id) {
      this.confirm('确定删除吗？', (e) => {
        if (e === 'cancel') return
        service.threadDelete(id, (dt, type) => {
          if (type === 'success') {
            this.message('删除成功', type)
            if (this.mod === 'thread') {
              this.state.deleteThreads.push(id)
              return this.emit('goto')
            }
            this.removeItem(id)
          } else {
            this.message(dt, type)
          }
        })
      })
    },
    // 获取主题计数器
    getThreadCount(data) {
      if (!data) return
      const items = data.items
      if (Array.isArray(items)) {
        const ids = []
        for (var i in items) {
          if (items[i].id && typeof this.threadCount[items[i].id] === 'undefined') {
            ids.push(items[i].id)
          }
        }
        if (ids.length) {
          service.batchGet('thread_count', ids, (res, type) => {
            if (type === 'success' && res.data) {
              for (const k in res.data) {
                this.threadCount[k] = res.data[k]
                this.followState[res.data[k].thread_userid] = res.data[k].follow_state
              }
              this.update()
              if (this.mod === 'thread') this.emit('update')
            }
          })
        }
      }
    }
  }
}
