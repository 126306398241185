<template>
  <el-container class="container">
    <input ref="gridy-uploader" type="file" accept=".gridy" style="display:none;" @change="importLocalFile">
    <input ref="images-uploader" :multiple="state.openLocalImages.multi" type="file" accept="image/*" style="display:none;" @change="importLocalImages">
    <div v-show="false" ref="share-url" class="clipboard" :data-clipboard-text="' ' + getInfo(file) + ' ' + mainHost + '#/?mod=workViewer&workid=' + file.workid" @click="shareIt(file.workid)" />
    <el-header v-if="isDesktop" :style="headerStyle" :class="{'bg-22' : !isEditer}">
      <div class="logo-site" @click="goto('editer')">
        <img src="@/assets/logo.png" title="Gridy.Art 百格画">
      </div>
      <div style="float: left" class="my-menu">
        <vue-file-toolbar-menu v-if="isEditer" :content="menuData" @receive="handle" />
      </div>
      <div style="float: left" class="my-header-tip" />
      <div style="float: right" class="plain-btns">
        <a v-show="false" id="link" ref="link" :href="open_url" target="_blank">link</a>
        <el-button type="text" icon="iconfont my-home" :class="{'blue': viewMod === 'index'}" @click="goto('index')"> 首页</el-button>
        <el-button type="text" icon="iconfont my-pixel" :class="{'blue': viewMod === 'works'}" @click="goto('works')"> 作品</el-button>
        <!--
        <el-dropdown trigger="hover" placement="bottom-start" @command="gotoWorks">
          <el-button type="text" icon="iconfont my-pixel" class="padding-left8 padding-right8" :class="{'blue': viewMod === 'works'}"> 作品</el-button>
          <el-dropdown-menu slot="dropdown" class="my-dropdown">
            <el-dropdown-item command="0" icon="iconfont my-pixel" :class="{'blue': viewMod === 'works' && view.works.classid === 0}"><span :class="{'blue': viewMod === 'works' && view.works.classid === 0}">像素画</span></el-dropdown-item>
            <el-dropdown-item command="1" icon="iconfont my-avatar" :class="{'blue': viewMod === 'works' && view.works.classid === 1}"><span :class="{'blue': viewMod === 'works' && view.works.classid === 1}">拼图</span></el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        -->
        <el-button type="text" icon="iconfont my-album" :class="{'blue': viewMod === 'album' || viewMod === 'albumWorks'}" @click="goto('album')"> 专辑</el-button>
        <!-- <el-button type="text" icon="iconfont my-man" :class="{'blue': viewMod === 'users'}" @click="goto('users')"> 创作者</el-button> -->
        <el-button type="text" icon="iconfont my-zone" :class="{'blue': viewMod === 'zone'}" @click="goto('zone')"> 圈子</el-button>
        <!-- <el-button type="text" icon="iconfont my-discover" :class="{'blue': viewMod === 'discover'}" @click="goto('discover')"> 发现</el-button> -->
        <!-- <el-button type="text" icon="iconfont my-ai-paint" :class="{'blue': viewMod === 'aiart'}" @click="goto('aiart')"> AI作画</el-button> -->
        <!-- <el-button type="text" icon="iconfont my-blockchain" :class="{'blue': viewMod === 'nft'}" @click="goto('nft')"> 数藏</el-button> -->
        <!-- <el-dropdown trigger="hover" placement="bottom-start" @command="createWork">
          <el-button type="text" icon="iconfont my-design" class="padding-left8 padding-right8" :class="{'blue': viewMod === 'editer'}"> 创作</el-button>
          <el-dropdown-menu slot="dropdown" class="my-dropdown">
            <el-dropdown-item command="gridy" icon="iconfont my-pixel">创作像素画</el-dropdown-item>
            <el-dropdown-item command="brickfy" icon="iconfont my-avatar">定制拼图</el-dropdown-item>
            <el-dropdown-item command="pixel" icon="iconfont my-avatar">AI像素画</el-dropdown-item>
            <el-dropdown-item command="cartoon" icon="iconfont my-cartoon">AI动漫</el-dropdown-item>
            <el-dropdown-item command="paint" icon="iconfont my-ai-paint">AI作画</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
        <el-button type="text" icon="iconfont my-gridsfy" @click="goto('editer')"> 创作像素画</el-button>
        <el-button type="text" icon="iconfont my-avatar" @click="createWork('brickfy')"> 定制拼图</el-button>
        <el-button type="text" icon="iconfont my-post-add" @click="openPost()"> 发布主题</el-button>
        <!-- <el-badge v-if="loginStatus" :value="formateNums(state.unread.total)" :hidden="!state.unread.total" class="item">
          <el-button type="text" icon="iconfont my-message" @click="goto('message', {}, true)"> 消息 </el-button>
        </el-badge> -->
        <span v-if="!loginStatus" style="cursor:pointer;margin-left:12px;" @click="login">
          <span class="el-avatar el-avatar--circle noavatar medium">
            <i class="iconfont my-man" />
          </span>
          <span class="el-dropdown color-cc padding-left8">请登录</span>
        </span>
        <el-popover v-if="loginStatus" v-model="myPopoverShow" placement="bottom" width="480" popper-class="popover-menus" trigger="click">
          <el-row class="popover-set-btns" style="border: 0;">
            <el-col :span="5">
              <el-badge v-if="loginStatus" :value="formateNums(state.unread.total)" :hidden="!state.unread.total">
                <el-button class="iconfont my-message" @click="goto('message')" />
                <div class="size10">我的消息</div>
              </el-badge>
            </el-col>
            <el-col :span="5"><el-button class="iconfont my-task" @click="goto('task')" /><div class="size10">我的任务</div></el-col>
            <el-col :span="5"><el-button class="iconfont my-items" @click="goto('items')" /><div class="size10">我的物品</div></el-col>
            <el-col :span="5"><el-button class="iconfont my-worth" @click="goto('wallet')" /><div class="size10">我的晶币</div></el-col>
            <el-col :span="5"><el-button class="iconfont my-orders" @click="goto('order')" /><div class="size10">我的订单</div></el-col>
          </el-row>
          <el-row class="popover-set-btns margin-bottom8" style="border: 0;">
            <el-col :span="5"><el-button class="iconfont my-material" @click="openDraft('draft')" /><div class="size10">本地草稿</div></el-col>
            <el-col :span="5"><el-button class="iconfont my-pixel" @click="openResource('work')" /><div class="size10">我的作品</div></el-col>
            <el-col :span="5"><el-button class="iconfont my-ai-paint" @click="openResource('paint')" /><div class="size10">我的AI作品</div></el-col>
            <el-col :span="5"><el-button class="iconfont my-blockchain" @click="goto('user', { userid: loginUserId, mng: 'yes', type: 'nft', typeid: 3 })" /><div class="size10">我的藏品</div></el-col>
            <el-col :span="5"><el-button class="iconfont my-post" @click="goto('user', { userid: loginUserId, mng: 'yes', type: 'thread', typeid: 0 })" /><div class="size10">我的主题</div></el-col>
          </el-row>
          <el-row class="popover-set-btns margin-bottom8" style="border: 0;">
            <el-col :span="5"><el-button class="iconfont my-homepage" @click="goto('user', { userid: loginUserId, type: 'thread', typeid: 0 })" /><div class="size10">我的主页</div></el-col>
            <el-col :span="5"><el-button class="iconfont my-album" @click="goto('user', { userid: loginUserId, mng: 'yes', type: 'album', typeid: 2 })" /><div class="size10">我的专辑</div></el-col>
            <el-col :span="5"><el-button class="iconfont my-follow" @click="goto('relation', { userid: loginUserId, mng: 'yes', type: 'follow' })" /><div class="size10">我的关注</div></el-col>
            <el-col :span="5"><el-button class="iconfont my-fans" @click="goto('relation', { userid: loginUserId, mng: 'yes', type: 'fans' })" /><div class="size10">我的粉丝</div></el-col>
            <el-col :span="5"><el-button class="iconfont my-guest" @click="goto('relation', { userid: loginUserId, mng: 'yes', type: 'guest' })" /><div class="size10">我的访客</div></el-col>
          </el-row>
          <el-row class="popover-set-btns" style="border: 0;">
            <el-col :span="5"><el-button class="iconfont my-down-round" @click="goto('download')" /><div class="size10">我的下载</div></el-col>
            <el-col :span="5"><el-button class="iconfont my-blank-star" @click="goto('favor')" /><div class="size10">我的收藏</div></el-col>
            <el-col :span="5"><el-button class="iconfont my-praise-blank" @click="goto('praise')" /><div class="size10">我的点赞</div></el-col>
            <el-col :span="5"><el-button class="iconfont my-footprint" @click="goto('footprint')" /><div class="size10">我的足迹</div></el-col>
            <el-col :span="5"><el-button class="iconfont my-invite" @click="goto('invite')" /><div class="size10">邀请好友</div></el-col>
          </el-row>
          <el-row class="popover-set-btns margin-bottom8" style="border: 0;">
            <el-col :span="5"><el-button class="iconfont my-sign" @click="goto('sign')" /><div class="size10">签到</div></el-col>
            <el-col :span="5"><el-button class="iconfont my-vip" @click="goto('vip')" /><div class="size10">VIP会员</div></el-col>
            <el-col :span="5"><el-button class="iconfont my-personal" @click="goto('profile')" /><div class="size10">个人设置</div></el-col>
            <el-col :span="5"><el-button class="iconfont my-setting" @click="goto('account')" /><div class="size10">账号设置</div></el-col>
            <el-col :span="5">
              <el-popconfirm title="确定退出登录吗？" cancel-button-type="Primary" placement="bottom" @confirm="logout()">
                <el-button slot="reference" class="iconfont my-logout" title="退出登录" />
              </el-popconfirm>
              <div class="size10">退出登录</div>
            </el-col>
          </el-row>
          <span slot="reference" class="margin-left12 padding-top8 padding-bottom8" style="cursor:pointer;">
            <span v-if="!avatar" class="el-avatar el-avatar--circle noavatar medium">
              <i class="iconfont my-man" />
            </span>
            <el-avatar v-if="avatar" :size="32" :src="avatar" />
            <span class="padding-left8 color-ee">{{ loginNickname }}</span>
            <i class="el-icon-arrow-down el-icon--right" />
          </span>
        </el-popover>
      </div>
    </el-header>
    <el-container>
      <el-aside v-if="isDesktop && isEditer" :style="leftSideStyle" class="border-top left-side">
        <el-button v-for="(tool, idx) in tools" :key="idx" size="mini" :type="state.act === tool[1] ? 'primary' : 'plain'" :class="'iconfont ' + tool[2] + ' my-margin-left'" :title="tool[3]" @click="setAct(tool[1])" />
        <div class="my-quick-colors" style="cursor: pointer">
          <div class="first-color" title="主选色（左键）" :style="{ background: state.color[0] || '#000000' }" @click="(event) => {toggleColorPicker(event, 'first-color')}" />
          <div class="second-color" title="次选色（右键）" :style="{ background: state.color[1] || '#FFFFFF' }" @click="(event) => {toggleColorPicker(event, 'second-color')}" />
          <div class="iconfont my-default-color" title="默认" @click="resetCurColor" />
          <div class="iconfont my-exchange" title="互换" @click="exchangeCurColor" />
        </div>
        <div v-if="false" class="my-pos-info">
          <div v-if="state.pos">
            x: {{ state.pos[0] }} <br>
            y: {{ state.pos[1] }}
          </div>
          <div v-if="state.hoverObj">
            idx: {{ state.hoverObj[0] }} <br>
            parentIdx: {{ state.hoverObj[1] }}
          </div>
        </div>
      </el-aside>
      <el-main v-loading="state.drawStartTime" shadow="never" :style="mainStyle" element-loading-text="渲染中..." element-loading-background="rgba(0, 0, 0, 0.8)">
        <div
          v-if="isEditer"
          ref="mainArea"
          v-finger:touch-start="touchStart"
          v-finger:press-move="pressMove"
          v-finger:touch-end="touchend"
          v-finger:tap="(e) => isDesktop ? {} : handleClick(e, 'tap')"
          v-finger:double-tap="doubleTap"
          v-finger:pinch="pinch"
          v-finger:multipoint-end="multipointEnd"
          style="display: flex; width: 100%; height: 100%;"
          @contextmenu.prevent="onContextmenu"
          @wheel="onWheel"
          @click="(e) => isDesktop ? handleClick(e) : {}"
          @mousemove="debounceGetPos"
        >
          <vue-draggable-resizable
            v-if="showMultiSelectBox"
            id="multiSelectBox"
            ref="multiSelectBox"
            class-name-handle="my-handle"
            :x="state.multiSelectBox.stopPos[0]"
            :y="state.multiSelectBox.stopPos[1]"
            :z="91"
            :w="state.multiSelectBox.stopSize[0]"
            :h="state.multiSelectBox.stopSize[1]"
            :draggable="canMultiDrag"
            :resizable="resizable"
            :handles="['br']"
            :lock-aspect-ratio="state.lockAspectRatio"
            :class="state.multiSelectBox.dragging ? 'multi-select-box my-obj' : 'multi-select-box my-obj-border'"
            @dragging="onBoxDragging"
            @dragstop="onBoxDrag"
            @resizing="onBoxResizing"
            @resizestop="onBoxResize"
          >
            <div slot="br"><span id="resizeBr" class="iconfont my-drag-resize" /></div>
          </vue-draggable-resizable>
          <vue-draggable-resizable
            v-if="canFreeSelect && state.freeSelector.show"
            :active.sync="state.freeSelector.active"
            :x="state.freeSelector.x"
            :y="state.freeSelector.y"
            :z="91"
            :min-width="state.gridSize"
            :min-height="state.gridSize"
            :w="state.freeSelector.w"
            :h="state.freeSelector.h"
            class="free-selector"
            :class="state.act === 'freeSelect' ? '' : 'solid'"
            :resizable="true"
            :draggable="true"
            :handles="['tl', 'tr', 'bl', 'br']"
            @dragging="onSelectorDragging"
            @dragstop="onSelectorDrag"
            @resizing="onSelectorResizing"
            @resizestop="onSelectorResize"
          >
            <div v-finger:double-tap="crop" class="free-selector-bg" @click.stop="() => {}" @dblclick.left.stop="crop" />
          </vue-draggable-resizable>
          <vue-draggable-resizable
            v-if="canFreeColorPick && state.freeColorPicker.open"
            :x="state.freeColorPicker.x"
            :y="state.freeColorPicker.y"
            :z="1"
            :w="102"
            :h="102"
            :grid="[state.gridSize, state.gridSize]"
            class="color-pick"
            :resizable="false"
            :draggable="true"
            @dragging="onFreeColorPicking"
            @dragstop="onFreeColorPick"
          >
            <div class="round" :style="{'border-color': state.freeColorPicker.color + '!important'}">
              <div class="border">
                <span class="cross">+</span>
              </div>
            </div>
          </vue-draggable-resizable>
          <vue-draggable-resizable
            :x="state.canvasAreaX"
            :y="state.canvasAreaY"
            :z="0"
            :w="state.gridSize"
            :h="state.gridSize"
            class="canvas-drag"
            :draggable="state.act === 'hand'"
            :handles="[]"
            @dragging="onHandDrag"
            @dragstop="onHandDrag"
          >
            <div v-show="openFileNums && !state.canvasIniting" :style="canvasAreaStyle" class="canvas-area">
              <div :style="defaultBgStyle" class="default-bg" />
              <canvas ref="canvasBg" class="canvas-bg" />
              <div ref="workArea" class="work-area">
                <obj
                  v-for="(obj, idx) in curObjs"
                  :key="'obj:' + state.sceneId + ':' + obj.id"
                  :ref="'obj:' + state.sceneId + ':' + obj.id"
                  :state="state"
                  :view="view"
                  :file="file"
                  :canvas-cols="parseInt(canvas.cols)"
                  :canvas-rows="parseInt(canvas.rows)"
                  :canvas-bg-color="canvasBgColor"
                  :grid-color="gridColor"
                  :file-type="file.type"
                  :idx="idx"
                  :obj="obj"
                  @receive="handle"
                />
              </div>
            </div>
          </vue-draggable-resizable>
        </div>
        <div v-if="isDesktop && isEditer && view.zoomBar" class="my-zoom-el">
          <el-row :gutter="2">
            <el-col :span="4"><el-button size="mini" :disabled="!btnStatus.zoomOut" title="缩小" plain icon="iconfont my-zoomout" @click.stop="zoom('-')" /></el-col>
            <el-col :span="16">
              <el-slider
                v-model="state.scale"
                show-stop
                :min="0"
                :max="2"
                :step="0.5"
                :format-tooltip="(val) => val * 100 + '%'"
                @change="(val) => zoom(val)"
              />
            </el-col>
            <el-col :span="4"><el-button size="mini" :disabled="!btnStatus.zoomIn" title="放大" plain icon="iconfont my-zoomin" @click.stop="zoom('+')" /></el-col>
          </el-row>
        </div>
        <div v-if="isDesktop && isEditer" class="my-open-files" @contextmenu.prevent="onTabContextmenu">
          <el-tabs v-model="state.fileId" type="card" closable @tab-remove="closeFile" @tab-click="(e) => openFile(0, e.name)">
            <el-tab-pane
              v-for="item in state.openFiles"
              :key="item.fileId"
              :label="item.name"
              :name="item.fileId"
            />
          </el-tabs>
        </div>
        <div v-if="isEditer && curObj && btnStatus.opts" :style="objOptsStyle">
          <el-button v-if="!curObj.lock" class="iconfont my-lock tap-btn margin-right4 size18" title="锁定" @click="lockSelectObjs()" />
          <el-button v-if="curObj.lock" class="iconfont my-unlock tap-btn margin-right4 size18" title="解锁" @click="unlockSelectObjs()" />
          <el-button class="iconfont my-copyparse tap-btn margin-right4 size18" title="复制" @click="copyAndParse(state.objIdx)" />
          <el-button class="iconfont my-eye-off tap-btn margin-right4 size18" title="隐藏" @click="hideSelectObjs()" />
          <el-button class="iconfont my-delete tap-btn margin-right4 size18" :disabled="curObj && curObj.lock" title="删除" @click="handle('delete', [false])" />
        </div>
        <topBar
          v-if="!isDesktop && isEditer"
          ref="topBar"
          :view="view"
          :state="state"
          :file="file"
          :btn-status="btnStatus"
          :login-status="loginStatus"
          :login-user-id="loginUserId"
          @receive="handle"
        />
        <toolBar
          v-if="!isDesktop && isEditer"
          ref="toolBar"
          :view="view"
          :state="state"
          :btn-status="btnStatus"
          :login-status="loginStatus"
          :login-user-id="loginUserId"
          @receive="handle"
        />
        <bottomBar
          v-if="isEditer && ((isDesktop && openFileNums) || !isDesktop)"
          ref="bottomBar"
          :tmp="tmp"
          :file="file"
          :cur-obj="curObj"
          :palette="palette"
          :cur-palette-id="curPaletteId"
          :view="view"
          :state="state"
          :edit-color="editColor"
          :btn-status="btnStatus"
          :login-status="loginStatus"
          :login-user-id="loginUserId"
          @receive="handle"
        />
        <works
          v-show="view.works.show"
          ref="works"
          :view="view"
          :state="state"
          :login-status="loginStatus"
          :login-user-id="loginUserId"
          @receive="handle"
        />
        <aiart
          v-show="view.aiart.show"
          ref="aiart"
          :view="view"
          :state="state"
          :login-status="loginStatus"
          :login-user-id="loginUserId"
          @receive="handle"
        />
        <nft
          v-show="view.nft.show"
          ref="nft"
          :view="view"
          :state="state"
          :login-status="loginStatus"
          :login-user-id="loginUserId"
          @receive="handle"
        />
        <album
          v-show="view.album.show"
          ref="album"
          :view="view"
          :state="state"
          :login-status="loginStatus"
          :login-user-id="loginUserId"
          @receive="handle"
        />
        <index
          v-show="view.index.show"
          ref="index"
          :view="view"
          :state="state"
          :login-status="loginStatus"
          :login-user-id="loginUserId"
          @receive="handle"
        />
        <zone
          v-show="view.zone.show"
          ref="zone"
          :view="view"
          :state="state"
          :login-status="loginStatus"
          :login-user-id="loginUserId"
          @receive="handle"
        />
        <discover
          v-show="view.discover.show"
          ref="discover"
          :view="view"
          :state="state"
          :login-status="loginStatus"
          :login-user-id="loginUserId"
          @receive="handle"
        />
        <foot-bar
          v-if="showFootbar"
          ref="footBar"
          :view="view"
          :state="state"
          :login-status="loginStatus"
          :login-user-id="loginUserId"
          @receive="handle"
        />
      </el-main>
      <el-aside v-if="isDesktop && isEditer && openFileNums" :style="rightSideStyle">
        <div class="toggle-right-side border-top" @click.stop="view.rightSide = !view.rightSide">
          <div>
            <span v-if="view.rightSide" class="iconfont my-close-right" title="收起" />
            <span v-if="!view.rightSide" class="iconfont my-open-left" title="打开" />
          </div>
        </div>
        <div v-show="view.rightSide" type="border-card" class="right-side objs-div" :style="rightSideActStyle">
          <div class="my-title-bar">
            <el-col :span="12">
              <span v-if="view.scenes" class="iconfont my-close-up" title="收起" @click.stop="view.scenes = !view.scenes" />
              <span v-if="!view.scenes" class="iconfont my-open-down" title="打开" @click.stop="view.scenes = !view.scenes" />
              场景
            </el-col>
            <el-col :span="12" style="text-align: right">
              <span v-if="scenes.length > 1 && !state.play" class="iconfont my-play" title="播放" @click="startPlay" />
              <span v-if="scenes.length > 1 && state.play" class="iconfont my-stop" title="停止" @click="stopPlay" />
              &nbsp;
              <span class="iconfont my-scenes-add" title="添加场景" @click="addScene" />
            </el-col>
          </div>
          <div v-if="view.scenes" class="my-scroll" :style="scenesAreaStyle">
            <div v-for="(scene, idx) in scenes" :key="scene.id" class="obj-div" :class="state.sceneIdx === idx ? 'select' : ''">
              <div class="obj-img" @click="selectScene(idx)">
                <scenes-preview
                  :key="'scenes-preview:' + state.sceneId"
                  :idx="idx"
                  :file="file"
                />
              </div>
              <div class="obj-info">
                <div><edit-text :val="scene.name" title="编辑场景名称" @receive="(val) => (saveNameAttr(val, scene))" /></div>
                <div>{{ canvas.cols }} x {{ canvas.rows }}</div>
              </div>
              <div class="obj-btn">
                <span class="iconfont my-copyparse" title="拷贝" @click="copyScene(idx)" />
                <el-popconfirm v-if="scenes.length > 1" title="确定删除吗？" cancel-button-type="Primary" @confirm="delScene(idx)">
                  <span slot="reference" class="iconfont my-delete" title="删除" />
                </el-popconfirm>
              </div>
            </div>
          </div>
          <div class="my-title-bar">
            <el-col :span="12">
              <span v-if="view.objs" class="iconfont my-close-up" title="收起" @click.stop="view.objs = !view.objs" />
              <span v-if="!view.objs" class="iconfont my-open-down" title="打开" @click.stop="view.objs = !view.objs" />
              对象
            </el-col>
            <el-col :span="12" style="text-align: right">
              <span class="iconfont my-blank-object-add" title="添加空对象" @click="addBlankObj(false)" />
              &nbsp;
              <span class="iconfont my-object-add" title="添加本地图片" @click="() => (openLocalImages('import'))" />
              &nbsp;
              <span class="iconfont my-link-add" title="添加网络图片" @click="() => (openWebImage('import'))" />
            </el-col>
          </div>
          <div v-if="view.objs" class="my-scroll" :style="objAreaStyle">
            <div v-for="(obj, idx) in curObjs" :key="obj.id" class="obj-div" :class="getObjClass(obj.id, '')">
              <div class="obj-img" :style="{ background: 'url(' + bgImg4 + ')' }" @click="(event) => select(obj.id, '', idx, -1, event.shiftKey)">
                <obj-preview
                  :key="'obj-preview:' + state.sceneId + ':' + obj.id"
                  :state="state"
                  :file-type="file.type"
                  :idx="idx"
                  :obj="obj"
                />
              </div>
              <div class="obj-info">
                <div><edit-text :val="obj.name" title="编辑对象名称" @receive="(val) => (saveNameAttr(val, obj))" /></div>
                <div @click="(event) => select(obj.id, '', idx, -1, event.shiftKey)">{{ obj.cols }} x {{ obj.rows }}</div>
              </div>
              <div class="obj-btn">
                <span v-if="obj.show" class="iconfont my-eye-open" title="隐藏" @click="show(idx)" />
                <span v-if="!obj.show" class="iconfont my-eye-close" title="显示" @click="show(idx)" />
                <span v-if="obj.lock" class="iconfont my-lock" title="解锁" @click="lock(idx)" />
                <span v-if="!obj.lock" class="iconfont my-unlock" title="锁定" @click="lock(idx)" />
                <span class="iconfont my-copyparse" title="拷贝" @click="copyAndParse(idx)" />
                <span class="iconfont my-delete" title="删除" @click="del(idx)" />
              </div>
            </div>
            <div class="obj-div">
              <div class="obj-img">
                <canvas ref="canvasGrid" />
              </div>
              <div class="obj-info padding-top12 padding-left4">
                网格
              </div>
              <div class="obj-btn">
                <el-button v-if="canvas.showGrid" class="iconfont my-eye-open box24" style="font-size:16px;padding:0;" title="隐藏" @click="setCanvasAttr('showGrid', false)" />
                <el-button v-if="!canvas.showGrid" class="iconfont my-eye-close box24" style="font-size:16px;padding:0;" title="显示" @click="setCanvasAttr('showGrid', true)" />
                <el-button class="iconfont box24 margin-top4" :style="{'background': canvas.gridColor + '!important', 'border': '1px solid #444!important'}" @click="(event) => {toggleColorPicker(event, 'canvas-gridcolor')}" />
              </div>
            </div>
            <div class="obj-div">
              <div class="obj-img">
                <div class="box40" :style="{'background': canvas.bgColor}" />
              </div>
              <div class="obj-info padding-top12 padding-left4">
                背景
              </div>
              <div class="obj-btn">
                <el-button v-if="canvas.showBg" class="iconfont my-eye-open box24" style="font-size:16px;padding:0;" title="隐藏" @click="setCanvasAttr('showBg', false)" />
                <el-button v-if="!canvas.showBg" class="iconfont my-eye-close box24" style="font-size:16px;padding:0;" title="显示" @click="setCanvasAttr('showBg', true)" />
                <el-button class="iconfont box24 margin-top4" :style="{'background': canvas.bgColor + '!important', 'border': '1px solid #444!important'}" @click="(event) => {toggleColorPicker(event, 'canvas-bgcolor')}" />
              </div>
            </div>
          </div>
          <div class="my-title-bar palette">
            <el-col :span="12" style="padding: 3px 0 0 5px">
              <span v-if="view.palette" class="iconfont my-close-up" title="收起" @click.stop="view.palette = !view.palette" />
              <span v-if="!view.palette" class="iconfont my-open-down" title="打开" @click.stop="view.palette = !view.palette" />
              调色板
            </el-col>
            <el-col v-if="file && file.type === 0" :span="12" style="text-align: right">
              <el-select v-model="palette.paletteId" placeholder="请选择调色板" @change="usePalette">
                <el-option
                  v-for="item in palette.opts"
                  v-show="item.value !== 'default'"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-col>
          </div>
          <div v-show="view.palette" class="my-palette-div">
            <palette ref="palette" v-model="state.color[0]" :view="view" :state="state" :can-remove="false" :palette-id="curPaletteId" :is-desktop="isDesktop" @receive="handle" />
          </div>
        </div>
      </el-aside>
    </el-container>
    <user
      v-if="view.user.show"
      ref="user"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      :show-footbar="showFootbar"
      @receive="handle"
    />
    <vip
      v-if="view.vip.show"
      ref="vip"
      mod="vip"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <sign
      v-if="view.sign.show"
      ref="sign"
      mod="sign"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <work-viewer
      v-if="view.workViewer.show"
      ref="workViewer"
      mod="workViewer"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <thread
      v-show="view.thread.show"
      ref="thread"
      :view="view"
      :state="state"
      :file="file"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <comment
      v-show="view.comment.show"
      ref="comment"
      mod="comment"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <reply
      v-show="view.reply.show"
      ref="reply"
      mod="reply"
      :view="view"
      :state="state"
      :file="file"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <album-works
      v-show="view.albumWorks.show"
      ref="albumWorks"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <users
      v-if="view.users.show"
      ref="users"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <topics
      v-if="view.topics.show"
      ref="topics"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <setup
      v-if="view.setup.show"
      ref="setup"
      mod="setup"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <services
      v-if="view.services.show"
      ref="services"
      mod="services"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <message
      v-if="view.message.show"
      ref="message"
      mod="message"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <chat
      v-if="view.chat.show"
      ref="chat"
      mod="chat"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <wallet
      v-if="view.wallet.show"
      ref="wallet"
      mod="wallet"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <items
      v-if="view.items.show"
      ref="items"
      mod="items"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <task
      v-if="view.task.show"
      ref="task"
      mod="task"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <nftorder
      v-if="view.nftorder.show"
      ref="nftorder"
      mod="nftorder"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <order
      v-if="view.order.show"
      ref="order"
      mod="order"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <resource
      v-if="view.resource.show"
      ref="resource"
      mod="resource"
      :db="db"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <invite
      v-if="view.invite.show"
      ref="invite"
      mod="invite"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <praise
      v-if="view.praise.show"
      ref="praise"
      mod="praise"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <favor
      v-if="view.favor.show"
      ref="favor"
      mod="favor"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <download
      v-if="view.download.show"
      ref="download"
      mod="download"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <footprint
      v-if="view.footprint.show"
      ref="footprint"
      mod="footprint"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <relation
      v-if="view.relation.show"
      ref="relation"
      mod="relation"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <profile
      v-if="view.profile.show"
      ref="profile"
      mod="profile"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <account
      v-if="view.account.show"
      ref="account"
      mod="account"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <topic-detail
      v-if="view.topicDetail.show"
      ref="topicDetail"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <add-new
      v-show="view.addNew.show"
      ref="addNew"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <sift
      v-show="view.sift.show"
      ref="sift"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <album-selector
      ref="albumSelector"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <action-sheet :view="view" />
    <new-file
      v-if="view.createNewfile"
      :view="view"
      :state="state"
      :latest-open="tmp.latestOpen"
      :canvas-ratio="canvasRatio"
      :size-opts="sizeOpts"
      :newfile-opts="newfileOpts"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <my-pop
      v-if="view.myPop.show"
      ref="myPop"
      :view="view"
      :state="state"
      :file="file"
      :db="db"
      :hotkeys="hotkeys"
      @receive="handle"
    />
    <pop-page
      v-if="view.popPage.show"
      ref="popPage"
      :view="view"
      :state="state"
      :feedback="feedback"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <order-mng
      v-if="view.orderMng.show"
      ref="orderMng"
      mod="orderMng"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <resource-mng
      v-if="view.resourceMng.show"
      ref="resourceMng"
      mod="resourceMng"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <resource-selecter
      v-if="view.resourceSelecter.show"
      ref="resourceSelecter"
      mod="resourceSelecter"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <post
      v-show="view.post.show"
      ref="post"
      mod="post"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <crop-image
      v-if="view.cropImage.show"
      ref="cropImage"
      mod="cropImage"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <gridy-viewer
      v-if="view.gridyViewer.show"
      ref="gridyViewer"
      mod="gridyViewer"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <bricky-viewer
      v-if="view.brickyViewer.show"
      ref="brickyViewer"
      mod="brickyViewer"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <paper-viewer
      v-if="view.paperViewer.show"
      ref="paperViewer"
      mod="paperViewer"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <my-publish
      v-if="view.myPublish.show"
      ref="myPublish"
      :view="view"
      :state="state"
      :db="db"
      :tmp="tmp"
      :file="tmp.file"
      @receive="handle"
    />
    <item-operate
      v-if="view.itemOperate.show"
      ref="itemOperate"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <pop
      v-if="view.pop.show"
      ref="pop"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <my-login
      ref="myLogin"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <my-notify :view="view" />
    <color-picker v-show="state.colorPicker.open" ref="colorPicker" v-model="state.colorPicker.color" :state="state" @change="pickColor" />
  </el-container>
</template>
<script>
import utils from '@/js/utils'
import conf from '@/js/conf/conf'
import GRIDY from '@/js/sdk/GridySDK'
import GRIDYXLS from '@/js/sdk/GridyXLS'
import GRIDYLXF from '@/js/sdk/GridyLXF'
// import GRIDYPAPER from '@/js/sdk/GridyPaper'
// import GRIDYBOM from '@/js/sdk/GridyBom'
import { Db } from '@/js/db'
import downloadjs from 'downloadjs'
import '@/styles/index.css'
import '@/styles/iconfont.css'
import '@/styles/main.css'
import bgImg1 from '@/assets/canvas_bg/bg_1.png'
import bgImg2 from '@/assets/canvas_bg/bg_2.png'
import bgImg4 from '@/assets/canvas_bg/bg_4.png'
import bgImg6 from '@/assets/canvas_bg/bg_6.png'
import bgImg8 from '@/assets/canvas_bg/bg_8.png'
import bgImg12 from '@/assets/canvas_bg/bg_12.png'
import bgImg16 from '@/assets/canvas_bg/bg_16.png'
import bgImg20 from '@/assets/canvas_bg/bg_20.png'
import bgImg24 from '@/assets/canvas_bg/bg_24.png'
import bgImg28 from '@/assets/canvas_bg/bg_28.png'
import bgImg32 from '@/assets/canvas_bg/bg_32.png'
import VueFileToolbarMenu from 'vue-file-toolbar-menu'
import topBar from '@/components/topBar'
import toolBar from '@/components/toolBar'
import bottomBar from '@/components/bottomBar'
import footBar from '@/components/web/footBar'
import addNew from '@/components/web/addNew'
import works from '@/components/web/works'
import aiart from '@/components/web/aiart'
import nft from '@/components/web/nft'
import album from '@/components/web/album'
import albumWorks from '@/components/web/albumWorks'
import index from '@/components/web/index'
import zone from '@/components/web/zone'
import discover from '@/components/web/discover'
import user from '@/components/web/user'
import users from '@/components/web/users'
import topics from '@/components/web/topics'
import topicDetail from '@/components/web/topicDetail'
import thread from '@/components/web/thread'
import comment from '@/components/web/comment'
import sift from '@/components/web/sift'
import reply from '@/components/web/reply'
import setup from '@/components/web/setup'
import services from '@/components/web/services'
import message from '@/components/web/message'
import chat from '@/components/web/chat'
import wallet from '@/components/web/wallet'
import items from '@/components/web/items'
import task from '@/components/web/task'
import order from '@/components/web/order'
import nftorder from '@/components/web/nftorder'
import resource from '@/components/web/resource'
import resourceMng from '@/components/web/resourceMng'
import orderMng from '@/components/web/orderMng'
import resourceSelecter from '@/components/web/resourceSelecter'
import cropImage from '@/components/web/cropImage'
import gridyViewer from '@/components/viewer/gridyViewer'
import paperViewer from '@/components/viewer/paperViewer'
import brickyViewer from '@/components/viewer/brickyViewer'
import workViewer from '@/components/viewer/workViewer'
import vip from '@/components/web/vip'
import sign from '@/components/web/sign'
import post from '@/components/web/post'
import invite from '@/components/web/invite'
import praise from '@/components/web/praise'
import favor from '@/components/web/favor'
import download from '@/components/web/download'
import footprint from '@/components/web/footprint'
import relation from '@/components/web/relation'
import profile from '@/components/web/profile'
import account from '@/components/web/account'
import itemOperate from '@/components/web/itemOperate'
import pop from '@/components/web/pop'
import popPage from '@/components/web/popPage'
import actionSheet from '@/components/web/actionSheet'
import albumSelector from '@/components/web/albumSelector'
import myNotify from '@/components/web/myNotify'
import editText from '@/components/editText'
import obj from '@/components/obj'
import objPreview from '@/components/objPreview'
import scenesPreview from '@/components/scenesPreview'
import colorPicker from '@/components/colorPicker.vue'
import palette from '@/components/palette'
import newFile from '@/components/newFile'
import myPop from '@/components/myPop'
import myPublish from '@/components/myPublish'
import myLogin from '@/components/myLogin'
import axios from 'axios'
import JSZip from 'jszip'
import Cookies from 'js-cookie'
import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
import steps from '@/js/steps'
import service from '@/js/service'
import printJS from 'print-js'
export default {
  components: {
    colorPicker,
    topBar,
    toolBar,
    bottomBar,
    footBar,
    addNew,
    works,
    aiart,
    nft,
    album,
    albumWorks,
    index,
    zone,
    discover,
    user,
    users,
    topics,
    topicDetail,
    thread,
    comment,
    sift,
    reply,
    setup,
    services,
    message,
    chat,
    wallet,
    items,
    task,
    order,
    nftorder,
    resource,
    resourceMng,
    orderMng,
    resourceSelecter,
    post,
    cropImage,
    gridyViewer,
    brickyViewer,
    paperViewer,
    workViewer,
    vip,
    sign,
    invite,
    praise,
    favor,
    download,
    footprint,
    relation,
    profile,
    account,
    actionSheet,
    myNotify,
    albumSelector,
    editText,
    palette,
    newFile,
    itemOperate,
    myPop,
    pop,
    popPage,
    myPublish,
    myLogin,
    // voxel,
    obj,
    objPreview,
    scenesPreview,
    VueFileToolbarMenu
  },
  data() {
    // 初始化默认场景、对象数据
    const data = {}
    data.x = data.y = conf.state.gridSize * -1
    data.GRIDY = null
    data.bgImg4 = bgImg4
    data.touchEndLock = false
    data.myPopoverShow = false
    data.platform = utils.platform()
    data.mainHost = conf.hosts().mainHost
    data.zoneApiHost = conf.hosts().zoneApiHost
    data.worksHost = conf.hosts().worksHost
    data.apiHost = conf.hosts().apiHost
    data.feedback = utils.deepClone(conf.feedback)
    data.tools = utils.deepClone(conf.tools)
    data.exportOpts = utils.deepClone(conf.exportOpts)
    data.sizeOpts = utils.deepClone(conf.sizeOpts)
    data.catalogs = utils.deepClone(conf.catalogs)
    data.canvasRatio = utils.deepClone(conf.canvasRatio)
    data.newfileOpts = utils.deepClone(conf.newfileOpts)
    data.tmp = utils.deepClone(conf.tmp)
    data.view = utils.deepClone(conf.view)
    data.palette = utils.deepClone(conf.palette)
    data.editColor = {
      color: '',
      newColor: '',
      idx: -1
    }
    data.state = utils.deepClone(conf.state)
    data.state.reqId = service.getReqId()
    data.state.brickDt = Object.values(conf.brickDt)
    data.state.platform = data.platform
    data.file = {}
    data.db = null
    data.dbName = 'files'
    data.open_url = ''
    // 播放句柄
    data.sto = null
    // 向导句柄
    data.driver = null
    data.loadingSto = null
    data.loadingHandle = null
    data.myNotifyHandle = null
    // 用户登录 token
    data.loginStatus = false
    data.loginUserId = 0
    data.loginNickname = ''
    data.avatar = ''
    data.token = {}
    data.unreadSto = null
    data.publishItv = null
    data.checkLoginCount = 0
    data.params = {}
    data.hashKeys = ['title', 'table', 'id', 'from', 'mod', 'nickname', 'classid', 'catalogName', 'typeid', 'type', 'sort', 'sortType', 'userid', 'dialogId', 'albumid', 'publishid', 'workid', 'threadid', 'postid', 'catalogid', 'topicid', 'taskid', 'orderid', 'mobile', 'hideBack', 'data', 'mng']
    return data
  },
  computed: {
    isDesktop() {
      return this.platform.type === 'desktop'
    },
    isTablet() {
      return this.platform.type === 'tablet'
    },
    isMobile() {
      return this.platform.type === 'mobile'
    },
    isEditer() {
      return this.view.mod === 'editer'
    },
    viewMod() {
      return this.view.mod
    },
    showFootbar() {
      return !this.isDesktop && !this.view.loading && !this.isEditer
      // return (!this.isDesktop && !this.view.loading && this.view.showFootBarMods.indexOf(this.view.mod) >= 0) || (!this.isDesktop && this.view.mod === 'user' && this.view.user.hideBack)
    },
    users() {
      return this.state.users
    },
    followState() {
      return this.state.followState
    },
    hotkeys() {
      return this.getHotkeys()
    },
    // 右侧操作栏宽度
    rightSideWidth() {
      return this.view.rightSide ? (this.view.rightSideWidth + this.view.rightToggleWidth) : this.view.rightToggleWidth
    },
    leftSideWidth() {
      return this.isDesktop ? this.view.leftSideWidth : (this.view.rightToolBar ? 0 : 40)
    },
    fixWidth() {
      return this.isDesktop ? this.view.leftSideWidth : 0
    },
    headerHeight() {
      return this.isDesktop ? this.view.headerHeight : 0
    },
    // 头部样式
    headerStyle() {
      return {
        width: '100%',
        height: this.view.headerHeight + 'px'
      }
    },
    // 主样式
    mainStyle() {
      const style = {
        position: 'absolute',
        'z-index': 90,
        top: '0',
        left: '0',
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        background: '#1b1b1b',
        'text-align': 'center'
      }
      if (this.isDesktop) {
        if (this.isEditer) {
          style.top = this.view.headerHeight + 'px'
          style.left = this.view.leftSideWidth + 'px'
          style.width = 'calc((100vw - ' + this.view.leftSideWidth + 'px) - ' + this.rightSideWidth + 'px)'
          style.height = 'calc(100vh - ' + this.view.headerHeight + 'px)'
        } else {
          style.top = this.view.headerHeight + 'px'
          // style.left = 'calc((100vw - 1250px) / 2)'
          // style.width = '1250px'
          // style.height = 'calc(100vh - ' + this.view.headerHeight + 'px)'
        }
      } else {
        style['padding-left'] = this.leftSideWidth + 'px'
      }
      return style
    },
    // 左侧工具栏样式
    leftSideStyle() {
      return {
        position: 'absolute',
        'z-index': 90,
        'padding-top': '10px',
        top: this.view.headerHeight + 'px',
        left: 0,
        width: this.view.leftSideWidth + 'px',
        height: 'calc(100vh - ' + this.view.headerHeight + 'px)'
      }
    },
    // 右侧操作栏样式
    rightSideStyle() {
      return {
        position: 'absolute',
        'z-index': 90,
        top: this.view.headerHeight + 'px',
        right: 0,
        width: this.rightSideWidth + 'px',
        height: 'calc(100vh - ' + this.view.headerHeight + 'px)'
      }
    },
    // 右侧操作栏样式
    rightSideActStyle() {
      return {
        float: 'left',
        width: this.view.rightSideWidth + 'px',
        height: 'calc(100vh - ' + this.view.headerHeight + 'px)'
      }
    },
    // 场景区域样式
    scenesAreaStyle() {
      return { width: '100%', height: (this.scenes.length < 3 ? this.scenes.length * 52 : 156) + 'px', 'overflow-x': 'hidden', 'overflow-y': 'auto' }
    },
    // 对象区域样式
    objAreaStyle() {
      const scenesHeight = (this.scenes.length < 3 ? this.scenes.length * 52 : 156) + 'px'
      const height = 'calc(100vh - 245px - ' + this.view.headerHeight + 'px - ' + scenesHeight + ')'
      return { width: '100%', height: height, 'overflow-x': 'hidden', 'overflow-y': 'auto' }
      // let factor = 1
      // if (!this.isDesktop) {
      //   factor = 2
      // }
      // const rows = this.curObjs.length + 2
      // return { width: '100%', height: (rows < 3 * factor ? rows * 52 : 156 * factor) + 'px', 'overflow-x': 'hidden', 'overflow-y': 'auto' }
    },
    curPaletteId() {
      if (this.palette.paletteId === '' || this.palette.paletteId === 'none' || this.palette.paletteId === 'default') {
        return 'base'
      }
      return this.palette.paletteId
    },
    // 调色板区域样式
    paletteAreaStyle() {
      const h = (this.scenes.length < 3 ? this.scenes.length * 51 : 154) + 35 + (this.curObjs.length < 3 ? this.curObjs.length * 51 : 154) + 35 + 35 + this.view.headerHeight
      return { width: '100%', height: 'calc(100vh - ' + h + 'px)', 'overflow-x': 'hidden', 'overflow-y': 'auto' }
    },
    // 可用的调色板颜色
    paletteOut() {
      if (!this.state.palette || !this.state.palette.out) {
        return []
      }
      return this.state.palette.out.slice(0, 128)
    },
    // 当前时间
    curTime() {
      return utils.date()
    },
    // 当前打开的文件数量
    openFileNums() {
      return this.state.openFiles.length
    },
    // 当前文件画布数据
    canvas() {
      return this.file.canvas || {}
    },
    // 当前全部场景
    scenes() {
      return this.canvas.scenes || []
    },
    // 当前场景
    curScene() {
      return this.scenes[this.state.sceneIdx] || {}
    },
    sceneId() {
      return this.curScene.id || ''
    },
    // 当前场景的对象
    curObjs() {
      return this.curScene.objs || []
    },
    // 当前场景是否0个对象
    noObjs() {
      return this.curObjs.length === 0
    },
    // 当前对象
    curObj() {
      if (this.state.parentObjIdx >= 0 && this.curObjs[this.state.parentObjIdx]) {
        return this.curObjs[this.state.parentObjIdx].objs[this.state.objIdx]
      }
      return this.curObjs[this.state.objIdx]
    },
    // 父对象
    parentObj() {
      return this.curObjs[this.state.parentObjIdx]
    },
    // 子对象
    subObjs() {
      return this.parentObj ? this.parentObj.objs : ''
    },
    // 画布背景颜色
    canvasBgColor() {
      return this.canvas.showBg ? this.canvas.bgColor : ''
    },
    // 网格边框颜色
    gridColor() {
      return this.canvas.showGrid ? this.canvas.gridColor : ''
    },
    // 是否可以选择子对象
    canSelectSubs() {
      return (this.state.act === 'select' || this.state.act === 'freeSelect')
    },
    // 是否可以框选
    canFreeSelect() {
      return (this.state.act === 'default' || this.state.act === 'select' || this.state.act === 'freeSelect')
    },
    // 是否可以多选后拖拽位移
    canMultiDrag() {
      return (this.state.act === 'default' || this.state.act === 'select' || this.state.act === 'resize')
    },
    // 是否可以缩放对象
    resizable() {
      return this.state.act === 'resize' && !this.state.multiSelectBox.dragging
    },
    // 是否可以框选对象的一部分
    canSelectPart() {
      return this.state.act === 'freeSelect'
    },
    // 是否正在选择对象的一部分
    isSelectPart() {
      return this.state.act === 'freeSelect' && this.state.freeSelector.partSelect
    },
    // 是否自由取色
    canFreeColorPick() {
      return (this.state.act === 'pick' && !this.isDesktop)
    },
    // 是否可以绘图
    canDrawShape() {
      return (this.state.act === 'line' || this.state.act === 'ellipse' || this.state.act === 'rectangle' || this.state.act === 'triangle' || this.state.act === 'star' || this.state.act === 'heart')
    },
    // 是否显示多选框
    showMultiSelectBox() {
      return this.state.selectNums > 1 && this.canMultiDrag && this.state.multiSelectBox.start
    },
    // 对象快捷操作按钮
    objOptsStyle() {
      let top = this.state.canvasAreaY - 36
      let left = this.state.canvasAreaX
      if (this.parentObj) {
        top = top + this.parentObj.y * this.state.gridSize
        left = left + this.parentObj.x * this.state.gridSize
      }
      if (this.curObj) {
        top = top + this.curObj.y * this.state.gridSize
        left = left + this.curObj.x * this.state.gridSize
      }
      const style = {
        position: 'absolute',
        'z-index': 90,
        width: '150px',
        height: '32px'
      }
      style.top = 'calc((100vh - ' + this.canvas.rows * this.state.gridSize + 'px) / 2 ' + (top < 0 ? ' - ' : ' + ') + Math.abs(top) + 'px)'
      if (this.isDesktop) {
        style.left = 'calc((((100vw - ' + this.view.leftSideWidth + 'px) - ' + this.rightSideWidth + 'px) - ' + this.canvas.cols * this.state.gridSize + 'px) / 2' + (left < 0 ? ' - ' : ' + ') + Math.abs(left) + 'px)'
      } else {
        style.left = 'calc((100vw - ' + (this.canvas.cols * this.state.gridSize - this.leftSideWidth) + 'px) / 2 ' + (left < 0 ? ' - ' : ' + ') + Math.abs(left) + 'px)'
      }
      if (!this.curObj) style.display = 'none'
      return style
    },
    // 画布区域样式
    canvasAreaStyle() {
      const style = {
        width: this.canvas.cols * this.state.gridSize + 'px',
        height: this.canvas.rows * this.state.gridSize + 'px',
        position: 'relative'
      }
      style.top = 'calc((100vh - ' + this.canvas.rows * this.state.gridSize + 'px) / 2)'
      if (this.isDesktop) {
        // 非创作模式时
        if (!this.isEditer) {
          style.left = 'calc((100vw - ' + this.canvas.cols * this.state.gridSize + 'px) / 2)'
        } else {
          style.left = 'calc((((100vw - ' + this.view.leftSideWidth + 'px) - ' + this.rightSideWidth + 'px) - ' + this.canvas.cols * this.state.gridSize + 'px) / 2)'
        }
      } else {
        style.left = 'calc((100vw - ' + (this.canvas.cols * this.state.gridSize - this.leftSideWidth) + 'px) / 2)'
      }
      return style
    },
    // 背景样式
    defaultBgStyle() {
      const bgImg = {
        1: bgImg1,
        2: bgImg2,
        4: bgImg4,
        6: bgImg6,
        8: bgImg8,
        12: bgImg12,
        16: bgImg16,
        20: bgImg20,
        24: bgImg24,
        28: bgImg28,
        32: bgImg32,
        64: bgImg32
      }
      return {
        background: 'url(' + bgImg[this.state.gridSize] + ')'
      }
    },
    canSave() {
      return this.state.canSave || !this.file.save
    },
    editerHavePop() {
      if (this.isEditer) {
        const mods = this.view.mods.concat(['pop', 'myPop', 'popPage', 'myPublish', 'post'])
        for (const i in mods) {
          if (mods[i] !== 'editer' && (this.view[mods[i]].show || this.view[mods[i]].open)) {
            return true
          }
        }
      }
      return false
    },
    // 按钮状态
    btnStatus() {
      let lock = false
      if (this.state.selectNums === 1) lock = this.curObj && this.curObj.lock
      if (!this.isEditer || this.editerHavePop) return {}
      return {
        opts: this.openFileNums && this.state.selectNums === 1 && this.state.act !== 'hand' && !this.state.draggingObj && !this.state.resizeingObj,
        save: this.canSave && this.openFileNums,
        saveRemote: this.openFileNums,
        saveAs: this.openFileNums,
        back: this.state.canBack,
        redo: this.state.canRedo,
        cleanHistory: this.state.canClean,
        cut: this.state.selectNums && !lock,
        copy: this.state.selectNums,
        parse: this.state.objAct,
        del: this.state.selectNums && !lock,
        mergeDown: this.state.selectNums === 1 && this.curObjs.length > 1 && !lock,
        rename: this.openFileNums,
        selectAll: this.curObjs.length,
        unSelect: this.state.selectNums,
        group: this.state.selectNums > 1,
        ungroup: this.state.canUngroup,
        lock: this.state.canLock,
        unlock: this.state.canUnlock,
        hide: this.state.selectNums,
        gridShape: this.state.selectNums && !lock,
        colorFilter: this.state.selectNums && !lock,
        brickSize: this.state.selectNums === 1 && this.curObj && !lock,
        setPalette: this.state.selectNums && !lock,
        denoise: this.state.selectNums && !lock,
        mngColors: this.state.selectNums === 1 && !lock,
        flip: this.state.selectNums && !lock,
        rotate: this.state.selectNums && !lock,
        clear: this.state.selectNums && !lock,
        restore: this.state.selectNums && this.curObj && (this.curObj.type === 'image' || this.curObj.type === 'shape') && !lock,
        merge: this.state.canMerge && !lock,
        layTop: this.state.selectNums && this.curObjs.length > 1,
        layUp: this.state.selectNums && this.curObjs.length > 1,
        layDown: this.state.selectNums && this.curObjs.length > 1,
        layBottom: this.state.selectNums && this.curObjs.length > 1,
        alignLeft: this.state.selectNums > 1,
        alignCenter: this.state.selectNums > 1,
        alignRight: this.state.selectNums > 1,
        alignTop: this.state.selectNums > 1,
        alignMiddle: this.state.selectNums > 1,
        alignBottom: this.state.selectNums > 1,
        alignSpaceH: this.state.selectNums > 2,
        alignSpaceV: this.state.selectNums > 2,
        zoomIn: this.state.scale < 2,
        zoomOut: this.state.scale > 0.125,
        zoomReset: this.state.scale !== 1
      }
    },
    /** 主菜单栏
     * note 统一使用iconfont图标
     * 打开：node_modules\vue-file-toolbar-menu\src\Bar\BarMenuItem.vue
     * 查找行：<span v-else-if="item.menu" class="material-icons chevron">chevron_right</span>
     * 替换为：<span v-else-if="item.menu" class="iconfont my-right"></span>
     */
    menuData() {
      let latestOpen = [{ text: '无' }]
      if (this.tmp.latestOpen.length) {
        latestOpen = this.tmp.latestOpen
      }
      const aspectItems = []
      const getAspectItem = (ratio) => {
        return { text: ratio.label, class: this.canvas.aspectFactor === ratio.id ? 'iconfont my-check' : '', disabled: !this.isEditer || !this.openFileNums, click: () => this.changeAspectFactor(ratio.id) }
      }
      for (var i in this.canvasRatio) {
        aspectItems.push(getAspectItem(this.canvasRatio[i]))
      }
      const sizeItems = []
      const canvasSizeOpts = this.sizeOpts[this.canvas.aspectFactor] || []
      const getSizeItem = (ratio) => {
        return { text: ratio.cols + ' x ' + ratio.rows, class: (this.canvas.cols === ratio.cols && this.canvas.rows === ratio.rows) ? 'iconfont my-check' : '', disabled: !this.isEditer || !this.openFileNums, click: () => this.setCanvasSize(ratio.cols, ratio.rows) }
      }
      let hitSize = false
      if (canvasSizeOpts.length) {
        for (const i in canvasSizeOpts) {
          if (!hitSize) hitSize = (this.canvas && this.canvas.cols === canvasSizeOpts[i].cols && this.canvas.rows === canvasSizeOpts[i].rows)
          sizeItems.push(getSizeItem(canvasSizeOpts[i]))
        }
      }
      sizeItems.push({ text: '自定义' + (!hitSize ? ' (' + this.canvas.cols + ' x ' + this.canvas.rows + ')' : ''), hotkey: 'Shift+J', class: !hitSize ? 'iconfont my-check' : '', disabled: !this.isEditer || !this.openFileNums, click: () => this.openSetCanvasSize() })
      const openMenu = [
        { text: '本地文件', hotkey: 'Ctrl+F', click: () => (this.openLocalFile()) },
        { text: '本地图片', hotkey: 'Ctrl+O', click: () => (this.openLocalImages('open')) },
        { text: '网络图片', hotkey: 'Ctrl+Shift+O', click: () => (this.openWebImage('open')) },
        { text: '我的草稿', hotkey: 'Ctrl+D', click: () => (this.openDraft('draft')) },
        { text: '我的作品', hotkey: 'Ctrl+Shift+D', click: () => (this.openResource('work')) }
      ]
      openMenu.push({ text: '快速打开', hotkey: 'Shift+O', click: () => (this.openLocalImages('open', false, '', '', 0, 0, true)) })
      const importMenu = [
        { text: '本地图片', hotkey: 'Ctrl+I', disabled: !this.openFileNums, click: () => (this.openLocalImages('import')) },
        { text: '网络图片', hotkey: 'Ctrl+Shift+I', disabled: !this.openFileNums, click: () => (this.openWebImage('import')) }
      ]
      importMenu.push({ text: '快速导入', hotkey: 'Shift+Q', click: () => (this.openLocalImages('import', false, '', '', 0, 0, true)) })
      const exportMenu = [
        { text: 'PNG', disabled: !this.openFileNums, click: () => (this.exportFile(this.file, 'png', 'auto', { gridSize: this.state.gridSize, brickfy: this.state.brickfy }, this.state.sceneIdx)) },
        { text: 'JPG', disabled: !this.openFileNums, click: () => (this.exportFile(this.file, 'jpg', 'auto', { gridSize: this.state.gridSize, brickfy: this.state.brickfy }, this.state.sceneIdx)) },
        { text: 'WEBP', disabled: !this.openFileNums, click: () => (this.exportFile(this.file, 'webp', 'auto', { gridSize: this.state.gridSize, brickfy: this.state.brickfy }, this.state.sceneIdx)) },
        // { text: 'PDF', class: 'iconfont my-vip', disabled: !this.openFileNums, click: () => (this.exportFile(this.file, 'pdf', 'pixel', { gridSize: this.state.gridSize, brickfy: this.state.brickfy }, this.state.sceneIdx, true)) },
        // { text: 'ICO', class: 'iconfont my-vip', disabled: !this.openFileNums, click: () => (this.exportFile(this.file, 'ico', 'pixel', { gridSize: this.state.gridSize, brickfy: this.state.brickfy }, this.state.sceneIdx, true)) },
        { text: 'GIF', class: 'iconfont my-vip', disabled: !this.openFileNums, click: () => (this.exportFile(this.file, 'gif', 'auto', { gridSize: this.state.gridSize, brickfy: this.state.brickfy }, this.state.sceneIdx, true)) },
        // { text: '虚拟拼图', class: 'iconfont my-vip', disabled: !this.openFileNums, click: () => (this.exportFile(this.file, 'lxf', 'advance', { gridSize: this.state.gridSize, brickfy: this.state.brickfy }, this.state.sceneIdx, true)) },
        { text: 'EXCEL表格画', class: 'iconfont my-vip', disabled: !this.openFileNums, click: () => (this.exportFile(this.file, 'xls', 'advance', { gridSize: this.state.gridSize, brickfy: this.state.brickfy }, this.state.sceneIdx, true)) },
        { text: '拼图效果图', class: 'iconfont my-vip', disabled: !this.openFileNums, click: () => (this.exportFile(this.file, 'png', 'brick', { gridSize: this.state.gridSize, workTypeid: 2, paletteId: 'brickfy', brickfy: true }, this.state.sceneIdx, true, null, true)) },
        { text: '拼图图纸', class: 'iconfont my-key', disabled: !this.openFileNums, click: () => (this.exportFile(this.file, 'paper', 'advance', { gridSize: this.state.gridSize, workTypeid: 2, paletteId: 'brickfy', brickfy: true }, this.state.sceneIdx, true, null, true)) },
        { text: 'GRIDY', disabled: !this.openFileNums, click: () => (this.exportFile(this.file, 'gridy', '', { gridSize: this.state.gridSize, brickfy: this.state.brickfy }, this.state.sceneIdx)) }
      ]
      return [
        {
          text: '文件',
          menu_width: 200,
          menu: [
            { text: '新建...', hotkey: 'Ctrl+Alt+N', click: () => (this.view.createNewfile = true) },
            {
              text: '打开',
              menu_width: 200,
              menu: openMenu
            },
            {
              text: '最近打开',
              menu_width: 200,
              menu: latestOpen
            },
            { is: 'separator' },
            { text: '保存', hotkey: 'Ctrl+S', disabled: !this.btnStatus.save, click: this.save },
            { text: '另存为...', hotkey: 'Ctrl+Shift+S', disabled: !this.btnStatus.saveAs, click: () => this.saveAs() },
            { is: 'separator' },
            { text: '同步到云端', hotkey: 'Alt+S', disabled: !this.btnStatus.saveRemote, click: () => this.saveRemote() },
            { is: 'separator' },
            { text: '重命名', hotkey: 'Shift+R', disabled: !this.btnStatus.rename, click: this.rename },
            { is: 'separator' },
            {
              text: '导入',
              disabled: !this.isEditer,
              menu_width: 200,
              menu: importMenu
            },
            {
              text: '导出',
              disabled: !this.isEditer,
              menu_width: 200,
              menu: exportMenu
            },
            { text: '导出预览...', hotkey: 'Ctrl+Shift+X', disabled: !this.openFileNums, click: this.exportPreview },
            { is: 'separator' },
            { text: '定制拼图...', hotkey: 'Ctrl+Shift+B', disabled: !this.openFileNums, click: () => (this.diy()) },
            { is: 'separator' },
            { text: '发布...', hotkey: 'Ctrl+Shift+P', disabled: !this.openFileNums, click: () => (this.publishWork()) },
            { text: '生成分享图', hotkey: 'Ctrl+Shift+T', disabled: !this.openFileNums, click: () => (this.createPOP()) },
            { text: '复制分享链接', hotkey: 'Ctrl+Shift+F', disabled: !this.openFileNums, click: () => (this.copyShareUrl()) },
            { is: 'separator' },
            { text: '打印', hotkey: 'Ctrl+P', disabled: !this.openFileNums, click: () => (this.printFile(true)) }
          ]
        },
        {
          text: '编辑',
          menu_width: 200,
          menu: [
            { text: '撤销', hotkey: 'Ctrl+Z', disabled: !this.btnStatus.back, click: this.back },
            { text: '重做', hotkey: 'Ctrl+Y', disabled: !this.btnStatus.redo, click: this.redo },
            { text: '清空历史', disabled: !this.btnStatus.cleanHistory, click: () => this.cleanHistory() },
            { is: 'separator' },
            { text: '剪切', hotkey: 'Ctrl+X', disabled: !this.btnStatus.cut, click: this.cut },
            { text: '复制', hotkey: 'Ctrl+C', disabled: !this.btnStatus.copy, click: this.copy },
            { text: '粘贴', hotkey: 'Ctrl+V', disabled: !this.btnStatus.parse, click: () => this.parse() },
            { text: '删除', hotkey: 'Del', disabled: !this.btnStatus.del, click: () => this.delete(false) },
            { is: 'separator' },
            { text: '全选', hotkey: 'Ctrl+A', disabled: !this.btnStatus.selectAll, click: () => this.selectAll(true) },
            { text: '取消选择', hotkey: 'Ctrl+Shift+A', disabled: !this.btnStatus.unSelect, click: () => this.selectAll(false) }
          ]
        },
        {
          text: '修改',
          menu_width: 200,
          menu: [
            {
              text: '排列',
              menu_width: 200,
              disabled: !this.isEditer,
              menu: [
                { text: '置于顶层', hotkey: 'Ctrl+Shift+]', class: 'iconfont my-lay-top', disabled: !this.btnStatus.layTop, click: () => this.setLayer('top') },
                { text: '置于底层', hotkey: 'Ctrl+Shift+[', class: 'iconfont my-lay-bottom', disabled: !this.btnStatus.layBottom, click: () => this.setLayer('bottom') },
                { text: '上移一层', hotkey: 'Ctrl+]', class: 'iconfont my-lay-up', disabled: !this.btnStatus.layUp, click: () => this.setLayer('+') },
                { text: '下移一层', hotkey: 'Ctrl+[', class: 'iconfont my-lay-down', disabled: !this.btnStatus.layDown, click: () => this.setLayer('-') }
              ]
            },
            { is: 'separator' },
            {
              text: '对齐',
              menu_width: 200,
              disabled: !this.isEditer,
              menu: [
                { text: '左对齐', hotkey: 'Ctrl+1', class: 'iconfont my-align-left', disabled: !this.btnStatus.alignLeft, click: () => this.align('left') },
                { text: '水平居中', hotkey: 'Ctrl+2', class: 'iconfont my-align-center', disabled: !this.btnStatus.alignCenter, click: () => this.align('center') },
                { text: '右对齐', hotkey: 'Ctrl+3', class: 'iconfont my-align-right', disabled: !this.btnStatus.alignRight, click: () => this.align('right') },
                { is: 'separator' },
                { text: '顶对齐', hotkey: 'Ctrl+4', class: 'iconfont my-align-top', disabled: !this.btnStatus.alignTop, click: () => this.align('top') },
                { text: '垂直居中', hotkey: 'Ctrl+5', class: 'iconfont my-align-middle', disabled: !this.btnStatus.alignMiddle, click: () => this.align('middle') },
                { text: '底对齐', hotkey: 'Ctrl+6', class: 'iconfont my-align-bottom', disabled: !this.btnStatus.alignBottom, click: () => this.align('bottom') },
                { is: 'separator' },
                { text: '水平等间距', hotkey: 'Ctrl+7', class: 'iconfont my-align-space-h', disabled: !this.btnStatus.alignSpaceH, click: () => this.align('spaceH') },
                { text: '垂直等间距', hotkey: 'Ctrl+8', class: 'iconfont my-align-space-v', disabled: !this.btnStatus.alignSpaceV, click: () => this.align('spaceV') }
              ]
            },
            { is: 'separator' },
            {
              text: '翻转',
              menu_width: 200,
              disabled: !this.isEditer,
              menu: [
                { text: '水平翻转', hotkey: 'Shift+H', class: 'iconfont my-flip-h', disabled: !this.btnStatus.flip, click: () => this.flip('h') },
                { text: '垂直翻转', hotkey: 'Shift+V', class: 'iconfont my-flip-v', disabled: !this.btnStatus.flip, click: () => this.flip('v') }
              ]
            },
            { is: 'separator' },
            {
              text: '旋转',
              menu_width: 200,
              disabled: !this.isEditer,
              menu: [
                { text: '顺时针旋转90°', hotkey: 'Shift+W', class: 'iconfont my-rotate-right', disabled: !this.btnStatus.rotate, click: () => this.rotate('w') },
                { text: '逆时针旋转90°', hotkey: 'Shift+I', class: 'iconfont my-rotate-left', disabled: !this.btnStatus.rotate, click: () => this.rotate('i') }
              ]
            },
            { is: 'separator' },
            { text: '锁定', hotkey: 'Ctrl+L', disabled: !this.btnStatus.lock, click: this.lockSelectObjs },
            { text: '解除锁定', hotkey: 'Ctrl+Shift+L', disabled: !this.btnStatus.unlock, click: this.unlockSelectObjs },
            { is: 'separator' },
            { text: '隐藏', hotkey: 'Ctrl+H', disabled: !this.btnStatus.hide, click: this.hideSelectObjs },
            { is: 'separator' },
            { text: '组合', hotkey: 'Ctrl+G', disabled: !this.btnStatus.group, click: this.group },
            { text: '取消组合', hotkey: 'Ctrl+Shift+G', disabled: !this.btnStatus.ungroup, click: this.ungroup },
            { is: 'separator' },
            { text: '合并', hotkey: 'Ctrl+M', disabled: !this.btnStatus.merge, click: this.merge },
            { is: 'separator' },
            { text: '恢复图形图像', hotkey: 'Ctrl+Shift+R', disabled: !this.btnStatus.restore, click: this.restore },
            { text: '清除对象', hotkey: 'Ctrl+Shift+C', disabled: !this.btnStatus.clear, click: this.clear }
          ]
        },
        {
          text: '对象',
          menu_width: 200,
          menu: [
            {
              text: '网格形状',
              menu_width: 200,
              disabled: !this.isEditer,
              menu: [
                { text: '方形', hotkey: 'Shift+S', class: this.state.fillShape === 'square' ? 'iconfont my-check' : '', disabled: !this.btnStatus.gridShape, click: () => this.setFillShape('square') },
                { text: '圆形', hotkey: 'Shift+C', class: this.state.fillShape === 'circle' ? 'iconfont my-check' : '', disabled: !this.btnStatus.gridShape, click: () => this.setFillShape('circle') },
                // { text: '三角形', hotkey: 'Shift+T', class: this.state.fillShape === 'triangle' ? 'iconfont my-check' : '', disabled: !this.btnStatus.gridShape, click: () => this.setFillShape('triangle') },
                { text: '默认', hotkey: 'Shift+N', class: this.state.fillShape === '' ? 'iconfont my-check' : '', disabled: !this.btnStatus.gridShape, click: () => this.setFillShape('') }
              ]
            },
            // {
            //   text: '网格大小',
            //   menu_width: 200,
            //   disabled: !this.isEditer,
            //   menu: [
            //     { text: '大', hotkey: 'Shift+1', class: this.state.brickSizeId === 0 ? 'iconfont my-check' : '', disabled: !this.btnStatus.brickSize, click: () => this.setBricksize(0) },
            //     { text: '默认', hotkey: 'Shift+2', class: this.state.brickSizeId === 1 ? 'iconfont my-check' : '', disabled: !this.btnStatus.brickSize, click: () => this.setBricksize(1) },
            //     { text: '小', hotkey: 'Shift+3', class: this.state.brickSizeId === 2 ? 'iconfont my-check' : '', disabled: !this.btnStatus.brickSize, click: () => this.setBricksize(2) }
            //     // { text: '极小', hotkey: 'Shift+4', class: this.state.brickSizeId === 3 ? 'iconfont my-check' : '', disabled: !this.btnStatus.brickSize, click: () => this.setBricksize(3) }
            //   ]
            // },
            {
              text: '对象缩放',
              menu_width: 200,
              disabled: !this.isEditer,
              menu: [
                { text: '50%', hotkey: 'Shift+-', disabled: !this.isEditer || !this.state.selectNums, click: () => this.setSize('scale', 0.5) },
                { text: '75%', hotkey: 'Shift+9', disabled: !this.isEditer || !this.state.selectNums, click: () => this.setSize('scale', 0.75) },
                { text: '150%', hotkey: 'Shift+0', disabled: !this.isEditer || !this.state.selectNums, click: () => this.setSize('scale', 1.5) },
                { text: '200%', hotkey: 'Shift++', disabled: !this.isEditer || !this.state.selectNums, click: () => this.setSize('scale', 2) }
              ]
            },
            {
              text: '对象大小',
              menu_width: 200,
              disabled: !this.isEditer,
              menu: [
                { text: '高度+1', hotkey: 'Shift+↓', disabled: !this.isEditer || !this.state.selectNums, click: () => this.setSize('incRows', 1) },
                { text: '高度-1', hotkey: 'Shift+↑', disabled: !this.isEditer || !this.state.selectNums, click: () => this.setSize('decRows', 1) },
                { text: '宽度+1', hotkey: 'Shift+→', disabled: !this.isEditer || !this.state.selectNums, click: () => this.setSize('incCols', 1) },
                { text: '宽度-1', hotkey: 'Shift+←', disabled: !this.isEditer || !this.state.selectNums, click: () => this.setSize('decCols', 1) }
              ]
            },
            {
              text: '对象位置',
              menu_width: 200,
              disabled: !this.isEditer,
              menu: [
                { text: '向下移动', hotkey: '↓', disabled: !this.isEditer || !this.state.selectNums, click: () => this.callSelectObjs('move', ['down']) },
                { text: '向上移动', hotkey: '↑', disabled: !this.isEditer || !this.state.selectNums, click: () => this.callSelectObjs('move', ['up']) },
                { text: '向右移动', hotkey: '→', disabled: !this.isEditer || !this.state.selectNums, click: () => this.callSelectObjs('move', ['right']) },
                { text: '向左移动', hotkey: '←', disabled: !this.isEditer || !this.state.selectNums, click: () => this.callSelectObjs('move', ['left']) }
              ]
            }
          ]
        },
        {
          text: '画布',
          menu_width: 200,
          menu: [
            {
              text: '画布比例',
              menu_width: 200,
              disabled: !this.isEditer,
              menu: aspectItems
            },
            {
              text: '画布大小',
              menu_width: 220,
              disabled: !this.isEditer,
              menu: sizeItems
            },
            { is: 'separator' },
            { text: '符合画布', hotkey: 'Shift+F', disabled: !this.isEditer || this.noObjs, click: this.autoCanvasSize }
          ]
        },
        {
          text: '视图',
          menu_width: 200,
          menu: [
            { text: '放大', hotkey: 'WheelUp', disabled: !this.btnStatus.zoomIn, click: () => this.zoom('+') },
            { text: '缩小', hotkey: 'WheelDown', disabled: !this.btnStatus.zoomOut, click: () => this.zoom('-') },
            { is: 'separator' },
            { text: '25%', class: this.state.gridSize === 2 ? 'iconfont my-check' : '', click: () => this.zoom('0.25') },
            { text: '50%', class: this.state.gridSize === 4 ? 'iconfont my-check' : '', click: () => this.zoom('0.5') },
            { text: '75%', class: this.state.gridSize === 6 ? 'iconfont my-check' : '', click: () => this.zoom('0.75') },
            { text: '100%', class: this.state.gridSize === 8 ? 'iconfont my-check' : '', click: () => this.zoom('1') },
            { text: '150%', class: this.state.gridSize === 12 ? 'iconfont my-check' : '', click: () => this.zoom('1.5') },
            { text: '200%', class: this.state.gridSize === 16 ? 'iconfont my-check' : '', click: () => this.zoom('2') },
            { is: 'separator' },
            { text: '重置缩放', hotkey: 'Alt+1', disabled: !this.btnStatus.zoomReset, click: () => this.zoom('1') }
          ]
        },
        {
          text: '帮助',
          menu_width: 200,
          menu: [
            { text: '快速入门', hotkey: 'Shift+F1', click: () => this.guide() },
            { text: '快捷键列表', hotkey: 'Shift+F2', click: () => this.openMyPop({ tab: 'hotkeys', btn: '', group: 'about' }) },
            { is: 'separator' },
            { text: '发送反馈', click: () => this.openPopPage({ 'type': 'feedback' }) },
            { text: '关于我们', click: () => this.openPopPage({ 'type': 'aboutUs' }) },
            { text: '检查更新', click: () => this.openPopPage({ 'type': 'checkUpdate' }) }
          ]
        }
      ]
    }
  },
  watch: {
    'openFileNums': {
      handler(nums) {
        if (this.isEditer) this.view.createNewfile = !nums
      }
    },
    'view.createNewfile': {
      handler() {
        if (this.view.createNewfile) {
          this.traceEvent('showCreateNewfile')
          this.traceEvent('goto')
        }
      }
    },
    // 'file': {
    //   handler(val) {
    //     setTimeout(() => {
    //       this.state.canSave = true
    //     }, 1000)
    //     utils.debounce(this.saveIt, 1000, 'saveItTimer')()
    //   },
    //   deep: true
    // },
    'state.act': {
      handler(act) {
        utils.debounce(() => { this.initFreeSelector(this.canFreeSelect) }, 100, 'initFreeSelectorTimer')()
        if (this.canMultiDrag) {
          this.calcMultiSelectBox()
        } else if (this.canDrawShape) {
          this.initDrawShape(true)
        } else if (this.canFreeColorPick) {
          this.initFreeColorPick()
        }
        this.state.freeColorPicker.open = this.canFreeColorPick
      }
    },
    'state.curPaletteId': {
      handler() {
        this.usePalette(this.state.curPaletteId)
      }
    },
    'viewMod': {
      handler() {
        if (this.viewMod === 'editer') {
          this.state.canvasIniting = true
          setTimeout(() => {
            this.drawCanvasBg()
            this.state.canvasIniting = false
          }, 50)
        }
      }
    }
  },
  mounted() {
    this.GRIDY = new GRIDY()
    this.driver = new Driver({
      doneBtnText: '完成',
      closeBtnText: '关闭',
      nextBtnText: '下一步',
      prevBtnText: '上一步',
      onDeselected: this.onDeselected
    })
    document.oncontextmenu = function(e) {
      return e.target.nodeName === 'INPUT' || e.target.nodeName === 'TEXTAREA'
    }
    document.onkeydown = this.onkeydown
    document.onkeyup = this.onkeyup
    // 获取参数
    this.params = utils.getHashParams()
    this.db = new Db()
    this.db.clean('acts')
    this.initFreeSelector(this.canFreeSelect)
    this.updateLatestOpen()
    this.getToken()
    if (this.loginStatus) {
      if (!this.token.nickname) {
        this.view.login.mod = 'active'
        this.view.login.show = true
      }
      this.getUnread()
    // } else {
    //   if (this.isDesktop && !this.params.workid && !this.params.url) this.login()
    }
    // 设置邀请id
    if (this.params.inviteid) window.localStorage.setItem('inviteid', this.params.inviteid)
    this.checkUpdate()
    this.ssoLogin()
    this.applyParams(() => { this.goto(this.viewMod, this.params) })
    setTimeout(() => {
      this.platform.loginStatus = this.loginStatus
      this.traceEvent('mounted', '', this.platform)
      this.traceEvent('goto')
    }, 100)
    window.onhashchange = () => {
      const params = utils.getHashParams()
      if (params.referrer === 'ga') return
      this.params = params
      this.ssoLogin()
      this.applyParams(() => { this.goto(this.viewMod) })
    }
    window.goto = this.goto
    window.openLink = this.openLink
    window.traceEvent = this.traceEvent
    // 移除系列号缓存
    service.removeSN()
  },
  methods: {
    // 自动检测登录状态
    autoCheckLogin() {
      if (this.loginStatus) return
      if (this.checkLoginCount > 10) {
        this.checkLoginCount = 0
        this.login()
      } else {
        this.checkLoginCount++
      }
    },
    // 记录用户事件
    traceEvent(EventId, Label = '', MapKv = {}) {
      // eslint-disable-next-line
      if (env !== 'pro') return
      const search = utils.param2Obj(window.location.search)
      MapKv.td_channelid = search.td_channelid || 'default'
      MapKv.loginStatus = this.loginStatus
      // eslint-disable-next-line
      if (TDAPP) {
        // eslint-disable-next-line
        TDAPP.onEvent(EventId, Label, MapKv)
      } else {
        setTimeout(() => {
          this.traceEvent(EventId, Label, MapKv)
        }, 1000)
      }
    },
    // 拖动中
    onHandDrag(x, y) {
      this.state.canvasAreaX = x
      this.state.canvasAreaY = y
    },
    openSift(type) {
      if (!type) return
      this.view.sift.type = type
      this.view.sift.show = true
      this.traceEvent('openSift', '', { type: type })
    },
    // 保存访问历史
    setViewHistory(mod, dt) {
      dt = dt || {}
      if (!mod || mod === dt.from) return
      this.view.historyMods.unshift([mod, utils.deepClone(dt)])
      // for (const idx in this.view.historyMods) {
      //   console.log(JSON.stringify(this.view.historyMods[idx]))
      // }
    },
    // 转场
    goto(mod, dt, pop, refreshHash = false) {
      let oldViewMod = ''
      let oldFrom = ''
      if (!mod) {
        if (this.view.historyMods.length) {
          const self = this.view.historyMods.shift()
          if (self && self.length > 1) {
            oldViewMod = self[0]
            oldFrom = self[1].from || ''
          }
          if (this.view.historyMods.length) {
            const from = this.view.historyMods.shift()
            if (from && from.length > 1) {
              oldViewMod = from[0]
              oldFrom = from[1].from || ''
            }
            if (from && from.length && from[1].from !== self[0]) {
              mod = from[0]
              dt = from[1]
            }
          } else {
            mod = this.viewMod === self[1].from ? 'index' : self[1].from
          }
        } else {
          mod = (this.view[this.viewMod] && this.view[this.viewMod].from !== this.viewMod) ? this.view[this.viewMod].from : ''
        }
      }
      mod = mod || 'index'
      dt = dt || {}
      dt.from = dt.from || this.viewMod
      // 避免死循环
      if ((mod === oldFrom && dt.from === oldViewMod)) dt.from = ''
      if (!this.view[mod]) return
      this.setViewHistory(mod, dt)
      const mods = this.view.mods
      if (pop || this.view[mod].pop) {
        this.view[mod].show = true
        this.view[mod].pop = true
      } else {
        for (const i in mods) {
          if (this.view[mods[i]]) this.view[mods[i]].show = (mod === mods[i])
        }
        this.view.mod = mod
      }
      const setAttrs = (modal) => {
        const keys = this.hashKeys
        for (var i in keys) {
          if (typeof this.view[modal][keys[i]] !== 'undefined') {
            if (dt[keys[i]] || this.params[keys[i]]) {
              this.view[modal][keys[i]] = dt[keys[i]] || this.params[keys[i]]
              if (keys[i] && keys[i].toUpperCase().substr(-2) === 'ID') this.view[modal][keys[i]] = parseInt(this.view[modal][keys[i]])
            }
          }
        }
      }
      setAttrs(mod)
      if (mod === 'user') {
        this.view[mod].hideBack = !this.showFootbar ? false : (dt.hideBack || false)
        if (dt.mng !== 'yes') this.view[mod].mng = 'no'
        if (dt.type) this.view[mod].type = dt.type
        if (dt.typeid) this.view[mod].typeid = parseInt(dt.typeid)
      } else if (mod === 'index') {
        this.view.works.classid = 0
      } else if (mod === 'albumWorks') {
        this.view.albumWorks.typeid = dt.typeid || 0
      } else if (mod === 'editer') {
        this.state.act = 'default'
        this.view.createNewfile = !this.openFileNums
        setTimeout(() => {
          this.initFreeSelector(this.canFreeSelect)
          this.state.showScenes = this.scenes.length > 1 && !this.isDesktop
        }, 100)
      }
      if (!pop && !this.view[mod].pop) {
        this.setLocationHash()
      }
      this.params = {}
      this.view.loading = false
      this.traceEvent('goto')
      this.traceEvent('goto_' + mod)
    },
    // 设置url
    setLocationHash(locationHash) {
      let hash = '/?referrer=ga'
      if (locationHash) {
        hash = hash + '&' + locationHash
        hash = hash.replace('&&', '&')
      } else if (this.viewMod) {
        const params = this.view[this.viewMod] || {}
        hash = hash + '&mod=' + this.viewMod
        const keys = this.hashKeys
        for (var i in keys) {
          if (keys[i] !== 'mod' && typeof params[keys[i]] !== 'undefined') hash = hash + '&' + [keys[i]] + '=' + params[keys[i]]
        }
      }
      window.location.hash = hash
    },
    ssoLogin() {
      const params = this.params || {}
      // 单点登录
      if (params.session && params.sso_token) {
        service.ssoLogin(params.session, params.sso_token, (msg, type) => {
          if (type === 'success') {
            this.getToken()
            if (this.loginStatus) {
              this.getUnread()
            }
          }
        })
      }
      // window.location.hash = '/?from=self'
    },
    applyParams(cb) {
      const defaultMod = (process && process.env && process.env.IS_ELECTRON) ? 'editer' : 'index'
      const params = this.params || {}
      this.view.mod = params.mod || defaultMod
      this.view.moreWorks.show = false
      this.view.createNewfile = this.view.mod === 'editer'
      // 打开作品或图片
      if (this.isEditer) {
        if (params.workid) {
          this.openRemoteWork(params.workid, false, params.userid, !this.isDesktop, cb)
        } else if (params.url) {
          params.name = params.name ? decodeURIComponent(params.name) : '网络图片'
          this.params = params
          this.importWebimage(decodeURIComponent(params.url), params.name, cb)
        } else {
          cb && cb(true)
        }
      } else {
        cb && cb(true)
      }
      // window.location.hash = '/?from=self'
    },
    // 显示操作项
    showActionSheet(userid, table, item, cb, btn) {
      if (!item || !table) return cb && cb()
      const actions = {
        show: true,
        title: '请选择',
        btns: []
      }
      const reportTypes = { 'work': 3, 'album': 4 }
      const reportType = reportTypes[table]
      const id = table + 'id'
      if (btn) actions.btns.push(btn)
      if ((userid && userid === this.loginUserId) || (this.loginUserId && this.loginUserId < 10000)) {
        actions.btns.push({ title: '设为' + (item.public ? '私密' : '公开'), cb: () => { this.actionOperate(table, item, item[id], { public: item.public ? 0 : 1 }, cb) } })
        if (table === 'album' || table === 'work') {
          actions.btns.push({
            title: '编辑',
            cb: () => {
              if (table === 'album') {
                this.openResourceMng({ 'table': 'album', 'act': 'edit', 'id': item[id] })
              } else {
                this.editRemoteWork(item.workid, true, this.loginUserId)
              }
            }
          })
        }
        if (item.flag || (this.loginUserId && this.loginUserId < 10000)) {
          actions.btns.push({
            title: item.flag ? '删除' : '还原',
            cb: () => {
              if (item.flag) {
                this.confirm('确定删除吗？', (action) => {
                  if (action === 'confirm') this.actionOperate(table, item, item[id], { flag: item.flag ? 0 : 1 }, cb)
                })
              } else {
                this.actionOperate(table, item, item[id], { flag: item.flag ? 0 : 1 }, cb)
              }
            } })
        }
      }
      if (this.loginUserId && this.loginUserId < 10000) {
        actions.btns.push({ title: item.check ? '取消审核' : '审核通过', cb: () => { this.actionOperate(table, item, item[id], { check: item.check ? 0 : 1 }, cb) } })
        actions.btns.push({ title: item.hot ? '取消热门' : '热门', cb: () => { this.actionOperate(table, item, item[id], { hot: item.hot ? 0 : 1 }, cb) } })
        actions.btns.push({ title: item.best ? '取消推荐' : '推荐', cb: () => { this.actionOperate(table, item, item[id], { best: item.best ? 0 : 1 }, cb) } })
        actions.btns.push({ title: item.original ? '取消原创' : '原创', cb: () => { this.actionOperate(table, item, item[id], { original: item.original ? 0 : 1 }, cb) } })
        actions.btns.push({ title: '热荐原开关', cb: () => { this.actionOperate(table, item, item[id], { original: item.original ? 0 : 1, best: item.best ? 0 : 1, hot: item.hot ? 0 : 1 }, cb) } })
      }
      const postId = ''
      const threadId = ''
      let workId, albumId
      if (table === 'album') albumId = item[id]
      if (table === 'work') workId = item[id]
      actions.btns = actions.btns.concat([
        { title: '广告垃圾', cb: () => { this.reports(threadId, reportType, '广告垃圾', this.loginUserId, postId, workId, albumId) } },
        { title: '违规内容', cb: () => { this.reports(threadId, reportType, '违规内容', this.loginUserId, postId, workId, albumId) } },
        { title: '恶意灌水', cb: () => { this.reports(threadId, reportType, '恶意灌水', this.loginUserId, postId, workId, albumId) } },
        { title: '重复发帖', cb: () => { this.reports(threadId, reportType, '重复发帖', this.loginUserId, postId, workId, albumId) } },
        { title: '其他理由', cb: () => { this.setReportReason(threadId, reportType, this.loginUserId, postId, workId, albumId) } }
      ])
      this.view.actionSheet = actions
    },
    // 数据操作
    actionOperate(table, item, id, data, cb) {
      service.put(table, id, data, (dt, type) => {
        if (type === 'success') {
          if (typeof data.hot !== 'undefined' && typeof data.original !== 'undefined' && typeof data.best !== 'undefined') {
            this.message('操作成功', type)
          } else if (typeof data.check !== 'undefined') {
            this.message('已' + (data.check ? '审核通过' : '取消审核'), type)
          } else if (typeof data.public !== 'undefined') {
            this.message('已设为' + (data.public ? '公开' : '私密'), type)
          } else if (typeof data.hot !== 'undefined') {
            this.message('已' + (data.hot ? '设为' : '取消') + '热门', type)
          } else if (typeof data.best !== 'undefined') {
            this.message('已' + (data.best ? '' : '取消') + '推荐', type)
          } else if (typeof data.original !== 'undefined') {
            this.message('已' + (data.original ? '设为' : '取消') + '原创', type)
          } else if (typeof data.flag !== 'undefined') {
            this.message('已' + (data.flag ? '还原' : '删除'), type)
          }
          for (const k in data) {
            item[k] = data[k]
          }
          if (table === 'work' || table === 'album') {
            // 同步数据
            const suffix = utils.titleCase(table)
            if (item.public && item.check && item.flag) {
              delete this.state['deleteHot' + suffix][id]
              delete this.state['deleteBest' + suffix][id]
              delete this.state['deleteOriginal' + suffix][id]
              delete this.state['delete' + suffix][id]
              delete this.state['update' + suffix][id]
            }
            if (!item.hot) {
              this.state['deleteHot' + suffix][id] = true
            }
            if (!item.best) {
              this.state['deleteBest' + suffix][id] = true
            }
            if (!item.original) {
              this.state['deleteOriginal' + suffix][id] = true
            }
            if (!item.public || !item.check || !item.flag) {
              this.state['deleteHot' + suffix][id] = true
              this.state['deleteBest' + suffix][id] = true
              this.state['deleteOriginal' + suffix][id] = true
              this.state['delete' + suffix][id] = true
            }
            this.state['update' + suffix][id] = data
          }
        } else {
          this.message(dt, type)
        }
        cb && cb(id, data, dt, type)
      }, true)
    },
    // 获取账户交易状态 -6禁用 -5审核中 -4审核拒绝 -3审核忽略 -2不存在 -1实名认证不通过 0-未实名 1-实名认证中 2-已实名认证 3-未设置交易密码 4-账号支持交易
    getTradeState(userid, receiver, cb) {
      if (userid || receiver) {
        const params = { type: 'trade_status' }
        if (userid) params.id = userid
        if (receiver) {
          if (receiver.length === 11 && utils.checkNow('mobile', receiver, true)) {
            params.mobile = receiver
          } else {
            params.chain_account = receiver
          }
        }
        service.actionGet('user', params, (ret, type) => {
          if (type === 'success') {
            return cb && cb(ret.data)
          }
          cb && cb()
        }, true)
      } else {
        cb && cb()
      }
    },
    // 获取用户信息
    getUser(userid, cb, simple) {
      if (userid) {
        const params = {}
        if (simple) params.simple = simple
        service.batchGet('user', [userid], (ret, type) => {
          if (type === 'success') {
            if (ret.data && ret.data.count) {
              this.state.users[userid] = ret.data.users[userid]
              this.followState[userid] = typeof ret.data.follow[userid] === 'undefined' ? -1 : ret.data.follow[userid]
              return cb && cb(ret.data.users[userid])
            }
          }
          cb && cb()
        }, false, params)
      } else {
        cb && cb()
      }
    },
    // 自动接受任务
    async autoAcceptTask(cb) {
      const url = 'lock:autoAcceptTask'
      const ttl = 600
      const lock = await service.getCache(url)
      if (lock) return
      // 加锁，每小时自动接受一次
      service.addCache(url, url, 'success', ttl)
      const params = {
        simple: 1,
        mod: 'auto'
      }
      service.listGet('task', params, cb, true)
    },
    // 判断是Vip
    checkVip(cb) {
      if (!this.loginStatus) {
        cb && cb(false)
        this.message('', 'login')
        return
      }
      this.getGroupId(() => {
        if ([1, 2, 8, 91, 96, 100, 300, 999].indexOf(this.state.userGroupId) < 0) {
          this.confirm('请先开通VIP', (action) => {
            if (action === 'confirm') this.goto('vip')
          })
          cb && cb(false)
        } else {
          cb && cb(true)
        }
      })
    },
    // 获取所在组Id
    getGroupId(cb) {
      service.actionGet('user', { type: 'groupid' }, (dt, type) => {
        this.state.userGroupId = 0
        if (type === 'success' && dt.data && dt.data.group_id) {
          this.state.userGroupId = dt.data.group_id
        }
        cb && cb()
      }, true)
    },
    // 获取成长值、晶石、晶钻 mod： stone 晶石 10006；exp 成长值 10008；coin 晶钻 10007
    getSource(cb, mod) {
      const params = {
        simple: 1,
        userid: this.loginUserId,
        mod: mod || 'source'
      }
      service.listGet('item', params, (dt, type) => {
        this.state.userGroupId = 0
        if (type === 'success' && dt.data) {
          this.state.userGroupId = dt.data.group_id || 0
          if (dt.data.items) {
            for (var i in dt.data.items) {
              this.state.source[dt.data.items[i].item_typeid] = dt.data.items[i]
            }
          }
        }
        cb && cb(dt, type)
      }, true)
    },
    // 获取钱包晶币
    getWallet(cb) {
      this.state.wallet = {}
      if (!this.loginUserId) return
      service.get('wallet', this.loginUserId, (dt, type) => {
        if (type === 'success' && dt.data && dt.data.user_id) {
          this.state.wallet = dt.data
        }
        cb && cb(dt, type)
      }, true)
    },
    // 倒计时
    countDown(num) {
      num = parseInt(num) || 60
      this.state.countDownNum = num
      this.state.countDownIt = setInterval(() => {
        this.state.countDownNum--
        if (this.state.countDownNum <= 0) {
          clearInterval(this.state.countDownIt)
          this.state.countDownIt = null
        }
      }, 1000)
    },
    // type 验证类别；0=注册; 1=登录; 2=实名认证; 3=二次认证;
    sendSMS(type, phone, cb) {
      if (!phone) return cb && cb()
      const data = {
        // 短信发送平台；0=腾讯; 1=其他;
        'platform': 0,
        'phone': phone,
        'type': type
      }
      let mustLogin = true
      if (type <= 1) mustLogin = false
      service.actionPost('sms', data, (dt, type) => {
        if (type === 'success') {
          this.traceEvent('send_sms')
          this.message('验证码已下发，请注意查收', type)
        }
        this.countDown(60)
        cb && cb(dt, type)
      }, mustLogin)
    },
    // 发送DZQ验证码
    sendDzqSMS(type, phone, cb) {
      if (!type || !phone) return cb && cb()
      service.sendSms(type, phone, (dt, status) => {
        if (status === 'success') {
          this.traceEvent('send_sms')
          this.message('验证码已下发，请注意查收', status)
        }
        this.countDown(dt.interval || 60)
        cb && cb(dt, status)
      })
    },
    follow(userid, cb) {
      this.followState[userid] = 0
      service.follow(userid, (res, type) => {
        if (type === 'success') {
          this.followState[userid] = res.isMutual
          service.incCount(this.loginUserId, 'follow')
        } else {
          // 回滚
          this.followState[userid] = -1
          this.message(res, type)
        }
        cb && cb(res, type)
      })
    },
    unfollow(userid, cb) {
      this.followState[userid] = -1
      service.unfollow(userid, (res, type) => {
        if (type !== 'success') {
          // 回滚
          this.followState[userid] = 0
          this.message(res, type)
        } else {
          service.decCount(this.loginUserId, 'follow')
        }
        cb && cb(res, type)
      })
    },
    // 获取菜单快捷键列表
    getMenuHotkeys(menu, preText, layer) {
      layer = layer || 0
      layer++
      if (layer <= 2) {
        preText = ''
      }
      preText = preText ? preText + ' ' : ''
      let hotkeysArr = []
      let hotkey = {}
      let arr = []
      Object.values(menu).map((val) => {
        if (val.text) {
          if (val.hotkey === 'Shift+J') {
            val.text = '自定义'
          }
          hotkey = { text: preText + val.text, hotkey: val.hotkey || '', sub: [] }
          if (val.menu && val.menu.length) {
            arr = this.getMenuHotkeys(val.menu, val.text, layer)
            if (layer === 1) {
              hotkey.sub = arr
            } else {
              hotkeysArr = hotkeysArr.concat(arr)
            }
          }
          if (hotkey.hotkey || hotkey.sub.length) {
            hotkeysArr.push(hotkey)
          }
        }
      })
      return hotkeysArr
    },
    // 获取快捷键列表
    getHotkeys() {
      const toolHotkeys = []
      Object.values(this.tools).map((val) => {
        toolHotkeys.push({ text: val[3], hotkey: val[0], sub: [] })
      })
      const menuHotkeys = this.getMenuHotkeys(utils.deepClone(this.menuData))
      const otherHotkeys = [
        { text: '选取部分对象时，按ESC可选择选取', hotkey: 'ESC', sub: [] },
        { text: '选取部分对象时，按DEL键可快速删除', hotkey: 'DEL', sub: [] },
        { text: '选取部分对象时，按回车键可快速裁剪', hotkey: 'Enter', sub: [] },
        { text: '按Shift键缩放对象时可锁定比例', hotkey: 'Shift', sub: [] },
        { text: '按空格键可显示原图', hotkey: 'Space', sub: [] },
        { text: '按Shift+B可快速设置画布背景', hotkey: 'Shift+B', sub: [] },
        { text: '按Shift+G可快速设置网格颜色', hotkey: 'Shift+G', sub: [] }
      ]
      const hotkeys = [
        { text: '工具', hotkey: '', sub: toolHotkeys },
        { text: '菜单', hotkey: '', sub: menuHotkeys },
        { text: '其他', hotkey: '', sub: otherHotkeys }
      ]
      return hotkeys
    },
    // 启动向导
    guide() {
      this.driver.defineSteps(steps)
      this.driver.start()
      this.traceEvent('guide')
    },
    // 向导步骤结束时调用
    onDeselected(el) {
      if (!el || !el.options) {
        return
      }
      if (el.options.element === '.left-side') {
        // 没有任何对象时，自动创建一个对象
        if (this.noObjs) {
          this.addBlankObj(false)
        }
      } else if (el.options.element === '.work-area') {
        this.setAct('fill')
      }
    },
    // 监听键盘事件 note
    // 编辑以下内容，支持 INPUT SELECT TEXTAREA 的 操作热键，如： ctrl+c/v/x
    // node_modules\vue-file-toolbar-menu\src\Bar\imports\bar-hotkey-manager.js
    // hotkeys.filter = function(event) {
    //   var tagName = (event.target || event.srcElement).tagName;
    //   return !(tagName.isContentEditable || tagName == 'INPUT' || tagName == 'SELECT' || tagName == 'TEXTAREA');
    // }
    onkeydown(event) {
      if (!this.isEditer) return
      const tagName = (event.target || event.srcElement).tagName
      if (this.tools['k_' + event.keyCode] && !event.shiftKey && !event.ctrlKey && !event.altKey && !event.metaKey && event.target.nodeName !== 'INPUT' && event.target.nodeName !== 'TEXTAREA') {
        this.setAct(this.tools['k_' + event.keyCode][1])
        return
      }
      if (event.keyCode === 13) {
        // 选取部分对象时，回车可直接裁剪
        if (this.isSelectPart) {
          this.crop()
        }
      } else if (event.keyCode === 16 || event.keyCode === 17) {
        // 按 Control 缩放对象时: 锁定比例
        if (this.state.act === 'resize') {
          this.state.lockAspectRatio = true
        }
      } else if (event.keyCode === 32 && !event.shiftKey && !event.ctrlKey && !event.altKey && !event.metaKey && event.target.nodeName !== 'INPUT' && event.target.nodeName !== 'TEXTAREA') {
        // 按 空格 时，显示原图
        this.toggleGridsfy(false)
      } else if (event.keyCode === 27) {
        // 按 Esc 时，重置选取框
        this.initFreeSelector(this.canFreeSelect)
      } else if (event.keyCode === 37 && !(tagName.isContentEditable || tagName === 'INPUT' || tagName === 'SELECT' || tagName === 'TEXTAREA')) {
        // 按 ← 移动选中对象 或 改变对象列数
        if (this.state.selectNums) {
          if (event.shiftKey) {
            this.setSize('decCols', 1)
          } else {
            this.callSelectObjs('move', ['left'])
          }
        } else if (event.shiftKey) {
          this.canvas.cols--
          this.setCanvasSize(this.canvas.cols, this.canvas.rows)
        }
      } else if (event.keyCode === 39 && !(tagName.isContentEditable || tagName === 'INPUT' || tagName === 'SELECT' || tagName === 'TEXTAREA')) {
        // 按 → 移动选中对象 或 改变对象列数
        if (this.state.selectNums) {
          if (event.shiftKey) {
            this.setSize('incCols', 1)
          } else if (this.state.selectNums) {
            this.callSelectObjs('move', ['right'])
          }
        } else if (event.shiftKey) {
          this.canvas.cols++
          this.setCanvasSize(this.canvas.cols, this.canvas.rows)
        }
      } else if (event.keyCode === 38 && !(tagName.isContentEditable || tagName === 'INPUT' || tagName === 'SELECT' || tagName === 'TEXTAREA')) {
        // 按 ↑ 移动选中对象 或 改变对象行数
        if (this.state.selectNums) {
          if (event.shiftKey) {
            this.setSize('decRows', 1)
          } else if (this.state.selectNums) {
            this.callSelectObjs('move', ['up'])
          }
        } else if (event.shiftKey) {
          this.canvas.rows--
          this.setCanvasSize(this.canvas.cols, this.canvas.rows)
        }
      } else if (event.keyCode === 40 && !(tagName.isContentEditable || tagName === 'INPUT' || tagName === 'SELECT' || tagName === 'TEXTAREA')) {
        // 按 ↓ 移动选中对象 或 改变对象行数
        if (this.state.selectNums) {
          if (event.shiftKey) {
            this.setSize('incRows', 1)
          } else if (this.state.selectNums) {
            this.callSelectObjs('move', ['down'])
          }
        } else if (event.shiftKey) {
          this.canvas.rows++
          this.setCanvasSize(this.canvas.cols, this.canvas.rows)
        }
      } else if (event.keyCode === 187 && event.shiftKey && !event.ctrlKey) {
        // Shift++
        this.setSize('scale', 2)
      } else if (event.keyCode === 66 && event.shiftKey && !event.ctrlKey) {
        // Shift+B
        this.canvas.showBg = !this.canvas.showBg
        this.handle('bgColor', this.canvas.showBg ? this.canvas.bgColor || '#3b3b3b' : '')
      } else if (event.keyCode === 71 && event.shiftKey && !event.ctrlKey) {
        // Shift+G
        this.canvas.showGrid = !this.canvas.showGrid
        this.handle('gridColor', this.canvas.showGrid ? this.canvas.gridColor || '#242424' : '')
      } else if (event.keyCode === 82 && event.shiftKey && !event.ctrlKey) {
        // Shift+R
        if (this.openFileNums) this.rename()
      }
    },
    // 监听键盘事件
    onkeyup(event) {
      if (!this.isEditer) return
      // const tagName = (event.target || event.srcElement).tagName
      if (event.keyCode === 8) {
        if (this.isSelectPart) {
          this.delete(false)
        }
      } else if (event.keyCode === 16 || event.keyCode === 17) {
        // 按 Control 缩放对象时
        if (this.state.act === 'resize') {
          this.state.lockAspectRatio = false
        }
      } else if (event.keyCode === 32 && !event.shiftKey && !event.ctrlKey && !event.altKey && !event.metaKey && event.target.nodeName !== 'INPUT' && event.target.nodeName !== 'TEXTAREA') {
        // 按 空格 时，显示原图
        this.toggleGridsfy(true)
      } else if (event.keyCode === 192) {
        // 打印调试数据
        // eslint-disable-next-line
        console.log('env', env)
        console.log('state', this.state)
        console.log('file', this.file)
        console.log('curScene', this.curScene)
        console.log('curObjs', this.curObjs)
        console.log('curObj', this.curObj, navigator, process, process.env, process.env.IS_ELECTRON)
      }
    },
    update() {
      this.$forceUpdate()
    },
    // 确认操作
    confirm(msg, fn, title = '提示') {
      if (!msg) return
      const opts = {
        cancelButtonText: '取消',
        confirmButtonText: '确定'
      }
      if (fn) opts.callback = fn
      this.$confirm(msg, title, opts)
    },
    // 强提醒
    alert(msg, fn, title) {
      if (!msg) return
      const opts = {
        confirmButtonText: '确定'
      }
      if (fn) opts.callback = fn
      this.$alert(msg, title || '提示', opts)
    },
    // 通知信息
    notify(title, msg, type, duration) {
      return this.$notify({
        title: title || '',
        message: msg || '操作失败，请稍后重试',
        type: type || 'success',
        duration: duration || 2000
      })
    },
    // 提示消息带操作按钮
    myNotify(show, msg, btn, fn) {
      const close = () => {
        this.view.myNotify.show = false
        if (this.myNotifyHandle) {
          clearTimeout(this.myNotifyHandle)
          this.myNotifyHandle = null
        }
      }
      if (!show) return close()
      this.view.myNotify.btn = btn || ''
      this.view.myNotify.fn = fn || null
      this.view.myNotify.content = msg || ''
      this.view.myNotify.show = true
      this.myNotifyHandle = setTimeout(close, 5000)
    },
    // 提示信息
    message(msg, type) {
      if (type === 'login') {
        const fn = (action) => {
          this.traceEvent('request_login_' + action)
          // if (action === 'confirm') this.login()
          this.login()
        }
        this.traceEvent('request_login')
        return this.alert(msg || '请登录', fn)
      }
      this.$message({
        showClose: true,
        message: msg || '操作失败，请稍后重试',
        type: type || 'info',
        offset: this.isDesktop ? '50' : '20'
      })
    },
    // 加载
    loading(show, msg) {
      const close = () => {
        if (this.loadingHandle) {
          this.loadingHandle.close()
          this.loadingHandle = null
        }
        if (this.loadingSto) {
          clearTimeout(this.loadingSto)
          this.loadingSto = null
        }
      }
      if (!show) return close()
      this.loadingHandle = this.$loading({
        lock: false,
        text: msg || '请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.6)'
      })
      this.loadingSto = setTimeout(close, 10000)
    },
    // 提交举报理由
    setReportReason(threadId, type, userId, postId, workId, albumId) {
      this.view.pop.title = '提交举报理由'
      this.view.pop.placeholder = ''
      this.view.pop.content = ''
      this.view.pop.classname = ''
      this.view.pop.rows = 3
      this.view.pop.maxlength = ''
      this.view.pop.type = ''
      this.view.pop.data = {}
      this.view.pop.show = true
      this.view.pop.fn = () => {
        if (!utils.checkNow('str2-4-255', this.view.pop.content, true)) {
          this.view.pop.loading = false
          return this.message('举报理由在4~100个汉字以内', 'error')
        }
        if (this.view.pop.content) {
          this.reports(threadId, type, this.view.pop.content, userId, postId, workId, albumId)
        } else {
          this.view.pop.fn = null
        }
        this.view.pop.loading = false
        this.view.pop.show = false
      }
    },
    // Prompt
    prompt(title, cb) {
      this.view.pop.title = title || ''
      this.view.pop.placeholder = ''
      this.view.pop.content = ''
      this.view.pop.classname = ''
      this.view.pop.rows = 3
      this.view.pop.maxlength = ''
      this.view.pop.type = ''
      this.view.pop.data = {}
      this.view.pop.show = true
      this.view.pop.fn = () => {
        this.view.pop.loading = false
        this.view.pop.show = false
        cb && cb(this.view.pop.content)
      }
    },
    // 举报
    reports(threadId, type, reason, userId, postId, workId, albumId) {
      service.reports(threadId, type, reason, userId, (dt, type) => {
        if (type === 'success') {
          this.message('已举报', type)
        } else {
          this.message(dt, type)
        }
      }, postId, workId, albumId)
      this.traceEvent('reports')
    },
    // 降噪
    denoise() {
      this.callSelectObjs('denoise', [this.state.denoiseFactor, this.state.mergerFactor, this.state.quantizeFactor])
      this.traceEvent('denoise')
    },
    // 色相/饱和度
    colorFilter() {
      const filter = {}
      for (const k in this.state.colorFilter) {
        filter[k] = this.state.colorFilter[k]
        this.state.colorFilter[k] = 0
      }
      this.callSelectObjs('colorFilter', [filter])
      this.traceEvent('colorFilter')
    },
    // 更换颜色
    changeObjColor(color, newColor) {
      this.callSelectObjs('changeColor', [color, newColor])
      this.traceEvent('changeObjColor')
    },
    // 删除颜色
    deleteObjColor(color) {
      this.callSelectObjs('changeColor', [color, ''])
      this.traceEvent('deleteObjColor')
    },
    // 获取对象颜色表
    getObjColors(orderby = 'asc') {
      this.GRIDY.setFile(this.file)
      const colorsObj = this.GRIDY.calcObjColors(this.state.sceneIdx, this.state.objIdx, this.state.parentObjIdx)
      let colors = []
      if (colorsObj) {
        for (const color in colorsObj) {
          colors.push([color, colorsObj[color]])
        }
      }
      colors = utils.sortArr(colors, 1)
      if (orderby === 'asc') {
        colors = colors.reverse()
      }
      this.state.objColors.count = colors.length
      this.state.objColors.colors = colors.slice(0, 128)
    },
    // 显示、隐藏取色框
    toggleColorPicker(event, pickId, params, fileType) {
      this.state.colorPicker.fileType = (typeof fileType !== 'undefined' ? fileType : this.file.type)
      this.state.colorPicker.open = !this.state.colorPicker.open
      // 默认 colorPickerBox 宽度 高度 为 287 320
      const colorPickerBox = { width: 287, height: 320 }
      if (this.$refs.colorPicker.height) {
        colorPickerBox.height = this.$refs.colorPicker.height
      }
      const box = event.target.getBoundingClientRect()
      this.state.colorPicker.pickId = pickId || ''
      this.state.colorPicker.params = params || []
      let x = box.x + box.width
      let y = box.y + box.height
      const w = utils.width()
      const h = utils.height()
      if (x + colorPickerBox.width > w) {
        x = box.x - colorPickerBox.width
      }
      if (y + colorPickerBox.height > h) {
        y = h - colorPickerBox.height
      }
      this.state.colorPicker.x = Math.max(0, x)
      this.state.colorPicker.y = Math.max(0, y)
    },
    // 取色
    pickColor(v) {
      if (this.state.colorPicker.pickId === 'first-color') {
        this.state.color[0] = this.state.colorPicker.color
        if (this.$refs.toolBar) this.$refs.toolBar.update()
      } else if (this.state.colorPicker.pickId === 'second-color') {
        this.state.color[1] = this.state.colorPicker.color
      } else if (this.state.colorPicker.pickId === 'canvas-bgcolor') {
        this.canvas.bgColor = this.state.colorPicker.color
        this.handle('bgColor', this.canvas.bgColor)
      } else if (this.state.colorPicker.pickId === 'canvas-gridcolor') {
        this.canvas.gridColor = this.state.colorPicker.color
        this.handle('gridColor', this.canvas.gridColor)
      } else if (this.state.colorPicker.pickId === 'change-color') {
        this.callSelectObjs('changeColor', [this.state.colorPicker.params[0], v])
      } else if (this.state.colorPicker.pickId === 'viewer-change-color') {
        this.$refs.gridyViewer.changeObjColor(this.state.colorPicker.params[0], v)
      } else if (this.state.colorPicker.pickId === 'bricky-change-color') {
        this.$refs.brickyViewer.changeObjColor(this.state.colorPicker.params[0], v)
      }
      this.traceEvent('pickColor_' + this.state.colorPicker.pickId)
    },
    // 获取对象样式Class
    getObjClass(id, parentId) {
      let k
      if (parentId) {
        k = 'sub-obj:' + this.state.sceneId + ':' + parentId + ':' + id
      } else {
        k = 'obj:' + this.state.sceneId + ':' + id
      }
      return this.state.selectObjs[k] ? 'select' : ''
    },
    // 打开本地图片
    openLocalImages(mod, multi, diyType = '', colorfyId = '', cols = 64, rows = 64, quick = false, isAi = false) {
      this.state.openLocalImages.mod = mod || 'open'
      this.state.openLocalImages.multi = !!multi || false
      this.state.openLocalImages.params.diyType = diyType
      this.state.openLocalImages.params.colorfyId = colorfyId
      this.state.openLocalImages.params.cols = cols
      this.state.openLocalImages.params.rows = rows
      this.state.openLocalImages.params.quick = quick
      this.state.openLocalImages.params.isAi = isAi
      setTimeout(() => {
        this.$refs['images-uploader'].click()
      }, 100)
      this.traceEvent('openLocalImages_' + this.state.openLocalImages.mod)
    },
    // 快速导入/打开
    quickImport(file, mod = 'open') {
      const name = utils.getFileName(file.name)
      const GRD = new GRIDY()
      GRD.setFileName(name)
      GRD.importImage(0, file, name, (status) => {
        GRD.setScenesAttrs(0, { name: name })
        if (status) {
          GRD.autoCanvasSize(false, true)
        }
        if (mod === 'open') {
          this.importIt(GRD.getFile(), 'fileJson', name, [])
        } else {
          this.importIt(GRD.getObj(0, 0), 'objJson', name, [])
        }
      }, { userid: this.loginUserId, brickSizeId: '', ratioid: '', compress: true })
    },
    // 导入本地图片
    importLocalImages() {
      const dom = this.$refs['images-uploader']
      const localFiles = dom.files
      if (!localFiles.length) {
        return
      }
      const file = localFiles[0]
      if (this.state.openLocalImages.hook) {
        this.state.openLocalImages.hook(file)
        this.state.openLocalImages.hook = null
      }
      if (this.state.openLocalImages.mod === 'open' || this.state.openLocalImages.mod === 'import') {
        if (this.state.openLocalImages.params.quick) {
          this.quickImport(file, this.state.openLocalImages.mod)
        } else {
          const conf = {
            data: { file: file, diyType: this.state.openLocalImages.params.diyType || '', isAi: this.state.openLocalImages.params.isAi, colorfyId: this.state.openLocalImages.params.colorfyId || '', cols: this.state.openLocalImages.params.cols, rows: this.state.openLocalImages.params.rows },
            fn: (data, type) => {
              this.importIt(data, type, file.name, [])
            }
          }
          this.openCropImage(conf)
        }
        this.state.openLocalImages.params = {}
        this.state.workCount = {}
      }
      dom.value = ''
      if (this.state.openLocalImages.callback) {
        this.state.openLocalImages.callback(file)
        this.state.openLocalImages.callback = null
      }
    },
    // 打开本地文件
    openLocalFile(mod, cb) {
      this.state.openLocalFiles.mod = mod || 'open'
      this.state.openLocalFiles.callback = cb || ''
      this.$refs['gridy-uploader'].click()
      this.traceEvent('openLocalFile_' + this.state.openLocalFiles.mod)
    },
    // 导入本地文件
    importLocalFile() {
      const dom = this.$refs['gridy-uploader']
      const localFiles = dom.files[0]
      if (!localFiles) {
        return
      }
      let notify
      if (localFiles.size > 1024 * 512) {
        // 导入的文件大于512KB时，显示提示信息
        notify = this.notify('提示', '正在打开文件...', 'info', 15000)
      }
      const delay = Math.min(500 + Math.ceil(localFiles.size / 512), 5000)
      const localZip = new JSZip()
      localZip.loadAsync(localFiles).then((zip) => {
        Object.values(zip.files).map((zipFile) => {
          zipFile.async('string').then((fileString) => {
            try {
              let res = JSON.parse(fileString)
              if (!Array.isArray(res)) {
                res = [res]
              }
              if (this.state.openLocalFiles.hook) {
                this.state.openLocalFiles.hook()
                this.state.openLocalFiles.hook = null
              }
              let openFileNums = 0
              Object.values(res).map((content) => {
                const file = utils.deepClone(content)
                file.id = utils.uuid()
                // 恢复草稿箱 或已打开10个文件后，不自动打开文件
                if (this.state.openLocalFiles.mod === 'restore' || openFileNums >= 10) {
                  this.saveIt(file, true)
                } else {
                  this.file = file
                  this.saveIt(file, true)
                  this.state.scale = 1
                  this.setAct('default')
                  this.setIdx(0, -1)
                  this.drawCanvasBg()
                  this.setOpenFiles(file.id, file.name)
                  this.getBackAndCleanState()
                  openFileNums++
                }
                this.view.createNewfile = false
              })
              // 延时刷新数据，确保可获取到最新的数据
              setTimeout(() => {
                if (this.state.openLocalFiles.mod === 'restore') {
                  this.alert('从本地恢复成功')
                } else {
                  this.goto('editer')
                }
                notify && notify.close()
                dom.value = ''
                this.updateLatestOpen()
              }, delay)
              this.state.workCount = {}
              if (this.state.openLocalFiles.callback) {
                this.state.openLocalFiles.callback()
                this.state.openLocalFiles.callback = null
              }
            } catch (e) {
              this.alert('当前文件已损坏，无法打开')
              notify && notify.close()
              dom.value = ''
            }
          }, (e) => {
            this.alert('当前文件已损坏，无法打开')
            notify && notify.close()
            dom.value = ''
          })
        })
      }, (e) => {
        this.alert('当前文件已损坏，无法打开')
        notify && notify.close()
        dom.value = ''
      })
    },
    // 打开网络图片
    openWebImage(mod) {
      this.state.openWebImages.mod = mod || 'open'
      this.$prompt('请输入图片网址', '添加网络图片').then(({ value }) => {
        if (value) {
          this.importWebimage(value)
        }
      }).catch(() => {})
      this.traceEvent('openWebImage_' + this.state.openWebImages.mod)
    },
    // 导入网络图片
    importWebimage(webimage, name, cb, tags = []) {
      const errorNotify = () => {
        cb && cb(false)
        this.message('该图片不支持外部加载', 'error')
      }
      // 支持网址 或 图片base64
      if (webimage) {
        if (utils.isUrl(webimage) || (webimage.indexOf('data:image/') !== -1 && webimage.indexOf('base64') !== -1)) {
          axios.get(webimage, { responseType: 'blob' })
            .then((res) => {
              if (res.status === 200 && utils.isImageMime(res.data.type)) {
                if (this.state.openWebImages.hook) {
                  this.state.openWebImages.hook()
                  this.state.openWebImages.hook = null
                }
                const file = utils.blobToFile(res.data, name || '网络图片', res.data.type)
                if (this.state.openWebImages.mod === 'open' || this.state.openWebImages.mod === 'import') {
                  const conf = {
                    data: { file: file, imageUrl: webimage },
                    fn: (data, type) => {
                      this.importIt(data, type, file.name, tags)
                    }
                  }
                  this.openCropImage(conf)
                }
                this.state.workCount = {}
                if (this.state.openWebImages.callback) {
                  this.state.openWebImages.callback()
                  this.state.openWebImages.callback = null
                }
                cb && cb(true)
              } else {
                errorNotify()
              }
            })
            // eslint-disable-next-line
            .catch((err) => {
              errorNotify()
            })
        } else {
          errorNotify()
        }
      }
    },
    importIt(data, type, name = '', tags = [], workType = 0) {
      if (type === 'fileJson') {
        data.tags = tags
        data.type = workType
        data.id = data.fileid || data.id + ''
        this.setFileAndOpen(data)
        this.saveIt('', true)
        this.updateLatestOpen()
        this.setHistory('importIt')
      } else {
        name = name || data.name
        this.state.drawStartTime = utils.time('time')
        let fitCavasSize = false
        if (this.state.openLocalImages.mod === 'open' || !this.openFileNums) {
          fitCavasSize = true
          this.newFile(name, this.state.limitCols, this.state.limitRows)
        }
        this.file.type = workType
        this.file.tags = tags
        this.file.origin = 2
        const dt = { name: name }
        if (type === 'objJson') dt.obj = data
        this.newObj(dt, fitCavasSize)
        this.goto('editer')
      }
      this.traceEvent('openWebImage_' + type)
    },
    // 导出本地草稿箱
    async exportDraft() {
      if (utils.isAlipayClient() || utils.isWeixinClient()) {
        this.$alert('请使用Gridy.Art客户端进行备份')
        return
      }
      let content = await this.db.batchGet('files', { del: '0' }, 'id')
      content = JSON.stringify(content)
      const filename = '草稿箱(' + utils.date('datetime') + ')'
      const zip = new JSZip()
      zip.file(filename + '.gwdt', content)
      zip.generateAsync({ type: 'blob' }).then((blob) => {
        downloadjs(blob, filename + '.gridy', 'application/zip')
      }, (e) => {
        this.$alert('导出失败')
      })
      this.traceEvent('exportDraft')
    },
    // 清空本地回收站
    clearRecycle(cb) {
      this.db.clearRecycle('files')
      this.updateLatestOpen()
      cb && cb()
      this.traceEvent('clearRecycle')
    },
    // 逻辑本地删除文件
    async delFile(fileId) {
      await this.db.del('files', { fileId: fileId })
    },
    // 恢复本地文件
    async restoreFile(fileId) {
      await this.db.restore('files', { fileId: fileId })
    },
    // 彻底删除本地文件
    async deleteFile(fileId) {
      await this.db.delete('files', { fileId: fileId })
      this.closeFile(fileId)
      this.updateLatestOpen()
    },
    // 改变画布比例
    changeAspectFactor(aspectFactor) {
      this.canvasRatio = utils.deepClone(conf.canvasRatio)
      let ratioDt = this.canvasRatio[0]
      for (var i in this.canvasRatio) {
        if (this.canvasRatio[i].id === aspectFactor) {
          ratioDt = this.canvasRatio[i]
        }
      }
      this.setCanvasSize(ratioDt.cols, ratioDt.rows)
      this.traceEvent('changeAspectFactor', '', { aspectFactor: aspectFactor })
    },
    // 获取盒子模型
    getBox(id) {
      const el = this.$refs[id]
      if (el) {
        return el.getBoundingClientRect()
      } else {
        return { x: 0, y: 0, left: 0, top: 0, right: 0, bottom: 0, width: 0, height: 0 }
      }
    },
    debounceGetPos(event) {
      utils.debounce(this.getPos, 100, 'getPos')(event)
    },
    // 获取鼠标在画布上的坐标
    getPos(event) {
      if (event.touches && event.touches[0]) {
        event.clientX = event.touches[0].clientX
        event.clientY = event.touches[0].clientY
      }
      const box = this.getBox('workArea')
      const pos = [Math.floor((event.clientX - box.left) / this.state.gridSize), Math.floor((event.clientY - box.top) / this.state.gridSize)]
      this.state.pos = pos
      return pos
    },
    // 计算鼠标触碰的子对象
    calcHoverSubObj(event, parentIdx, parentObj, subObjs) {
      let j
      for (j in subObjs) {
        if (subObjs[j].show && this.mouseonObj(event, subObjs[j], parentObj)) {
          this.state.hoverObj = [j, parentIdx]
          return true
        }
      }
    },
    // 计算鼠标触碰的对象
    calcHoverObj(event) {
      this.state.hoverObj = [-1, -1]
      let i
      for (i in this.curObjs) {
        if (this.curObjs[i].show && this.mouseonObj(event, this.curObjs[i])) {
          if (this.curObjs[i].type === 'group') {
            if (this.calcHoverSubObj(event, i, this.curObjs[i], this.curObjs[i].objs)) {
              break
            }
          } else {
            this.state.hoverObj = [i, -1]
          }
          break
        }
      }
      this.autoUnselectAll()
      return this.state.hoverObj
    },
    // 检测鼠标是否在工作区域
    mouseonWorkArea(event) {
      if (event.touches && event.touches[0]) {
        event.clientX = event.touches[0].clientX
        event.clientY = event.touches[0].clientY
      }
      const box = this.getBox('workArea')
      let x = event.clientX - box.x
      let y = event.clientY - box.y
      x = Math.floor(x / this.state.gridSize)
      y = Math.floor(y / this.state.gridSize)
      return (x >= 0 && x < this.canvas.cols && y >= 0 && y < this.canvas.rows)
    },
    // 检测鼠标是否在对象上
    mouseonObj(event, obj, parentObj) {
      if (!obj || !obj.show) {
        return
      }
      let fixTouchFactor = 0
      if (event.touches && event.touches[0]) {
        event.clientX = event.touches[0].clientX
        event.clientY = event.touches[0].clientY
        // 移动端模糊触摸修正
        fixTouchFactor = 3
      }
      const box = this.getBox('workArea')
      let x = event.clientX - box.x
      let y = event.clientY - box.y
      x = Math.floor(x / this.state.gridSize)
      y = Math.floor(y / this.state.gridSize)
      if (parentObj) {
        x = x - parentObj.x
        y = y - parentObj.y
      }
      obj.cols = parseInt(obj.cols)
      obj.rows = parseInt(obj.rows)
      const objX = obj.x - fixTouchFactor
      const objY = obj.y - fixTouchFactor
      const objCols = obj.cols + 2 * fixTouchFactor
      const objRows = obj.rows + 2 * fixTouchFactor
      return (x >= objX && x < objX + objCols && y >= objY && y < objY + objRows)
    },
    // 滚轴缩放
    onWheel(event) {
      if (event.deltaY > 0) {
        this.zoom('-')
      } else {
        this.zoom('+')
      }
    },
    // 处理MainArea的点击事件
    handleClick(event, tap) {
      // 适配触摸模式
      if (tap) {
        if (event.touches && event.touches[0]) {
          event.clientX = event.touches[0].clientX
          event.clientY = event.touches[0].clientY
        } else if (event.changedTouches && event.changedTouches[0]) {
          event.clientX = event.changedTouches[0].clientX
          event.clientY = event.changedTouches[0].clientY
        }
        event.button = 0
      }
      // 没有任何对象时，自动创建一个对象
      if (this.noObjs && (this.state.act === 'pick' || this.state.act === 'fill' || this.state.act === 'batchFill' || this.state.act === 'erase' || this.state.act === 'batchErase' || this.state.act === 'brush' || this.state.act === 'spray')) {
        this.addBlankObj(false)
      }
      // 缩放对象时，停止点击事件
      if (this.state.resizing) {
        this.state.resizing = false
        return
      }
      this.calcHoverObj(event)
      if (this.isSelectPart && !this.mouseonObj(event, this.curObj, this.parentObj)) {
        // 选取部分对象，且鼠标不在对象上时，初始化选取框
        this.initFreeSelector(this.canFreeSelect)
      } else if (this.canDrawShape) {
        this.initDrawShape(true)
      } else if (this.state.act === 'txt' && event.target.nodeName !== 'INPUT') {
        this.getPos(event)
        this.newTxtObj(event)
      }
    },
    // 是否显示右键菜单
    contextmenuType(event) {
      if (this.state.act === 'pick' || this.state.act === 'fill' || this.state.act === 'batchFill' || this.state.act === 'erase' || this.state.act === 'batchErase' || this.state.act === 'brush' || this.state.act === 'spray' || this.state.act === 'hand') {
        return 'none'
      } else if (event.target.id === 'multiSelectBox' || event.target.id === 'objWorkArea') {
        return 'edit'
      } else if (this.isSelectPart) {
        return 'partEdit'
      } else {
        return 'default'
      }
    },
    // 右键菜单
    onContextmenu(event) {
      event.stopPropagation()
      if (!this.isDesktop) return
      // 插入文本
      if (this.state.act === 'txt' && event.target.nodeName !== 'INPUT') {
        this.getPos(event)
        this.newTxtObj(event)
        return
      }
      // 绘图时，不显示右键菜单
      if (this.canDrawShape) {
        return
      }
      const menuType = this.contextmenuType(event)
      if (menuType === 'none') {
        return
      }
      let items = []
      const commonItems = [
        {
          label: '剪切',
          icon: 'iconfont my-cut',
          onClick: this.cut
        },
        {
          label: '复制',
          icon: 'iconfont my-copy',
          onClick: this.copy
        },
        {
          label: '粘贴',
          icon: 'iconfont my-paste',
          disabled: !this.state.objAct,
          onClick: this.parse
        },
        {
          label: '删除',
          icon: 'iconfont my-delete',
          divided: true,
          onClick: () => { this.delete(false) }
        }
      ]
      if (menuType === 'partEdit') {
        const cropItem = [
          {
            label: '裁剪',
            icon: 'iconfont my-crop',
            onClick: this.crop
          }
        ]
        const cancelItem = [
          {
            label: '取消',
            icon: 'iconfont my-close',
            onClick: () => { this.initFreeSelector(true) }
          }
        ]
        items = cropItem.concat(commonItems).concat(cancelItem)
      } else if (menuType === 'edit') {
        const hideItems = [
          {
            label: '锁定',
            icon: 'iconfont my-lock',
            disabled: !this.btnStatus.lock,
            onClick: () => { this.lockSelectObjs() }
          },
          {
            label: '解除锁定',
            icon: 'iconfont my-unlock',
            disabled: !this.btnStatus.unlock,
            divided: true,
            onClick: () => { this.unlockSelectObjs() }
          },
          {
            label: '隐藏',
            icon: 'iconfont my-eye-close',
            disabled: !this.btnStatus.hide,
            divided: true,
            onClick: () => { this.hideSelectObjs() }
          }
        ]
        items = commonItems.concat(hideItems)
      } else if (menuType === 'default') {
        items = [
          {
            label: '撤销',
            icon: 'iconfont my-cancel',
            disabled: !this.btnStatus.back,
            onClick: () => {
              this.back()
            }
          },
          {
            label: '重做',
            icon: 'iconfont my-redo',
            disabled: !this.btnStatus.redo,
            divided: true,
            onClick: () => {
              this.redo()
            }
          },
          {
            label: '粘贴',
            icon: 'iconfont my-paste',
            disabled: !this.btnStatus.parse,
            divided: true,
            onClick: () => {
              this.handle('parse')
            }
          },
          {
            label: '放大',
            icon: 'iconfont my-zoomin',
            disabled: !this.btnStatus.zoomIn,
            onClick: () => {
              this.zoom('+')
            }
          },
          {
            label: '缩小',
            icon: 'iconfont my-zoomout',
            disabled: !this.btnStatus.zoomOut,
            onClick: () => {
              this.zoom('-')
            }
          },
          {
            label: '重置缩放',
            icon: 'el-icon-refresh',
            disabled: !this.btnStatus.zoomReset,
            onClick: () => {
              this.zoom('1')
            }
          }
        ]
      }
      this.$contextmenu({
        items: items,
        event,
        customClass: 'my-contextmenu',
        zIndex: 990,
        minWidth: 180
      })
    },
    // 获取调色板数据
    getPaletteColors(palId) {
      const colors = {}
      if (palId === '' || palId === 'none' || palId === 'default' || !this.palette.data[palId]) {
        return colors
      }
      palId = palId === 'brickfy' ? 'brickfyArr' : palId
      for (const i in this.palette.data[palId]) {
        let c = this.palette.data[palId][i]
        if (!utils.isString(c)) {
          c = utils.rgb2hex(c)
        }
        c = c.toUpperCase()
        colors[c] = true
      }
      return colors
    },
    // 移除调色板上的颜色
    removeColor(color, unuse) {
      if (!this.state.selectNums || !this.state.selectObjs) {
        return
      }
      const palDt = utils.deepClone(this.curObj.palette)
      if (unuse) {
        palDt[color] = false
      } else {
        delete palDt[color]
      }
      this.usePalette(this.curObj.paletteId, palDt)
      const objs = this.curObjs
      Object.values(this.state.selectObjs).map((arr) => {
        if (arr[1] >= 0) {
          Object.values(objs[arr[1]].objs).map((o) => {
            if (unuse) {
              o.palette[color] = false
            } else {
              delete o.palette[color]
            }
            this.callObj(o.id, objs[arr[0]].id, 'setPalette', [o.paletteId, o.palette])
          })
        } else {
          if (unuse) {
            objs[arr[0]].palette[color] = false
          } else {
            delete objs[arr[0]].palette[color]
          }
          this.callObj(objs[arr[0]].id, '', 'setPalette', [objs[arr[0]].paletteId, objs[arr[0]].palette])
        }
      })
    },
    // 互换当前颜色
    exchangeCurColor() {
      this.state.color.reverse()
    },
    // 恢复默认的当前颜色
    resetCurColor() {
      this.state.color = [...this.state.defaultColor]
    },
    // 获取图形名称
    getShapeName() {
      return conf.shapes[this.state.act] || '未命名'
    },
    // 新增绘图对象
    newDrawObject(pos, type = '') {
      const options = {
        name: this.getShapeName(),
        x: Math.floor(pos[0] / this.state.gridSize),
        y: Math.floor(pos[1] / this.state.gridSize),
        cols: 1,
        rows: 1,
        gridsfy: false,
        userid: this.loginUserId
      }
      const obj = this.GRIDY.createObj(options)
      obj.type = type
      this.curObjs.unshift(obj)
      this.calcLayer()
      this.selectTopOne()
      this.traceEvent('newDrawObject', '', { type: type })
    },
    // 新增对象
    newObj(data, fitCavasSize) {
      this.state.drawStartTime = utils.time('time')
      const options = {
        name: utils.getFileName(data.name),
        userid: this.loginUserId
      }
      let obj
      if (data.obj) {
        obj = data.obj
      } else {
        obj = this.GRIDY.createObj(options)
        obj.originUrl = data.originUrl || ''
        obj.initSize = true
      }
      // 偏移新对象坐标
      if (this.curObjs[0]) {
        obj.x = this.curObjs[0].x + 2
        obj.y = this.curObjs[0].y + 2
      }
      this.curObjs.unshift(obj)
      if (fitCavasSize) this.autoCanvasSize()
      this.calcLayer()
      this.selectTopOne()
      this.setHistory('newObj', [data])
      this.view.cropImage.show = false
      this.view.gridyViewer.show = false
      this.traceEvent('newObj')
    },
    // 执行操作命令
    handle(act, data) {
      if (act === 'update') {
        this.$forceUpdate()
      } else if (act === 'showBg') {
        this.canvas.showBg = !data
        this.setHistory(act, data)
      } else if (act === 'bgColor') {
        if (data) this.canvas.bgColor = data
        this.drawCanvasBg()
        this.drawObjs()
        this.setHistory(act, data)
      } else if (act === 'gridColor') {
        if (data) this.canvas.gridColor = data
        this.drawCanvasBg()
        this.drawObjs()
        this.setHistory(act, data)
      } else if (act === 'setAct') {
        this.setAct(data)
      } else if (act === 'pick') {
        this.state.color[parseInt(data[0])] = data[1]
        this.update()
      } else if (typeof this[act] === 'function') {
        if (!utils.isObject(data)) {
          data = [data]
        }
        this[act].apply(null, data)
      } else {
        console.error('undeclare function: ' + act)
      }
    },
    // 刷新视图数据
    renewView(mod) {
      if (this.view[mod].show && this.$refs[mod]) this.$refs[mod].renewView()
    },
    // 绘制画布背景
    drawCanvasBg() {
      this.state.canvasIniting = false
      this.update()
      setTimeout(this.drawCanvasGrid, 100)
      const canvasBg = this.$refs.canvasBg
      if (!canvasBg) return
      const canvasBgCtx = canvasBg.getContext('2d')
      canvasBg.width = this.canvas.cols * this.state.gridSize + 1
      canvasBg.height = this.canvas.rows * this.state.gridSize + 1
      canvasBgCtx.clearRect(0, 0, canvasBg.width, canvasBg.height)
      if (this.canvas.showBg) {
        canvasBgCtx.fillStyle = this.canvas.bgColor || '#3b3b3b'
        canvasBgCtx.fillRect(0, 0, canvasBg.width, canvasBg.height)
      }
      if (this.canvas.showGrid) {
        canvasBgCtx.fillStyle = this.canvas.gridColor || '#242424'
        canvasBgCtx.fillRect(0, 0, canvasBg.width, canvasBg.height)
        let x
        let y
        for (x = 0; x < this.canvas.cols; x++) {
          for (y = 0; y < this.canvas.rows; y++) {
            if (this.canvas.showBg) {
              canvasBgCtx.fillStyle = this.canvas.bgColor || '#3b3b3b'
              canvasBgCtx.fillRect(x * this.state.gridSize + 1, y * this.state.gridSize + 1, this.state.gridSize - 1, this.state.gridSize - 1)
            } else {
              canvasBgCtx.clearRect(x * this.state.gridSize + 1, y * this.state.gridSize + 1, this.state.gridSize - 1, this.state.gridSize - 1)
            }
          }
        }
      }
    },
    // 绘制网格
    drawCanvasGrid() {
      const canvasGrid = this.$refs.canvasGrid
      if (!canvasGrid) return
      const canvasGridCtx = canvasGrid.getContext('2d')
      canvasGrid.width = 41
      canvasGrid.height = 41
      canvasGridCtx.clearRect(0, 0, canvasGrid.width, canvasGrid.height)
      canvasGridCtx.fillStyle = this.canvas.gridColor || '#242424'
      canvasGridCtx.fillRect(0, 0, canvasGrid.width, canvasGrid.height)
      let x
      let y
      const gridSize = 5
      for (x = 0; x < 8; x++) {
        for (y = 0; y < 8; y++) {
          canvasGridCtx.clearRect(x * gridSize + 1, y * gridSize + 1, gridSize - 1, gridSize - 1)
        }
      }
    },
    setCanvasAttr(k, v) {
      this.canvas[k] = v
      this.drawCanvasBg()
      this.drawObjs()
    },
    // 设置画布尺寸
    openSetCanvasSize() {
      let ratioDt = {}
      this.canvasRatio = utils.deepClone(conf.canvasRatio)
      for (var i in this.canvasRatio) {
        if (this.canvasRatio[i].id === this.canvas.aspectFactor) {
          ratioDt = this.canvasRatio[i]
        }
      }
      ratioDt.cols = this.canvas.cols
      ratioDt.rows = this.canvas.rows
      ratioDt.aspectFactor = this.canvas.aspectFactor
      const data = {
        ratio: this.canvasRatio,
        ratioDt: ratioDt,
        showPopover: false
      }
      this.openPop({ title: '设置画布尺寸', type: 'setCanvasSize', data: data })
    },
    // 修剪、符合画布
    autoCanvasSize(padding) {
      this.GRIDY.setFile(this.file)
      this.GRIDY.autoCanvasSize(padding)
      this.traceEvent('autoCanvasSize')
      this.saveIt('', true)
    },
    // 设置画布尺寸
    setCanvasSize(cols, rows) {
      this.GRIDY.setFile(this.file)
      this.GRIDY.setCanvasSize(cols, rows)
      this.setHistory('setCanvasSize', [cols, rows])
      this.traceEvent('setCanvasSize')
      this.saveIt('', true)
    },
    // 清空历史
    cleanHistory(fileId) {
      if (!fileId && this.file && this.file.id) {
        fileId = this.file.id
      }
      if (fileId) {
        this.db.delete('acts', { fileId: fileId })
        this.state.historyId[fileId] = ''
      }
      this.state.canClean = false
      this.state.canBack = false
      this.state.canRedo = false
      this.traceEvent('cleanHistory')
    },
    // 保存历史
    async setHistory(fun, params) {
      if (!this.file || !this.file.id) {
        return
      }
      if (this.state.historyId[this.file.id]) {
        // 先后退，再编辑时，清除已后退的历史数据
        const acts = await this.db.getNextAll('acts', { fileId: this.file.id }, this.state.historyId[this.file.id])
        if (acts.length) {
          Object.values(acts).map((file) => {
            this.db.delete('acts', { id: file.id })
          })
        }
        this.state.historyId[this.file.id] = ''
      }
      const data = {
        fileId: this.file.id,
        act: fun,
        data: this.file,
        actTime: utils.date('datetime')
      }
      this.db.add('acts', data)
      this.getBackAndCleanState()
      this.saveIt()
    },
    // 获取后退和清除的按钮状态
    async getBackAndCleanState() {
      if (this.file && this.file.id) {
        this.state.canRedo = false
        if (this.state.historyId[this.file.id]) {
          const acts = await this.db.getNext('acts', { fileId: this.file.id }, this.state.historyId[this.file.id])
          if (acts.length > 1) {
            this.state.canRedo = true
          }
        }
        const nums = await this.db.totalCount('acts', { fileId: this.file.id })
        if (nums) {
          this.state.canBack = true
          this.state.canClean = true
          return
        }
      }
      this.state.canRedo = false
      this.state.canBack = false
      this.state.canClean = false
    },
    // 撤销
    async back() {
      if (this.file && this.file.id && !this.state.backLock) {
        this.state.backLock = true
        const acts = await this.db.getPreview('acts', { fileId: this.file.id }, this.state.historyId[this.file.id])
        this.state.backLock = false
        if (acts.length) {
          let file
          if (acts.length === 1) {
            file = acts[0]
            this.state.canBack = false
          } else {
            file = acts[1]
            this.state.canBack = true
          }
          this.state.historyId[this.file.id] = file.id
          this.state.canRedo = true
          this.state.canClean = true
          this.file = utils.deepClone(file.data)
          this.state.scale = 1
          this.drawCanvasBg()
          this.drawObjs()
          this.calcLayer()
          this.selectTopOne()
          this.setOpenFiles(this.file.id, this.file.name)
          this.saveIt('', true)
        }
      }
    },
    // 重做
    async redo() {
      if (this.file && this.file.id && !this.state.redoLock) {
        this.state.redoLock = true
        const acts = await this.db.getNext('acts', { fileId: this.file.id }, this.state.historyId[this.file.id])
        this.state.redoLock = false
        if (acts.length) {
          let file
          if (acts.length === 1) {
            file = acts[0]
            this.state.canRedo = false
          } else {
            file = acts[1]
            this.state.canRedo = true
          }
          this.state.historyId[this.file.id] = file.id
          this.state.canBack = true
          this.state.canClean = true
          this.file = utils.deepClone(file.data)
          this.state.scale = 1
          this.drawCanvasBg()
          this.drawObjs()
          this.calcLayer()
          this.selectTopOne()
          this.setOpenFiles(this.file.id, this.file.name)
          this.saveIt('', true)
        }
      }
    },
    // 绘制全部对象
    drawObjs(delay) {
      Object.keys(this.curObjs).map((i) => {
        this.drawObj(i, delay)
      })
    },
    // 绘制特定对象
    drawObj(idx, delay) {
      if (!this.curObjs[idx]) {
        return
      }
      setTimeout(() => {
        if (this.curObjs[idx]) this.callObj(this.curObjs[idx].id, '', 'draw', [])
      }, delay || 100)
    },
    // 缩放网格画
    zoom(act) {
      if (act === '+') {
        if (this.state.gridSize === 1) {
          this.state.gridSize = 2
        } else if (this.state.gridSize < 8) {
          this.state.gridSize = this.state.gridSize + 2
        } else {
          this.state.gridSize = this.state.gridSize + 4
        }
        this.state.gridSize = Math.min(this.state.gridSize, 32)
      } else if (act === '-') {
        if (this.state.gridSize > 8) {
          this.state.gridSize = this.state.gridSize - 4
        } else {
          this.state.gridSize = this.state.gridSize - 2
        }
        this.state.gridSize = Math.max(this.state.gridSize, 1)
      } else {
        if (act < 0.125) {
          act = 0.125
        } else if (act > 2) {
          act = 2
        }
        this.state.gridSize = Math.round(8 * Math.abs(act))
      }
      this.file.gridSize = this.state.gridSize
      this.state.scale = this.state.gridSize / conf.state.gridSize
      this.drawCanvasBg()
      this.drawObjs()
      this.debounceApplySelect()
      if (this.isSelectPart) {
        this.initFreeSelector(true)
      }
    },
    // 显示原图开关
    toggleGridsfy(status) {
      this.callSelectObjs('toggleGridsfy', [status])
    },
    // 设置对象大小
    setSize(mod, val) {
      this.callSelectObjs('setSize', [mod, val])
    },
    // 设置对象网格大小 del?
    setGridSize(gridSize) {
      this.state.objGridSize = gridSize
      this.callSelectObjs('setGridSize', [gridSize])
    },
    // 设置当前idx, parentIdx
    setIdx(idx, parentIdx) {
      idx = idx >= 0 ? idx : -1
      parentIdx = parentIdx >= 0 ? parentIdx : -1
      this.state.objIdx = idx
      this.state.parentObjIdx = parentIdx
    },
    // 下移一层
    layDown() {
      this.setLayer('-')
    },
    // 设置图层
    setLayer(act) {
      this.GRIDY.setFile(this.file)
      this.state.objIdx = this.GRIDY.setLayer(this.state.sceneIdx, this.state.objIdx, this.state.parentObjIdx, act)
      this.calcLayer()
      this.setHistory('setLayer', [act])
    },
    // 选择场景
    selectScene(idx) {
      if (!this.scenes[idx]) {
        return
      }
      this.state.sceneIdx = idx
      this.state.sceneId = this.scenes[idx].id
      this.selectTopOne()
    },
    // 拷贝场景
    copyScene(idx) {
      if (!this.scenes[idx]) {
        return
      }
      const scene = utils.deepClone(this.scenes[idx])
      scene.refSceneId = scene.id
      scene.id = utils.uuid()
      let i
      let j
      for (i in scene.objs) {
        scene.objs[i].refSceneId = scene.refSceneId
        scene.objs[i].refObjectId = scene.objs[i].id
        scene.objs[i].id = utils.uuid()
        if (scene.objs[i].objs) {
          for (j in scene.objs[i].objs) {
            scene.objs[i].objs[j].id = utils.uuid()
          }
        }
      }
      this.scenes.push(scene)
      this.selectScene(this.scenes.length - 1)
      this.setHistory('copyScene', [idx])
      this.traceEvent('copyScene')
    },
    // 删除场景
    delScene(idx) {
      this.canvas.scenes.splice(idx, 1)
      this.selectScene(0)
      this.setHistory('delScene', [idx])
      this.traceEvent('delScene')
    },
    // 添加场景
    addScene() {
      this.scenes.push(utils.deepClone(conf.schema.scene))
      this.scenes[0].id = utils.uuid()
      this.scenes[0].name = '新的场景'
      this.selectScene(this.scenes.length - 1)
      this.setHistory('addScene')
      this.traceEvent('addScene')
    },
    // 计算对象图层
    calcLayer() {
      let i
      let j
      const objs = this.curObjs || []
      const freeSelector = this.state.freeSelector
      for (i in objs) {
        // 被选取框部分选中，临时放到最上层
        if ((freeSelector.partSelect && (freeSelector.parentIdx === i || freeSelector.idx === i))) {
          objs[i].z = this.state.maxLayers
        } else {
          objs[i].z = this.state.maxLayers - i
        }
        if (objs[i].type === 'group' && objs[i].objs && objs[i].objs.length) {
          for (j in objs[i].objs) {
            // 被选取框部分选中，临时放到最上层
            if ((freeSelector.partSelect && freeSelector.parentIdx === i && freeSelector.idx === j)) {
              objs[i].objs[j].z = this.state.maxLayers
            } else {
              objs[i].objs[j].z = this.state.maxLayers - j
            }
          }
        }
      }
    },
    // 锁定/解锁对象
    lock(idx) {
      this.curObjs[idx].lock = !this.curObjs[idx].lock
      this.setHistory('lock', [idx, this.curObjs[idx].lock])
    },
    // 显示/隐藏对象
    show(idx) {
      this.curObjs[idx].show = !this.curObjs[idx].show
      this.setHistory('show', [idx, this.curObjs[idx].show])
    },
    // 设置操作工具
    setAct(act) {
      act = act || 'default'
      this.state.preAct = this.state.act
      this.state.act = act
      if (act === 'txt') this.requireTxt()
    },
    // 切换操作工具
    toggleAct(act) {
      if (this.state.act === act) {
        this.setAct(this.state.preAct || 'default')
      } else {
        this.setAct(act)
      }
    },
    requireTxt() {
      this.setAct()
      this.view.pop.placeholder = '请输入文本'
      this.view.pop.title = '插入文本'
      this.view.pop.content = ''
      this.view.pop.classname = 'pixel'
      this.view.pop.rows = 1
      this.view.pop.maxlength = 16
      this.view.pop.type = ''
      this.view.pop.data = {}
      this.view.pop.show = true
      this.view.pop.fn = () => {
        let value = utils.trim(this.view.pop.content, true)
        if (!value) {
          this.message('请输入文本', 'error')
          this.setAct(this.state.preAct)
          return
        }
        // 限制8个汉字或16个字符
        value = utils.getString(value, 8)
        const len = utils.getLen(value)
        const x = Math.max(0, (parseInt(this.canvas.cols) - len * 8) / 2)
        const y = Math.max(0, Math.min(this.canvas.rows - 16, (len - 1) * 2))
        this.newTxtObj({}, [x, y], value)
        this.view.pop.fn = null
        this.view.pop.show = false
      }
    },
    // 重置选中的对象
    resetSelectObjs() {
      this.state.selectObjs = {}
      this.state.selectNums = 0
    },
    // 选择最上层的对象
    selectTopOne() {
      if (this.curObjs) {
        setTimeout(() => {
          let idx = -1
          for (const i in this.curObjs) {
            if (idx <= 0 && this.curObjs[i].show) {
              idx = i
              break
            }
          }
          if (idx >= 0) {
            this.selectOneObj(this.curObjs[idx].id, '', idx, -1)
            return
          }
        }, 10)
      }
      this.selectAll(false)
    },
    // 防抖选择并激活一个对象
    debounceSelect(objId, parentObjId, idx, parentIdx, multiSelect) {
      if (multiSelect) {
        utils.debounce(this.selectObj, 100, 'selectObj')(objId, parentObjId, idx, parentIdx, true, multiSelect)
      } else {
        utils.debounce(this.selectOneObj, 100, 'selectObj')(objId, parentObjId, idx, parentIdx)
      }
    },
    // 选择并激活一个对象
    select(objId, parentObjId, idx, parentIdx, multiSelect) {
      if (multiSelect) {
        this.selectObj(objId, parentObjId, idx, parentIdx, true, multiSelect)
      } else {
        this.selectOneObj(objId, parentObjId, idx, parentIdx)
      }
    },
    // 选中一个对象
    selectOneObj(objId, parentObjId, idx, parentIdx) {
      this.setIdx(idx, parentIdx)
      let k
      let v
      const selectObjs = {}
      if (parentObjId && parentIdx >= 0) {
        k = 'sub-obj:' + this.state.sceneId + ':' + parentObjId + ':' + objId
        v = [idx, parentIdx]
      } else {
        k = 'obj:' + this.state.sceneId + ':' + objId
        v = [idx, -1]
      }
      selectObjs[k] = v
      this.state.selectObjs = selectObjs
      this.state.selectNums = 1
      this.debounceApplySelect()
      this.active(idx, parentIdx)
    },
    // 设置选中的对象
    selectObj(objId, parentObjId, idx, parentIdx, status, multiSelect) {
      this.setIdx(idx, parentIdx)
      let v
      let k
      if (parentObjId && parentIdx >= 0) {
        k = 'sub-obj:' + this.state.sceneId + ':' + parentObjId + ':' + objId
        v = [idx, parentIdx]
      } else {
        k = 'obj:' + this.state.sceneId + ':' + objId
        v = [idx, -1]
      }
      if (status) {
        // 多选时，自动取消选择
        if (multiSelect && this.state.selectObjs[k]) {
          delete this.state.selectObjs[k]
        } else {
          this.state.selectObjs[k] = v
        }
      } else {
        delete this.state.selectObjs[k]
      }
      // 统计选择数量
      this.state.selectNums = Object.keys(this.state.selectObjs).length
      this.debounceApplySelect()
      this.active(idx, parentIdx)
    },
    // 取消选中对象
    unselectObj(objId, parentObjId, idx, parentIdx) {
      this.setIdx(idx, parentIdx)
      let k
      if (parentObjId && parentIdx >= 0) {
        k = 'sub-obj:' + this.state.sceneId + ':' + parentObjId + ':' + objId
      } else {
        k = 'obj:' + this.state.sceneId + ':' + objId
      }
      delete this.state.selectObjs[k]
      // 统计选择数量
      this.state.selectNums = Object.keys(this.state.selectObjs).length
      this.debounceApplySelect()
      this.active(idx, parentIdx)
    },
    // 防抖应用选中项
    debounceApplySelect() {
      utils.debounce(this.applySelect, 100, 'applySelectObjsTimer')()
    },
    // 应用选中项
    applySelect() {
      const objs = this.curObjs
      const paletteObj = {}
      const fillShapeObj = {}
      const gridSizeObj = {}
      let brickfyNums = 0
      let lockNums = 0
      let unlockNums = 0
      let groupNums = 0
      let selectNums = 0
      let firstObjId = ''
      if (this.state.selectNums) {
        // 选中对象及子对象
        let k
        let v
        for (k in this.state.selectObjs) {
          v = this.state.selectObjs[k]
          if (v[1] >= 0 && objs[v[1]] && objs[v[1]].objs && objs[v[1]].objs[v[0]]) {
            if (objs[v[1]].objs[v[0]].show) {
              if (!firstObjId) {
                firstObjId = objs[v[1]].objs[v[0]].id
              }
              selectNums++
              paletteObj[objs[v[1]].objs[v[0]].paletteId] = objs[v[1]].objs[v[0]].palette
              if (objs[v[1]].objs[v[0]].fillShape) {
                fillShapeObj[objs[v[1]].objs[v[0]].fillShape] = objs[v[1]].objs[v[0]].fillShape
              }
              objs[v[1]].objs[v[0]].gridSize = objs[v[1]].objs[v[0]].gridSize || 8
              gridSizeObj[objs[v[1]].objs[v[0]].gridSize] = objs[v[1]].objs[v[0]].gridSize
              if (objs[v[1]].objs[v[0]].brickfy) {
                brickfyNums++
              }
              if (objs[v[1]].objs[v[0]].lock) {
                lockNums++
              } else {
                unlockNums++
              }
            } else {
              // 隐藏的不能被选中
              delete this.state.selectObjs[k]
            }
          } else {
            if (objs[v[0]] && objs[v[0]].show) {
              if (!firstObjId) {
                firstObjId = objs[v[0]].id
              }
              selectNums++
              paletteObj[objs[v[0]].paletteId] = objs[v[0]].palette
              if (objs[v[0]].fillShape) {
                fillShapeObj[objs[v[0]].fillShape] = objs[v[0]].fillShape
              }
              objs[v[0]].gridSize = objs[v[0]].gridSize || 8
              gridSizeObj[objs[v[0]].gridSize] = objs[v[0]].gridSize
              if (objs[v[0]].brickfy) {
                brickfyNums++
              }
              if (objs[v[0]].lock) {
                lockNums++
              } else {
                unlockNums++
              }
              if (objs[v[0]].type === 'group') {
                groupNums++
              }
            } else {
              // 隐藏的不能被选中
              delete this.state.selectObjs[k]
            }
          }
        }
      }
      this.state.selectNums = selectNums
      if (this.canMultiDrag) {
        this.calcMultiSelectBox()
      }
      const fillShapeIds = Object.keys(fillShapeObj)
      if (fillShapeIds.length === 1) {
        this.state.fillShape = fillShapeIds[0]
      } else {
        this.state.fillShape = ''
      }
      const gridSizeArr = Object.keys(gridSizeObj)
      if (gridSizeArr.length === 1) {
        this.state.objGridSize = gridSizeArr[0]
      } else {
        this.state.objGridSize = 0
      }
      this.state.brickfy = (brickfyNums === this.state.selectNums && this.state.selectNums)
      this.state.canUngroup = !!groupNums
      this.state.canMerge = !!groupNums || this.state.selectNums > 1
      this.state.canLock = !!unlockNums
      this.state.canUnlock = !!lockNums
    },
    // 激活一个对象
    active(idx, parentIdx) {
      this.setIdx(idx >= 0 ? idx : this.state.objIdx, parentIdx >= 0 ? parentIdx : this.state.parentObjIdx)
      this.state.activeObj = {}
      this.state.activeObj[idx + '_' + parentIdx] = true
    },
    // 取消激活一个对象
    deactive(idx, parentIdx) {
      this.state.activeObj = {}
      // 取消激活的对象，有选取框时，重置选取框
      if (this.isSelectPart && this.state.freeSelector.idx === idx && this.state.freeSelector.parentIdx === parentIdx) {
        this.initFreeSelector(this.canFreeSelect)
      }
    },
    // 全选/取消全选对象
    selectAll(status) {
      if (status) {
        this.resetSelectObjs()
        const objs = this.curObjs
        let i
        let j
        for (i in objs) {
          if (this.canSelectSubs && objs[i].type === 'group') {
            for (j in objs[i].objs) {
              this.selectObj(objs[i].objs[j].id, objs[i].id, j, i, status)
            }
          } else {
            this.selectObj(objs[i].id, '', i, -1, status)
          }
        }
      } else if (this.state.selectNums) {
        this.resetSelectObjs()
        this.debounceApplySelect()
      }
    },
    // 自动取消当前选择
    autoUnselectAll() {
      if (this.state.hoverObj[0] === -1 && (this.state.act === 'resize' || this.state.act === 'pick' || this.state.act === 'fill' || this.state.act === 'batchFill' || this.state.act === 'erase' || this.state.act === 'batchErase' || this.state.act === 'brush' || this.state.act === 'spray')) {
        // 鼠标点击对象之外时：取消全部已选项
        this.selectAll(false)
      }
    },
    // 添加新的文本对象
    newTxtObj(event, pos, txt) {
      txt = utils.trim(txt, true)
      if (!txt) return
      this.state.colorIdx = event && event.button === 2 ? 1 : 0
      const curColor = this.state.color[this.state.colorIdx]
      this.setAct()
      const options = {}
      options.content = txt
      options.color = curColor
      options.x = pos ? pos[0] : this.state.pos[0]
      options.y = pos ? pos[1] : this.state.pos[1]
      this.GRIDY.setFile(this.file)
      this.GRIDY.addTxtObj(this.state.sceneIdx, options)
      this.calcLayer()
      this.selectTopOne()
      this.setHistory('newTxtObj')
      this.traceEvent('newTxtObj')
    },
    initDrawShape(continueDrawShape) {
      this.state.drawShapeBox = utils.deepClone(conf.state.drawShapeBox)
      if (!this.isDesktop) return
      utils.removeEvent(this.$refs.mainArea, 'mousemove', this.drawingShape)
      utils.removeEvent(this.$refs.mainArea, 'mouseup', this.stopDrawShape)
      // 继续绘制图形
      if (continueDrawShape) {
        utils.addEvent(this.$refs.mainArea, 'mousedown', this.starDrawShape)
      } else {
        utils.removeEvent(this.$refs.mainArea, 'mousedown', this.starDrawShape)
      }
    },
    starDrawShape(event) {
      if (!this.canDrawShape) return
      this.state.colorIdx = event.button === 2 ? 1 : 0
      const pos = this.getDrawPos(event)
      this.tmp.lastPos = []
      this.state.drawShapeBox = { start: true, x: pos[0], y: pos[1], w: 0, h: 0 }
      this.newDrawObject(pos, 'shape')
      if (!this.isDesktop) return
      utils.addEvent(this.$refs.mainArea, 'mousemove', this.drawingShape)
      utils.addEvent(this.$refs.mainArea, 'mouseup', this.stopDrawShape)
      this.traceEvent('starDrawShape')
    },
    stopDrawShape(event) {
      this.drawingShape(event, 'stopDrawShape')
      this.callObj(this.curObj.id, '', 'stopDrawShape', [this.state.color[this.state.colorIdx]])
      this.initDrawShape(this.canDrawShape)
      setTimeout(() => {
        this.setAct('default')
      }, 10)
    },
    // 绘制图形
    drawingShape(event, eventType) {
      if (!this.canDrawShape) return
      const curColor = this.state.color[this.state.colorIdx]
      let pos = this.getDrawPos(event)
      if (!isNaN(pos[0]) && !isNaN(pos[1])) {
        this.tmp.lastPos = [pos[0], pos[1]]
      } else {
        if (!this.tmp.lastPos.length) {
          this.tmp.lastPos = [this.state.drawShapeBox.x + 11, this.state.drawShapeBox.y + 11]
        }
        pos = this.tmp.lastPos
      }
      const w = pos[0] - this.state.drawShapeBox.x
      const h = pos[1] - this.state.drawShapeBox.y
      if (w < 0) {
        this.curObj.x = Math.floor(pos[0] / this.state.gridSize)
      }
      if (h < 0) {
        this.curObj.y = Math.floor(pos[1] / this.state.gridSize)
      }
      this.curObj.cols = Math.max(Math.floor(Math.abs(w) / this.state.gridSize), 1)
      this.curObj.rows = Math.max(Math.floor(Math.abs(h) / this.state.gridSize), 1)
      const minSize = {
        'line': 5,
        'ellipse': 11,
        'rectangle': 11,
        'triangle': 11,
        'star': 19
      }
      if (eventType === 'stopDrawShape' && minSize[this.state.act]) {
        // 修正行、列数量，保证不变形
        if (this.state.act === 'line') {
          if (this.curObj.cols < minSize[this.state.act] && this.curObj.rows < minSize[this.state.act]) {
            if (this.curObj.rows > this.curObj.cols) {
              this.curObj.rows = minSize[this.state.act]
            } else {
              this.curObj.cols = minSize[this.state.act]
            }
          }
        } else {
          this.curObj.cols = Math.max(this.curObj.cols, minSize[this.state.act])
          this.curObj.rows = Math.max(this.curObj.rows, minSize[this.state.act])
        }
      }
      this.callObj(this.curObj.id, '', 'clearCanvas', ['origin', this.curObj.cols * this.state.gridSize, this.curObj.rows * this.state.gridSize])
      if (this.state.act === 'line') {
        let x1 = 0
        let y1 = 0
        let x2 = this.curObj.cols
        let y2 = this.curObj.rows
        if ((w < 0 && h > 0) || (w > 0 && h < 0)) {
          x1 = this.curObj.cols
          y1 = 0
          x2 = 0
          y2 = this.curObj.rows
        }
        this.callObj(this.curObj.id, '', 'clearCanvas', ['thumb', this.curObj.cols * this.state.gridSize, this.curObj.rows * this.state.gridSize])
        this.callObj(this.curObj.id, '', 'drawLine', ['thumbCtx', x1 * this.state.gridSize, y1 * this.state.gridSize, x2 * this.state.gridSize, y2 * this.state.gridSize, curColor, this.isDesktop ? 0.1 : 0.6])
        this.callObj(this.curObj.id, '', 'drawLine', ['originCtx', x1 * this.state.gridSize, y1 * this.state.gridSize, x2 * this.state.gridSize, y2 * this.state.gridSize, curColor, 6])
      } else if (this.state.act === 'ellipse') {
        this.callObj(this.curObj.id, '', 'drawEllipse', ['originCtx', this.curObj.cols * this.state.gridSize / 2, this.curObj.rows * this.state.gridSize / 2, this.curObj.cols * this.state.gridSize / 2, this.curObj.rows * this.state.gridSize / 2, curColor])
      } else if (this.state.act === 'rectangle') {
        this.callObj(this.curObj.id, '', 'drawRectangle', ['originCtx', 0, 0, this.curObj.cols * this.state.gridSize, this.curObj.rows * this.state.gridSize, curColor])
      } else if (this.state.act === 'triangle') {
        this.callObj(this.curObj.id, '', 'drawTriangle', ['originCtx', this.curObj.cols * this.state.gridSize / 2, 0, 0, this.curObj.rows * this.state.gridSize, this.curObj.cols * this.state.gridSize, this.curObj.rows * this.state.gridSize, curColor])
      } else if (this.state.act === 'star') {
        const cols = Math.max(this.curObj.cols, this.curObj.rows)
        this.curObj.cols = cols
        this.curObj.rows = cols
        this.callObj(this.curObj.id, '', 'clearCanvas', ['origin', this.curObj.cols * this.state.gridSize, this.curObj.rows * this.state.gridSize])
        this.callObj(this.curObj.id, '', 'drawStar', ['originCtx', cols * this.state.gridSize / 2, cols * this.state.gridSize / 2, cols * this.state.gridSize / 2, 0, curColor])
      }
    },
    // 初始化自由取色
    initFreeColorPick() {
      const x = Math.ceil(this.canvas.cols / 2)
      const y = Math.ceil(this.canvas.rows / 2)
      const box = this.getBox('workArea')
      this.state.workAreaBox = box
      this.state.freeColorPicker.x = box.x + x * this.state.gridSize + 0.5 * this.state.gridSize - 51
      this.state.freeColorPicker.y = box.y + y * this.state.gridSize + 0.5 * this.state.gridSize - 51
    },
    // 自由取色
    onFreeColorPicking(x, y) {
      this.state.freeColorPicker.x = x
      this.state.freeColorPicker.y = y
      const posX = x + 51 - this.state.workAreaBox.x + this.fixWidth
      const posY = y + 51 - this.state.workAreaBox.y + this.headerHeight
      const tapObjs = this.calcTapObj({ x: posX, y: posY })
      let color
      for (const i in tapObjs) {
        color = this.callObj(tapObjs[i].id, tapObjs[i].parentId, 'getPosColor', [{ x: x + 51, y: y + 51 }])
        if (color) break
      }
      this.state.freeColorPicker.color = color || this.canvasBgColor
    },
    onFreeColorPick(x, y) {
      if (this.state.freeColorPicker.color) this.handle('pick', [0, this.state.freeColorPicker.color])
      this.setAct(this.state.preAct || 'fill')
    },
    // 获取绘图鼠标位置
    getDrawPos(event) {
      if (event.touches && event.touches[0]) {
        event.clientX = event.touches[0].clientX
        event.clientY = event.touches[0].clientY
      }
      const box = this.getBox('workArea')
      const pos = [event.clientX - box.x, event.clientY - box.y]
      pos[0] = parseInt(pos[0])
      pos[1] = parseInt(pos[1])
      return pos
    },
    // 获取选取框鼠标位置
    getSelectorPos(event, gridInt) {
      if (event.touches && event.touches[0]) {
        event.clientX = event.touches[0].clientX
        event.clientY = event.touches[0].clientY
      }
      const pos = [event.clientX - this.fixWidth, event.clientY - this.headerHeight]
      if (gridInt) {
        pos[0] = this.getGridInt(pos[0])
        pos[1] = this.getGridInt(pos[1])
      } else {
        pos[0] = parseInt(pos[0])
        pos[1] = parseInt(pos[1])
      }
      return pos
    },
    // 转化成gridSize的整数倍
    getGridInt(i) {
      return Math.floor(i / this.state.gridSize) * this.state.gridSize
    },
    touchStart(event) {
      if (this.canFreeSelect) {
        this.startFreeSelect(event)
      } else if (this.canDrawShape) {
        this.starDrawShape(event)
      }
    },
    pressMove(event) {
      if (this.canFreeSelect) {
        this.freeSelecting(event)
      } else if (this.canDrawShape) {
        this.drawingShape(event)
      }
    },
    touchend(event) {
      if (this.canFreeSelect) {
        this.stopFreeSelect(event)
      } else if (this.canDrawShape) {
        this.stopDrawShape(event)
      }
    },
    doubleTap() {
      if (this.state.act === 'default' || this.state.act === 'select' || this.state.act === 'resize' || this.state.act === 'hand') {
        if (this.state.scale <= 1) {
          this.zoom(2)
        } else {
          this.zoom(1)
        }
      }
    },
    /** 待优化，暂不开启
      v-finger:pinch="pinch"
      v-finger:multipoint-end="multipointEnd"
     */
    pinch(evt) {
      if (this.state.act === 'hand' && !this.isDesktop) {
        this.state.pinchScale = evt.zoom
        this.state.pinch = true
      }
    },
    multipointEnd() {
      if (this.state.act === 'hand' && !this.isDesktop) {
        if (this.touchEndLock || !this.state.pinch) return
        if (this.state.pinchScale <= 1) {
          this.zoom('-')
        } else {
          this.zoom('+')
        }
        this.touchEndLock = true
        setTimeout(() => {
          this.touchEndLock = false
        }, 300)
      }
    },
    // 初始化选择框
    initFreeSelector(continueFreeSelect) {
      this.state.freeSelector = utils.deepClone(conf.state.freeSelector)
      this.state.freeSelector.x = this.state.gridSize * -1
      this.state.freeSelector.y = this.state.gridSize * -1
      this.state.freeSelector.w = this.state.gridSize
      this.state.freeSelector.h = this.state.gridSize
      utils.removeEvent(this.$refs.mainArea, 'mousemove', this.freeSelecting)
      utils.removeEvent(this.$refs.mainArea, 'mouseup', this.stopFreeSelect)
      // 继续使用选取框
      if (continueFreeSelect) {
        utils.addEvent(this.$refs.mainArea, 'mousedown', this.startFreeSelect)
      } else {
        utils.removeEvent(this.$refs.mainArea, 'mousedown', this.startFreeSelect)
      }
      this.calcLayer()
    },
    // 开始选取子对象
    freeSelectSubs(event, parentIdx, parentObj, subObjs) {
      if (!subObjs || !subObjs.length) {
        return
      }
      // 使用select、freeSelect工具可选择组合内的子对象
      let j
      for (j in subObjs) {
        if (this.mouseonObj(event, subObjs[j], parentObj)) {
          // 选择子对象
          this.select(subObjs[j].id, parentObj.id, j, parentIdx, event.shiftKey)
          if (!this.canSelectPart) {
            this.initFreeSelector(true)
            return 'return'
          }
          return 'break'
        }
      }
    },
    // 开始选取
    startFreeSelect(event) {
      // 编辑对象文本 || 不能部分选取 || 已经部分选取 || 使用鼠标右键
      if (this.state.editTxt || !this.canFreeSelect || this.isSelectPart || event.button === 2) {
        return
      }
      // 未按shift时进行多选时
      if (!event.shiftKey) {
        // 不是部分选择 && 多选 && 鼠标在已选定对象上时：支持同时拖动多个对象
        this.calcHoverObj(event)
        let hoverSelectObj = false
        Object.values(this.state.selectObjs).map((arr) => {
          if (arr[0] === this.state.hoverObj[0]) {
            if (!hoverSelectObj) hoverSelectObj = true
          }
        })
        if (!this.canSelectPart && this.state.selectNums > 1 && hoverSelectObj) {
          return
        }
        this.selectAll(false)
      }
      this.state.freeSelector = utils.deepClone(conf.state.freeSelector)
      this.state.freeSelector.start = true
      this.state.freeSelector.show = false
      this.state.freeSelector.active = false
      const pos = this.getSelectorPos(event)
      this.state.freeSelector.x = pos[0]
      this.state.freeSelector.y = pos[1]
      this.state.freeSelector.startPos = pos
      const objs = utils.deepClone(this.curObjs || [])
      let i
      for (i in objs) {
        if (this.mouseonObj(event, objs[i])) {
          if (this.canSelectSubs && objs[i].type === 'group') {
            const act = this.freeSelectSubs(event, i, objs[i], objs[i].objs)
            if (act === 'break') {
              break
            } else if (act === 'return') {
              return
            }
          } else {
            this.select(objs[i].id, '', i, -1, event.shiftKey)
            if (!this.canSelectPart) {
              this.initFreeSelector(true)
              return
            }
          }
          break
        }
      }
      utils.addEvent(this.$refs.mainArea, 'mousemove', this.freeSelecting)
      utils.addEvent(this.$refs.mainArea, 'mouseup', this.stopFreeSelect)
      this.traceEvent('startFreeSelect')
    },
    // 选取中
    freeSelecting(event) {
      // 适配触摸模式
      if (event.type === 'touchmove') event.button = 0
      if (!this.canFreeSelect || event.button !== 0 || !this.state.freeSelector.start || this.isSelectPart) {
        return
      }
      const pos = this.getSelectorPos(event)
      const w = pos[0] - this.state.freeSelector.startPos[0]
      const h = pos[1] - this.state.freeSelector.startPos[1]
      if (w < 0) {
        this.state.freeSelector.x = pos[0]
      }
      if (h < 0) {
        this.state.freeSelector.y = pos[1]
      }
      this.state.freeSelector.w = Math.max(Math.abs(w), this.state.gridSize)
      this.state.freeSelector.h = Math.max(Math.abs(h), this.state.gridSize)
      if (this.state.freeSelector.w > this.state.gridSize || this.state.freeSelector.h > this.state.gridSize) {
        this.state.freeSelector.show = true
      }
    },
    // 选取结束
    stopFreeSelect(event) {
      // 适配触摸模式
      if (event.type === 'touchend') event.button = 0
      if (!this.canFreeSelect || event.button !== 0 || !this.state.freeSelector.start || this.isSelectPart) {
        return
      }
      // 选择范围太小，直接结束
      if (this.state.freeSelector.w < this.state.gridSize * 3 && this.state.freeSelector.h < this.state.gridSize * 3) {
        this.initFreeSelector(true)
        return
      }
      // 保留显示选取框，可继续拖拽、改变尺寸
      this.state.freeSelector.start = false
      this.state.freeSelector.active = true
      utils.removeEvent(this.$refs.mainArea, 'mousedown', this.startFreeSelect)
      utils.removeEvent(this.$refs.mainArea, 'mousemove', this.freeSelecting)
      utils.removeEvent(this.$refs.mainArea, 'mouseup', this.stopFreeSelect)
      this.calcSelectObjs(this.state.freeSelector)
    },
    // 计算选中的子对象 或 子对象的部分数据
    calcSelectSubObjs(freeSelector, parentIdx, parentObj) {
      // 修正坐标
      const selectBox = this.calcSelectBox(freeSelector, parentObj)
      let j
      // 选择子对象
      for (j in parentObj.objs) {
        if (this.checkSelect(selectBox, parentObj.objs[j])) {
          if (this.canSelectPart && this.checkSelectPart(selectBox, parentObj.objs[j])) {
            // 选中部分子对象
            this.select(parentObj.objs[j].id, parentObj.id, j, parentIdx, false)
            freeSelector.partSelect = true
            freeSelector.idx = j
            freeSelector.parentIdx = parentIdx
            return true
          } else {
            // 选中多个子对象
            this.select(parentObj.objs[j].id, parentObj.id, j, parentIdx, true)
          }
        }
      }
    },
    // 计算选中的对象 或 对象的部分数据
    calcSelectObjs(freeSelector) {
      freeSelector.partSelect = false
      const selectBox = this.calcSelectBox(freeSelector)
      const objs = utils.deepClone(this.curObjs || [])
      let num = 0
      let i
      for (i in objs) {
        if (this.checkSelect(selectBox, objs[i])) {
          if (this.canSelectPart && this.checkSelectPart(selectBox, objs[i])) {
            if (this.canSelectSubs && objs[i].type === 'group' && objs[i].objs && objs[i].objs.length) {
              if (this.calcSelectSubObjs(freeSelector, i, objs[i])) {
                break
              }
            } else {
              // 选中部分对象
              this.select(objs[i].id, '', i, -1, false)
              freeSelector.partSelect = true
              freeSelector.idx = i
              freeSelector.parentIdx = -1
            }
            break
          } else {
            // 选中多个对象
            this.select(objs[i].id, '', i, -1, !!num)
            num++
          }
        }
      }
      // 不是部分选择时，初始化选取框
      if (!this.isSelectPart) {
        this.initFreeSelector(true)
      }
      this.calcLayer()
    },
    // 计算点选的子对象
    calcTapSubObj(box, parentId, parentObj) {
      box.x = box.x - parentObj.x * this.state.gridSize
      box.y = box.y - parentObj.y * this.state.gridSize
      const tapObjs = []
      for (const j in parentObj.objs) {
        if (this.checkSelect(box, parentObj.objs[j])) {
          tapObjs.push({ id: parentObj.objs[j].id, parentId: parentId })
        }
      }
      return tapObjs
    },
    // 计算点选的对象
    calcTapObj(pos) {
      const objs = this.curObjs || []
      let tapObjs = []
      for (const i in objs) {
        if (this.checkSelect({ x: pos.x, y: pos.y, w: 1, h: 1 }, objs[i])) {
          if (objs[i].type === 'group' && objs[i].objs && objs[i].objs.length) {
            tapObjs = tapObjs.concat(this.calcTapSubObj({ x: pos.x, y: pos.y, w: 1, h: 1 }, objs[i].id, objs[i]))
          } else {
            tapObjs.push({ id: objs[i].id, parentId: '' })
          }
        }
      }
      return tapObjs
    },
    // 计算选择框
    calcSelectBox(freeSelector, parentObj) {
      freeSelector = utils.deepClone(freeSelector || this.state.freeSelector)
      const selectBox = { x: freeSelector.x, y: freeSelector.y, w: freeSelector.w, h: freeSelector.h }
      const box = this.getBox('workArea')
      // 转换坐标
      selectBox.x = selectBox.x - box.x + this.fixWidth
      selectBox.y = selectBox.y - box.y + this.headerHeight
      if (parentObj) {
        selectBox.x = selectBox.x - parentObj.x * this.state.gridSize
        selectBox.y = selectBox.y - parentObj.y * this.state.gridSize
      }
      this.state.selectBox = selectBox
      return selectBox
    },
    // 检测是否选中
    checkSelect(box, obj) {
      if (!obj || !obj.show) {
        return false
      }
      return !(((obj.y + obj.rows) * this.state.gridSize < box.y) || (obj.y * this.state.gridSize > box.y + box.h) || ((obj.x + obj.cols) * this.state.gridSize < box.x) || (obj.x * this.state.gridSize > box.x + box.w))
    },
    // 检测是否部分选中
    checkSelectPart(box, obj) {
      if (!obj || !obj.show || obj.lock) {
        return false
      }
      return (box.x >= obj.x * this.state.gridSize && box.y >= obj.y * this.state.gridSize && box.x + box.w <= (obj.x + obj.cols) * this.state.gridSize && box.y + box.h <= (obj.y + obj.rows) * this.state.gridSize)
    },
    // 限制选择框尺寸和位置：只能在对象内
    limitSelectBox(freeSelector, obj, parentObj) {
      // 限制尺寸
      freeSelector.w = freeSelector.w < this.state.gridSize * 3 ? this.state.gridSize * 3 : freeSelector.w
      freeSelector.h = freeSelector.h < this.state.gridSize * 3 ? this.state.gridSize * 3 : freeSelector.h
      freeSelector.w = freeSelector.w > obj.cols * this.state.gridSize ? obj.cols * this.state.gridSize : freeSelector.w
      freeSelector.h = freeSelector.h > obj.rows * this.state.gridSize ? obj.rows * this.state.gridSize : freeSelector.h
      const selectBox = this.calcSelectBox(freeSelector, parentObj)
      let x
      let y
      if (selectBox.x < obj.x * this.state.gridSize) {
        x = obj.x * this.state.gridSize
      } else if (selectBox.x + selectBox.w > (obj.x + obj.cols) * this.state.gridSize) {
        x = selectBox.x - (selectBox.x + selectBox.w - (obj.x + obj.cols) * this.state.gridSize)
      } else {
        x = selectBox.x
      }
      if (selectBox.y < obj.y * this.state.gridSize) {
        y = obj.y * this.state.gridSize
      } else if (selectBox.y + selectBox.h > (obj.y + obj.rows) * this.state.gridSize) {
        y = selectBox.y - (selectBox.y + selectBox.h - (obj.y + obj.rows) * this.state.gridSize)
      } else {
        y = selectBox.y
      }
      const box = this.getBox('workArea')
      x = x + box.x - this.fixWidth
      y = y + box.y - this.headerHeight
      if (parentObj) {
        x = x + parentObj.x * this.state.gridSize
        y = y + parentObj.y * this.state.gridSize
      }
      setTimeout(() => {
        freeSelector.x = x
        freeSelector.y = y
      }, 100)
    },
    // 拖动中
    onSelectorDragging(x, y) {
      this.state.freeSelector.x = x
      this.state.freeSelector.y = y
      this.state.freeSelector.startPos = [x, y]
    },
    // 拖动
    onSelectorDrag(x, y) {
      this.onSelectorDragging(x, y)
      this.setIdx(this.state.freeSelector.idx, this.state.freeSelector.parentIdx)
      const selectBox = this.calcSelectBox(this.state.freeSelector, this.parentObj)
      if (this.checkSelect(selectBox, this.curObj)) {
        this.limitSelectBox(this.state.freeSelector, this.curObj, this.parentObj)
      } else {
        // 未选中当前对象：重置选取框
        this.initFreeSelector(true)
      }
    },
    // 缩放中
    onSelectorResizing(x, y, w, h) {
      this.state.freeSelector.x = x
      this.state.freeSelector.y = y
      this.state.freeSelector.w = w
      this.state.freeSelector.h = h
      this.state.freeSelector.startPos = [x, y]
    },
    // 缩放
    onSelectorResize(x, y, w, h) {
      this.onSelectorResizing(x, y, w, h)
      this.setIdx(this.state.freeSelector.idx, this.state.freeSelector.parentIdx)
      this.limitSelectBox(this.state.freeSelector, this.curObj, this.parentObj)
      this.calcSelectObjs(this.state.freeSelector)
    },
    // 计算选中的对象box
    calcMultiSelectBox() {
      const multiSelectBox = utils.deepClone(conf.state.multiSelectBox)
      if (this.state.selectNums > 1) {
        this.copySelectObjs('selectObjs')
        const box = this.calcBox(this.tmp.selectObjs)
        if (box) {
          const workAreaBox = this.getBox('workArea')
          multiSelectBox.start = true
          multiSelectBox.startSize = [box.w * this.state.gridSize, box.h * this.state.gridSize]
          multiSelectBox.stopSize = multiSelectBox.startSize
          multiSelectBox.startPos = [box.x * this.state.gridSize + workAreaBox.x - this.fixWidth, box.y * this.state.gridSize + workAreaBox.y - this.headerHeight]
          multiSelectBox.stopPos = multiSelectBox.startPos
        }
        this.tmp.selectObjs = ''
      }
      this.state.multiSelectBox = multiSelectBox
    },
    // 拖动中
    onBoxDragging(x, y) {
      this.state.multiSelectBox.stopPos = [this.getGridInt(x), this.getGridInt(y)]
      this.state.multiSelectBox.offsetX = Math.round((this.state.multiSelectBox.stopPos[0] - this.state.multiSelectBox.startPos[0]) / this.state.gridSize)
      this.state.multiSelectBox.offsetY = Math.round((this.state.multiSelectBox.stopPos[1] - this.state.multiSelectBox.startPos[1]) / this.state.gridSize)
      this.state.multiSelectBox.dragging = true
      this.dragSelectObjs()
    },
    // 拖动
    onBoxDrag(x, y) {
      Object.values(this.state.selectObjs).map((arr) => {
        if (arr[1] >= 0) {
          this.resetGroupBoxSize(...arr)
        }
      })
      this.calcMultiSelectBox()
      this.setHistory('onBoxDrag', [x, y])
    },
    // 缩放框缩放时
    onBoxResizing(x, y, width, height) {
      this.state.multiSelectBox.stopSize = [width, height]
      this.state.multiSelectBox.offsetCols = Math.round((width - this.state.multiSelectBox.startSize[0]) / this.state.gridSize)
      this.state.multiSelectBox.offsetRows = Math.round((height - this.state.multiSelectBox.startSize[1]) / this.state.gridSize)
      this.state.multiSelectBox.resizing = true
      this.resizeSelectObjs('onResizing')
    },
    // 缩放框缩放完成
    onBoxResize(x, y, width, height) {
      this.resizeSelectObjs('onResize')
      this.calcMultiSelectBox()
    },
    // 拖拽选中的对象
    dragSelectObjs() {
      const objs = this.curObjs
      Object.values(this.state.selectObjs).map((arr) => {
        if (arr[1] >= 0) {
          objs[arr[1]].objs[arr[0]].x = objs[arr[1]].objs[arr[0]].x + this.state.multiSelectBox.offsetX
          objs[arr[1]].objs[arr[0]].y = objs[arr[1]].objs[arr[0]].y + this.state.multiSelectBox.offsetY
        } else {
          objs[arr[0]].x = objs[arr[0]].x + this.state.multiSelectBox.offsetX
          objs[arr[0]].y = objs[arr[0]].y + this.state.multiSelectBox.offsetY
        }
      })
      this.state.multiSelectBox.startPos = this.state.multiSelectBox.stopPos
      this.state.multiSelectBox.offsetX = this.state.multiSelectBox.offsetY = 1
    },
    // 缩放选中的对象
    resizeSelectObjs(fn) {
      const objs = this.curObjs
      let id
      let parentId
      let width
      let height
      Object.values(this.state.selectObjs).map((arr) => {
        if (arr[1] >= 0) {
          id = objs[arr[1]].objs[arr[0]].id
          parentId = objs[arr[1]].id
          width = objs[arr[1]].objs[arr[0]].cols + this.state.multiSelectBox.offsetCols
          height = objs[arr[1]].objs[arr[0]].rows + this.state.multiSelectBox.offsetRows
        } else {
          id = objs[arr[0]].id
          parentId = ''
          width = objs[arr[0]].cols + this.state.multiSelectBox.offsetCols
          height = objs[arr[0]].rows + this.state.multiSelectBox.offsetRows
        }
        width = Math.ceil(width) * this.state.gridSize
        height = Math.ceil(height) * this.state.gridSize
        this.callObj(id, parentId, fn, ['', '', width, height])
      })
    },
    // 获取对象的部分数据
    getPartObj(box, obj, mod) {
      if (!obj || !obj.data || !obj.data.length) {
        return
      }
      // 剪切部分选取的对象
      box.x = Math.round(box.x / this.state.gridSize) - obj.x
      box.y = Math.round(box.y / this.state.gridSize) - obj.y
      box.w = Math.round(box.w / this.state.gridSize)
      box.h = Math.round(box.h / this.state.gridSize)
      const partObj = { refSceneId: this.sceneId, refObjectId: obj.id, x: box.x + obj.x, y: box.y + obj.y, cols: box.w, rows: box.h, fillShape: obj.fillShape, paletteId: obj.paletteId, data: [] }
      partObj.data = new Array(partObj.cols * partObj.rows)
      let x = 0
      let y = 0
      Object.keys(obj.data).map((i) => {
        y = Math.floor(i / obj.cols)
        x = i - y * obj.cols
        if (x >= box.x && x < box.x + box.w && y >= box.y && y < box.y + box.h) {
          partObj.data[(x - box.x) + (y - box.y) * partObj.cols] = obj.data[i] || ''
          if (mod === 'partCut' || mod === 'partDel') {
            obj.data[i] = ''
          }
        }
      })
      if (mod === 'partCut' || mod === 'partDel') {
        this.callObj(obj.id, '', 'createOrigin', [true])
      }
      if (mod === 'partCut' || mod === 'partCopy') {
        this.tmp.partObj = partObj
        this.state.objAct = mod
      } else if (mod === 'partDel') {
        this.tmp.partObj = ''
        this.state.objAct = ''
      }
      return partObj
    },
    // 对selectObjs 进行排序
    sortSelectObjs() {
      const arr = Object.values(this.state.selectObjs)
      const sortIdx = function(a, b) {
        return a[0] - b[0]
      }
      const sortParentIdx = function(a, b) {
        return a[1] - b[1]
      }
      return arr.sort(sortIdx).sort(sortParentIdx)
    },
    setFillShape(shape) {
      this.state.fillShape = shape
      this.file.fillShape = shape
      this.callSelectObjs('setFillShape', [shape])
    },
    setBricksize(sizeId) {
      this.state.brickSizeId = sizeId
      this.callSelectObjs('setBricksize', [sizeId])
    },
    setPalette(paletteId) {
      this.file.paletteId = paletteId
      this.callSelectObjs('setPalette', [paletteId])
    },
    // 设置调色板颜色
    usePalette(palId, palDt) {
      this.palette.paletteId = palId || 'base'
      if (this.palette.paletteId === 'none' || this.palette.paletteId === 'default') {
        this.palette.paletteId = 'base'
      }
      let palColors = {}
      // palColors = utils.sortColors(this.getPaletteColors(this.palette.paletteId))
      palColors = this.getPaletteColors(this.palette.paletteId)
      palColors = Object.keys(palColors)
      // palColors = palColors.sort()
      setTimeout(() => {
        this.state.drawStartTime = 0
        this.$refs['palette'] && this.$refs['palette'].setColors(palColors)
        this.$refs['bottomBar'] && this.$refs['bottomBar'].setColors(palColors)
        this.$refs['colorPicker'] && this.$refs['colorPicker'].setColors(palColors)
      }, 10)
    },
    // 复制对象
    copy() {
      if (this.isSelectPart) {
        this.setIdx(this.state.freeSelector.idx, this.state.freeSelector.parentIdx)
        this.getPartObj(this.state.selectBox, this.curObj, 'partCopy')
        this.initFreeSelector(true)
      } else if (this.state.selectNums) {
        this.copySelectObjs()
        this.state.objAct = 'copy'
        this.tmp.partObj = ''
      }
    },
    // 复制选中的对象
    copySelectObjs(tmpKey, withLockObj = true) {
      const deepClone = (ob) => {
        const o = utils.deepClone(ob)
        o.refSceneId = this.sceneId
        o.refObjectId = o.id
        return o
      }
      if (this.state.selectNums) {
        tmpKey = tmpKey || 'objs'
        const objs = this.curObjs
        let obj
        this.tmp[tmpKey] = []
        const selectObjs = this.sortSelectObjs()
        Object.values(selectObjs).map((arr) => {
          if (arr[1] >= 0) {
            obj = deepClone(objs[arr[1]].objs[arr[0]])
            obj.x = obj.x + objs[arr[1]].x
            obj.y = obj.y + objs[arr[1]].y
            if (withLockObj) {
              this.tmp[tmpKey].unshift(obj)
            } else {
              if (!obj.lock) this.tmp[tmpKey].unshift(obj)
            }
          } else {
            if (withLockObj) {
              this.tmp[tmpKey].unshift(deepClone(objs[arr[0]]))
            } else {
              if (!objs[arr[0]].lock) this.tmp[tmpKey].unshift(deepClone(objs[arr[0]]))
            }
          }
        })
      }
    },
    // 剪切对象
    cut(idx, parentIdx) {
      if (this.isSelectPart) {
        this.setIdx(this.state.freeSelector.idx, this.state.freeSelector.parentIdx)
        this.getPartObj(this.state.selectBox, this.curObj, 'partCut')
        if (this.state.freeSelector.parentIdx >= 0) {
          this.drawObj(this.state.freeSelector.parentIdx)
          this.callObj(this.curObj.id, this.parentObj.id, 'createOrigin', [true])
        } else {
          this.drawObj(this.state.freeSelector.idx)
          this.callObj(this.curObj.id, '', 'createOrigin', [true])
        }
        this.initFreeSelector(true)
        this.resetGroupBoxSize()
        this.setHistory('cut', [idx, parentIdx])
      } else if (this.state.selectNums) {
        this.copySelectObjs()
        this.delete(false)
        this.state.objAct = 'cut'
        this.tmp.partObj = ''
      }
    },
    // 对象裁剪
    crop() {
      if (this.isSelectPart) {
        this.setIdx(this.state.freeSelector.idx, this.state.freeSelector.parentIdx)
        const obj = this.curObj
        const partObj = this.getPartObj(this.state.selectBox, obj, 'partCrop')
        if (partObj) {
          obj.cols = parseInt(partObj.cols)
          obj.rows = parseInt(partObj.rows)
          obj.x = partObj.x
          obj.y = partObj.y
          obj.data = partObj.data
          if (this.state.freeSelector.parentIdx >= 0) {
            this.drawObj(this.state.freeSelector.parentIdx)
            this.callObj(obj.id, this.parentObj.id, 'createOrigin', [true])
          } else {
            this.drawObj(this.state.freeSelector.idx)
            this.callObj(obj.id, '', 'createOrigin', [true])
          }
          this.setHistory('crop')
          this.setAct('default')
        }
      }
      this.initFreeSelector(true)
      this.resetGroupBoxSize()
    },
    // 黏贴对象
    parse(idx, parentIdx) {
      let obj
      // 黏贴对象部分数据
      if (this.state.objAct && this.tmp.partObj) {
        const options = {
          x: this.tmp.partObj.x,
          y: this.tmp.partObj.y,
          cols: parseInt(this.tmp.partObj.cols),
          rows: parseInt(this.tmp.partObj.rows),
          data: utils.deepClone(this.tmp.partObj.data),
          refSceneId: this.tmp.partObj.refSceneId,
          refObjectId: this.tmp.partObj.refObjectId,
          fillShape: this.tmp.partObj.fillShape,
          paletteId: this.tmp.partObj.paletteId,
          userid: this.loginUserId
        }
        obj = this.GRIDY.createObj(options)
        if (this.state.objAct === 'partCut') {
          obj.name = '剪切的对象'
          // 剪切、黏贴是一次性的
          this.tmp.partObj = ''
          this.state.objAct = ''
        } else if (this.state.objAct === 'partCopy') {
          obj.name = '复制的对象'
        }
        this.curObjs.unshift(obj)
        this.selectTopOne()
        this.calcLayer()
        this.setHistory('parse', [idx, parentIdx])
        return
      }
      if (!this.tmp.objs.length) {
        return
      }
      // 黏贴对象
      if (this.state.objAct === 'copy') {
        Object.keys(this.tmp.objs).map((i) => {
          obj = utils.deepClone(this.tmp.objs[i])
          // 拷贝的是组合，重置子对象id
          if (obj.type === 'group' && obj.objs && obj.objs.length) {
            Object.keys(obj.objs).map((j) => {
              obj.objs[j].id = utils.uuid()
              obj.objs[j].lock = false
              obj.objs[j].from = this.state.objAct
            })
          }
          obj.from = this.state.objAct
          obj.id = utils.uuid()
          obj.lock = false
          this.curObjs.unshift(obj)
        })
      } else if (this.state.objAct === 'cut') {
        Object.keys(this.tmp.objs).map((i) => {
          obj = utils.deepClone(this.tmp.objs[i])
          obj.lock = false
          obj.from = this.state.objAct
          this.curObjs.unshift(obj)
        })
        this.state.objAct = ''
        this.tmp.objs = []
      }
      this.selectTopOne()
      this.calcLayer()
      this.setHistory('parse', [idx, parentIdx])
    },
    // 拷贝对象
    copyAndParse(idx) {
      const obj = utils.deepClone(this.curObjs[idx])
      obj.from = 'copyAndParse'
      obj.refSceneId = this.sceneId
      obj.refObjectId = obj.id
      obj.id = utils.uuid()
      obj.x = obj.x + 2
      obj.y = obj.y + 2
      obj.resizable = true
      obj.draggable = true
      obj.lock = false
      if (obj.objs) {
        for (const i in obj.objs) {
          obj.objs[i].id = utils.uuid()
        }
      }
      this.curObjs.unshift(obj)
      this.selectTopOne()
      this.calcLayer()
      this.setHistory('copyAndParse', [idx])
    },
    // 新增空对象
    addBlankObj(init, saveHistory = true) {
      const options = {
        name: init ? '默认对象' : '新的对象',
        cols: parseInt(this.canvas.cols),
        rows: parseInt(this.canvas.rows),
        userid: this.loginUserId
      }
      const obj = this.GRIDY.createObj(options)
      // let i
      // const len = this.canvas.cols * this.canvas.rows
      // for (i = 0; i < len; i++) {
      //   obj.data.push('#FFFFFF')
      // }
      this.curObjs.unshift(obj)
      this.selectTopOne()
      if (saveHistory) this.setHistory('addBlankObj', [init])
    },
    // 删除单个对象
    del(idx, parentIdx) {
      this.setIdx(idx, parentIdx)
      const objs = this.subObjs || this.curObjs || []
      if (this.curObj) {
        if (this.parentObj) {
          this.unselectObj(this.curObj.id, this.parentObj.id, idx, parentIdx)
        } else {
          this.unselectObj(this.curObj.id, '', idx, -1)
        }
        if (objs[idx] && !objs[idx].lock) {
          objs.splice(idx, 1)
        }
      }
      this.selectTopOne()
      this.setHistory('del', [idx, parentIdx])
    },
    // 删除选中的对象 或 删除对象部分数据
    delete(silence) {
      if (this.isSelectPart) {
        this.setIdx(this.state.freeSelector.idx, this.state.freeSelector.parentIdx)
        // 删除对象部分数据
        this.getPartObj(this.state.selectBox, this.curObj, 'partDel')
        if (this.state.freeSelector.parentIdx >= 0) {
          this.drawObj(this.state.freeSelector.parentIdx)
          this.callObj(this.curObj.id, this.parentObj.id, 'createOrigin', [true])
        } else {
          this.drawObj(this.state.freeSelector.idx)
          this.callObj(this.curObj.id, '', 'createOrigin', [true])
        }
        this.initFreeSelector(true)
        this.resetGroupBoxSize()
      } else if (this.state.selectNums) {
        this.GRIDY.setFile(this.file)
        this.GRIDY.delete(this.state.sceneIdx, this.state.selectObjs)
        // 重置所有组合box
        this.resetAllGroupBoxSize()
        this.selectTopOne()
      }
      if (!silence) {
        this.setHistory('delete', [silence])
      }
    },
    // 调用对象方法
    callObj(id, parentId, fn, params) {
      if (!id || !fn) {
        return
      }
      params = params || []
      if (parentId) {
        if (this.$refs['obj:' + this.state.sceneId + ':' + parentId]) return this.$refs['obj:' + this.state.sceneId + ':' + parentId][0].$refs['sub-obj:' + this.state.sceneId + ':' + parentId + ':' + id][0][fn](...params)
      } else {
        if (this.$refs['obj:' + this.state.sceneId + ':' + id]) return this.$refs['obj:' + this.state.sceneId + ':' + id][0][fn](...params)
      }
    },
    // 调用已选对象方法
    callSelectObjs(fn, params) {
      if (!fn) {
        return
      }
      params = params || []
      const objs = this.curObjs
      if (this.state.selectNums) {
        Object.values(this.state.selectObjs).map((arr) => {
          if (arr[1] >= 0) {
            if (objs[arr[1]]) this.callObj(objs[arr[1]].objs[arr[0]].id, objs[arr[1]].id, fn, params)
          } else {
            if (objs[arr[0]]) this.callObj(objs[arr[0]].id, '', fn, params)
          }
        })
      }
    },
    // 设置已选对象属性
    setSelectObjs(k, v) {
      if (!k) {
        return
      }
      const objs = this.curObjs
      if (this.state.selectNums) {
        Object.values(this.state.selectObjs).map((arr) => {
          if (arr[1] >= 0) {
            objs[arr[1]].objs[arr[0]][k] = v
          } else {
            objs[arr[0]][k] = v
          }
        })
        this.setHistory('setSelectObjs', [k, v])
      }
    },
    // 清除已选的对象
    clear() {
      this.callSelectObjs('clear')
    },
    // 恢复已选的对象
    restore() {
      this.callSelectObjs('restore')
    },
    // 向下合并图层
    mergeDown(idx) {
      if (this.curObjs[idx + 1]) {
        if (this.curObjs[idx + 1].lock) {
          this.alert('锁定的对象不能合并')
          return
        }
        this.select(this.curObjs[idx + 1].id, '', idx + 1, -1, true)
        this.merge()
      }
    },
    // 重置所有组合的矩形容器
    resetAllGroupBoxSize() {
      if (this.curObjs.length) {
        Object.keys(this.curObjs).map((i) => {
          this.resetGroupBoxSize(0, i)
        })
      }
    },
    // 重置组合的矩形容器
    resetGroupBoxSize(idx, parentIdx) {
      this.setIdx(idx >= 0 ? idx : this.state.objIdx, parentIdx >= 0 ? parentIdx : this.state.parentObjIdx)
      const parentObj = this.parentObj
      if (!parentObj || parentObj.type !== 'group') {
        return
      }
      const objs = this.subObjs || []
      const box = this.calcBox(objs)
      if (!box) {
        return
      }
      parentObj.x = parentObj.x + box.x
      parentObj.y = parentObj.y + box.y
      parentObj.cols = parseInt(box.w)
      parentObj.rows = parseInt(box.h)
      let i
      for (i in objs) {
        objs[i].x = objs[i].x - box.x
        objs[i].y = objs[i].y - box.y
      }
    },
    // 对齐
    align(mod) {
      this.copySelectObjs('selectObjs')
      if (!this.tmp.selectObjs || this.tmp.selectObjs.length === 0) {
        this.tmp.selectObjs = ''
        return
      }
      this.GRIDY.setFile(this.file)
      if (mod === 'spaceH' || mod === 'spaceV') {
        this.GRIDY.alignAverage(this.state.sceneIdx, this.state.selectObjs, mod)
      } else {
        this.GRIDY.align(this.state.sceneIdx, this.state.selectObjs, mod)
      }
      this.setHistory('align', [mod])
    },
    // 计算对象的矩形容器
    calcBox(objs) {
      let tl
      let rb
      objs = utils.deepClone(objs || this.curObjs || [])
      const selectObjs = []
      let i
      for (i in objs) {
        if (!tl) {
          tl = [objs[i].x, objs[i].y]
        }
        if (!rb) {
          rb = [objs[i].x + objs[i].cols, objs[i].y + objs[i].rows]
        }
        selectObjs.push(i)
        tl[0] = objs[i].x < tl[0] ? objs[i].x : tl[0]
        tl[1] = objs[i].y < tl[1] ? objs[i].y : tl[1]
        rb[0] = (objs[i].x + objs[i].cols) > rb[0] ? (objs[i].x + objs[i].cols) : rb[0]
        rb[1] = (objs[i].y + objs[i].rows) > rb[1] ? (objs[i].y + objs[i].rows) : rb[1]
      }
      if (selectObjs.length) {
        return { objs: selectObjs, x: tl[0], y: tl[1], w: rb[0] - tl[0], h: rb[1] - tl[1] }
      }
    },
    // 合并
    merge() {
      this.copySelectObjs('selectObjs', false)
      if (!this.tmp.selectObjs || this.tmp.selectObjs.length <= 1) {
        this.tmp.selectObjs = ''
        return
      }
      this.GRIDY.setFile(this.file)
      this.GRIDY.merge(this.state.sceneIdx, this.state.selectObjs, this.tmp.selectObjs)
      this.tmp.selectObjs = ''
      this.state.objAct = ''
      this.selectTopOne()
      this.setHistory('merge')
    },
    // 组合
    group() {
      this.copySelectObjs('selectObjs')
      if (!this.tmp.selectObjs || this.tmp.selectObjs.length <= 1) {
        this.tmp.selectObjs = ''
        return
      }
      this.GRIDY.setFile(this.file)
      this.GRIDY.group(this.state.sceneIdx, this.state.selectObjs, this.tmp.selectObjs)
      this.tmp.selectObjs = ''
      this.selectTopOne()
      this.calcLayer()
      this.setHistory('group')
    },
    // 取消组合
    ungroup() {
      if (this.state.selectNums === 0) {
        return
      }
      this.GRIDY.setFile(this.file)
      const ungroupObjNums = this.GRIDY.ungroup(this.state.sceneIdx, this.state.selectObjs)
      this.selectAll(false)
      // 自动选择已取消组合的对象
      for (let i = 0; i < ungroupObjNums; i++) {
        this.select(this.curObjs[i].id, '', i, -1, true)
      }
      this.setHistory('ungroup')
    },
    // 隐藏
    hideSelectObjs() {
      this.setSelectObjs('show', false)
    },
    // 锁定
    lockSelectObjs() {
      this.setSelectObjs('lock', true)
      this.state.canLock = false
      this.state.canUnlock = true
    },
    // 解除锁定
    unlockSelectObjs() {
      this.setSelectObjs('lock', false)
      this.state.canLock = true
      this.state.canUnlock = false
    },
    // 翻转
    flip(mod) {
      this.callSelectObjs('flipObj', [mod])
    },
    // 旋转
    rotate(mod) {
      this.callSelectObjs('rotateObj', [mod])
    },
    // 设置帧率和循环次数
    setRateAndLoop(rate, loop) {
      this.file.rate = parseInt(rate)
      this.file.loop = parseInt(loop)
    },
    // 开始逐个播放场景
    startPlay() {
      this.setAct('hand')
      this.state.play = true
      this.tmp.count = 0
      this.play()
    },
    // 播放场景
    play() {
      if (this.scenes.length < 2 || !this.state.play) {
        return
      }
      this.tmp.count++
      if (this.state.sceneIdx === this.scenes.length - 1) {
        this.state.sceneIdx = 0
      } else if (this.state.sceneIdx < this.scenes.length - 1) {
        this.state.sceneIdx = this.state.sceneIdx + 1
      }
      this.selectScene(this.state.sceneIdx)
      this.file.rate = this.file.rate || 12
      this.file.loop = this.file.loop || 0
      if (this.file.loop && this.tmp.count / this.scenes.length >= this.file.loop) {
        this.tmp.count = 0
        this.state.play = false
        return
      }
      this.sto = setTimeout(this.play, 1000 / this.file.rate)
    },
    // 停止播放场景
    stopPlay() {
      this.state.play = false
      this.tmp.count = 0
      if (this.sto) clearTimeout(this.sto)
      this.setAct()
    },
    // 打开创建新文件
    openCreateNewFile() {
      this.view.createNewfile = true
    },
    // 创建新文件
    createNewFile() {
      if (this.viewMod !== 'editer') this.goto('editer', [])
      let ratioDt = this.canvasRatio[0]
      for (var i in this.canvasRatio) {
        if (this.canvasRatio[i].id === this.newfileOpts.ratio) {
          ratioDt = this.canvasRatio[i]
        }
      }
      ratioDt = utils.deepClone(ratioDt)
      if (this.newfileOpts.size) {
        ratioDt.cols = this.newfileOpts.size.cols || ratioDt.cols
        ratioDt.rows = this.newfileOpts.size.rows || ratioDt.rows
      }
      this.newFile(this.newfileOpts.name, ratioDt.cols, ratioDt.rows, 0, false, false, true, ratioDt.id)
      this.newfileOpts = utils.deepClone(conf.newfileOpts)
      this.view.createNewfile = false
      this.view.resource.show = false
      this.view.user.show = false
      this.usePalette('base')
      this.state.palette = {}
    },
    setFileAndOpen(file) {
      this.file = file
      this.state.scale = 1
      this.selectScene(0)
      this.drawCanvasBg()
      // this.drawObjs()
      this.selectTopOne()
      this.closeMyPop()
      this.setAct()
      this.setOpenFiles(this.file.id, this.file.name)
      this.goto('editer')
      setTimeout(() => {
        if (file.type === 1) {
          this.usePalette('brickfy')
          if (this.curObj) this.curObj.fillShape = 'circle'
        }
      }, 100)
    },
    // 创建随机图案
    createGeoFile() {
      const name = '随机图案'
      let cols = 80
      let rows = 80
      const box = this.GRIDY.createGeoObj(cols, rows)
      cols = box.cols
      rows = box.rows
      const file = this.GRIDY.createFile({ userid: this.loginUserId, name: name, cols: cols, rows: rows })
      const scene = this.GRIDY.createScene({ userid: this.loginUserId, name: name })
      const obj = this.GRIDY.createObj({ userid: this.loginUserId, name: name, cols: cols, rows: rows, data: box.data, fillShape: '' })
      scene.objs = [obj]
      file.canvas.scenes.push(scene)
      file.type = 0
      file.origin = 1
      this.setFileAndOpen(file)
      this.saveIt('', true)
      this.traceEvent('createGeoFile')
    },
    // 新建文件
    async newFile(name, cols, rows, refWorkid, hash, nocache, addBlankObj, aspectFactor) {
      this.state.canvasIniting = true
      this.state.previewCols = cols
      this.state.previewRows = rows
      this.state.gridSize = 8
      this.state.scale = 1
      name = utils.getFileName(name)
      const options = {
        name: name,
        aspectFactor: aspectFactor || '',
        cols: parseInt(cols),
        rows: parseInt(rows),
        refWorkid: refWorkid || 0,
        userid: this.loginUserId
      }
      const file = this.GRIDY.createFile(options)
      file.nocache = !!nocache
      if (hash) file.fileId = hash
      const scene = this.GRIDY.createScene({ userid: this.loginUserId, name: name })
      file.canvas.scenes.push(scene)
      this.file = file
      this.setAct()
      if (addBlankObj) this.addBlankObj(true, false)
      this.setIdx(0, -1)
      setTimeout(() => {
        this.drawCanvasBg()
        this.state.canvasIniting = false
      }, 10)
      this.setOpenFiles(this.file.id, this.file.name)
      this.setHistory('newFile', [name, cols, rows])
      service.incCount(this.loginUserId, 'work_create')
      this.traceEvent('newFile')
    },
    // 更新最近打开的文件
    async updateLatestOpen() {
      const files = await this.db.pageGet(this.dbName, { 'del': '0' }, 'openTime', 1, 10)
      let num = 0
      const latestOpen = []
      if (files.length) {
        Object.values(files).map((val) => {
          num++
          if (num <= 10) {
            latestOpen.push({ text: val.name, id: val.id, openTime: val.openTime, click: () => { this.openFile(val.id) } })
          }
        })
      }
      this.tmp.latestOpen = latestOpen
      this.update()
    },
    // 打开最近的文件
    openLatestFile(id) {
      this.view.createNewfile = false
      this.openFile(id)
    },
    // 打开或导入素材
    async openAsset(file, mod) {
      mod = mod || 'open'
      this.state.drawStartTime = utils.time('time')
      if (mod === 'open' || !this.openFileNums) {
        this.newFile(file.name, this.state.limitCols, this.state.limitRows)
      }
      file.url = file.url || window.URL.createObjectURL(file.raw)
      this.newObj(file)
      this.closeMyPop()
      this.setAct()
    },
    // 打开文件
    async openFile(id, fileId, source, cb) {
      source = source || 'db'
      let file
      if (source === 'db') {
        if (fileId) {
          file = await this.db.get(this.dbName, { fileId: fileId })
        } else if (id) {
          file = await this.db.get(this.dbName, { id: id })
        }
      }
      if (this.file && file && this.file.id === file.id) {
        return
      }
      if (file) {
        // 保存最近打开的文件
        this.db.update(this.dbName, file.id, { openTime: utils.date('datetime') })
        this.updateLatestOpen()
        file.id = file.fileId
        delete file.fileId
        // 设置默认值
        file.gridSize = file.gridSize || 8
        file.formate = file.formate || 'png'
        // 数据引用
        this.file = file
        this.state.scale = 1
        this.selectScene(0)
        this.drawCanvasBg()
        // this.drawObjs()
        this.selectTopOne()
        this.closeMyPop()
        this.setAct()
        this.setOpenFiles(this.file.id, this.file.name)
        this.goto('editer')
        this.setHistory('open')
        setTimeout(() => {
          if (file.type === 1) {
            this.usePalette('brickfy')
            if (this.curObj) this.curObj.fillShape = 'circle'
          }
        }, 100)
        cb && cb()
      }
      this.getBackAndCleanState()
      this.traceEvent('openFile')
    },
    // 关闭文件
    closeFile(fileId) {
      Object.keys(this.state.openFiles).map((idx) => {
        if (this.state.openFiles[idx] && this.state.openFiles[idx].fileId === fileId) {
          // 关闭当前文件时执行
          if (this.file.id === fileId) {
            // 打开下一个文件
            const openFiles = utils.deepClone(this.state.openFiles)
            const openFile = openFiles[parseInt(idx) - 1] || openFiles[parseInt(idx) + 1]
            // 关闭当前文件
            this.state.openFiles.splice(idx, 1)
            if (openFile) {
              this.openFile(0, openFile.fileId)
            } else {
              this.closeAllFiles()
            }
          } else {
            this.state.openFiles.splice(idx, 1)
          }
          return
        }
      })
      this.cleanHistory(fileId)
      this.traceEvent('closeFile')
      this.updateLatestOpen()
    },
    // 关闭全部文件
    closeAllFiles() {
      this.db.clean('acts')
      this.state.fileId = ''
      this.state.openFiles = []
      this.file = {}
      this.traceEvent('closeAllFiles')
    },
    // 设置打开的文件
    setOpenFiles(fileId, name) {
      // if (!this.isDesktop) this.state.openFiles = []
      if (!fileId) {
        this.closeAllFiles()
        return
      }
      let newOpen = true
      Object.keys(this.state.openFiles).map((idx) => {
        if (this.state.openFiles[idx].fileId === fileId) {
          this.state.openFiles[idx].name = name
          newOpen = false
        }
      })
      if (newOpen) {
        this.state.openFiles.unshift({ fileId: fileId, name: name })
      }
      this.state.fileId = fileId
    },
    // 请求输入系列号(download模式，必须验证SN)
    reqSN(use_type = 0, channel_orderid = '', channel_data = '', cb, download = 0) {
      // 全部免系列号
      // cb && cb(true, {})
      // return
      let dataSN = service.getSN(use_type)
      const useSN = () => {
        service.actionPost('sn', { sn: dataSN.sn, use_type: use_type, channel_orderid: channel_orderid, channel_data: channel_data, use_count: 1 }, (dt, type) => {
          if (type === 'success' && dt && dt.data) {
            this.view.pop.show = false
            dataSN = dt.data
            service.setSN(use_type, dataSN, true)
            this.message('系列号验证通过', type)
          } else {
            service.removeSN()
            this.message(dt, type)
          }
          cb && cb(type === 'success', dataSN)
        })
      }
      if (dataSN.sn) {
        if (dataSN.pass && !download) {
          cb && cb(true, dataSN)
        } else {
          useSN()
        }
        return
      }
      this.view.pop.title = '获取图纸'
      this.view.pop.placeholder = '请输入产品系列号'
      this.view.pop.content = ''
      this.view.pop.classname = ''
      this.view.pop.maxlength = 18
      this.view.pop.rows = 1
      this.view.pop.type = ''
      this.view.pop.data = {}
      this.view.pop.show = true
      this.view.pop.fn = () => {
        this.view.pop.loading = false
        if (!this.view.pop.content) {
          return this.message('请输入产品系列号', 'error')
        } else {
          dataSN.sn = this.view.pop.content
          useSN()
        }
      }
    },
    // 关闭弹出层
    closeMyPop() {
      this.view.myPop.show = false
    },
    openPop(config) {
      config = config || {}
      this.view.pop.title = config.title || ''
      this.view.pop.classname = config.classname || ''
      this.view.pop.type = config.type || ''
      this.view.pop.data = config.data || {}
      this.view.pop.fn = config.fn || ''
      this.view.pop.show = true
      this.traceEvent('openPop_' + this.view.pop.type)
      this.traceEvent('goto')
    },
    openAiPaint(data = {}) {
      this.openPopPage({ 'type': 'paint', 'data': data })
    },
    openPopPage(config) {
      this.view.popPage.show = false
      setTimeout(() => {
        config = config || {}
        // this.view.popPage.fromUrl = false
        this.view.popPage.title = config.title || ''
        this.view.popPage.from = config.from || ''
        this.view.popPage.publishid = config.publishid || 0
        this.view.popPage.orderid = config.orderid || 0
        this.view.popPage.type = config.type || ''
        this.view.popPage.data = config.data || {}
        this.view.popPage.showHead = typeof config.showHead === 'undefined' ? true : !!config.showHead
        this.view.popPage.show = true
        this.traceEvent('openPopPage_' + this.view.popPage.type)
        this.traceEvent('goto')
      }, 100)
    },
    openDraft(tab = 'draft') {
      this.openResource('draft', 'fileId', tab, [['draft', '草稿箱'], ['recycle', '回收站']])
    },
    openOrderMng(config) {
      if (!this.loginStatus) {
        this.message('', 'login')
        return
      }
      config = config || {}
      this.view.orderMng.title = config.title || ''
      this.view.orderMng.from = config.from || ''
      this.view.orderMng.id = config.id || ''
      this.view.orderMng.act = config.act || ''
      this.view.orderMng.data = config.data || {}
      this.view.orderMng.showHead = typeof config.showHead === 'undefined' ? true : !!config.showHead
      this.view.orderMng.show = true
      this.traceEvent('openOrderMng_' + this.view.orderMng.act)
      this.traceEvent('goto')
    },
    openResource(table, keyfield, tab = '', tabs = []) {
      if (!this.loginStatus && table === 'paint') {
        this.message('', 'login')
        return
      }
      this.view.resource.show = false
      this.view.resource.tabs = tabs
      this.view.resource.tab = tab
      this.view.resource.keyfield = keyfield || ''
      setTimeout(() => {
        this.goto('resource', { 'table': table })
      }, 100)
    },
    openResourceMng(config) {
      if (!this.loginStatus) {
        this.message('', 'login')
        return
      }
      config = config || {}
      this.view.resourceMng.title = config.title || ''
      this.view.resourceMng.from = config.from || ''
      this.view.resourceMng.table = config.table || ''
      this.view.resourceMng.keyfield = config.keyfield || ''
      this.view.resourceMng.id = config.id || ''
      this.view.resourceMng.act = config.act || ''
      this.view.resourceMng.data = config.data || {}
      this.view.resourceMng.showHead = typeof config.showHead === 'undefined' ? true : !!config.showHead
      this.view.resourceMng.show = true
      this.traceEvent('openResourceMng_' + this.view.resourceMng.table)
      this.traceEvent('goto')
    },
    openResourceSelecter(config) {
      config = config || {}
      this.view.resourceSelecter.title = config.title || ''
      this.view.resourceSelecter.from = config.from || ''
      this.view.resourceSelecter.table = config.table || ''
      this.view.resourceSelecter.keyfield = config.keyfield || ''
      this.view.resourceSelecter.maxSelectCount = config.maxSelectCount || 1
      this.view.resourceSelecter.typeid = config.typeid || '0'
      this.view.resourceSelecter.type = config.type || 'selecter'
      this.view.resourceSelecter.types = config.types || []
      this.view.resourceSelecter.fn = config.fn || null
      this.view.resourceSelecter.show = true
      this.traceEvent('openResourceSelecter_' + this.view.resourceSelecter.table)
      this.traceEvent('goto')
    },
    openCropImage(config) {
      this.view.cropImage.show = false
      setTimeout(() => {
        config = config || {}
        this.view.cropImage.title = config.title || ''
        this.view.cropImage.data = config.data || {}
        this.view.cropImage.fn = config.fn || null
        this.view.cropImage.show = true
        this.traceEvent('openCropImage')
        this.traceEvent('goto')
      }, 100)
    },
    openGridyViewer(config) {
      this.view.gridyViewer.show = false
      setTimeout(() => {
        config = config || {}
        this.view.gridyViewer.title = config.title || ''
        this.view.gridyViewer.data = config.data || {}
        this.view.gridyViewer.fn = config.fn || null
        this.view.gridyViewer.show = true
        this.traceEvent('openGridyViewer', '', { diyType: this.view.gridyViewer.data.diyType || '' })
        this.traceEvent('goto')
      }, 100)
    },
    openPaperViewer(config) {
      this.view.paperViewer.show = false
      setTimeout(() => {
        config = config || {}
        this.view.paperViewer.title = config.title || ''
        this.view.paperViewer.data = config.data || {}
        this.view.paperViewer.fn = config.fn || null
        this.view.paperViewer.show = true
        this.traceEvent('openPaperViewer')
        this.traceEvent('goto')
      }, 100)
    },
    // 定制商品
    diy(diyType = 'brick') {
      this.openBrickyViewer({ data: { colorfyId: '', work: this.file, type: 'workViewer', diyType: diyType, fillShape: '', preview: true }})
    },
    openBrickyViewer(config) {
      this.view.brickyViewer.show = false
      setTimeout(() => {
        config = config || {}
        this.view.brickyViewer.title = config.title || ''
        this.view.brickyViewer.data = config.data || {}
        this.view.brickyViewer.fn = config.fn || null
        this.view.brickyViewer.show = true
        this.traceEvent('openBrickyViewer')
        this.traceEvent('goto')
      }, 100)
    },
    openWorkViewer(config) {
      this.view.workViewer.show = false
      setTimeout(() => {
        config = config || {}
        this.view.workViewer.title = config.title || ''
        this.view.workViewer.data = config.data || {}
        this.view.workViewer.fn = config.fn || null
        this.view.workViewer.show = true
        this.traceEvent('openWorkViewer')
        this.traceEvent('goto')
      }, 100)
    },
    openPost(config) {
      if (!this.loginStatus) {
        this.message('', 'login')
        return
      }
      config = config || {}
      this.view.post.title = config.title || ''
      this.view.post.from = config.from || ''
      this.view.post.id = config.id || ''
      this.view.post.act = config.act || ''
      this.view.post.data = config.data || {}
      this.view.post.showHead = typeof config.showHead === 'undefined' ? true : !!config.showHead
      this.view.post.show = true
      this.traceEvent('openPost', '', { id: this.view.post.id, act: this.view.post.act })
      this.traceEvent('goto')
    },
    gotoWorks(classid = 0) {
      this.view.works.classid = classid
      this.view.works.type = 'new'
      this.view.works.typeid = 0
      this.view.works.catalogName = '全部'
      this.view.works.catalogid = 0
      this.view.works.sort = 'desc'
      this.view.works.sortType = 'update'
      this.goto('works', { classid: classid })
    },
    createWork(type) {
      if (type === 'pixel') {
        this.openPopPage({ 'type': 'toPixel' })
      } else if (type === 'brickfy') {
        this.openPopPage({ 'type': 'toBrickfy' })
      } else if (type === 'cartoon') {
        this.openPopPage({ 'type': 'toCartoon' })
      } else if (type === 'paint') {
        this.openAiPaint()
      } else {
        // this.view.createNewfile = true
        this.goto('editer')
      }
    },
    // 打开弹出层
    openMyPop(opts) {
      this.view.myPop = {
        show: true,
        tab: opts.tab || '',
        group: opts.group || 'about',
        mod: opts.mod || 'tab'
      }
      this.traceEvent('openMyPop_' + opts.tab)
      this.traceEvent('goto')
    },
    // 复制分享链接
    copyShareUrl() {
      this.$refs['share-url'].click()
      this.traceEvent('copyShareUrl')
    },
    // 复制分享链接
    shareIt(workid) {
      workid = workid || this.file.workid
      if (!workid) {
        this.alert('请先发布，再分享', () => {
          this.publishWork()
        })
        return
      }
      // eslint-disable-next-line
      var clipboard = new ClipboardJS('.clipboard')
      clipboard.on('success', (e) => {
        e.clearSelection()
        this.message('链接复制成功，请粘贴分享', 'success')
        clipboard.destroy()
      })
      service.get('work_share', workid)
      service.incCount(this.loginUserId, 'work_share')
      this.traceEvent('shareIt')
    },
    // 生成分享图
    createPOP(file, opts = {}, sceneIdx = 0) {
      file = file || this.file
      this.traceEvent('createPOP')
      // 降级下载
      let downDegrade = false
      if (utils.isAlipayClient() || utils.isWeixinClient()) {
        downDegrade = true
      }
      this.loading(true, 'AI处理中，请耐心等待...')
      const exportIt = () => {
        const cb = (res, status) => {
          setTimeout(() => {
            this.loading(false)
          }, 1000)
          if (status === 'error' && res) {
            this.alert(res)
            return
          }
          if (downDegrade) {
            this.openPopPage({ title: '下载提示', type: 'download', data: { type: '', res: res }})
          }
        }
        const options = { gridSize: opts.gridSize || this.state.gridSize, fillShape: opts.fillShape || this.state.fillShape, brickfy: opts.brickfy, paletteId: opts.paletteId || this.state.paletteId, formate: 'pop', type: '' }
        options.download = !downDegrade
        const token = service.getToken()
        options.avatar = token.avatar ? this.getAvatar(token) : ''
        options.nickname = token.nickname || ''
        options.info = this.getInfo(file)
        service.actionGet('qrcode', { type: 'invite', mod: 'workViewer', workid: file.workid }, (dt, type) => {
          if (type === 'success' && dt.data && dt.data.url) {
            options.qrcode = dt.data.url
          }
          this.GRIDY.setFile(file)
          this.GRIDY.createPOP(sceneIdx || this.state.sceneIdx, options, cb)
        })
      }
      setTimeout(exportIt, 1000)
    },
    getInfo(file) {
      return (file.name || '') + ' ' + this.getWorkTags(file) + ' ' + (file.info || '')
    },
    getWorkTags(work) {
      if (!work.tags) return ''
      let tags = ''
      if (work.tags && work.tags.length) {
        tags = '#' + work.tags.join('# #') + '#'
      }
      return tags
    },
    // 发布作品
    publishWork(file, share, fn = null) {
      this.traceEvent('publishWork')
      if (service.authentication()) {
        if (share) this.tmp.share = share
        this.tmp.file = file || this.file
        if (this.file.workid && this.tmp.file.userid && this.tmp.file.userid > 0 && this.tmp.file.userid - this.loginUserId !== 0) return this.message('无权发布他人作品', 'error')
        this.tmp.show = this.tmp.show || this.file.public
        if (this.tmp.catalogid > 0 && this.tmp.file.catalogid <= 0) this.tmp.file.catalogid = this.tmp.catalogid
        this.view.myPublish.show = true
        this.view.myPublish.fn = fn || null
        this.traceEvent('goto')
      } else {
        this.message('', 'login')
      }
    },
    // 保存到远程
    saveRemote(file, silence, share, fn) {
      const token = service.getToken()
      if (!service.authentication()) {
        if (!silence) this.message('', 'login')
        return
      }
      file = file || this.file
      this.saveDb(file, file.fileId || file.id)
      if (file.workid && file.userid && file.userid > 0 && file.userid - token.userId !== 0) {
        if (!silence) this.message('无权保存他人作品', 'error')
        this.traceEvent('saveRemote_access_fail')
        return
      }
      this.traceEvent('saveRemote')
      if (file.canvas && file.canvas.scenes && file.canvas.scenes.length > 1) {
        file.animation = 1
      } else {
        file.animation = 0
      }
      const time = utils.date('datetime')
      // 来源；0=原生创作; 1=二次创作; 2=导图创作;
      file.origin = this.GRIDY.getOriginType(file)
      file.userid = utils.getInt(token.userId)
      file.public = file.public || 0
      file.public_at = file.public ? time : 0
      file.save = true
      file.saveTime = time
      file.share = share
      const data = {
        'platform': this.platform.value,
        'ver': conf.ver,
        'origin': file.origin,
        'userid': file.userid,
        'type': utils.getInt(file.type),
        'catalogid': file.catalogid,
        'name': file.name,
        'animation': file.animation,
        'info': file.info || '无',
        'tags': file.tags ? file.tags.join(',') : '',
        'width': utils.getInt(file.gridSize) * utils.getInt(file.canvas.cols),
        'height': utils.getInt(file.gridSize) * utils.getInt(file.canvas.rows),
        'fileid': file.fileId || file.id,
        'threadid': file.threadid || 0,
        'postid': file.postid || 0,
        'check': 1,
        'flag': 1,
        'public': file.public,
        'public_at': file.public_at,
        'data': JSON.stringify(file)
      }
      const createThread = () => {
        let tags = ''
        if (file.tags.length) {
          tags = '#' + file.tags.join('# #') + '#'
        }
        // 作品分享的categoryId=2
        const threadDt = {
          'title': (data.name === data.info ? '' : data.name) || '',
          'categoryId': 2,
          'content': {
            'text': '<p>' + tags + ' ' + (data.info || '') + '</p>\n'
          },
          'position': {},
          'realm': 1,
          'refid': file.workid
        }
        service.createThread(threadDt, (res, type) => {
          if (type === 'success') {
            // 更新作品帖子ID
            file.threadid = res.threadId
            file.postid = res.postId
            this.saveRemote(file, true, false)
            if (fn) fn(file)
            this.state.canSave = false
            this.traceEvent('createThread')
          } else {
            if (!silence) this.message(res, type)
          }
        })
      }
      var saveWork = () => {
        const cb = (res, type) => {
          if (type === 'login') {
            if (!silence) this.message('', 'login')
          } else if (type === 'success') {
            if (!file.workid) {
              file.workid = res.data.id
            }
            const dt = utils.deepClone(file)
            dt.fileId = dt.fileId || dt.id
            delete dt.id
            this.db.save('files', dt, { fileId: dt.fileId })
            if (share) {
              createThread()
            } else {
              this.state.canSave = false
              if (fn) fn(file)
            }
            let consumeMsg = ''
            if (res.data.consumeNums) consumeMsg = '，本次消费 ' + res.data.consumeNums + ' 个晶钻，签到和做任务可以获得晶钻'
            if (!silence) this.message((res.msg || '操作成功') + consumeMsg, type)
          } else {
            if (!silence) this.message(res, type)
          }
        }
        if (file.workid) {
          delete data.flag
          delete data.check
          service.put('work', file.workid, data, cb, true)
          this.traceEvent('putWork')
        } else {
          service.post('work', data, cb, true)
          this.traceEvent('postWork')
        }
      }
      saveWork()
    },
    // 保存文件
    saveIt(file, silence) {
      file = file || this.file
      if (!file.id) return
      const time = utils.date('datetime')
      const data = utils.deepClone(file)
      // 来源；0=原生创作; 1=二次创作; 2=导图创作;
      data.origin = this.GRIDY.getOriginType(data)
      data.updateTime = time
      data.openTime = time
      data.fileId = data.fileId || data.id
      data.publish = data.publish || false
      delete data.id
      if (!file.nocache) {
        this.saveDb(data, data.fileId)
      }
      if (!silence) {
        this.updateLatestOpen()
      }
      this.traceEvent('saveIt')
      this.autoCheckLogin()
    },
    // 保存到本地数据库(加锁)
    saveDb(data, fileId) {
      if (!this.tmp.lock[fileId]) {
        this.tmp.lock[fileId] = true
        this.db.save(this.dbName, data, { fileId: fileId }).then(() => {
          delete this.tmp.lock[fileId]
        })
        return
      }
      setTimeout(() => {
        this.saveDb(data, fileId)
      }, 500)
    },
    // 保存
    save() {
      if (this.file.name === '未命名' || this.file.name === '') {
        this.view.pop.title = '保存'
        this.view.pop.placeholder = '请输入作品名称'
        this.view.pop.content = this.file.name
        this.view.pop.classname = ''
        this.view.pop.rows = 1
        this.view.pop.maxlength = 24
        this.view.pop.type = ''
        this.view.pop.data = {}
        this.view.pop.show = true
        this.view.pop.fn = () => {
          this.view.pop.loading = false
          this.view.pop.show = false
          const value = utils.trim(this.view.pop.content, true)
          if (!utils.checkNow('filename', value, true)) {
            return this.message('名称18个汉字以内，不能包含特殊字符', 'error')
          }
          this.file.name = value
          this.saveIt()
          this.setOpenFiles(this.file.id, this.file.name)
          this.setHistory('save')
          this.message('保存成功', 'success')
        }
      } else {
        this.saveIt()
        this.message('保存成功', 'success')
      }
    },
    // 另存为
    saveAs() {
      this.view.pop.title = '另存为'
      this.view.pop.placeholder = '请输入作品名称'
      this.view.pop.content = this.file.name
      this.view.pop.classname = ''
      this.view.pop.rows = 1
      this.view.pop.maxlength = 24
      this.view.pop.type = ''
      this.view.pop.data = {}
      this.view.pop.show = true
      this.view.pop.fn = () => {
        this.view.pop.loading = false
        this.view.pop.show = false
        const value = utils.trim(this.view.pop.content, true)
        if (!utils.checkNow('filename', value, true)) {
          return this.message('名称18个汉字以内，不能包含特殊字符', 'error')
        }
        this.copyFile(this.file, value, '作品已另存为：' + value, (fileId) => {
          this.traceEvent('saveAs')
          const oldFileId = this.file.id
          this.openFile(0, fileId, 'db', () => {
            this.closeFile(oldFileId)
          })
        })
      }
    },
    // 保存name属性
    saveNameAttr(name, obj) {
      obj.name = name
      this.saveIt(this.file, true)
    },
    // 重命名
    rename() {
      this.view.pop.title = '重命名'
      this.view.pop.placeholder = '请输入作品名称'
      this.view.pop.content = this.file.name
      this.view.pop.classname = ''
      this.view.pop.rows = 1
      this.view.pop.maxlength = 24
      this.view.pop.type = ''
      this.view.pop.data = {}
      this.view.pop.show = true
      this.view.pop.fn = () => {
        this.view.pop.loading = false
        this.view.pop.show = false
        const value = utils.trim(this.view.pop.content, true)
        if (!utils.checkNow('filename', value, true)) {
          return this.message('名称18个汉字以内，不能包含特殊字符', 'error')
        }
        this.file.name = value
        this.saveIt()
        this.setOpenFiles(this.file.id, this.file.name)
        this.setHistory('rename')
        this.message('重命名成功', 'success')
      }
    },
    // 更新文件
    updateFile(file, mod) {
      file.updateTime = utils.date('datetime')
      this.db.update(this.dbName, file.id, file)
      if (mod === 'rename') {
        Object.values(this.state.openFiles).map((val) => {
          if (val.fileId === file.fileId) {
            // 文件有打开, 则更新name
            this.setOpenFiles(file.fileId, file.name)
          }
        })
      }
      this.updateLatestOpen()
    },
    // 复制文件
    cloneFile(file, name) {
      const data = utils.deepClone(file)
      data.refFileId = data.fileId || data.id || ''
      data.refWorkid = data.workid || 0
      data.fileId = utils.uuid()
      data.workid = 0
      data.publish = false
      data.publishTime = ''
      data.userid = this.loginUserId
      delete data.id
      data.name = name || ('副本 ' + data.name)
      delete data.openTime
      return data
    },
    // 创建副本
    copyFile(file, name, tip, fn) {
      const data = this.cloneFile(file, name)
      this.db.add(this.dbName, data)
      this.message(tip || '副本创建成功', 'success')
      fn && fn(data.fileId)
    },
    // 打开作品
    async openWork(file) {
      const work = await this.db.get(this.dbName, { fileId: file.fileid })
      if (work) {
        // 本地打开
        this.openFile('', file.fileid)
      } else {
        this.openRemoteWork(file.workid, true, this.loginUserId)
      }
      this.closeMyPop()
    },
    // 获取作品计数器
    getWorkCount(workid) {
      service.get('work_count', workid, (ret, type) => {
        if (type === 'success') {
          this.state.workCount = ret.data || {}
          this.file.threadid = ret.data.threadid || 0
          if (!this.file.threadid) this.file.postid = 0
        }
      }, false)
    },
    // 获取发行信息
    getPublish(publishid) {
      if (publishid <= 0) {
        if (this.publishItv) {
          clearInterval(this.publishItv)
          this.publishItv = null
        }
        return
      }
      service.get('publish', publishid, (ret, type) => {
        if (type === 'success') {
          if (ret.data && ret.data.items) {
            for (var i in ret.data.items) {
              const item = ret.data.items[i]
              this.state.publishes[item.publishid] = item
            }
          }
          if (ret.data && ret.data.series) {
            for (var seriesid in ret.data.series) {
              this.state.series[seriesid] = ret.data.series[seriesid]
            }
          }
        }
      }, false)
    },
    // 使用网络加载的作品
    useRemoteWork(data, nocache, cb) {
      this.getUser(data.userid, (user) => {
        this.file = utils.deepClone(data.data)
        this.file.workid = data.workid
        this.file.userid = data.userid
        this.file.threadid = data.threadid || 0
        this.file.postid = data.postid || 0
        this.file.publish = true
        this.file.public = data.public || false
        this.file.best = data.best || false
        this.file.check = data.check || false
        this.file.flag = data.flag || false
        this.file.hot = data.hot || false
        this.file.original = data.original || false
        this.file.nocache = !!nocache
        this.file.updateTime = this.file.updateTime || data.public_at * 1000
        this.file.publishid = data.publishid
        this.state.gridSize = conf.state.gridSize
        this.resetGridSize()
        this.setAct('default')
        this.setIdx(0, -1)
        this.drawCanvasBg()
        this.setOpenFiles(this.file.id, this.file.name)
        this.setHistory('newFile', [this.file.name, this.file.canvas.cols, this.file.canvas.rows])
        if (user) this.file.creator = user.nickname || ''
        this.state.dragX = 0
        this.state.dragY = 0
        this.getWorkCount(data.workid)
        this.getPublish()
        // this.publishItv = setInterval(this.getPublish, 1000 * 30)
        cb && cb(true)
      }, 'tiny')
    },
    // 网络加载
    openRemoteWork(workid, mustLogin, userid, nocache, cb) {
      this.view.loading = true
      if (this.loginUserId !== parseInt(userid)) userid = ''
      service.get('work_data', workid, (ret, type) => {
        if (type === 'success') {
          // if (ret.data.on_chain > 0) {
          //   cb && cb(false)
          //   this.message('上链中或已上链的作品不可编辑', 'error')
          //   this.view.loading = false
          // } else if (ret.data.publish_state > 0) {
          //   cb && cb(false)
          //   this.message('申请发行数字藏品的作品不可编辑', 'error')
          //   this.view.loading = false
          // } else {
          //   this.useRemoteWork(ret.data, nocache, cb)
          // }
          this.useRemoteWork(ret.data, nocache, cb)
        } else {
          cb && cb(false)
          this.view.loading = false
        }
      }, mustLogin, { userid: userid }, false)
    },
    // 重置 gridSize 以适配屏幕
    resetGridSize(obj, resetViewMod, width, height) {
      obj = obj || { cols: this.canvas.cols, rows: this.canvas.rows, gridSize: this.state.gridSize }
      if (!obj.cols || !obj.rows || !obj.gridSize) return
      width = width || (utils.width() - 40)
      height = height || (utils.height() - 100)
      const maxRows = Math.floor(height / this.state.gridSize) - 2
      const maxCols = Math.floor(width / this.state.gridSize) - 2
      if ((obj.cols > maxCols || obj.rows > maxRows) && obj.gridSize > 2) {
        this.state.gridSize = this.state.gridSize - 2
        this.state.scale = this.state.gridSize / conf.state.gridSize
        obj.gridSize = this.state.gridSize
        return this.resetGridSize(obj, resetViewMod, width, height)
      }
    },
    // 编辑远程作品
    editRemoteWork(workid, mustLogin, userid, nocache, cb) {
      if (this.loginUserId !== parseInt(userid)) userid = ''
      service.get('work_data', workid, (ret, type) => {
        if (type === 'success') {
          if ((ret.data.on_chain > 0 || ret.data.publish_state > 0) && this.loginUserId > 10000) {
            cb && cb(false)
            this.message('无法编辑该作品', 'error')
          } else {
            this.openWorkData(ret.data, nocache)
          }
        } else {
          this.message(ret, type)
          cb && cb(false)
        }
      }, mustLogin, { userid: userid }, false)
    },
    // 打开作品
    openWorkData(data, nocache) {
      data.data.id = data.data.fileid || data.data.id + ''
      this.file = utils.deepClone(data.data)
      this.file.workid = data.workid
      this.file.userid = data.userid
      this.file.threadid = data.threadid || 0
      this.file.postid = data.postid || 0
      this.file.publish = true
      this.file.public = data.public || false
      this.file.best = data.best || false
      this.file.check = data.check || false
      this.file.flag = data.flag || false
      this.file.hot = data.hot || false
      this.file.original = data.original || false
      this.file.nocache = !!nocache
      this.file.updateTime = this.file.updateTime || data.public_at * 1000
      this.file.publishid = data.publishid
      this.state.gridSize = conf.state.gridSize
      this.resetGridSize()
      this.setAct('default')
      this.setIdx(0, -1)
      this.drawCanvasBg()
      this.setOpenFiles(this.file.id, this.file.name)
      this.setHistory('newFile', [this.file.name, this.file.canvas.cols, this.file.canvas.rows])
      this.goto('editer')
      this.state.dragX = 0
      this.state.dragY = 0
    },
    // 二次创作 ?? del
    copyWork(file) {
      const imgUrl = this.worksHost + 'work/action/download/raw/workid/' + file.workid + '/' + file.name + '.png'
      this.importWebimage(imgUrl, file.name)
      this.closeMyPop()
    },
    // 查看作品
    viewWork(file) {
      window.open(this.mainHost + 'view/' + file.catalogid + '/' + file.workid + '/' + file.name + '.html')
    },
    // 作品统计+1
    workCount(workid, type) {
      if (!workid || ['diy', 'idea'].indexOf(type) < 0) return
      service.post('work_count', { workid: workid, type: type }, (res, status) => {
        if (status === 'success' && res.data && res.data.id) {
          if (this.state.workCount[type + '_count']) {
            this.state.workCount[type + '_count']++
          } else {
            this.state.workCount[type + '_count'] = 1
          }
        }
      })
    },
    // 下载+1
    downCount(workid, formate, gridSize, shape) {
      formate = utils.snakeCase(formate)
      if (!formate) return
      service.get('work_down', workid, () => {
        this.state.workCount.down_state = 1
        this.state.workCount.down_count = this.state.workCount.down_count + 1
        service.incCount(this.loginUserId, 'work_down')
      }, false, { count_field: formate, size: gridSize, shape: shape })
    },
    // 消费晶钻 使用类型 typeid： 51=创建专辑消费; 52=发布百格画消费; 53=AI作画消费；54=导出像素画；55=导出百格画消费；56=导出高清百格画消费；57=高级导出消费
    consume(typeid, cb) {
      service.actionGet('item', { type: 'consume', id: typeid }, (dt, type) => {
        if (type === 'success') {
          if (dt.data && dt.data.consumeNums && this.loginUserId >= 10000) {
            setTimeout(() => {
              this.alert('本次消费 ' + dt.data.consumeNums + ' 个晶钻，签到和做任务可以获得晶钻')
            }, 1000)
          }
          this.traceEvent('consume', '', { typeid: typeid })
          cb && cb(true)
        } else {
          cb && cb(false)
          this.message(dt, type)
        }
      }, true, false)
    },
    // 导出类型 type: auto 自动识别、 pixle 像素画、gridy 百格画、brick 拼图、advance 高级导出 orginImage 原图
    exportFile(file, formate, type, opts, sceneIdx, needVip = false, voxelViewerEl = null, copyScene = false, base64 = null, filename = '') {
      let consume = true
      if (formate === 'pop') {
        this.createPOP(file, opts, sceneIdx)
        return
      } else if (formate === 'paper') {
        needVip = false
        consume = false
      }
      this.traceEvent('exportFile', '', { formate: formate, type: type, needVip: needVip })
      let isVip = false
      // 降级下载
      let downDegrade = false
      if (utils.isAlipayClient() || utils.isWeixinClient()) {
        downDegrade = true
      }
      if (downDegrade && ['png', 'jpg', 'webp', 'gif', 'ico', 'paper', 'originImage', 'pop'].indexOf(formate) < 0) {
        this.alert('请使用Gridy.Art客户端下载该文件')
        return
      }
      if (formate !== 'paper') this.loading(true, 'AI处理中，请耐心等待...')
      const exportIt = () => {
        const cb = (res, status) => {
          setTimeout(() => {
            this.loading(false)
          }, 1000)
          if (status === 'error' && res) {
            this.alert(res)
            return
          }
          if (downDegrade) {
            this.openPopPage({ title: '下载提示', type: 'download', data: { type: type, res: res }})
          }
        }
        this.GRIDY.setFile(file)
        const options = { gridSize: opts.gridSize, hideColors: opts.hideColors, fillShape: opts.fillShape, roundTile: opts.roundTile, brickfy: opts.brickfy, workTypeid: opts.workTypeid || 0, isBrick: file.type === 1, frameId: opts.frameId || 'blackBlack', formate: formate, type: type }
        if (!isVip && this.loginUserId >= 1000) options.addWatermark = true
        if (type === 'auto') {
          if (file.fillShape === 'none' || file.fillShape === '') {
            type === 'pixel'
            options.workTypeid = 0
          } else {
            type === 'gridy'
            options.workTypeid = 1
          }
        } else if (type === 'pixel' || type === 'pixel_high') {
          options.gridSize = type === 'pixel_high' ? 32 : 1
          options.fillShape = 'none'
          options.workTypeid = 0
        } else if (type === 'gridy' || type === 'gridy_high') {
          options.gridSize = type === 'gridy_high' ? 32 : file.gridSize
          options.fillShape = 'square'
          options.workTypeid = 1
        } else if (type === 'brick' || type === 'brick_high') {
          options.gridSize = type === 'brick_high' ? 32 : file.gridSize
          options.workTypeid = 2
          options.pictureFrame = true
          options.bgId = 'white'
        }
        options.download = !downDegrade
        if (formate === 'glb' || formate === 'gltf') {
          if (voxelViewerEl) {
            voxelViewerEl.exportScenes(formate === 'glb', cb)
          } else {
            this.alert('请在“导出预览”里导出')
          }
        } else if (formate === 'xls') {
          this.GRIDYXLS = new GRIDYXLS(file)
          options.sceneIdx = sceneIdx
          this.GRIDYXLS.exportFile(options, cb)
        } else if (formate === 'paper' || formate === 'lxf' || (formate === 'png' && (type === 'brick' || type === 'brick_high'))) {
          const run = (GRD, idx) => {
            if (formate === 'paper') {
              this.loading(true, 'AI处理中，请耐心等待...')
              options.fillShape = 'circle'
              options.gridSize = 32
              options.paperMod = true
              GRD.exportPaper(idx, 0, -1, options, () => {
                cb && cb('', 'success')
              })
            } else if (formate === 'lxf') {
              // 有BUG，暂未开放
              this.GRIDYLXF = new GRIDYLXF(GRD.getFile())
              options.sceneIdx = sceneIdx
              this.GRIDYLXF.exportFile(options, cb)
            } else if (formate === 'png') {
              options.isBrick = file.type === 1
              options.fillShape = 'circle'
              GRD.exportScene(idx, options, cb)
            }
          }
          // 复制场景数据并加工
          if (copyScene) {
            const canvas = document.createElement('canvas')
            const sceneOpts = utils.deepClone(options)
            sceneOpts.paletteId = 'brickfy'
            sceneOpts.colorfyId = ''
            sceneOpts.fillShape = ''
            sceneOpts.fn = (sceneDt) => {
              const TMPGRIDY = new GRIDY({ name: file.name })
              TMPGRIDY.addScene({ name: file.name })
              TMPGRIDY.addBlankObj(0)
              TMPGRIDY.setCanvasSize(sceneDt.cols, sceneDt.rows)
              const obj = TMPGRIDY.getObj(0, 0, -1)
              obj.name = file.name
              obj.x = 0
              obj.y = 0
              obj.cols = sceneDt.cols
              obj.rows = sceneDt.rows
              obj.data = sceneDt.data
              if (formate === 'paper') {
                this.reqSN(0, '', '', (status) => {
                  if (status) {
                    run(TMPGRIDY, 0)
                  }
                }, 1)
              } else {
                run(TMPGRIDY, 0)
              }
            }
            this.GRIDY.drawScene(canvas, sceneIdx, sceneOpts)
          } else {
            if (formate === 'paper') {
              this.reqSN(0, '', '', (status) => {
                if (status) {
                  run(this.GRIDY, sceneIdx)
                }
              }, 1)
            } else {
              run(this.GRIDY, sceneIdx)
            }
          }
        } else if (formate === 'gridy') {
          this.GRIDY.exportGridy(file, options, cb)
        } else if (formate === 'gif') {
          this.GRIDY.exportGif(file, options, cb)
        } else if (formate === 'originImage') {
          this.GRIDY.exportObj(sceneIdx, 0, -1, options, cb)
        } else if (formate === 'assets') {
          this.GRIDY.exportImage(base64, filename, type, options, cb)
        } else {
          this.GRIDY.exportScene(sceneIdx, options, cb)
        }
        if (file.workid && formate !== 'orginImage') this.downCount(file.workid, formate, options.gridSize, options.fillShape)
      }
      if (!type || type === 'pop' || type === 'originImage') {
        setTimeout(exportIt, 50)
        return
      }
      // typeid 晶钻（10007） 使用类型： 51=创建专辑消费; 52=发布百格画消费; 53=AI作画消费；54=导出像素画；55=导出百格画消费；56=动画导出消费；57=导出高清百格画消费；58=高级导出消费；59=pdf导出消费；60=ico导出消费
      let consumeTypeid = 54
      if (type === 'pixel' || type === 'pixel_high') {
        consumeTypeid = 54
      } else if (type === 'gridy' || type === 'gridy_high') {
        consumeTypeid = 55
      } else if (type === 'brick' || type === 'brick_high') {
        consumeTypeid = 57
      } else if (type === 'advance') {
        consumeTypeid = 58
      } else if (type === 'assets') {
        consumeTypeid = 55
      }
      if (formate === 'gif') {
        consumeTypeid = 56
      } else if (formate === 'pdf') {
        consumeTypeid = 59
      } else if (formate === 'ico') {
        consumeTypeid = 60
      }
      if (needVip) {
        this.checkVip((status) => {
          isVip = status
          if (!status) {
            setTimeout(() => {
              this.loading(false)
            }, 50)
            return
          }
          if (consume) {
            this.consume(consumeTypeid, (success) => {
              if (!success) {
                this.loading(false)
                return
              }
              setTimeout(exportIt, 50)
            })
          } else {
            exportIt()
          }
        })
      } else {
        if (consume) {
          this.consume(consumeTypeid, (success) => {
            if (!success) {
              this.loading(false)
              return
            }
            setTimeout(exportIt, 50)
          })
        } else {
          exportIt()
        }
      }
    },
    showExportSheet(file, type, opts = {}, sceneIdx = 0, voxelViewerEl = null, copyScene = false) {
      const actions = {
        show: true,
        btns: []
      }
      this.traceEvent('showExportSheet')
      opts = utils.deepClone(opts)
      opts.brickfy = false
      if (type === 'exportAdvance') {
        actions.title = '高级导出'
        actions.btns = actions.btns.concat([
          { title: 'EXCEL表格画', icon: 'my-vip', cb: () => { this.exportFile(file, 'xls', 'advance', opts, sceneIdx) } },
          { title: 'GLTF体素模型', icon: 'my-vip', cb: () => { this.exportFile(file, 'gltf', 'advance', opts, sceneIdx, true, voxelViewerEl) } },
          { title: 'GLB体素模型', icon: 'my-vip', cb: () => { this.exportFile(file, 'glb', 'advance', opts, sceneIdx, true, voxelViewerEl) } }
        ])
      } else if (type === 'exportGridy') {
        actions.title = '导出百格画'
        actions.btns = actions.btns.concat([
          { title: 'PNG', cb: () => { this.exportFile(file, 'png', 'gridy', opts, sceneIdx) } },
          { title: 'JPG', cb: () => { this.exportFile(file, 'jpg', 'gridy', opts, sceneIdx) } },
          { title: 'WEBP', cb: () => { this.exportFile(file, 'webp', 'gridy', opts, sceneIdx) } },
          { title: 'GIF', icon: 'my-vip', cb: () => { this.exportFile(file, 'gif', 'gridy', opts, sceneIdx, true) } },
          { title: '高清PNG', icon: 'my-vip', cb: () => { this.exportFile(file, 'png', 'gridy_high', opts, sceneIdx, true) } },
          { title: '高清JPG', icon: 'my-vip', cb: () => { this.exportFile(file, 'jpg', 'gridy_high', opts, sceneIdx, true) } },
          { title: '高清WEBP', icon: 'my-vip', cb: () => { this.exportFile(file, 'webp', 'gridy_high', opts, sceneIdx, true) } },
          { title: '高清GIF', icon: 'my-vip', cb: () => { this.exportFile(file, 'gif', 'gridy_high', opts, sceneIdx, true) } }
          // ,{ title: 'GRIDY', cb: () => { this.exportFile(file, 'gridy', 'file', opts, sceneIdx) } }
        ])
      } else if (type === 'exportBrick') {
        opts.brickfy = true
        opts.roundTile = true
        actions.title = '导出拼图'
        actions.btns = actions.btns.concat([
          { title: '效果图', cb: () => { this.exportFile(file, 'png', 'brick', opts, sceneIdx, false, voxelViewerEl, copyScene) } },
          { title: '高清效果图', icon: 'my-vip', cb: () => { this.exportFile(file, 'png', 'brick_high', opts, sceneIdx, true, voxelViewerEl, copyScene) } },
          { title: '拼图图纸', icon: 'my-key', cb: () => { this.exportFile(file, 'paper', 'brick', opts, sceneIdx, true, voxelViewerEl, copyScene) } }
          // { title: '虚拟拼图', icon: 'my-vip', cb: () => { this.exportFile(file, 'lxf', 'brick', opts, sceneIdx, true, voxelViewerEl, copyScene) } },
        ])
        if (this.loginUserId && this.loginUserId < 10000) {
          const roundOpts = utils.deepClone(opts)
          roundOpts.roundTile = false
          actions.btns = actions.btns.concat([
            { title: '效果图（方块）', cb: () => { this.exportFile(file, 'png', 'brick', roundOpts, sceneIdx, false, voxelViewerEl, copyScene) } },
            { title: '高清效果图（方块）', cb: () => { this.exportFile(file, 'png', 'brick_high', roundOpts, sceneIdx, true, voxelViewerEl, copyScene) } }
          ])
        }
      } else {
        actions.title = '导出像素画'
        actions.btns = actions.btns.concat([
          { title: 'PNG', cb: () => { this.exportFile(file, 'png', 'pixel', opts, sceneIdx) } },
          { title: 'JPG', cb: () => { this.exportFile(file, 'jpg', 'pixel', opts, sceneIdx) } },
          { title: 'WEBP', cb: () => { this.exportFile(file, 'webp', 'pixel', opts, sceneIdx) } },
          { title: 'GIF', icon: 'my-vip', cb: () => { this.exportFile(file, 'gif', 'pixel', opts, sceneIdx, true) } },
          { title: '高清PNG', icon: 'my-vip', cb: () => { this.exportFile(file, 'png', 'pixel_high', opts, sceneIdx, true) } },
          { title: '高清JPG', icon: 'my-vip', cb: () => { this.exportFile(file, 'jpg', 'pixel_high', opts, sceneIdx, true) } },
          { title: '高清WEBP', icon: 'my-vip', cb: () => { this.exportFile(file, 'webp', 'pixel_high', opts, sceneIdx, true) } },
          { title: '高清GIF', icon: 'my-vip', cb: () => { this.exportFile(file, 'gif', 'pixel_high', opts, sceneIdx, true) } }
          // { title: 'PDF', icon: 'my-vip', cb: () => { this.exportFile(file, 'pdf', 'pixel', opts, sceneIdx, true) } },
          // { title: 'ICO', icon: 'my-vip', cb: () => { this.exportFile(file, 'ico', 'pixel', opts, sceneIdx, true) } },
        ])
      }
      this.view.actionSheet = actions
    },
    // 导出预览
    exportPreview() {
      this.openGridyViewer({ data: { work: utils.deepClone(this.file), type: 'workViewer', preview: true }})
      this.traceEvent('exportPreview')
    },
    // 打印
    printFile() {
      this.GRIDY.setFile(this.file)
      this.GRIDY.getSceneImage(0)
      printJS({ printable: this.GRIDY.getSceneImage(0), type: 'image', documentTitle: this.file.name })
      this.traceEvent('printFile')
    },
    // 关闭文件标签
    closeTab(mod, fileId, fileIdx) {
      fileIdx = parseInt(fileIdx)
      let closeFiles = []
      if (mod === 'one') {
        this.closeFile(fileId)
      } else if (mod === 'all') {
        this.closeAllFiles()
      } else if (mod === 'left') {
        closeFiles = this.state.openFiles.splice(0, fileIdx)
      } else if (mod === 'right') {
        closeFiles = this.state.openFiles.splice(fileIdx + 1, this.openFileNums - fileIdx - 1)
      } else if (mod === 'other') {
        closeFiles = this.state.openFiles.splice(fileIdx + 1, this.openFileNums - fileIdx - 1)
        const closeLeftFiles = this.state.openFiles.splice(0, fileIdx)
        closeFiles.concat(closeLeftFiles)
      }
      if (closeFiles.length) {
        Object.values(closeFiles).map((file) => {
          this.cleanHistory(file.fileId)
        })
      }
    },
    // 显示右键关闭文件标签按钮
    onTabContextmenu(event) {
      event.stopPropagation()
      const fileId = event.target.id.replace('tab-', '')
      let fileIdx
      Object.keys(this.state.openFiles).map((idx) => {
        if (this.state.openFiles[idx].fileId === fileId) {
          fileIdx = idx
        }
      })
      this.openFile(0, fileId)
      let closeLeftState = false
      if (fileIdx > 0) {
        closeLeftState = true
      }
      const items = [
        {
          label: '关闭',
          onClick: () => {
            this.closeTab('one', fileId, fileIdx)
          }
        },
        {
          label: '关闭其他',
          disabled: this.openFileNums === 1,
          onClick: () => {
            this.closeTab('other', fileId, fileIdx)
          }
        },
        {
          label: '关闭到左侧',
          disabled: !closeLeftState,
          onClick: () => {
            this.closeTab('left', fileId, fileIdx)
          }
        },
        {
          label: '关闭到右侧',
          disabled: !(this.openFileNums - fileIdx - 1),
          onClick: () => {
            this.closeTab('right', fileId, fileIdx)
          }
        },
        {
          label: '全部关闭',
          onClick: () => {
            this.closeTab('all', fileId, fileIdx)
          }
        }
      ]
      this.$contextmenu({
        items: items,
        event,
        customClass: 'my-contextmenu',
        zIndex: 990,
        minWidth: 180
      })
    },
    // 打开登录窗口
    login() {
      this.view.login.show = true
      this.traceEvent('login_show')
      this.traceEvent('goto')
    },
    // 退出登录
    logout(silence = false) {
      clearTimeout(this.unreadSto)
      service.logout(() => {
        this.unread = 0
        this.token = {}
        this.loginStatus = false
        this.avatar = ''
        this.loginNickname = ''
        this.loginUserId = 0
        this.state.reqId = service.getReqId()
        if (!silence) this.message('已退出登录', 'success')
      })
      this.traceEvent('logout')
    },
    // 获取用户token
    getToken() {
      this.token = service.getToken()
      this.avatar = this.getAvatar()
      this.loginStatus = service.authentication()
      this.loginNickname = this.token.nickname
      this.loginUserId = this.token.userId || 0
      return this.loginStatus
    },
    getAvatar(token) {
      token = token || this.token
      const ver = Math.round(new Date(token.avatar_at) / 1000)
      if (token.avatar) return service.getCosUrl(token.avatar, 'avatar', ver) || ''
    },
    setCookie() {
      Cookies.set('session', this.token.session || '', { expires: 30, path: '' })
      this.state.reqId = service.getReqId()
    },
    getCookie() {
      return Cookies.get('session')
    },
    formateNums: utils.formateNums,
    // 获取未读信息
    getUnread() {
      if (this.unreadSto) {
        clearTimeout(this.unreadSto)
        this.unreadSto = null
      }
      this.getToken()
      service.getUnread((data, type) => {
        if (type === 'success' && data.data && data.data.unread) {
          // this.unread = (data.unreadNotifications || 0) + (data.dialogNotifications || 0)
          const unread = data.data.unread || {}
          unread.total = (unread.system || 0) + (unread.social || 0) + (unread.dialog || 0)
          this.state.unread = unread
          this.unreadSto = setTimeout(this.getUnread, 1000 * 60)
        } else if (type !== 'login') {
          this.unreadSto = setTimeout(this.getUnread, 1000 * 60 * 5)
        }
      })
    },
    // 检查更新
    checkUpdate() {
      // Web 版本无需更新
      if (this.platform.value < 10) {
        return
      }
      const params = {
        platform: this.platform.value
      }
      this.state.newVersion = ''
      service.actionGet('release', params, (res, type) => {
        if (type === 'success' && res.data) {
          const curVer = utils.getInt(conf.ver.replace(/\./g, ''))
          const ver = utils.getInt((res.data.ver || conf.ver).replace(/\./g, ''))
          if (ver > curVer) {
            this.state.newVersion = res.data
            // 强制更新
            if (res.data.force_update) {
              this.alert('为了提供更好的服务，请安装最新版本', (e) => {
                if (e === 'confirm') {
                  window.open(res.data.download || conf.hosts().downloadUrl)
                }
              })
            }
          }
        }
      })
    },
    openLink(link) {
      if (!link) return
      this.open_url = link
      this.$refs.link.href = link
      this.$refs.link.click()
    }
  }
}
</script>
