<template>
  <div style="width:100%;height:100%;">
    <pull-to :id="'task-' + mod + '-container'" :style="listStyle" class="my-list" :top-load-method="(loaded) => topPull ? loadData(loaded, showUp ? 'up' : 'renew') : {}" :bottom-load-method="(loaded) => bottomPull ? loadData(loaded, 'down') : {}" @infinite-scroll="getData('down')">
      <div class="flow-container">
        <div v-for="(column, index) in columns" :key="index" class="column" :style="{'width': columnWidth + 'px' }">
          <div v-for="(item, i) in column.items" :key="i" class="item">
            <div class="card" :style="{ 'width': cardWidth + 'px', 'height': (item._height - 5) + 'px' }">
              <div class="user">
                <div class="left">
                  <span v-if="!(item.thumb && item.thumb.split('my-').length === 1)" class="el-avatar el-avatar--circle noavatar large" v-html="getIcon(item.thumb)" />
                  <el-avatar v-if="item.thumb && item.thumb.split('my-').length === 1" :size="42" :src="getThumb(item)" />
                </div>
                <div class="center white" :style="{'line-height': '20px', 'padding-top':'4px'}">
                  {{ item.name }} <span v-html="getTaskCount(item.state)" /> <span v-if="mod === 'finish'" class="color-99">{{ item.finish_at ? formateTime(item.finish_at) : '' }}</span>
                  <span class="color-cc" v-html="getPrizeInfo(item.prize_conf)" />
                </div>
                <div class="float-right">
                  <el-button v-if="mod === 'mine' && !item.finish && item.accept_type > 0" class="close-btn" @click="abandonIt(item)"><i class="iconfont my-close" /></el-button>
                  <el-button v-if="mod === 'mine' && !item.finish" class="tap-btn bg-blue" @click="doIt(item)">去完成</el-button>
                  <el-button v-if="mod === 'mine' && item.finish" class="tap-btn bg-green" @click="finishIt(item)">领取奖励</el-button>
                  <el-button v-if="mod === 'new'" class="tap-btn bg-blue" @click="acceptIt(item)">接受任务</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="itemNums && !data.noDownPage" class="tip load" @click="getData('down')"><i class="iconfont my-dot-more" /></div>
      <div v-if="flowHeight > height && data.noDownPage" class="tip">已显示全部内容</div>
      <el-empty v-if="(!itemNums && !loading[mod])" :image="emptyImage" :description="mod === 'invite' ? '快邀请好友吧' : '这里空空的'" />
    </pull-to>
    <div v-if="data.scrollTop > 200" class="go-top">
      <el-button class="tap-btn" @click="backTop()">
        <i class="iconfont my-top" />
      </el-button>
    </div>
  </div>
</template>
<script>
import { mixins } from '@/mixins/task'
export default {
  mixins: [mixins]
}
</script>
