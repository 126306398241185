
export const mixins = {
  props: {
    view: {
      type: Object,
      default() {
        return {}
      }
    },
    state: {
      type: Object,
      default() {
        return {}
      }
    },
    db: {
      type: Object,
      default() {
        return {}
      }
    },
    file: {
      type: Object,
      default() {
        return {}
      }
    },
    mod: {
      type: String,
      default() {
        return ''
      }
    },
    pageSize: {
      type: Number,
      default: 15
    },
    loginStatus: {
      type: Boolean,
      default: false
    },
    loginUserId: {
      type: Number,
      default: 0
    }
  },
  computed: {
    isDesktop() {
      return this.state.platform.type === 'desktop'
    },
    isTablet() {
      return this.state.platform.type === 'tablet'
    },
    isMobile() {
      return this.state.platform.type === 'mobile'
    },
    reqId() {
      return this.state.reqId
    },
    marginTop() {
      const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height
      return Math.max(Math.floor(((height - 640) / height) * 40), 0) + 'vh'
    },
    config() {
      return this.view[this.view.mod]
    },
    viewMod() {
      return this.view.mod
    },
    isEditer() {
      return this.view.mod === 'editer'
    },
    users() {
      return this.state.users || {}
    },
    followState() {
      return this.state.followState || {}
    },
    // 当前文件画布数据
    canvas() {
      return this.file.canvas || {}
    },
    // 当前全部场景
    scenes() {
      return this.canvas.scenes || []
    },
    // 当前场景
    curScene() {
      return this.scenes[this.state.sceneIdx] || {}
    },
    // 当前场景的对象
    curObjs() {
      return this.curScene.objs || []
    },
    // 当前场景是否0个对象
    noObjs() {
      return this.curObjs.length === 0
    }
  },
  methods: {
    // 调用父组件方法
    emit(fn, params) {
      if (!fn) return
      this.$emit('receive', fn, params || [])
    },
    checkLogin() {
      if (!this.loginStatus) this.message('', 'login')
      return this.loginStatus
    },
    callSub(ref, fn) {
      const el = this.$refs[ref]
      if (el && el[fn]) {
        el[fn]()
      } else {
        setTimeout(() => {
          this.callSub(ref, fn)
        }, 100)
      }
    },
    closePop(config) {
      if (config && config.show) config.show = false
      let showing = false
      const mods = this.view.mods.concat(['pop', 'myPop', 'popPage', 'myPublish', 'post'])
      for (const i in mods) {
        if (this.view[mods[i]].show) {
          showing = true
          break
        }
      }
      if (!showing) this.goto('index')
    },
    // 转场
    goto(mod, dt) {
      this.emit('goto', [mod, dt])
    },
    // 登录用户转场
    userGoto(mod, dt) {
      if (this.checkLogin()) this.goto(mod, dt)
    },
    // 打开通用弹出页
    openPopPage(config) {
      this.emit('openPopPage', [config])
    },
    // 打开资源管理页
    openResourceMng(config) {
      this.emit('openResourceMng', [config])
    },
    // 打开资源选择器
    openResourceSelecter(config) {
      this.emit('openResourceSelecter', [config])
    },
    // 打开发布主页页
    openPost(config) {
      this.emit('openPost', [config])
    },
    // 左滑返回
    onSwipe(evt) {
      if (this.isDesktop) return
      if (evt.direction === 'Left' || evt.direction === 'Right') this.goto()
    },
    // 左右滑动，自动切换tab
    switchTab(config, direction, back = false) {
      if (this.isDesktop) return
      if (!config.types) return config
      const i = config.types.length
      for (const k in config.types) {
        if (config.types[k][0] === config.type) {
          config.typeid = k
        }
      }
      const goback = () => {
        if (back && this.viewMod !== 'index' && this.viewMod !== 'works' && this.viewMod !== 'zone' && (this.viewMod !== 'user' || (this.viewMod === 'user' && this.loginUserId !== this.view.user.userid))) this.goto()
      }
      if (direction === 'Right') {
        config.typeid--
        if (config.typeid < 0) {
          config.typeid = 0
          goback()
        }
      } else if (direction === 'Left') {
        config.typeid++
        if (config.typeid >= i) {
          config.typeid = i - 1
          goback()
        }
      }
      config.type = config.types[config.typeid][0]
      return config
    },
    // 关注
    follow(userid, cb) {
      this.emit('follow', [userid, cb])
    },
    // 取消关注
    unfollow(userid, cb) {
      this.emit('unfollow', [userid, cb])
    },
    // 提示信息
    message(msg, type) {
      this.emit('message', [msg, type])
    },
    alert(msg, fn) {
      this.emit('alert', [msg, fn])
    },
    notify(title, msg, type, duration) {
      this.emit('notify', [title, msg, type, duration])
    },
    myNotify(show, msg, btn, fn) {
      this.emit('myNotify', [show, msg, btn, fn])
    },
    // 确认操作
    confirm(msg, fn, title) {
      this.emit('confirm', [msg, fn, title])
    },
    update() {
      this.$forceUpdate()
    },
    // 执行操作命令
    handle(act, data) {
      this.emit(act, data)
      // this.emit('handle', [act, data])
    }
  }
}
