<template>
  <div>
    <div :style="{'width': size + 'px', 'height': size + 'px'}">
      <canvas ref="previewCanvas" :style="previewStyle" />
    </div>
    <div v-if="obj.lock" class="obj-lock small" style="position:relative;" :style="{top: -(size - 20) / 2 - 20 + 'px', left: (size - 20) / 2 + 'px'}">
      <span class="iconfont my-lock" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ObjPreview',
  props: {
    state: {
      type: Object,
      default() {
        return {}
      }
    },
    obj: {
      type: Object,
      default() {
        return {}
      }
    },
    idx: {
      type: Number,
      default: 0
    },
    fileType: {
      type: [Number, String],
      default: 0
    },
    size: {
      type: Number,
      default: 40
    }
  },
  data() {
    return {}
  },
  computed: {
    previewStyle() {
      if (!this.obj.cols || !this.obj.rows) {
        return {}
      }
      let width
      let height
      let mt = 0
      let ml = 0
      if (this.obj.cols < this.size && this.obj.rows < this.size) {
        width = this.obj.cols
        height = this.obj.rows
        mt = (this.size - height) / 2
        ml = (this.size - width) / 2
      } else {
        if (this.obj.cols > this.obj.rows) {
          width = this.size
          height = this.obj.rows * width / this.obj.cols
          mt = (this.size - height) / 2
        } else {
          height = this.size
          width = this.obj.cols * height / this.obj.rows
          ml = (this.size - width) / 2
        }
      }
      return {
        'width': width + 'px',
        'height': height + 'px',
        'margin-top': mt + 'px',
        'margin-left': ml + 'px'
      }
    }
  },
  watch: {
    'obj': {
      handler(val) {
        this.draw()
      },
      deep: true
    }
  },
  mounted() {
    this.previewCanvas = this.$refs.previewCanvas
    this.previewCtx = this.previewCanvas.getContext('2d')
    this.draw()
  },
  methods: {
    // 清除画布
    clearCanvas(id, w, h) {
      if (!this[id + 'Canvas'] || !this[id + 'Ctx']) {
        return
      }
      w = w || this[id + 'Canvas'].width
      h = h || this[id + 'Canvas'].height
      this[id + 'Canvas'].width = w
      this[id + 'Canvas'].height = h
      this[id + 'Ctx'].clearRect(0, 0, w, h)
    },
    draw() {
      if (!this.obj.cols || !this.obj.rows) {
        return
      }
      this.clearCanvas('preview', this.obj.cols, this.obj.rows)
      if (this.obj.type === 'group') {
        this.drawSubObjs()
        return
      }
      let x, y
      let i = 0
      const len = this.obj.data.length
      for (i = 0; i < len; i++) {
        y = Math.floor(i / this.obj.cols)
        x = Math.floor(i - y * this.obj.cols)
        if (this.obj.data[i]) {
          this.previewCtx.fillStyle = this.obj.data[i]
          this.previewCtx.fillRect(x, y, 1, 1)
        }
      }
    },
    // 绘制子对象
    drawSubObjs() {
      if (!this.obj.objs) {
        return
      }
      Object.keys(this.obj.objs).reverse().map((i) => (this.drawSubObj(i)))
    },
    // 绘制特定子对象
    drawSubObj(idx) {
      if (!this.obj.objs || !this.obj.objs[idx]) {
        return
      }
      const obj = this.obj.objs[idx]
      let x, y
      let i = 0
      const len = obj.data.length
      for (i = 0; i < len; i++) {
        y = Math.floor(i / obj.cols)
        x = Math.floor(i - y * obj.cols)
        if (obj.data[i]) {
          this.previewCtx.fillStyle = obj.data[i]
          this.previewCtx.fillRect(x + obj.x, y + obj.y, 1, 1)
        }
      }
    }
  }
}
</script>
