<template>
  <div>
    <el-drawer :title="view.myNotify.title || ''" :visible.sync="view.myNotify.show" direction="btt" size="10%" :modal="false" :show-close="false" custom-class="notify">
      <div class="float-left">
        <i class="el-icon-success" /> {{ view.myNotify.content }}
      </div>
      <div class="float-right">
        <el-button v-if="view.myNotify.btn && view.myNotify.fn" class="txt-btn" @click="view.myNotify.fn()">{{ view.myNotify.btn }}</el-button>
      </div>
    </el-drawer>
  </div>
</template>
<script>
export default {
  props: {
    view: {
      type: Object,
      default() {
        return {}
      }
    }
  }
}
</script>
