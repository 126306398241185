<template>
  <div class="footer nav">
    <ul>
      <li v-finger:double-tap="() => (doubleTap('index'))">
        <el-button class="tap-btn" :class="{ 'on': view.mod === 'index' && !view.user.show }" @click="goto('index')">
          <i class="iconfont my-home size24" />
        </el-button>
      </li>
      <li v-finger:double-tap="() => (doubleTap('works'))">
        <el-button class="tap-btn" :class="{ 'on': (view.mod === 'works') && !view.user.show }" @click="goto('works')">
          <i class="iconfont my-pixel size24" />
        </el-button>
      </li>
      <li class="center">
        <el-button class="tap-btn" @click="view.addNew.show = true">
          <i class="iconfont my-add blue size48" />
        </el-button>
      </li>
      <li v-finger:double-tap="() => (doubleTap(view.mod === 'discover' ? 'discover' : 'zone'))">
        <el-button class="tap-btn" :class="{ 'on': (view.mod === 'zone' || view.mod === 'discover') && !view.user.show }" @click="goto(view.mod === 'discover' ? 'discover' : 'zone')">
          <i class="iconfont my-zone size24" />
        </el-button>
      </li>
      <li v-finger:double-tap="() => (renewUserView())">
        <el-button class="tap-btn" :class="{ 'on': (view.user.show && loginUserId && view.user.userid === loginUserId) }" @click="userGoto('user', { userid: loginUserId, type: 'thread', typeid: 0, hideBack: true })">
          <i class="iconfont my-personal size24" />
        </el-button>
      </li>
    </ul>
  </div>
</template>
<script>
import { mixins } from '@/mixins/common'
export default {
  mixins: [mixins],
  data() {
    const data = {}
    return data
  },
  methods: {
    // 转场
    goto(mod, dt) {
      this.view.historyMods = []
      this.emit('goto', [mod, dt])
    },
    // 登录用户转场
    userGoto(mod, dt) {
      if (this.checkLogin()) this.goto(mod, dt)
    },
    renewMessageView() {
      if (this.checkLogin()) this.doubleTap('message')
    },
    renewUserView() {
      if (this.checkLogin()) this.doubleTap('user')
    },
    doubleTap(mod) {
      this.emit('renewView', [mod])
    }
  }
}
</script>
