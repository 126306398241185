<template>
  <el-dialog :title="title" :visible.sync="config.show" :fullscreen="platform.type !== 'desktop'" :top="marginTop" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" custom-class="pop_page postDialog">
    <div slot="title" style="height: 26px;">
      <div class="title-btn back size16 padding-top4 padding-left4" @click="closePop(config)"><span class="color-dd">取消</span></div>
      <span class="el-dialog__title">{{ title }}</span>
      <div class="title-btn size16" @click="postThread()"><span :class="{'blue': !config.loading && form.txt, 'color-dd': !form.txt || config.loading}">发布</span></div>
    </div>
    <div v-loading="config.loading" :class="{'my-container': platform.type !== 'desktop'}" :element-loading-text=" postPercent ? postPercent + '%' : '发布中，请稍候...'" element-loading-background="rgba(0, 0, 0, 0.8)">
      <div :style="{'width': '100%', 'height': (showEmojis ? height - 260 : height) + 'px', 'overflow-y': 'auto'}" @click="showEmojis=false">
        <div v-if="showTitleInput" class="post-row">
          <el-input id="titleInput" ref="titleInput" v-model="form.title" type="input" class="post-input" :maxlength="200" :placeholder="'填写标题会有更多赞哦（选填）'" rows="1" @blur="handleInputBlur" @focus="showEmojis=false" />
        </div>
        <div class="post-row">
          <el-input id="txtInput" ref="txtInput" v-model="form.txt" type="textarea" class="post-input" :maxlength="maxLength" :placeholder="'说点什么...'" :autosize="{ minRows: 3, maxRows: 16}" @blur="handleInputBlur" @focus="showEmojis=false" />
        </div>
        <div v-if="showImages && !showGridy" class="post-row">
          <div style="width:100%;">
            <el-col v-for="(img, i) in images" :key="i" class="padding4" :span="isDesktop ? 5 : 8" :style="{'min-width': thumbSize + 8 + 'px', 'height': thumbSize + 8 + 'px'}">
              <div class="round4" :style="{'width': thumbSize + 'px', 'height': thumbSize + 'px', 'background-color': 'var(--color-1b)'}">
                <el-image class="round4" :style="{'width': thumbSize + 'px', 'height': thumbSize + 'px'}" fit="cover" :src="img.url" />
              </div>
              <div class="opacity-bg align-center" :style="{'width': thumbSize + 'px', 'height':'24px', 'line-height':'24px', 'position': 'relative', 'top':'-24px'}"><i class="iconfont my-delete size16 white" @click="() => (images.splice(i, 1))" /></div>
            </el-col>
            <el-col v-if="images.length < limitFileCount" class="padding4" :span="isDesktop ? 5 : 8">
              <div class="round4 padding4 bg-1b color-cc align-center" :style="{'width': thumbSize + 'px', 'height': thumbSize + 'px'}" @click="openFiles('image')"><br><span :style="{'font-size': thumbSize * 0.6 + 'px', 'line-height': thumbSize * 0.4 + 'px'}">+</span><br><span class="size12">上传图片</span></div>
            </el-col>
            <el-col v-if="images.length < limitFileCount" class="padding4" :span="isDesktop ? 5 : 8">
              <div class="round4 padding4 bg-1b color-cc align-center" :style="{'width': thumbSize + 'px', 'height': thumbSize + 'px'}" @click="openSelecter({ 'table': 'paint', 'keyfield': 'paintid', 'maxSelectCount': 9 })"><br><span :style="{'font-size': thumbSize * 0.6 + 'px', 'line-height': thumbSize * 0.4 + 'px'}">+</span><br><span class="size12">选择AI作画</span></div>
            </el-col>
            <el-col v-if="images.length < limitFileCount" class="padding4" :span="isDesktop ? 5 : 8">
              <div class="round4 padding4 bg-1b color-cc align-center" :style="{'width': thumbSize + 'px', 'height': thumbSize + 'px'}" @click="openSelecter({ 'table': 'attachments_1', 'keyfield': 'id', 'maxSelectCount': 9 })"><br><span :style="{'font-size': thumbSize * 0.6 + 'px', 'line-height': thumbSize * 0.4 + 'px'}">+</span><br><span class="size12">选择素材</span></div>
            </el-col>
          </div>
        </div>
        <div v-if="showAttachment && !showGridy" class="post-row">
          <div style="width:100%;">
            <el-row v-for="(attachment, i) in attachments" :key="i" class="padding4">
              <div class="round4 padding4 bg-1b" :style="{'width': width - 20 + 'px', 'height': '35px', 'line-height': '28px'}">
                <div class="color-cc ellipsis float-left padding-left8" :style="{'width': width - 70 + 'px'}">{{ '附件' + (i + 1) + '：' + attachment.name }}</div>
                <div class="padding-right8 float-right" @click="() => (attachments.splice(i, 1))"><i class="iconfont my-delete size16 white" /></div>
              </div>
            </el-row>
            <el-row v-if="attachments.length < limitFileCount" class="padding4">
              <el-col :span="isDesktop ? 5 : 8">
                <div class="round4 padding4 bg-1b align-center margin-right4" :style="{'width': thumbSize + 'px', 'height': '35px', 'line-height': '30px'}" @click="openFiles('attachment')"><span class="size12 color-dd">+ 上传附件</span></div>
              </el-col>
              <el-col :span="isDesktop ? 5 : 8">
                <div class="round4 padding4 bg-1b align-center margin-left4" :style="{'width': thumbSize + 'px', 'height': '35px', 'line-height': '30px'}" @click="openSelecter({ 'table': 'attachments_0', 'keyfield': 'id', 'maxSelectCount': 9 })"><span class="size12 color-dd">+ 选择文件</span></div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div v-if="showGridy && refWork.workid" class="round8 bg-1b margin-left10 margin-right10 padding8">
          <el-row>
            <div class="float-left" style="height:80px;">
              <div style="width:90px;height:80px;text-align: center;">
                <img :src="worksHost + 'work/action/download/gridy/size/8/workid/' + refWork.workid + '/' + refWork.workid + '.png?ver=' + refWork.update_at" style="margin:0 auto;" :style="imgStyle(refWork)">
              </div>
            </div>
            <div class="float-left padding-left8 padding-top12 line-height-20" style="width:calc(100% - 115px);">
              <div class="ellipsis" style="width:100%;">{{ refWork.name }}</div>
              <span class="color-dd size12">{{ formateTime(refWork.update_at) }}</span>
            </div>
            <div class="padding-right4 float-right" @click="refWork = {}"><i class="iconfont my-delete size16 white" /></div>
          </el-row>
        </div>
        <div v-if="showGridy && !refWork.workid" class="post-row">
          <div style="width:100%;">
            <el-row class="padding4">
              <el-col :span="12">
                <div class="round4 padding4 bg-1b align-center margin-right4" :style="{'height': '35px', 'line-height': '30px'}" @click="openSelecter({ 'table': 'work', 'maxSelectCount': 1 })"><span class="size12 color-dd">+ 选择作品</span></div>
              </el-col>
              <el-col :span="12">
                <div class="round4 padding4 bg-1b align-center margin-left4" :style="{'height': '35px', 'line-height': '30px'}" @click="openSelecter({ 'table': 'nft', 'keyfield': 'nftid', 'maxSelectCount': 1 })"><span class="size12 color-dd">+ 选择数字藏品</span></div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div v-show="showEmojis" class="footer post-emojis" :style="{'position': isDesktop ? 'absolute' : ''}">
        <div v-for="(emojis, key) in emojis" :key="key" class="post-block">
          <div class="catalog">
            <div>{{ getCatalogName(key) }}</div>
          </div>
          <div class="emojis">
            <span v-for="(emoji, name) in emojis" :key="name" class="size24 float-left" style="width: 10%;line-height: 32px;" @click="addEmoji(emoji)" v-text="emoji" />
          </div>
        </div>
      </div>
      <div class="footer post-target" :style="{'position': isDesktop ? 'absolute' : '', 'bottom': showEmojis ? '338px': ''}">
        <el-col :span="12" class="align-left padding-left8">
          <el-button @click="toggleTitleInput">
            <i class="iconfont my-title" /> {{ showTitleInput ? '取消' : '添加' }}标题
          </el-button>
        </el-col>
        <el-col :span="12" class="align-right padding-right8">
          <el-button @click="emit('openSift', ['post'])">
            <i class="iconfont my-zone" :class="{'blue': config.catalogName }" /><span :class="{'blue': config.catalogName }">发布到{{ config.catalogName || '圈子' }}</span> <i class="iconfont my-right size10" :class="{'blue': config.catalogName }" />
          </el-button>
        </el-col>
      </div>
      <div class="footer post-btns" :style="{'position': isDesktop ? 'absolute' : '', 'bottom': showEmojis ? '288px': ''}">
        <input ref="file-uploader" :multiple="platform.type === 'desktop'" type="file" :accept="acceptExtends" style="display:none;" @change="selectFiles">
        <el-row>
          <ul>
            <li>
              <el-button class="tap-btn" @click="() => (!showGridy ? showImages = !showImages : () => {})">
                <i class="iconfont my-image" :class="{'blue': !showGridy && showImages, 'color-66': showGridy}" />
              </el-button>
            </li>
            <li>
              <el-button class="tap-btn" @click="() => (!showAttachment && !showImages ? showGridy = !showGridy : () => {})">
                <i class="iconfont my-pixel" :class="{'blue': showGridy && !showAttachment && !showImages, 'color-66': showAttachment || showImages}" />
              </el-button>
            </li>
            <li>
              <el-button class="tap-btn" @click="showEmojis = !showEmojis">
                <i class="iconfont my-smile" :class="{'blue': showEmojis}" />
              </el-button>
            </li>
            <li>
              <el-button class="tap-btn" @click="openSelecter({ 'table': 'user_follow', 'keyfield': 'userid', 'maxSelectCount': 3 })">
                <i class="iconfont my-at" />
              </el-button>
            </li>
            <li>
              <el-button class="tap-btn">
                <i class="iconfont my-topic" @click="openSelecter({ 'table': 'topics', 'keyfield': 'id', 'maxSelectCount': 3 })" />
              </el-button>
            </li>
            <li>
              <el-button class="tap-btn" @click="toggleAttachment">
                <i class="iconfont my-attachment" :class="{'blue': !showGridy && showAttachment, 'color-66': showGridy}" />
              </el-button>
            </li>
          </ul>
        </el-row>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { mixins } from '@/mixins/common'
import utils from '@/js/utils'
import md5 from 'js-md5'
import emojis from '@/js/conf/emojis'
import conf from '@/js/conf/conf'
import service from '@/js/service'
import GRIDY from '@/js/sdk/GridySDK'
import COSSDK from '@/js/sdk/cosSDK'
import { setTimeout } from 'timers'
export default {
  components: {},
  mixins: [mixins],
  props: {},
  data() {
    const form = {
      title: '',
      txt: '',
      catalogId: 0,
      draft: 0,
      position: {}
    }
    const rules = {
      phone: [{ trigger: 'blur', validator: this.validPhone }],
      code: [{ trigger: 'blur', validator: this.validCode }],
      username: [{ trigger: 'blur', validator: this.validUsername }],
      password: [{ trigger: 'blur', validator: this.validPassword }],
      repassword: [{ trigger: 'blur', validator: this.validRepassword }],
      nickname: [{ trigger: 'blur', validator: this.validNickname }],
      bankname: [{ trigger: 'blur', validator: this.validBankname }],
      realname: [{ trigger: 'blur', validator: this.validRealname }],
      idcard: [{ trigger: 'blur', validator: this.validIdcard }],
      bankcard: [{ trigger: 'blur', validator: this.validBankcard }],
      checked: [{ trigger: 'blur', validator: this.validChecked }],
      address: [{ trigger: 'blur', validator: this.validAddress }],
      area: [{ trigger: 'blur', validator: this.validArea }]
    }
    let container_width, container_height
    if (this.state.platform.type === 'desktop') {
      container_width = 632
      container_height = 632
    } else {
      container_width = utils.width()
      container_height = utils.height()
    }
    const data = {}
    data.COSSDK = null
    data.GRIDY = null
    data.width = container_width - 3
    // data.height = container_height - 134
    data.height = container_height - 38
    data.imageSize = 120
    data.worksHost = conf.hosts().worksHost
    data.platform = utils.platform()
    data.emojis = emojis
    data.maxLength = 50000
    data.maxFilesize = 1024 * 1024 * 5
    data.cursorIndex = 0
    data.cursorId = 'txtInput'
    data.limitFileCount = 9
    data.accept = 'image/*'
    data.maxImageWidth = 1024
    data.maxImageHeight = 1024
    data.refWork = {}
    data.images = []
    data.attachments = []
    data.imageIds = []
    data.docIds = []
    data.postProcess = { total: 0, remain: 0 }
    data.form = form
    data.showTitleInput = false
    data.showImages = false
    data.showAttachment = false
    data.showGridy = false
    data.showEmojis = false
    data.rules = rules
    data.resource = {}
    data.countDownNum = 0
    data.countDownIt = null
    return data
  },
  computed: {
    config() {
      return this.view.post
    },
    act() {
      return this.config.act
    },
    id() {
      return this.config.id
    },
    title() {
      return this.config.title
    },
    thumbSize() {
      return Math.floor((this.width - 16) / (this.isDesktop ? 5 : 3)) - 8
    },
    postPercent() {
      return this.postProcess.total ? Math.round((this.postProcess.total - this.postProcess.remain) * 100 / this.postProcess.total) : ''
    },
    acceptExtends() {
      if (this.accept === 'image') {
        return '.jpg,.jpeg,.png,.gif,.bmp,.tiff,.webp'
      } else {
        return '.zip,.rar,.txt,.doc,.ppt,.xls,.pdf,.docx,.xlsx'
      }
    }
  },
  watch: {
    'view.post.show': {
      handler() {
        this.showEmojis = false
        if (this.view.post.show) {
          this.update()
        }
      }
    }
  },
  methods: {
    toggleAttachment() {
      if (this.loginUserId >= 10000) {
        this.alert('对不起，没有附件功能权限')
        return
      }
      if (!this.showGridy) {
        this.showAttachment = !this.showAttachment
      }
    },
    getCatalogName(k) {
      const names = {
        'Frequently': '常用表情',
        'People': '笑脸和情感',
        'Nature': '动物和自然',
        'Objects': '物品',
        'Places': '旅行和地点'
      }
      return names[k] || ''
    },
    toggleTitleInput() {
      this.showTitleInput = !this.showTitleInput
      if (this.showTitleInput) {
        this.showEmojis = false
        const input = document.getElementById('titleInput')
        if (input) input.focus()
      }
    },
    handleInputBlur(e) {
      this.cursorId = e.srcElement.id
      this.cursorIndex = e.srcElement.selectionStart
    },
    addEmoji(emoji) {
      let data = (this.cursorId === 'titleInput') ? this.form.title : this.form.txt
      if (this.maxLength - data.length >= 2) {
        let s1 = ''
        let s2 = ''
        if (data.length < this.cursorIndex) {
          data = data + emoji
        } else {
          s1 = data.substring(0, this.cursorIndex)
          s2 = data.substring(this.cursorIndex, data.length)
          data = s1 + emoji + s2
        }
      }
      if (this.cursorId === 'titleInput') {
        this.form.title = data
      } else {
        this.form.txt = data
      }
      this.showEmojis = false
      this.cursorId = 'txtInput'
      this.cursorIndex = 0
      const input = document.getElementById(this.cursorId)
      if (input) input.focus()
    },
    getAttachment(item) {
      if (!item || !item.file_path) return ''
      const ver = item.updated_at ? Math.round(new Date(item.updated_at) / 1000) : ''
      let type = 'attachments'
      if (item.file_path.split('/paint/').length > 1) type = 'paint'
      return service.getCosUrl(item.file_path.replace('public/' + type + '/', 'cos://') + item.attachment, type, ver) || ''
    },
    getPaint(item) {
      if (!item || !item.images || !item.images[0]) return ''
      return service.getCosUrl(item.images[0], 'paint', item.add_at) || ''
    },
    openSelecter(config) {
      config.fn = (items) => {
        let str = ''
        Object.values(items).map((item) => {
          if (config.table === 'user_follow') {
            str = str + ' @' + item.nickname + ' '
          } else if (config.table === 'topics') {
            str = str + ' #' + item.content + '# '
          } else if (config.table === 'work' || config.table === 'nft') {
            this.refWork = item
          } else if (config.table === 'attachments_0') {
            item.name = item.file_name
            this.attachments.push(item)
          } else if (config.table === 'attachments_1') {
            item.url = this.getAttachment(item)
            this.images.push(item)
          } else if (config.table === 'paint') {
            item.url = this.getPaint(item)
            this.images.push(item)
          }
        })
        this.form.txt = this.form.txt + str
      }
      if (config.table === 'attachments_0') {
        if (this.attachments.length >= this.limitFileCount) {
          this.alert('最多上传' + this.limitFileCount + '个附件')
          return
        } else {
          config.maxSelectCount = this.limitFileCount - this.attachments.length
        }
      } else if (config.table === 'attachments_1') {
        if (this.images.length >= this.limitFileCount) {
          this.alert('最多上传' + this.limitFileCount + '张图片')
          return
        } else {
          config.maxSelectCount = this.limitFileCount - this.images.length
        }
      }
      this.emit('openResourceSelecter', [config])
    },
    openFiles(accept) {
      if ((accept === 'image' && this.images.length >= this.limitFileCount) || (accept !== 'image' && this.attachments.length >= this.limitFileCount)) {
        this.alert('最多上传' + this.limitFileCount + (this.accept === 'image' ? '张图片' : '个附件'))
        return
      }
      this.accept = accept
      setTimeout(() => {
        this.$refs['file-uploader'].click()
      }, 100)
    },
    selectFiles() {
      const dom = this.$refs['file-uploader']
      const localFiles = dom.files
      if (!localFiles.length) {
        return
      } else if (this.accept === 'image' && this.images.length + localFiles.length > this.limitFileCount) {
        if (this.images.length) {
          this.alert('还能上传' + (this.limitFileCount - this.images.length) + '张图片')
        } else {
          this.alert('最多上传' + this.limitFileCount + '张图片')
        }
        return
      } else if (this.accept === 'attachment' && this.attachments.length + localFiles.length > this.limitFileCount) {
        if (this.attachments.length) {
          this.alert('还能上传' + (this.limitFileCount - this.images.length) + '个附件')
        } else {
          this.alert('最多上传' + this.limitFileCount + '个附件')
        }
        return
      }
      const denyFiles = []
      Object.values(localFiles).map((file) => {
        if (this.accept === 'image') {
          this.GRIDY = new GRIDY()
          this.GRIDY.compressImage(file.name, window.URL.createObjectURL(file), (fileObj) => {
            file.width = fileObj.width
            file.height = fileObj.height
            file.ext = fileObj.ext
            file.mime = fileObj.mime
            file.quality = fileObj.quality
            file.md5 = md5(fileObj.base64)
            file.url = fileObj.base64
            this.images.push(file)
          }, this.maxImageWidth, this.maxImageHeight, 'file')
        } else {
          if (file.size > this.maxFilesize) {
            denyFiles.push(file.name)
          } else {
            this.attachments.push(file)
          }
        }
      })
      if (denyFiles.length) {
        this.alert(denyFiles.join('、') + ' 超过' + utils.formateBytes(this.maxFilesize) + '，无法上传')
      }
    },
    calcWorkSize(w, h) {
      if (w < 80 && h < 80) return { width: w, height: h }
      let width, height
      if (w > h) {
        width = 80
        height = Math.round(width * h / w)
      } else {
        height = 80
        width = Math.round(height * w / h)
      }
      return { width: width, height: height }
    },
    imgStyle(work) {
      if (!work) return {}
      const size = this.calcWorkSize(work.width, work.height)
      const top = (80 - size.height) / 2
      return { 'width': size.width + 'px', 'height': size.height + 'px', 'margin-top': top + 'px' }
    },
    handle(act, data) {
      this.emit(act, data)
    },
    getDate(time) {
      if (!time) return ''
      return utils.date('datetime', 0, new Date(time * 1000))
    },
    formateTime: utils.formateTime,
    formateNums: utils.formateNums,
    formateMoney: utils.formateMoney,
    uploadImage(file, i = 1, type = 1, cb) {
      // 限制每秒上传1个文件
      setTimeout(() => {
        this.upload({ file: file, name: file.name, width: file.width, height: file.height, ext: file.ext, mime: file.mime, quality: file.quality, md5: file.md5 }, type, cb)
      }, i * 1000)
    },
    uploadFile(file, type = 0, cb) {
      // 构造数据
      const arr = file.name.split('.')
      const ext = arr.length > 1 ? arr.pop() : ''
      utils.fileToBase64(file, (base64) => {
        const fileObj = {
          name: file.name,
          file: file,
          md5: md5(base64),
          ext: ext,
          width: 0,
          height: 0,
          mime: file.type
        }
        this.upload(fileObj, type, cb)
      })
    },
    upload(fileObj, type = 1, cb) {
      if (!fileObj.file || !fileObj.md5 || !fileObj.ext) {
        cb && cb('error')
        return
      }
      fileObj.path = 'public/attachments/' + utils.caclUserPath(this.loginUserId) + '/'
      fileObj.attachment = fileObj.md5 + '.' + fileObj.ext
      const key = fileObj.path + fileObj.attachment
      // 上传文件
      this.COSSDK = new COSSDK()
      this.COSSDK.putObject({
        Bucket: conf.cosBucket.bucket,
        Region: conf.cosBucket.region,
        Key: key,
        Body: fileObj.file
      }, (err, data) => {
        if (!err && data && data.statusCode === 200 && data.Location) {
          this.saveAttachment(fileObj, type, cb)
        } else {
          cb && cb('error')
        }
      })
    },
    // 保存附件
    saveAttachment(fileObj, type = 1, cb) {
      const data = {
        'type': type,
        'attachment': fileObj.attachment,
        'file_path': fileObj.path,
        'file_name': fileObj.name,
        'file_size': fileObj.file.size,
        'file_width': fileObj.width,
        'file_height': fileObj.height,
        'file_type': fileObj.mime
      }
      service.post('attachments', data, (dt, ret) => {
        if (ret === 'success' && dt && dt.data && dt.data.id) {
          fileObj.id = dt.data.id
          this.saveIndexes(fileObj, type, cb)
        } else {
          cb && cb('error')
        }
      }, true)
    },
    // 关联到主题数据 type: 附件类型(0帖子附件，1帖子图片，2帖子视频，3帖子音频，4消息图片)
    saveIndexes(fileObj, type = 1, cb) {
      if (type === 0) {
        this.docIds.push(fileObj.id)
      } else if (type === 1) {
        this.imageIds.push(fileObj.id)
      }
      cb && cb()
    },
    postThread() {
      if (!this.form.txt) {
        this.alert('说点什么呗')
        return
      } else if (utils.getLen(this.form.txt) < 10) {
        this.alert('多说一点，会有更多赞哦')
        return
      }
      if (this.config.loading) return
      this.config.loading = true
      let total = 1
      if (!this.showGridy && this.showImages && this.images.length) {
        total = total + this.images.length
      }
      if (!this.showGridy && this.showAttachment && this.attachments.length) {
        total = total + this.attachments.length
      }
      this.postProcess.total = total
      this.postProcess.remain = total
      this.docIds = []
      this.imageIds = []
      const post = (type) => {
        this.postProcess.remain--
        if (type === 'error') {
          this.alert('太火爆了，请稍后重新发布', type)
          this.config.loading = false
          return
        }
        if (this.postProcess.remain <= 0) {
          const threadDt = {
            'title': this.showTitleInput ? (this.form.title || '') : '',
            'categoryId': this.config.catalogid,
            'content': {
              'text': this.form.txt || '',
              'indexes': {}
            },
            'position': {}
          }
          if (this.showGridy && this.refWork.workid) {
            threadDt.realm = 1
            threadDt.refid = this.refWork.workid
          }
          if (!this.showGridy && this.showAttachment && this.docIds.length) {
            threadDt.content.indexes[108] = { tomId: 108, body: { docIds: this.docIds, priceList: [] }}
          }
          if (!this.showGridy && this.showImages && this.imageIds.length) {
            threadDt.content.indexes[101] = { tomId: 101, body: { imageIds: this.imageIds, priceList: [] }}
          }
          service.createThread(threadDt, (res, type) => {
            if (type === 'success') {
              service.incCount(this.loginUserId, 'thread_create')
              this.postProcess.remain--
              this.init()
              this.message('发布成功', 'success')
              this.closePop(this.config)
              this.view.zone.renew = utils.time('time')
            } else {
              this.message(res, type)
            }
            this.config.loading = false
          })
        }
      }
      if (!this.showGridy && this.showImages && this.images.length) {
        for (const i in this.images) {
          if (this.images[i].id) {
            this.saveIndexes(this.images[i], 1, post)
          } else if (this.images[i].paintid) {
            this.saveAttachment(this.getPaintFileObj(this.images[i]), 1, post)
          } else {
            this.uploadImage(this.images[i], i, 1, post)
          }
        }
      }
      if (!this.showGridy && this.showAttachment && this.attachments.length) {
        for (const k in this.attachments) {
          if (this.attachments[k].id) {
            this.saveIndexes(this.attachments[k], 0, post)
          } else {
            this.uploadFile(this.attachments[k], 0, post)
          }
        }
      }
      post()
    },
    getPaintFileObj(paint) {
      const arr = paint.images[0].split('/')
      const name = arr[arr.length - 1]
      let path = paint.images[0].replace(name, '')
      path = path.replace('cos://', 'public/paint/')
      const fileObj = {
        'attachment': name,
        'path': path,
        'name': name,
        'file': { size: paint.file_size },
        'width': paint.width,
        'height': paint.height,
        'mime': 'image/png'
      }
      return fileObj
    },
    init() {
      this.form = {
        title: '',
        txt: '',
        catalogId: 0,
        draft: 0,
        position: {}
      }
      this.refWork = {}
      this.images = []
      this.attachments = []
      this.imageIds = []
      this.docIds = []
      this.showTitleInput = false
      this.showImages = false
      this.showAttachment = false
      this.showGridy = false
      this.showEmojis = false
    }
  }
}
</script>
