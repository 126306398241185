<template>
  <div style="width:100%;height:100%;">
    <pull-to :id="'aiart-' + mod + '-container'" :style="listStyle" class="my-list" :top-load-method="(loaded) => loadData(loaded, showUp ? 'up' : 'renew')" :bottom-load-method="(loaded) => loadData(loaded, 'down')" @infinite-scroll="getData('down')">
      <div class="flow-container">
        <div v-for="(column, index) in columns" :key="index" class="column" :style="{'width': columnWidth + 'px' }">
          <div v-for="(item, i) in column.items" :key="i" class="item">
            <div class="card" :style="{ 'width': cardWidth + 'px', 'height': (item._height - 5) + 'px' }">
              <div>
                <img :src="item._image" :class="{'round-top': cardWidth - 16 < item._raw_width}" :style="{ width: item._raw_width + 'px', height: item._raw_height + 'px' }" @click="openGridyViewer(item.paintid, item)">
                <div class="title">
                  <div class="left" style="width:100%;">
                    <span v-if="!item.check" class="tag bg-red white">封</span>
                    <!-- <span v-if="item.original" class="tag">原</span> <span v-if="item.best" class="tag">荐</span> -->
                    <!-- <span v-for="tag in item.tags" :key="tag" class="tag bg-gray white">{{ tag }}</span> -->
                    {{ item.name }}
                  </div>
                  <!-- <div class="right">
                    <el-button class="tap-btn" @click="showActionSheet(item)">
                      <i class="iconfont my-dot-more-v" />
                    </el-button>
                  </div> -->
                </div>
                <div class="foot">
                  <div class="left" @click="emit('goto', ['user', { userid: item.userid } ])">
                    <span v-if="!users[item.userid].avatar" class="el-avatar el-avatar--circle noavatar small">
                      <i class="iconfont my-man" />
                    </span>
                    <el-avatar v-if="users[item.userid].avatar" :size="24" :src="getAvatar(item)" />
                  </div>
                  <div class="center ellipsis" style="width:calc(100% - 100px);" @click="emit('goto', ['user', { userid: item.userid } ])">
                    {{ (users[item.userid]) ? users[item.userid].nickname : view.anonymity }}
                  </div>
                  <div class="right">
                    <el-button class="aiart-btn" @click="openAiPaint(item)">创作相似</el-button>
                  </div>
                  <!-- <div class="right">
                    <el-button v-if="mod === 'favor'" class="tap-btn" @click="() => unfavorIt(item.paintid)">
                      <i class="iconfont my-star" />
                    </el-button>
                    <el-button v-if="mod !== 'favor'" class="tap-btn" @click="() => praiseIt(item.paintid)">
                      <i class="iconfont" :class="{ 'my-praise-fill': state.praiseState[item.paintid], 'my-praise-blank': !state.praiseState[item.paintid] }" />
                    </el-button>
                  </div>
                  <div class="right">
                    <span v-if="mod === 'favor'">{{ state.favorState[item.paintid] && state.favorState[item.paintid] > 0 ? formateNums(state.favorState[item.paintid]) : '' }}</span>
                    <span v-if="mod !== 'favor'">{{ state.praiseCount[item.paintid] && state.praiseCount[item.paintid] > 0 ? formateNums(state.praiseCount[item.paintid]) : '' }}</span>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="itemNums && !data.noDownPage" class="tip load" @click="getData('down')"><i class="iconfont my-dot-more" /></div>
      <div v-if="flowHeight > height && data.noDownPage" class="tip">已显示全部内容</div>
      <el-empty v-if="(!itemNums && !loading[mod])" :image="emptyImage" description="这里空空的" />
    </pull-to>
    <div v-if="data.scrollTop > 200" class="go-top">
      <el-button class="tap-btn" @click="backTop()">
        <i class="iconfont my-top" />
      </el-button>
    </div>
  </div>
</template>
<script>
import { mixins } from '@/mixins/aiart'
export default {
  mixins: [mixins]
}
</script>
