<template>
  <div ref="colorPicker" v-clickoutside="closePanel" class="m-colorPicker" @click="event => { event.stopPropagation() }">
    <div id="colorPickerBox" class="box" :class="{ open: openStatus }" :style="{ left: state.colorPicker.x + 'px', top: state.colorPicker.y + 'px', 'opacity': showHtml5Color ? 0 : 1 }">
      <div class="bd">
        <div v-if="state.colorPicker.fileType === 0" class="tabs" style="position:unset;margin-bottom:6px;padding-top: 0;">
          <el-tabs v-model="palette.paletteId">
            <el-tab-pane
              v-for="item in palette.opts"
              v-show="item.value !== 'default'"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :name="item.value"
            />
          </el-tabs>
        </div>
        <div v-if="state.colorPicker.fileType !== 0" class="color-ff" style="position:unset;margin-bottom:6px;padding-top: 0;">
          调色板
        </div>
        <div v-if="palette.paletteId === 'base' || palette.paletteId === ''">
          <ul class="tColor">
            <li
              v-for="(color, index) of tColor"
              :key="index"
              :style="{ backgroundColor: color }"
              :title="color.toUpperCase()"
              @mouseover="hoveColor = color"
              @mouseout="hoveColor = null"
              @click="updataValue(color)"
            />
          </ul>
          <ul class="bColor">
            <li v-for="(item, index) of colorPanel" :key="index">
              <ul>
                <li
                  v-for="(color, cindex) of item"
                  :key="cindex"
                  :style="{ backgroundColor: color }"
                  :title="color.toUpperCase()"
                  @mouseover="hoveColor = color"
                  @mouseout="hoveColor = null"
                  @click="updataValue(color)"
                />
              </ul>
            </li>
          </ul>
          <h3 v-show="!showHtml5Color">标准色</h3>
          <ul class="tColor">
            <li
              v-for="(color, index) of bColor"
              :key="index"
              :style="{ backgroundColor: color }"
              :title="color.toUpperCase()"
              @mouseover="hoveColor = color"
              @mouseout="hoveColor = null"
              @click="updataValue(color)"
            />
          </ul>
          <h3 v-if="moreColor" style="margin-bottom: 0px;" @click="triggerHtml5Color">更多颜色...</h3>
          <!-- 用以激活HTML5颜色面板 -->
          <input
            v-if="moreColor"
            ref="html5Color"
            v-model="html5Color"
            type="color"
            @change="updataValue(html5Color)"
          >
        </div>
        <div v-if="palette.paletteId !== 'base' && palette.paletteId !== ''">
          <ul class="tColor">
            <li
              v-for="(color, index) of palColors"
              :key="index"
              :style="{ backgroundColor: color }"
              :title="color.toUpperCase()"
              @mouseover="hoveColor = color"
              @mouseout="hoveColor = null"
              @click="updataValue(color)"
            />
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import clickoutside from '@/directive/clickoutside'
import conf from '@/js/conf/conf'
import utils from '@/js/utils'
export default {
  directives: {
    clickoutside
  },
  props: {
    state: {
      type: Object,
      default() {
        return {}
      }
    },
    // 当前颜色值
    value: {
      type: String,
      required: true
    },
    objPalette: {
      type: Boolean,
      default: true
    },
    notnull: {
      type: Boolean,
      default: false
    },
    // 默认颜色
    defaultColor: {
      type: String,
      default: '#000000'
    },
    // 禁用状态
    disabled: {
      type: Boolean,
      default: false
    },
    moreColor: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      open: false,
      showHtml5Color: false,
      palette: utils.deepClone(conf.palette),
      palColors: [],
      colors: [],
      // 鼠标经过的颜色块
      hoveColor: null,
      // 主题颜色
      tColor: ['#000000', '#ffffff', '#eeece1', '#e2534d', '#f9974c', '#ffc12a', '#9aba60', '#47acc5', '#1e497b', '#956FE7'],
      // 颜色面板
      colorConfig: [
        ['#0d0d0d', '#808080'],
        ['#7f7f7f', '#f2f2f2'],
        ['#1c1a10', '#ddd8c3'],
        ['#632623', '#f2dbdb'],
        ['#99490f', '#fee9da'],
        ['#7F6000', '#FFF2CC'],
        ['#4d602c', '#eaf1de'],
        ['#1e5867', '#d9eef3'],
        ['#0e243d', '#c6d9f0'],
        ['#511B78', '#F0EDF6']
      ],
      // 标准颜色
      bColor: ['#000000', '#ffffff', '#ff1e02', '#ffc12a', '#ffff3a', '#90cf5b', '#00af57', '#0071be', '#00215f', '#72349d'],
      html5Color: this.value
    }
  },
  computed: {
    openStatus() {
      return (this.state.colorPicker.open || this.open)
    },
    // 颜色面板
    colorPanel() {
      const colorArr = []
      for (const color of this.colorConfig) {
        colorArr.push(this.gradient(color[1], color[0], 5))
      }
      return colorArr
    }
  },
  watch: {
    'html5Color': {
      handler() {
        utils.debounce(this.emitHtml5Color, 100, 'emitHtml5ColorTimer')()
      }
    },
    'state.colorPicker.open': {
      handler() {
        this.showHtml5Color = false
        if (this.state.colorPicker.open) {
          if (this.state.colorPicker.fileType === 1) {
            this.palette.paletteId = 'brickfy'
          } else {
            this.palette.paletteId = 'base'
          }
        }
        // if (this.state.colorPicker.open) this.palColors = Object.keys(utils.sortColors(this.getPaletteColors(this.palette.paletteId)))
        if (this.state.colorPicker.open) this.palColors = Object.keys(this.getPaletteColors(this.palette.paletteId))
      }
    },
    'palette.paletteId': {
      handler() {
        this.state.curPaletteId = this.palette.paletteId
        // this.palColors = Object.keys(utils.sortColors(this.getPaletteColors(this.palette.paletteId)))
        this.palColors = Object.keys(this.getPaletteColors(this.palette.paletteId))
      }
    }
  },
  methods: {
    // 获取调色板数据
    getPaletteColors(palId) {
      const colors = {}
      if (palId === '' || palId === 'none' || palId === 'default' || !this.palette.data[palId]) {
        this.palette.paletteId = 'base'
        return colors
      }
      palId = palId === 'brickfy' ? 'brickfyArr' : palId
      for (const i in this.palette.data[palId]) {
        let c = this.palette.data[palId][i]
        if (!utils.isString(c)) {
          c = utils.rgb2hex(c)
        }
        c = c.toUpperCase()
        colors[c] = true
      }
      return colors
    },
    setColors: function(colors) {
      this.colors = colors || []
    },
    openPanel() {
      this.open = !this.disabled
      this.state.colorPicker.x = 20
      this.state.colorPicker.y = 20
    },
    closePanel() {
      this.state.colorPicker.open = false
      this.open = false
    },
    triggerHtml5Color() {
      this.showHtml5Color = true
      this.$refs.html5Color.click()
    },
    emitHtml5Color() {
      this.emitValue(this.html5Color)
    },
    emitValue(value) {
      this.$emit('input', value)
      this.$emit('change', value)
    },
    // 更新组件的值 value
    updataValue(value) {
      this.emitValue(value)
      this.showHtml5Color = false
      this.state.colorPicker.open = false
      this.open = false
    },
    // 设置默认颜色
    handleDefaultColor() {
      this.updataValue(this.defaultColor)
    },
    // 格式化 hex 颜色值
    parseColor(hexStr) {
      if (hexStr.length === 4) {
        hexStr = '#' + hexStr[1] + hexStr[1] + hexStr[2] + hexStr[2] + hexStr[3] + hexStr[3]
      } else {
        return hexStr
      }
    },
    // RGB 颜色 转 HEX 颜色
    rgbToHex(r, g, b) {
      const hex = ((r << 16) | (g << 8) | b).toString(16)
      return '#' + new Array(Math.abs(hex.length - 7)).join('0') + hex
    },
    // HEX 转 RGB 颜色
    hexToRgb(hex) {
      hex = this.parseColor(hex)
      const rgb = []
      for (let i = 1; i < 7; i += 2) {
        rgb.push(parseInt('0x' + hex.slice(i, i + 2)))
      }
      return rgb
    },
    // 计算渐变过渡颜色
    gradient(startColor, endColor, step) {
      // hex 转换为 rgb
      const sColor = this.hexToRgb(startColor)
      const eColor = this.hexToRgb(endColor)

      // 计算R\G\B每一步的差值
      const rStep = (eColor[0] - sColor[0]) / step
      const gStep = (eColor[1] - sColor[1]) / step
      const bStep = (eColor[2] - sColor[2]) / step

      const gradientColorArr = []
      // 计算每一步的hex值
      for (let i = 0; i < step; i++) {
        gradientColorArr.push(this.rgbToHex(parseInt(rStep * i + sColor[0]), parseInt(gStep * i + sColor[1]), parseInt(bStep * i + sColor[2])))
      }
      return gradientColorArr
    }
  }
}
</script>
<style lang="scss" scoped>
.m-colorPicker{
  position: absolute; z-index: 9988; text-align: left; font-size: 14px; display: inline-block;
  outline: none;
  ul,li,ol{ list-style: none; margin: 0; padding: 0; }
  .colorBtn{ width: 20px; height: 20px; }
  .colorBtn.noColor{
    background: #ffffff!important;
  }
  .colorBtn.noColor:after{
      content: "";
      background: red;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      display: inline-block;
      position: absolute;
      top: 9px;
      left: -2px;
      width: 24px;
      height: 2px;
    }
  .colorBtn.disabled{ cursor: no-drop; }
  .box{
    position: absolute; width: 240px; background: #1b1b1b; border: 1px solid #282828; visibility: hidden; border-radius: 2px; margin-top: 2px; padding: 8px; box-shadow: 0 0 5px rgba(0,0,0,.15); opacity: 0;
    box-sizing: content-box;
    h3{ margin: 5px 0 5px 0; font-size: 14px; font-weight: normal; padding: 4px; line-height: 1; color: #eeeeee;}
    input {
      visibility: hidden;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
  .box.open{ visibility: visible; opacity: 1;z-index: 1; }
  .hd{
    overflow: hidden; line-height: 29px;
    .colorView{ width: 100px; height: 30px; float: left; transition: background-color .3s ease; }
    .defaultColor{ width: 80px; float: right; text-align: center; border: 1px solid #ddd; cursor: pointer; color: #333; }
  }
  .noColor{
    li{ width: 18px; height: 18px; line-height: 12px!important; display: inline-block; margin-right: 6px;}
    div{
      height: 18px;
      width: 18px;
      background: #ffffff;
      border: 1px solid #cccccc;
    }
    div:after{
      content: "";
      background: red;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      display: inline-block;
      position: absolute;
      top: 13px;
      left: 6px;
      width: 24px;
      height: 2px;
    }
  }
  .tColor{
    li{ width: 20px; height: 20px; display: inline-block; margin: 0 2px; transition: all .3s ease; }
    li:hover{ box-shadow: 0 0 5px rgba(0,0,0,.4); transform: scale(1.3); }
  }
  .bColor{
    li{
      width: 20px; display: inline-block; margin: 0 2px;
      li{ display: block; width: 20px; height: 20px; transition: all .3s ease; margin: 0; }
      li:hover{ box-shadow: 0 0 5px rgba(0,0,0,.4); transform: scale(1.3); }
    }
  }
}
</style>
