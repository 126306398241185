<template>
  <el-dialog :title="title" :visible.sync="config.show" :fullscreen="platform.type !== 'desktop'" :top="marginTop" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" custom-class="pop_page have_footer orderMngDialog">
    <div slot="title" class="header">
      <div class="left">
        <el-button v-if="!isDesktop" class="tap-btn iconfont my-back size20" @click="closePop(config)" />
        &nbsp;
      </div>
      <div class="middle">
        {{ title }}
      </div>
      <div class="float-right align-right">
        <el-button v-if="isDesktop" class="tap-btn iconfont my-close size20" @click="closePop(config)" />
        &nbsp;
      </div>
    </div>
    <div :class="{'my-container': platform.type !== 'desktop'}">
      <div class="my-block">
        <div class="my-row round4" @click="openSelecter({ 'table': 'address', 'keyfield': 'addressid', 'maxSelectCount': 1 })">
          <el-row style="min-height: 45px;">
            <div class="float-left" :style="{'padding-top': (isDesktop ? 12 : 8) + 'px'}" style="width: 35px;">
              <i class="iconfont my-localtion size20 color-ff" />
            </div>
            <div class="float-left" style="width: calc(100% - 70px)">
              <span v-if="!form.address.addressid" style="line-height:45px;">请选择配送地址</span>
              <span v-if="form.address.addressid" class="size16">{{ form.address.name }}</span> <span v-if="form.address.addressid" class="color-99 margin-left4">{{ form.address.phone }}</span> <br>
              <span v-if="form.address.addressid" class="color-bb line-height-20">{{ form.address.province }} {{ form.address.city }} {{ form.address.address }}</span>
            </div>
            <div class="float-right" :style="{'padding-top': (isDesktop ? 16 : 6) + 'px'}" style="width: 30px;">
              <i class="iconfont my-right float-right color-cc" />
            </div>
          </el-row>
        </div>
      </div>
      <div class="my-block">
        <div class="my-row round-top4">
          <el-row>
            <el-col :span="16" class="ellipsis">选择商品</el-col>
            <el-col :span="8" class="align-right">
              <!-- <i class="iconfont size16" :class="{'my-open-down': !showSkus, 'my-close-up': showSkus }" @click="showSkus = !showSkus" /> -->
            </el-col>
          </el-row>
        </div>
        <el-row v-for="(item, i) in config.data" v-show="((orderId && item.addCart) || !orderId)" :key="i" class="my-row" :class="{'line-height-15': isDesktop}" style="min-height: 100px;border-top: 1px solid var(--color-22);">
          <el-col v-if="!orderId" style="width: 35px;">
            <i class="iconfont my-ok size20" :class="{'color-66': !item.addCart, 'blue': item.addCart }" @click="cartToggle(item)" />
          </el-col>
          <el-col style="width: 80px;">
            <el-image style="width: 80px; height: 80px;border: 1px solid var(--color-22);background: var(--color-22);" :src="item.imageUrl" class="round4" fit="scale-down" />
          </el-col>
          <el-col class="padding-left10" style="width: calc(100% - 115px);">
            <el-row class="padding-bottom4 size16 color-ee ellipsis">
              <!-- {{ item.name }} -->
              定制拼图 {{ item.num > 0 ? ('x' + item.num) : '' }}
            </el-row>
            <el-row v-if="item.frame">
              <span class="color-cc">
                {{ item.frame.name }}
              </span>
            </el-row>
            <el-row class="padding-top4">
              <span class="color-ee line-height-20">
                ¥{{ formateMoney(pre_price || line_price) }}
              </span>
              <el-input-number v-if="!orderId" v-model="item.num" :step="1" :min="0" :max="100" size="mini" class="float-right my-input-number" style="width: 100px;" @change="changeNum(item)" />
            </el-row>
          </el-col>
          <el-row v-if="showSkus && !item.isSuite" class="padding-top4">
            <el-col v-if="item.frame" :span="24" style="padding: 3px;line-height: 32px;border: 1px solid var(--color-22);">
              <i class="iconfont my-border size28 float-left color-cc" style="margin: 0 2px 2px 2px;" />
              <div class="float-left padding-left4 color-cc">
                {{ item.frame.name + ' x' + item.frame.num }}
              </div>
              <div class="float-right padding-right8" @click="hideToggle(item.frame)">
                <span :class="{'color-88 line-through': item.frame.hide}">¥{{ formateMoney(getPrice(item.frame)) }}</span>
                <!-- <i class="iconfont my-ok size20 padding-left8" :class="{green: !item.frame.hide, 'color-66': item.frame.hide}" style="line-height:32px;" /> -->
              </div>
            </el-col>
            <el-col v-for="(brick, k) in item.bricks" :key="k" :span="isDesktop ? 12 : 24" style="padding: 3px;line-height: 32px;border: 1px solid var(--color-22);">
              <div class="box32 border-33 round align-center float-left size14" :title="'#' + brick.brickId" :style="{ background: brick.color, color: whiteBrickNum.indexOf(brick.brickId) >= 0 ? '#FFFFFF' : '#000000' }">
                {{ brick.brickId }}
              </div>
              <div class="float-left padding-left4 color-cc">{{ brick.count + '颗 x' + brick.num }}</div>
              <div class="float-right padding-right8" @click="hideToggle(brick)">
                <span :class="{'color-88 line-through': brick.hide}">¥{{ formateMoney(getPrice(brick)) }}</span> <i class="iconfont my-ok size20 padding-left8" :class="{green: !brick.hide, 'color-66': brick.hide}" style="line-height:32px;" />
              </div>
            </el-col>
          </el-row>
          <el-row v-if="showSkus && item.isSuite" class="padding-top4">
            <el-col :span="24" style="padding: 3px;line-height: 32px;border: 1px solid var(--color-22);">
              <i class="iconfont my-border size28 float-left color-cc" style="margin: 0 2px 2px 2px;" />
              <div class="float-left padding-left4 color-cc">
                {{ item.goodsSuite.name + ' x' + item.goodsSuite.num }}
              </div>
              <div class="float-right padding-right8" @click="hideToggle(item.goodsSuite)">
                <span :class="{'color-88 line-through': item.goodsSuite.hide}">¥{{ formateMoney(getPrice(item.goodsSuite)) }}</span>
                <!-- <i class="iconfont my-ok size20 padding-left8" :class="{green: !item.goodsSuite.hide, 'color-66': item.goodsSuite.hide}" style="line-height:32px;" /> -->
              </div>
            </el-col>
            <el-col v-for="(brick, k) in item.goodsBricks" :key="k" :span="isDesktop ? 12 : 24" style="padding: 3px;line-height: 32px;border: 1px solid var(--color-22);">
              <div class="box32 border-33 round align-center float-left size14" :title="'#' + brick.brickId" :style="{ background: brick.color, color: whiteBrickNum.indexOf(brick.brickId) >= 0 ? '#FFFFFF' : '#000000' }">
                {{ brick.brickId }}
              </div>
              <div class="float-left padding-left4 color-cc">{{ brick.count + '颗 x' + brick.num }}</div>
              <div class="float-right padding-right8" @click="hideToggle(brick)">
                <span :class="{'color-88 line-through': brick.hide}">¥{{ formateMoney(getPrice(brick)) }}</span> <i class="iconfont my-ok size20 padding-left8" :class="{green: !brick.hide, 'color-66': brick.hide}" style="line-height:32px;" />
              </div>
            </el-col>
          </el-row>
        </el-row>
        <el-row v-for="(item, i) in suites" v-show="((orderId && item.addCart) || !orderId)" :key="'s' + i" class="my-row" :class="{'line-height-15': isDesktop}" style="min-height: 100px;border-top: 1px solid var(--color-22);">
          <el-col v-if="!orderId" style="width: 35px;">
            <i class="iconfont my-ok size20" :class="{'color-66': !item.addCart, 'blue': item.addCart }" @click="cartToggle(item)" />
          </el-col>
          <el-col style="width: 80px;">
            <el-image style="width: 80px; height: 80px;border: 1px solid var(--color-22);background: var(--color-22);" :src="cdnHost + item.image + '?v=1.06'" class="round4" fit="scale-down" />
          </el-col>
          <el-col class="padding-left10" style="width: calc(100% - 115px);">
            <el-row class="padding-bottom4 size16 color-ee ellipsis">
              {{ item.name }} {{ item.num > 0 ? ('x' + item.num) : '' }}
            </el-row>
            <el-row v-if="item.info">
              <span class="color-cc">
                {{ item.info }}
              </span>
            </el-row>
            <el-row class="padding-top4">
              <!-- <span class="line-height-20" :class="{'color-ee': item.addCart && item.num > 0 ,'color-99': !item.addCart || item.num < 1}">
                ¥{{ formateMoney(item.price * Math.max(item.num, 1)) }}
              </span> -->
              <span class="line-height-20 color-ee">
                ¥{{ formateMoney(item.price) }}
              </span>
              <el-input-number v-if="!orderId" v-model="item.num" :step="1" :min="0" :max="100" size="mini" class="float-right my-input-number" style="width: 100px;" @change="changeNum(item)" />
            </el-row>
          </el-col>
        </el-row>
        <el-row class="my-row round-bottom4" style="border-top: 1px solid var(--color-22);">
          <el-col :span="24" class="right">
            合计 <span class="white">¥{{ formateMoney(line_price) }}</span>
          </el-col>
        </el-row>
      </div>
      <div class="my-block line-height-25">
        <div class="my-row round4">
          <el-row>
            <el-col :span="8">配送费</el-col>
            <el-col v-if="order && order.isIntraRegion" :span="16" class="right">
              {{ express_price ? '+ ¥' + formateMoney(express_price) : '已免运费' }}
            </el-col>
            <el-col v-if="!(order && order.isIntraRegion)" v-show="order" :span="16" class="right">
              <span v-if="order && order.address" class="red">不在配送范围</span>
              <span v-if="order && !order.address">请选择配送地址</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">优惠券</el-col>
            <el-col v-if="coupon_money" :span="16" class="right red">
              - ¥{{ formateMoney(coupon_money) }}
            </el-col>
            <el-col v-if="!coupon_money" :span="16" class="right">
              <span v-if="order && order.couponList && order.couponList.length">
                {{ order.couponList.length }}张优惠券
              </span>
              <span v-if="!order || !order.couponList || !order.couponList.length">
                无可用优惠券
              </span>
            </el-col>
          </el-row>
          <el-row v-if="discount_money">
            <el-col :span="8">会员折扣</el-col>
            <el-col :span="16" class="right red">
              - ¥{{ formateMoney(discount_money) }}
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">订单金额</el-col>
            <el-col :span="16" class="right color-ee">
              ¥{{ formateMoney(pay_price) }}
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="my-block line-height-20">
        <div class="my-row round4" @click="setOrderRemark()">
          <el-row>
            <el-col :span="6">订单备注</el-col>
            <el-col :span="18" class="right">
              <div class="text intro" :class="{'align-right': !form.remark }">{{ form.remark || '选填' }}</div>
              <div class="arrow"><i class="iconfont my-right" /></div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div slot="footer" class="footer padding-top8" style="position:absolute;">
      <div class="align-right">
        <span v-if="!emptyCart" class="color-cc">实付</span> <span v-if="!emptyCart" class="red">¥<span class="size20">{{ formateMoney(pay_price) }}</span></span>
        <el-button type="light" class="btn-ok margin-left8 margin-right12" style="max-width:168px;" @click="orderNow()">
          {{ orderId ? '立即支付' : '提交订单' }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { mixins } from '@/mixins/common'
import utils from '@/js/utils'
import GRIDY from '@/js/sdk/GridySDK'
import conf from '@/js/conf/conf'
import service from '@/js/service'
export default {
  components: {},
  mixins: [mixins],
  props: {},
  data() {
    const form = {
      address: {
        addressid: 0,
        name: '',
        phone: '',
        province: '',
        city: '',
        district: '',
        address: ''
      },
      remark: ''
    }
    const data = {}
    data.worksHost = conf.hosts().worksHost
    data.cdnHost = conf.hosts().cdnHost
    data.platform = utils.platform()
    data.whiteBrickNum = conf.whiteBrickNum
    data.brickSkus = conf.brickSkus
    data.form = form
    data.GRIDY = null
    data.goods = {}
    data.hideGoodsIds = []
    data.cart = {}
    data.order = null
    data.personal = null
    data.showSkus = false
    // 商品总金额(不含优惠折扣)
    data.total_price = 0
    data.discount_money = 0
    data.discount = 0
    // 优惠券抵扣金额
    data.coupon_money = 0
    data.coupon_id = 0
    // 订单金额(含优惠折扣)
    data.order_price = 0
    // 运费金额
    data.express_price = 0
    data.delivery_type = 10
    // 实际付款金额(包含运费)
    data.pay_price = 0
    // 预估价
    data.pre_price = 0
    data.line_price = 0
    data.payType = 10
    data.orderId = 0
    data.can_pay = false
    data.pay_data = {}
    data.showSuites = true
    data.selectSuite = false
    data.suites = []
    data.emptyCart = false
    return data
  },
  computed: {
    config() {
      return this.view.orderMng
    },
    table() {
      return 'order'
    },
    act() {
      return this.config.act
    },
    title() {
      return this.view.resourceActs[this.act] + this.view.resourceNames[this.table]
    }
  },
  watch: {},
  mounted() {
    this.GRIDY = new GRIDY()
    this.emit('loading', [true])
    this.initDt()
    this.syncCart()
    this.update()
  },
  methods: {
    hideToggle(item) {
      if (!item.price || this.orderId) return
      item.hide = !item.hide
      this.calcHideGoods(item)
      this.checkOut()
      this.update()
    },
    cartToggle(item) {
      item.addCart = !item.addCart
      if (item.addCart && item.num < 1) item.num = 1
      this.syncCart()
      this.update()
    },
    changeNum(item) {
      item.addCart = item.num > 0
      this.syncCart()
      this.update()
    },
    getPrice(item) {
      if (this.order && this.order.goodsList && this.order.goodsList.length) {
        for (const i in this.order.goodsList) {
          if (this.order.goodsList[i].goods_id === item.goodsId) {
            return this.order.goodsList[i].goods_price * this.order.goodsList[i].total_num * 100
          }
        }
      }
      return item.price
    },
    // 获取结算信息
    checkOut() {
      if (this.orderId) return
      if (this.cart) {
        const cartIds = []
        for (const cartId in this.cart) {
          if (!this.cart[cartId].hide) cartIds.push(cartId)
        }
        if (!cartIds.length) {
          return
        }
        service.checkOut(cartIds, (dt, type) => {
          if (type === 'success') {
            this.personal = dt.data.personal
            this.order = dt.data.order
            if (this.order.existAddress && this.order.address) {
              this.form.address = {
                addressid: this.order.address.id,
                name: this.order.address.name,
                phone: this.order.address.phone,
                province: this.order.address.region.province,
                city: this.order.address.region.city,
                district: this.order.address.region.region,
                address: this.order.address.detail
              }
            }
            this.express_price = this.order.expressPrice * 100
            this.delivery_type = this.order.delivery
            this.total_price = this.order.orderTotalPrice * 100
            this.order_price = this.order.orderPrice * 100
            this.pay_price = this.order.orderPayPrice * 100
            let line_price = 0
            let discount_money = 0
            const suiteGoods = {}
            if (this.suites && this.suites.length) {
              for (const i in this.suites) {
                suiteGoods[this.suites[i].goodsId] = i
              }
            }
            if (this.order.goodsList && this.order.goodsList.length) {
              for (const i in this.order.goodsList) {
                line_price = line_price + this.order.goodsList[i].goods_price * this.order.goodsList[i].total_num * 100
                discount_money = discount_money + this.order.goodsList[i].grade_total_money * 100
                if (!utils.empty(suiteGoods[this.order.goodsList[i].goods_id])) {
                  // 更新价格
                  this.suites[suiteGoods[this.order.goodsList[i].goods_id]].price = this.order.goodsList[i].goods_price * 100
                }
              }
            }
            this.line_price = line_price
            // 保存预估价
            if (!this.pre_price) this.pre_price = line_price
            this.discount_money = discount_money
            this.discount = Math.round((line_price - discount_money) * 100 / line_price) / 10
            this.coupon_money = this.order.couponMoney * 100
            this.coupon_id = this.order.couponId
            // 自动使用优惠券
            if (!this.order.errorMsg && this.order.isIntraRegion && !this.coupon_id && this.order.couponList && this.order.couponList.length) {
              let coupon_id = 0
              let coupon_money = 0
              for (const i in this.order.couponList) {
                if (coupon_money < this.order.couponList[i].reduce_price * 100) {
                  coupon_money = this.order.couponList[i].reduce_price * 100
                  coupon_id = this.order.couponList[i].user_coupon_id
                }
              }
              if (coupon_id) {
                // 自动使用优惠券
                this.coupon_id = coupon_id
                this.checkOut()
              }
            }
            if (!this.order.isIntraRegion && this.order.existAddress && this.order.address) {
              this.alert('很抱歉，您的收货地址不在配送范围内')
            }
            this.update()
          } else {
            this.message(dt, type)
          }
          this.emit('loading', [false])
        }, this.coupon_id)
      }
    },
    // 下单
    orderNow() {
      if (this.emptyCart) {
        this.alert('请选择要购买的商品')
        return
      }
      if (this.can_pay) {
        this.showPayment(this.pay_data, (status) => {
          if (status) {
            this.closePop(this.config)
          }
        })
        return
      }
      if (this.orderId) return
      if (this.cart) {
        const cartIds = []
        for (const cartId in this.cart) {
          if (!this.cart[cartId].hide) cartIds.push(cartId)
        }
        if (!cartIds.length) {
          this.alert('无法下单，请返回后重试！')
          return
        }
        const token = service.getToken()
        if (!token || !token.userId || !token.storeToken || !token.storeToken.userId) {
          this.alert('无法下单，请返回后重试！')
        }
        this.emit('loading', [true])
        const order_uid = token.storeToken.userId
        // 提交定制数据到服务端
        const orderDt = this.config.data[0]
        const suites = []
        for (const i in this.suites) {
          if (this.suites[i].addCart) {
            suites.push(this.suites[i])
          }
        }
        const order = {
          type: 1,
          trade: 2,
          order_uid: order_uid,
          name: orderDt.name,
          info: orderDt.info,
          num: orderDt.num,
          cols: orderDt.cols,
          rows: orderDt.rows,
          colorfyid: orderDt.colorfyId,
          frameid: orderDt.frameId,
          bgid: orderDt.bgId,
          workid: orderDt.workid,
          catalogid: orderDt.work.catalogid,
          publishid: orderDt.work.publishid,
          diy: orderDt.addCart ? 1 : 0,
          suites: suites,
          data: utils.deepClone(orderDt)
        }
        if (!order.diy && suites.length) {
          // 改写 name info
          order.name = suites[0].name
          order.info = suites[0].info
        }
        const run = () => {
          // console.error('xxxx', orderDt, order)
          service.post('brick_order', order, (r, t) => {
            if (t === 'success' && r && r.data && r.data.id) {
              const order_source = 0
              const order_source_id = r.data.id
              const order_source_uid = token.userId
              service.orderNow(cartIds, this.form.remark, (dt, type) => {
                if (type === 'success') {
                  this.orderId = parseInt(dt.data.orderId)
                  this.payType = dt.data.payType
                  const order_id = this.orderId
                  const amount = dt.data.payPrice * 100
                  const order_uid = dt.data.userId
                  const order_sn = dt.data.orderNo
                  service.put('brick_order', order_source_id, { order_uid: order_uid, order_id: order_id, order_sn: order_sn, amount: amount }, (ret, status) => {
                    if (status === 'success') {
                      this.can_pay = true
                      this.pay_data = { pay_status: 0, amount: amount, brick_orderid: order_source_id }
                      this.showPayment(this.pay_data, (status) => {
                        if (status) {
                          this.closePop(this.config)
                        }
                      })
                    } else {
                      this.message(ret, status)
                    }
                    this.emit('loading', [false])
                  }, true)
                } else {
                  this.message(dt, type)
                  this.emit('loading', [false])
                }
              }, this.coupon_id, order_source, order_source_id, order_source_uid)
            } else {
              this.message(r || '无法下单，请返回重试！', t)
              this.emit('loading', [false])
            }
          }, true)
        }
        order.image = order.data.imageUrl
        delete order.data.imageUrl
        const file = utils.blobToFile(utils.base64ToBlob(order.image), utils.uuid() + '.png')
        service.upload(file, 'order', this.loginUserId, (dt, type) => {
          if (type === 'success') {
            order.image = dt
            run()
          } else {
            this.message(dt, type)
            this.emit('loading', [false])
          }
        })
      }
    },
    showPayment(order, cb) {
      this.view.pop.title = '支付'
      this.view.pop.type = 'payment_brick'
      this.view.pop.data = order || {}
      this.view.pop.show = true
      this.view.pop.fn = cb
    },
    // 同步购物车
    syncCart() {
      if (this.orderId) return
      if (utils.isArray(this.config.data)) {
        const goods = []
        for (const i in this.config.data) {
          const nums = this.config.data[i].num
          if (this.config.data[i].addCart) {
            if (this.config.data[i].isSuite) {
              goods.push({ goodsId: this.config.data[i].goodsSuite.goodsId, goodsNum: nums * this.config.data[i].goodsSuite.num, goodsSkuId: '0' })
              for (const j in this.config.data[i].goodsBricks) {
                goods.push({ goodsId: this.config.data[i].goodsBricks[j].goodsId, goodsNum: nums * this.config.data[i].goodsBricks[j].num, goodsSkuId: '0' })
              }
            } else {
              goods.push({ goodsId: this.config.data[i].frame.goodsId, goodsNum: nums * this.config.data[i].frame.num, goodsSkuId: '0' })
              for (const j in this.config.data[i].bricks) {
                goods.push({ goodsId: this.config.data[i].bricks[j].goodsId, goodsNum: nums * this.config.data[i].bricks[j].num, goodsSkuId: '0' })
              }
            }
          }
        }
        for (const i in this.suites) {
          const suite = this.suites[i]
          if (suite.addCart) {
            goods.push({ goodsId: suite.goodsId, goodsNum: suite.num, goodsSkuId: '0' })
          }
        }
        this.emptyCart = goods.length === 0
        if (goods.length) {
          service.syncCart(goods, (dt, type) => {
            if (type === 'success') {
              this.cart = dt.data.cart
              this.calcHideGoods()
              this.checkOut()
            } else {
              this.message(dt, type)
              this.closePop(this.config)
              this.emit('loading', [false])
            }
          })
        } else {
          this.alert('请选择要购买的商品')
        }
      }
    },
    // 计算隐藏商品
    calcHideGoods(item) {
      if (this.orderId) return
      const hideGoodsIds = []
      if (utils.isArray(this.config.data)) {
        for (const i in this.config.data) {
          const hideColors = []
          if (this.config.data[i].frame.hide) hideGoodsIds.push(this.config.data[i].frame.goodsId)
          for (const j in this.config.data[i].bricks) {
            if (this.config.data[i].bricks[j].hide) {
              hideGoodsIds.push(this.config.data[i].bricks[j].goodsId)
              hideColors.push(this.config.data[i].bricks[j].color)
            }
          }
          this.config.data[i].hideColors = hideColors
        }
      }
      if (this.cart) {
        let selectGoods = 0
        for (const cartId in this.cart) {
          this.cart[cartId].hide = hideGoodsIds.indexOf(this.cart[cartId].goods_id) >= 0
          if (!this.cart[cartId].hide) selectGoods++
        }
        if (!selectGoods && item) {
          item.hide = false
        }
      }
      this.hideGoodsIds = hideGoodsIds
    },
    // 计算订单金额
    calcPrice() {
      this.total_price = 0
      if (utils.isArray(this.config.data)) {
        for (const i in this.config.data) {
          let price = 0
          if (!this.config.data[i].frame.hide && this.config.data[i].frame.price) price = this.config.data[i].frame.price
          for (const j in this.config.data[i].bricks) {
            if (!this.config.data[i].bricks[j].hide) {
              price = price + this.config.data[i].bricks[j].price
            }
          }
          // 商品总金额(不含优惠折扣)
          this.config.data[i].total_price = price
          this.total_price = this.total_price + price
        }
      }
    },
    isEmpty(val) {
      return utils.empty(val)
    },
    initDt() {
      let emptyCart = true
      const suiteColorfy = {
        'classic': true,
        'golden': true,
        'dance': true
      }
      if (utils.isArray(this.config.data)) {
        for (const i in this.config.data) {
          const ret = this.calcSkus(this.config.data[i].name, this.config.data[i].work, this.config.data[i].colorfyId, this.config.data[i].hideColors, this.config.data[i].frameId)
          emptyCart = false
          this.config.data[i].addCart = true
          this.config.data[i].name = ret.name
          this.config.data[i].info = ret.info
          this.config.data[i].cols = ret.cols
          this.config.data[i].rows = ret.rows
          this.config.data[i].frame = ret.frame
          this.config.data[i].colors = ret.colors
          this.config.data[i].bricks = ret.bricks
          this.config.data[i].goodsSuite = ret.goodsSuite
          this.config.data[i].goodsBricks = ret.goodsBricks
          this.config.data[i].num = this.config.data[i].num || 1
          this.config.data[i].workid = this.config.data[i].work.workid || 0
          // if (this.config.data[i].isSuite && ret.cols === 48 && ret.rows === 48 && this.config.data[i].colorfyId) {
          //   // 剔除相同套装
          //   delete suiteColorfy[this.config.data[i].colorfyId]
          // }
        }
      }
      const suites = []
      for (const colorfyId in suiteColorfy) {
        const goods = this.GRIDY.matchGoods(48, 48, [], colorfyId).goodsSuite
        if (emptyCart) {
          emptyCart = false
          goods.addCart = true
        } else {
          goods.addCart = false
        }
        suites.push(goods)
      }
      this.suites = suites
    },
    calcSkus(name, work, colorfyId, hideColors, frameId) {
      name = '定制拼图《' + (name || work.name) + '》'
      this.GRIDY.setFile(work)
      const colors = this.getColors(colorfyId, hideColors)
      const bricks = this.GRIDY.getBricks(colors)
      const obj = this.GRIDY.getObj(0, 0, -1)
      const pictureFrame = this.GRIDY.calcFrameRatio(obj.cols, obj.rows, frameId)
      const cols = pictureFrame.cols
      const rows = pictureFrame.rows
      const frame = { name: pictureFrame.name, goodsId: pictureFrame.goodsId, skuId: pictureFrame.skuId, num: pictureFrame.num, price: pictureFrame.price, cols: cols, rows: rows, frameId: frameId, ratioId: pictureFrame.ratioId, hide: pictureFrame.hide }
      const goods = this.GRIDY.matchGoods(cols, rows, colors, colorfyId)
      // console.error('calcSkus', this.config.data, { goodsSuite: goods.goodsSuite, goodsBricks: goods.goodsBricks, colors: colors, bricks: bricks, frame: frame, name: name, info: pictureFrame.name, cols: cols, rows: rows })
      return { goodsSuite: goods.goodsSuite, goodsBricks: goods.goodsBricks, colors: colors, bricks: bricks, frame: frame, name: name, info: pictureFrame.name, cols: cols, rows: rows }
    },
    // 获取颜色数据
    getColors(colorfyId, hideColors, orderby = 'asc') {
      if (!utils.isArray(hideColors)) hideColors = []
      const colorsObj = this.GRIDY.calcObjColors(0, 0, -1, '#FFFFFF', colorfyId)
      let colors = []
      if (colorsObj) {
        for (let color in colorsObj) {
          if (color) {
            color = color.toUpperCase()
            colors.push([color, colorsObj[color], conf.brickMap[color] || conf.brickDt[color], hideColors.indexOf(color) >= 0])
          }
        }
      }
      colors = utils.sortArr(colors, 1)
      if (orderby === 'asc') {
        colors = colors.reverse()
      }
      return colors
    },
    // 打开选择器
    openSelecter(config) {
      if (this.orderId) return
      config.fn = (items) => {
        if (config.table === 'address') {
          if (items && items[0]) {
            this.form.address = utils.deepClone(items[0])
            this.form.address.isdefault = 1
            service.syncAddress(this.form.address, (dt, type) => {
              if (type === 'success') this.checkOut()
            })
          }
        }
      }
      this.emit('openResourceSelecter', [config])
    },
    setOrderRemark() {
      if (this.orderId) return
      this.view.pop.title = '订单备注'
      this.view.pop.placeholder = ''
      this.view.pop.content = this.form.remark || ''
      this.view.pop.classname = ''
      this.view.pop.maxlength = 100
      this.view.pop.rows = 3
      this.view.pop.type = ''
      this.view.pop.data = {}
      this.view.pop.show = true
      this.view.pop.loading = false
      this.view.pop.fn = () => {
        this.form.remark = this.view.pop.content
        this.view.pop.fn = null
        this.view.pop.show = false
      }
    },
    handle(act, data) {
      this.emit(act, data)
    },
    formateTime: utils.formateTime,
    formateNums: utils.formateNums,
    formateMoney: utils.formateMoney
  }
}
</script>
