<template>
  <div class="my-container add-new">
    <el-drawer :visible.sync="view.addNew.show" direction="btt" size="12%" :show-close="false" custom-class="add-new">
      <el-col :span="8">
        <div @click="openEditer">
          <i class="iconfont my-gridsfy" />
          <div>创作像素画</div>
        </div>
      </el-col>
      <el-col :span="8">
        <div @click="openPopPage('toBrickfy')">
          <i class="iconfont my-avatar" />
          <div>定制拼图</div>
        </div>
      </el-col>
      <!-- <el-col :span="6">
        <div @click="openPopPage('toPixel')">
          <i class="iconfont my-avatar" />
          <div>AI像素画</div>
        </div>
      </el-col>
      <el-col :span="4">
        <div @click="openPopPage('toCartoon')">
          <i class="iconfont my-cartoon" />
          <div>AI动漫</div>
        </div>
      </el-col>
      <el-col :span="4">
        <div @click="openAiPaint">
          <i class="iconfont my-ai-paint" />
          <div>AI作画</div>
        </div>
      </el-col> -->
      <el-col :span="8">
        <div @click="openPost">
          <i class="iconfont my-post-add" />
          <div>发布主题</div>
        </div>
      </el-col>
      <!-- <el-col :span="6">
        <span @click="message('敬请期待', 'info')">
          <i class="iconfont my-voxel gray" />
          <div class="gray">体素模型</div>
        </span>
      </el-col> -->
    </el-drawer>
  </div>
</template>
<script>
import { mixins } from '@/mixins/common'
export default {
  components: {},
  mixins: [mixins],
  methods: {
    handle(act, data) {
      this.emit(act, data)
    },
    openPopPage(type) {
      this.view.addNew.show = false
      this.emit('openPopPage', [{ 'type': type }])
    },
    openPost() {
      this.view.addNew.show = false
      this.emit('openPost')
    },
    openEditer() {
      this.view.addNew.show = false
      // this.view.createNewfile = true
      this.goto('editer', [])
    },
    openAiPaint() {
      this.view.addNew.show = false
      this.emit('openAiPaint', [])
    }
  }
}
</script>
