/**
 * @author funfly
 * @mail 389193@qq.com
 * @date 2022-1-29
 * @copyright Gridy.Art
 */
const steps = [
  {
    element: '.my-menu',
    popover: {
      title: '开始',
      description: '在"文件"中选择"新建..."',
      position: 'bottom'
    }
  },
  {
    element: '.my-link-add',
    popover: {
      title: '导入网络图片',
      description: '通过网址导入图片后，将自动生成网格画',
      position: 'left'
    }
  },
  {
    element: '.my-object-add',
    popover: {
      title: '导入本地图片',
      description: '选择导入本地图片后，将自动生成网格画',
      position: 'left'
    }
  },
  {
    element: '.my-blank-object-add',
    popover: {
      title: '添加空对象',
      description: '添加空对象后可以自由创作',
      position: 'left'
    }
  },
  {
    element: '.left-side',
    popover: {
      title: '选择工具',
      description: '请选择作图工具进行艺术创作',
      position: 'right'
    }
  },
  {
    element: '.work-area',
    popover: {
      title: '开始艺术创作',
      description: '单击左键或右键开始作画，也可以按住左键或右键连续作画',
      position: 'right'
    }
  }
]

export default steps
