<template>
  <div v-show="!state.play" class="headbar">
    <div class="float-left align-left" style="width: 160px;padding-left: 2px;max-width: calc(100vw - 250px);">
      <el-button class="iconfont my-close" @click="emit('goto', ['', '', '', true])" />
    </div>
    <div class="float-left" style="width: calc(100vw - 320px);min-width: 80px;">
      <el-button class="iconfont my-cancel" :disabled="!btnStatus.back" @click="emit('back')" />
      <el-button class="iconfont my-redo" :disabled="!btnStatus.redo" @click="emit('redo')" />
    </div>
    <div class="float-right" style="width: 160px;">
      <span class="color-44">|</span>
      <el-popover v-model="layerPopoverShow" placement="bottom" width="260" popper-class="layer-popover" trigger="click">
        <div class="layer-mng objs-div">
          <div class="my-title-bar">
            <el-col :span="12">
              <div class="margin-top4">
                图层对象
              </div>
            </el-col>
            <el-col :span="12" style="text-align: right">
              <el-button class="iconfont my-blank-object-add" title="添加空对象" @click="emit('addBlankObj', [false])" />
              <el-button class="iconfont my-object-add" title="添加本地图片" @click="() => emit('openLocalImages', ['import'])" />
              <el-button class="iconfont my-link-add" title="添加网络图片" @click="() => emit('openWebImage', ['import'])" />
            </el-col>
          </div>
          <div v-if="view.objs" :style="objAreaStyle">
            <div v-for="(obj, idx) in curObjs" :key="obj.id" class="obj-div" :class="getObjClass(obj.id, '')">
              <div class="obj-img" :style="{ background: 'url(' + bgImg4 + ')' }" @click="(event) => emit('select', [obj.id, '', idx, -1, event.shiftKey])">
                <obj-preview
                  :key="'obj-preview:' + state.sceneId + ':' + obj.id"
                  :state="state"
                  :file-type="file.type"
                  :idx="idx"
                  :obj="obj"
                />
              </div>
              <div class="obj-info padding-left4">
                <div><edit-text :val="obj.name" title="编辑对象名称" @receive="(val) => (obj.name = val)" /></div>
                <div class="color-99" @click="(event) => emit('select', [obj.id, '', idx, -1, event.shiftKey])">{{ obj.cols }} x {{ obj.rows }}</div>
              </div>
              <div class="obj-btn">
                <el-button v-if="obj.show" class="iconfont my-eye-open" title="隐藏" @click="emit('show', [idx])" />
                <el-button v-if="!obj.show" class="iconfont my-eye-close" title="显示" @click="emit('show', [idx])" />
                <el-dropdown trigger="click" placement="bottom-start" size="medium" @command="(e) => emit('handle', [e, [idx]])">
                  <el-button class="iconfont my-dot-more-v" title="更多" @click="(event) => emit('select', [obj.id, '', idx, -1, event.shiftKey])" />
                  <el-dropdown-menu slot="dropdown" class="my-dropdown">
                    <el-dropdown-item v-if="!obj.lock" command="lockSelectObjs" class="iconfont my-lock"> 锁定</el-dropdown-item>
                    <el-dropdown-item v-if="obj.lock" command="unlockSelectObjs" class="iconfont my-unlock"> 解锁</el-dropdown-item>
                    <el-dropdown-item :disabled="!btnStatus.copy" command="copyAndParse" class="iconfont my-copyparse"> 拷贝</el-dropdown-item>
                    <el-dropdown-item :disabled="!btnStatus.del" command="del" class="iconfont my-delete"> 删除</el-dropdown-item>
                    <el-dropdown-item :disabled="!btnStatus.clear" command="clear" class="iconfont my-clear"> 清除</el-dropdown-item>
                    <el-dropdown-item :disabled="!btnStatus.restore" command="restore" class="iconfont my-restore"> 恢复</el-dropdown-item>
                    <el-dropdown-item v-if="idx < curObjs.length - 1" command="mergeDown" :disabled="!btnStatus.mergeDown" class="iconfont my-mergedown"> 向下合并</el-dropdown-item>
                    <el-dropdown-item v-if="idx < curObjs.length - 1" command="layDown" :disabled="!btnStatus.layDown" class="iconfont my-lay-down"> 下移一层</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
            <div class="obj-div">
              <div class="obj-img">
                <canvas ref="canvasGrid" />
              </div>
              <div class="obj-info padding-top12 padding-left4">
                网格
              </div>
              <div class="obj-btn">
                <el-button v-if="canvas.showGrid" class="iconfont my-eye-open" title="隐藏" @click="emit('setCanvasAttr', ['showGrid', false])" />
                <el-button v-if="!canvas.showGrid" class="iconfont my-eye-close" title="显示" @click="emit('setCanvasAttr', ['showGrid', true])" />
                <el-button class="iconfont" :style="{'background': canvas.gridColor + '!important', 'border': '1px solid #444!important'}" @click="(event) => {emit('toggleColorPicker', [event, 'canvas-gridcolor'])}" />
              </div>
            </div>
            <div class="obj-div">
              <div class="obj-img">
                <div class="box40" :style="{'background': canvas.bgColor}" />
              </div>
              <div class="obj-info padding-top12 padding-left4">
                背景
              </div>
              <div class="obj-btn">
                <el-button v-if="canvas.showBg" class="iconfont my-eye-open" title="隐藏" @click="emit('setCanvasAttr', ['showBg', false])" />
                <el-button v-if="!canvas.showBg" class="iconfont my-eye-close" title="显示" @click="emit('setCanvasAttr', ['showBg', true])" />
                <el-button class="iconfont" :style="{'background': canvas.bgColor + '!important', 'border': '1px solid #444!important'}" @click="(event) => {emit('toggleColorPicker', [event, 'canvas-bgcolor'])}" />
              </div>
            </div>
          </div>
        </div>
        <el-button slot="reference" class="iconfont my-lay-bottom" />
      </el-popover>
      <el-popover v-model="setPopoverShow" placement="bottom" width="260" trigger="click">
        <el-row>
          <el-col :span="8">创建时间</el-col>
          <el-col :span="16" class="align-right color-99">{{ file.createTime }}</el-col>
        </el-row>
        <el-row>
          <el-col :span="8">画布尺寸</el-col>
          <el-col :span="16" class="align-right color-99">{{ canvas.cols + ' x ' + canvas.rows }}</el-col>
        </el-row>
        <el-row>
          <el-col :span="8">创作耗时</el-col>
          <el-col :span="16" class="align-right color-99">{{ consumeTime() }}</el-col>
        </el-row>
        <el-row class="popover-set-btns">
          <el-col :span="6"><el-button class="iconfont my-full-canvas" @click="emit('autoCanvasSize')" /><div class="size10">符合画布</div></el-col>
          <el-col :span="6"><el-button class="iconfont my-canvas" @click="emit('openSetCanvasSize')" /><div class="size10">画布调整</div></el-col>
          <el-col :span="6"><el-button class="iconfont my-bar-left" :class="{'blue': !view.rightToolBar}" @click="view.rightToolBar = false" /><div class="size10">左侧界面</div></el-col>
          <el-col :span="6"><el-button class="iconfont my-bar-right" :class="{'blue': view.rightToolBar}" @click="view.rightToolBar = true" /><div class="size10">右侧界面</div></el-col>
        </el-row>
        <el-button slot="reference" class="iconfont my-set-up" />
      </el-popover>
      <el-button class="iconfont my-material" @click="emit('openDraft', ['draft'])" />
      <el-dropdown trigger="click" placement="bottom-start" @command="(e) => selectOpt(e)">
        <el-button class="iconfont my-out" />
        <el-dropdown-menu slot="dropdown" class="my-dropdown">
          <el-dropdown-item command="openCreateNewFile"> <i class="iconfont my-file" /> 新建...</el-dropdown-item>
          <el-dropdown-item command="save"> <i class="iconfont my-save-as" /> 保存</el-dropdown-item>
          <el-dropdown-item command="saveAs"> <i class="iconfont my-save-as" /> 另存为...</el-dropdown-item>
          <el-dropdown-item command="saveRemote"> <i class="iconfont my-cloud-sync" /> 同步到云端</el-dropdown-item>
          <el-dropdown-item command="rename"> <i class="iconfont my-rename" /> 重命名</el-dropdown-item>
          <el-dropdown-item command="exportPixel" divided> <div class="padding-top4 padding-bottom4"><i class="iconfont my-export-out" />导出像素画</div></el-dropdown-item>
          <el-dropdown-item command="exportGridy"> <div class="padding-top4 padding-bottom4"><i class="iconfont my-export-out" />导出百格画</div></el-dropdown-item>
          <el-dropdown-item command="exportBrick"> <div class="padding-top4 padding-bottom4"><i class="iconfont my-export-out" />导出拼图...</div></el-dropdown-item>
          <el-dropdown-item command="exportPreview"> <div class="padding-top4 padding-bottom4"><i class="iconfont my-export-out" />导出预览...</div></el-dropdown-item>
          <el-dropdown-item command="diyBrick" divided> <div class="padding-top4 padding-bottom4"><i class="iconfont my-diy" />定制拼图...</div></el-dropdown-item>
          <!-- <el-dropdown-item command="openMywork" divided> <div class="padding-top4 padding-bottom4"><i class="iconfont my-pixel" /> 我的作品</div></el-dropdown-item> -->
          <!-- <el-dropdown-item command="openAiPaint" divided> <div class="padding-top4 padding-bottom4"><i class="iconfont my-ai-paint" /> AI作画</div></el-dropdown-item> -->
          <el-dropdown-item command="publishWork" divided> <i class="iconfont my-zone" /> 发布......</el-dropdown-item>
          <el-dropdown-item command="copyShareUrl"> <i class="iconfont my-share-out" /> 复制分享链接</el-dropdown-item>
          <el-dropdown-item command="createPOP"> <i class="iconfont my-pop" /> 生成分享图</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
import { mixins } from '@/mixins/common'
import utils from '@/js/utils'
import editText from '@/components/editText'
import objPreview from '@/components/objPreview'
import bgImg4 from '@/assets/canvas_bg/bg_4.png'
export default {
  components: {
    editText,
    objPreview
  },
  mixins: [mixins],
  props: {
    btnStatus: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return { setPopoverShow: false, layerPopoverShow: false, bgImg4: bgImg4 }
  },
  computed: {
    // 对象区域样式
    objAreaStyle() {
      let factor = 1
      if (!this.isDesktop) {
        factor = 2
      }
      const rows = this.curObjs.length + 2
      return { width: '100%', height: (rows < 3 * factor ? rows * 52 : 156 * factor) + 'px', 'overflow-x': 'hidden', 'overflow-y': 'auto' }
    },
    // 画布背景颜色
    canvasBgColor() {
      return this.canvas.showBg ? this.canvas.bgColor : ''
    },
    // 网格边框颜色
    gridColor() {
      return this.canvas.showGrid ? this.canvas.gridColor : ''
    }
  },
  watch: {
    'file.canvas.gridColor': {
      handler(val) {
        this.drawCanvasGrid()
      }
    }
  },
  methods: {
    selectOpt(act) {
      if (act === 'exportPixel' || act === 'exportGridy' || act === 'exportBrick' || act === 'exportAdvance') {
        const opts = { gridSize: this.state.gridSize, brickfy: this.state.brickfy }
        if (act === 'exportBrick') {
          opts.workTypeid = 2
          opts.paletteId = 'brickfy'
          opts.brickfy = true
        }
        this.emit('showExportSheet', [this.file, act, opts, this.state.sceneIdx])
      } else if (act === 'openMywork') {
        this.emit('openResource', ['work'])
      } else if (act === 'openAiPaint') {
        this.emit('openResource', ['paint'])
      } else if (act === 'diyBrick') {
        this.emit('diy', ['brick'])
      } else {
        this.emit(act, [])
      }
    },
    consumeTime() {
      return utils.getCountDown((new Date() - new Date(this.file.createTime)) / 1000)
    },
    // 绘制网格
    drawCanvasGrid() {
      const canvasGrid = this.$refs.canvasGrid
      if (!canvasGrid) return
      const canvasGridCtx = canvasGrid.getContext('2d')
      canvasGrid.width = 41
      canvasGrid.height = 41
      canvasGridCtx.clearRect(0, 0, canvasGrid.width, canvasGrid.height)
      canvasGridCtx.fillStyle = this.canvas.gridColor || '#242424'
      canvasGridCtx.fillRect(0, 0, canvasGrid.width, canvasGrid.height)
      let x
      let y
      const gridSize = 5
      for (x = 0; x < 8; x++) {
        for (y = 0; y < 8; y++) {
          canvasGridCtx.clearRect(x * gridSize + 1, y * gridSize + 1, gridSize - 1, gridSize - 1)
        }
      }
    },
    // 获取对象样式Class
    getObjClass(id, parentId) {
      let k
      if (parentId) {
        k = 'sub-obj:' + this.state.sceneId + ':' + parentId + ':' + id
      } else {
        k = 'obj:' + this.state.sceneId + ':' + id
      }
      return this.state.selectObjs[k] ? 'select' : ''
    }
  }
}
</script>
