<template>
  <div v-show="config.show" v-finger:swipe="onSwipe" class="my-container">
    <div v-show="!isDesktop" class="header">
      <div class="left">
        &nbsp;
      </div>
      <div class="middle">
        <el-button-group>
          <el-button size="small" :type="'plain'">圈子</el-button>
          <el-button size="small" :type="'primary'" @click="goto('discover')">发现</el-button>
        </el-button-group>
      </div>
      <div class="right" />
    </div>
    <div class="tabs" :style="isDesktop ? 'top: 4px' : ''">
      <el-col :span="16">
        <el-tabs v-model="config.type">
          <el-tab-pane v-for="(type, idx) in config.types" :key="idx" :label="type[1]" :name="type[0]" />
        </el-tabs>
      </el-col>
      <el-col :span="8" class="filter">
        <el-button class="tap-btn" @click="emit('openSift', ['zone'])">
          {{ config.catalogName }}
          <i class="iconfont my-filter" />
        </el-button>
      </el-col>
    </div>
    <thread-list
      v-show="config.show && config.type === 'new'"
      ref="zone-new"
      mod="new"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <thread-list
      v-show="config.show && config.type === 'best'"
      ref="zone-best"
      mod="best"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
  </div>
</template>
<script>
import threadList from '@/components/web/threadList'
import { mixins } from '@/mixins/common'
export default {
  components: {
    threadList
  },
  mixins: [mixins],
  data() {
    const data = {}
    data.swipeAds = []
    return data
  },
  computed: {
    config() {
      return this.view.zone
    }
  },
  methods: {
    handle(act, data) {
      this.emit(act, data)
    },
    onSwipe(evt) {
      this.switchTab(this.config, evt.direction)
    },
    renewView() {
      const el = this.$refs['zone-' + this.config.type]
      if (el) el.renewView()
    }
  }
}
</script>
