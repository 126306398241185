<template>
  <el-dialog :title="title" :visible.sync="config.show" :fullscreen="platform.type !== 'desktop'" :top="marginTop" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" custom-class="pop_page header_40 selecter resourceSelecterDialog">
    <div slot="title" style="height: 26px;">
      <div class="title-btn back size16 padding-top4 padding-left4" @click="closePop(config)"><span class="color-99">取消</span></div>
      <span class="el-dialog__title">{{ title }}</span>
      <div class="title-btn size16" @click="selectItems()"><span :class="{'color-99': !canSelect, 'blue': canSelect}">确定</span></div>
    </div>
    <div :class="{'my-container': platform.type !== 'desktop'}">
      <resource-list
        v-show="config.show && config.type === 'selecter'"
        ref="resource-selecter"
        mod="selecter"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        @receive="handle"
      />
    </div>
  </el-dialog>
</template>
<script>
import resourceList from '@/components/web/resourceList'
import { mixins } from '@/mixins/common'
import utils from '@/js/utils'
export default {
  components: {
    resourceList
  },
  mixins: [mixins],
  data() {
    return {
      platform: utils.platform(),
      canSelect: false,
      selectMod: false,
      selectIds: {}
    }
  },
  computed: {
    config() {
      return this.view.resourceSelecter
    },
    table() {
      return this.config.table
    },
    act() {
      return this.config.act
    },
    keyfield() {
      return this.config.keyfield || this.table + 'id'
    },
    title() {
      return this.view.resourceNames[this.table] ? ('选择' + this.view.resourceNames[this.table]) : '请选择'
    }
  },
  watch: {
    // 'view.resourceSelecter.show': {
    //   handler() {
    //     setTimeout(() => {
    //       this.init()
    //       this.initView()
    //     }, 10)
    //   }
    // },
    'view.resourceSelecter.type': {
      handler() {
        this.init()
      }
    },
    'view.resourceSelecter.renew': {
      handler() {
        this.renewView()
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    handle(act, data) {
      if (act === 'setSelectData') {
        this.selectMod = data[0]
        this.selectIds = data[1]
        this.canSelect = this.selectMod && Object.keys(this.selectIds).length > 0
        this.update()
      } else if (act === 'cancelSelect') {
        this.cancelSelect()
      } else {
        this.emit(act, data)
      }
    },
    init() {
      this.canSelect = false
      this.selectMod = false
      this.selectIds = {}
    },
    renewView() {
      this.$refs['resource-' + this.config.type].renewView()
    },
    initView() {
      this.$refs['resource-' + this.config.type].init()
    },
    cancelSelect() {
      this.$refs['resource-' + this.config.type].cancelSelect()
      this.init()
    },
    getSelectItems() {
      return this.$refs['resource-' + this.config.type].getSelectItems()
    },
    selectItems() {
      if (this.canSelect) {
        const items = this.getSelectItems()
        this.config.fn && this.config.fn(items)
        this.closePop(this.config)
      }
      this.cancelSelect()
    },
    addResource() {
      this.openResourceMng({ 'table': this.table, 'act': 'add' })
    }
  }
}
</script>
