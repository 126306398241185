import conf from '@/js/conf/conf'
import PullTo from 'vue-pull-to'
import emptyImage from '@/assets/empty.png'
import utils from '@/js/utils'
import service from '@/js/service'
import countDown from '@/components/countDown'
export const mixins = {
  components: {
    PullTo,
    countDown
  },
  props: {
    view: {
      type: Object,
      default() {
        return {}
      }
    },
    state: {
      type: Object,
      default() {
        return {}
      }
    },
    db: {
      type: Object,
      default() {
        return {}
      }
    },
    file: {
      type: Object,
      default() {
        return {}
      }
    },
    mod: {
      type: String,
      default() {
        return 'new'
      }
    },
    params: {
      type: Object,
      default() {
        return {}
      }
    },
    thumbSize: {
      type: Number,
      default: 320
    },
    pageSize: {
      type: Number,
      default: 8
    },
    loginStatus: {
      type: Boolean,
      default: false
    },
    loginUserId: {
      type: Number,
      default: 0
    },
    subtractHeight: {
      type: Number,
      default: 0
    },
    isPop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const catalogs = utils.deepClone(conf.catalogs)
    const schema = {
      scrollTop: 0,
      // 瀑布流各列数据
      columns: [],
      items: [],
      horizWidth: 0,
      currentPage: 0,
      cursorValue: 0,
      cursorValueUp: 0,
      cursorValueDown: 0,
      cursorSkipUp: 0,
      cursorSkipDown: 0,
      skip: 0,
      noUpPage: false,
      noDownPage: false
    }
    let container_width, container_height
    if (this.state.platform.type === 'desktop' && this.isPop) {
      container_width = 632
      container_height = 632
    } else {
      container_width = utils.width()
      container_height = utils.height()
    }
    const data = {
      worksHost: conf.hosts().worksHost,
      container_width: container_width,
      container_height: container_height,
      width: container_width - 3,
      height: container_height - 95,
      // 限制缩略图尺寸
      maxThumbWidth: 240,
      maxThumbHeight: 240,
      renew: false,
      fixHeight: 70,
      // 达到保留页数时，重新渲染列表
      remainPages: 100,
      showUp: false,
      userid: 0,
      seriesid: 0,
      series: {},
      works: {},
      nfts: {},
      tradeState: {},
      emptyImage: emptyImage,
      catalogs: catalogs,
      schema: schema,
      dt: {
        'publish': utils.deepClone(schema),
        'preorder': utils.deepClone(schema),
        'paradrop': utils.deepClone(schema),
        'compound': utils.deepClone(schema),
        'calendar': utils.deepClone(schema),
        'user': utils.deepClone(schema),
        'userPrivate': utils.deepClone(schema),
        'praise': utils.deepClone(schema),
        'favor': utils.deepClone(schema),
        'footprint': utils.deepClone(schema),
        'download': utils.deepClone(schema),
        'series': utils.deepClone(schema),
        'index': utils.deepClone(schema)
      },
      loading: {
        'publish': false,
        'preorder': false,
        'paradrop': false,
        'compound': false,
        'calendar': false,
        'user': false,
        'userPrivate': false,
        'praise': false,
        'favor': false,
        'footprint': false,
        'download': false,
        'series': false,
        'index': false
      }
    }
    return data
  },
  computed: {
    isMobile() {
      return this.state.platform.type === 'mobile'
    },
    reqId() {
      return this.state.reqId
    },
    thumbWidth() {
      if (this.isPop) {
        return 280
      } else {
        return this.thumbSize
      }
    },
    isDesktop() {
      return this.state.platform.type === 'desktop'
    },
    simple() {
      return this.mod === 'user' || this.mod === 'userPrivate'
    },
    maxColumns() {
      if (this.mod === 'index' && this.state.platform.type !== 'mobile') {
        return Math.min(this.limit, parseInt(this.width / this.thumbSize))
      } else {
        return Math.max(Math.floor(this.width / (this.thumbWidth + 10)), 1)
      }
    },
    columnWidth() {
      return Math.round(this.width / this.maxColumns)
    },
    cardWidth() {
      return this.columnWidth - 10
    },
    // 瀑布流高度
    flowHeight() {
      let height = 0
      for (let i = 0; i < this.columns.length; i++) {
        const its = this.columns[i].items
        const colHeight = its[its.length - 1]._height + its[its.length - 1]._top
        if (colHeight > height) height = colHeight
      }
      return height
    },
    headHeight() {
      return (!this.isDesktop && this.config.showHead) ? 40 : 0
    },
    listStyle() {
      if (this.subtractHeight) return { 'height': 'calc(100% - ' + this.subtractHeight + 'px)', 'margin-top': '5px' }
      return { 'height': 'calc(100% - ' + (45 + this.headHeight) + 'px)', 'margin-top': (45 + this.headHeight) + 'px' }
    },
    config() {
      return this.view.nft
    },
    users() {
      return this.state.users
    },
    data() {
      return this.dt[this.mod]
    },
    itemNums() {
      return this.data.items.length
    },
    columns() {
      return this.data ? this.data.columns : []
    },
    limit() {
      let limit = this.pageSize
      // 非首页及非移动端时分页数量X2
      if (!this.isMobile && this.mod !== 'index') limit = limit * 2
      if (this.mod === 'index' && this.state.platform.type !== 'mobile') {
        return Math.min(limit, parseInt(this.width / this.thumbSize))
      }
      return limit
    }
  },
  watch: {
    'view.praise.show': {
      handler() {
        if (this.view.praise.show && this.mod === 'praise' && this.view.praise.type === 'nft') this.updateData()
      }
    },
    'view.praise.type': {
      handler() {
        if (this.view.praise.show && this.mod === 'praise' && this.view.praise.type === 'nft') this.updateData(true)
      }
    },
    'view.favor.show': {
      handler() {
        if (this.view.favor.show && this.mod === 'favor' && this.view.favor.type === 'nft') this.updateData()
      }
    },
    'view.favor.type': {
      handler() {
        if (this.view.favor.show && this.mod === 'favor' && this.view.favor.type === 'nft') this.updateData(true)
      }
    },
    'view.footprint.show': {
      handler() {
        if (this.view.footprint.show && this.mod === 'footprint' && this.view.footprint.type === 'nft') this.updateData()
      }
    },
    'view.footprint.type': {
      handler() {
        if (this.view.footprint.show && this.mod === 'footprint' && this.view.footprint.type === 'nft') this.updateData(true)
      }
    },
    'view.index.show': {
      handler() {
        if (this.view.index.show && this.mod === 'index') this.updateData()
      }
    },
    'view.download.show': {
      handler() {
        if (this.view.download.show && this.mod === 'download' && this.view.download.type === 'nft') this.updateData()
      }
    },
    'view.download.type': {
      handler() {
        if (this.view.download.show && this.mod === 'download' && this.view.download.type === 'nft') this.updateData(true)
      }
    },
    'view.seriesWorks.show': {
      handler() {
        if (this.view.seriesWorks.show && this.mod === 'series' && this.view.seriesWorks.seriesid !== this.seriesid) this.updateData()
      }
    },
    'view.user.show': {
      handler() {
        if (this.view.user.show && ((this.mod === 'user' && this.view.user.type === 'nft') || (this.mod === 'userPrivate' && this.view.user.type === 'nftPrivate'))) {
          if ((this.view.user.userid && this.userid !== this.view.user.userid) || !this.itemNums) {
            if (this.userid !== this.view.user.userid) this.init()
            this.getData('down')
          }
          this.update()
        }
      }
    },
    'view.user.type': {
      handler() {
        if (this.view.user.show && ((this.mod === 'user' && this.view.user.type === 'nft') || (this.mod === 'userPrivate' && this.view.user.type === 'nftPrivate'))) {
          if (!this.view.user.pop) this.emit('setLocationHash')
          if ((this.view.user.userid && this.userid !== this.view.user.userid) || !this.itemNums) {
            if (this.userid !== this.view.user.userid) this.init()
            this.getData('down')
          }
          this.update()
        }
      }
    },
    'view.nft.show': {
      handler(show) {
        if (this.config.type === this.mod && show) {
          if (!this.itemNums) this.getData('down')
          this.update()
          this.deleteItems()
        }
      }
    },
    'view.nft.type': {
      handler() {
        if (this.config.type === this.mod && this.config.show) {
          if (!this.config.pop) this.emit('setLocationHash')
          this.deleteItems()
          if (!this.itemNums) this.getData('down')
        }
      }
    },
    'view.nft.sort': {
      handler() {
        if (this.config.type === this.mod && this.config.show) {
          this.init()
          if (!this.config.pop) this.emit('setLocationHash')
          if (!this.itemNums) this.getData('down')
        }
      }
    },
    'view.nft.sortType': {
      handler() {
        if (this.config.type === this.mod && this.config.show) {
          this.init()
          if (!this.config.pop) this.emit('setLocationHash')
          if (!this.itemNums) this.getData('down')
        }
      }
    },
    'view.nft.catalogid': {
      handler() {
        if (this.config.type === this.mod && this.config.show) {
          this.init()
          if (!this.config.pop) this.emit('setLocationHash')
          if (!this.itemNums) this.getData('down')
        }
      }
    },
    'view.series.renew': {
      handler() {
        if (this.mod === 'hot' || this.mod === 'best' || this.mod === 'original' || this.mod === 'new') this.updateItems()
      }
    },
    'data.scrollTop': {
      handler() {
        if ((this.view.seriesWorks.show && this.mod === 'series') || (this.view.user.show && ((this.mod === 'user' && this.view.user.type === 'nft') || (this.mod === 'userPrivate' && this.view.user.type === 'nftPrivate')))) {
          this.emit('setScrollTop', [this.data.scrollTop])
        }
      }
    }
  },
  mounted() {
    this.calcSize()
    const container = this.scrollContainer()
    if (container) container.onscroll = () => { this.data.scrollTop = container.scrollTop }
    window.addEventListener('resize', this.onResize)
    if (this.config.show && !this.itemNums && this.mod !== 'user' && this.mod !== 'userPrivate') return this.getData('down')
    if (this.view.index.show && this.mod === 'index') this.updateData()
  },
  methods: {
    // 更新数据
    updateItems() {
      for (var i in this.data.items) {
        const data = this.state.updateWork[this.data.items[i].workid]
        if (data) {
          for (const k in data) {
            this.data.items[i][k] = data[k]
          }
        }
      }
      this.renderItems(this.data.items, true)
    },
    // 逻辑删除作品
    deleteItems() {
      let deleteIds = []
      if (this.mod === 'hot') deleteIds = this.state.deleteHotWork
      if (this.mod === 'best') deleteIds = this.state.deleteBestWork
      if (this.mod === 'original') deleteIds = this.state.deleteOriginalWork
      if (this.mod === 'new') deleteIds = this.state.deleteWork
      const ids = []
      for (var id in deleteIds) {
        ids.push(parseInt(id))
      }
      if (ids.length) {
        const items = []
        for (var i in this.data.items) {
          if (ids.indexOf(this.data.items[i].workid) < 0) items.push(this.data.items[i])
        }
        this.data.items = items
        if (this.mod === 'hot') this.state.deleteHotWork = {}
        if (this.mod === 'best') this.state.deleteBestWork = {}
        if (this.mod === 'original') this.state.deleteOriginalWork = {}
        if (this.mod === 'new') this.state.deleteWork = {}
        this.renderItems(this.data.items, true)
      }
    },
    // 移除作品
    removeItem(id) {
      for (var i in this.data.items) {
        if (this.data.items[i].workid === id) {
          this.data.items.splice(i, 1)
        }
      }
      this.renderItems(this.data.items, true)
    },
    // 设置主题属性
    setItem(workid, key, value) {
      for (var i in this.data.items) {
        if (this.data.items[i].workid === workid) {
          this.data.items[i][key] = value
          return this.data.items[i]
        }
      }
    },
    scrollContainer() {
      const main = document.getElementById('nft-' + this.mod + '-container')
      if (main) {
        const scroll = main.getElementsByClassName('scroll-container')
        if (scroll.length) return scroll[0]
      }
    },
    onResize() {
      if (!this.config.show && !this.view.user.show && !this.view.praise.show && !this.view.favor.show && !this.view.footprint.show && !this.view.index.show && !this.view.download.show && !this.view.seriesWorks.show) return
      this.calcSize()
      // this.backTop()
      // this.data.scrollTop = 0
      this.renderItems(this.data.items, true)
    },
    backTop() {
      const container = this.scrollContainer()
      if (container) {
        setTimeout(() => {
          container.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
        }, 100)
      }
    },
    renewView() {
      this.backTop()
      this.loadData(() => {}, 'renew')
    },
    showItem(item) {
      if (item._top <= this.data.scrollTop - this.height && item._top + item._height >= this.data.scrollTop - this.height) return true
      if (item._top >= this.data.scrollTop - this.height && item._top <= this.data.scrollTop + this.height + this.height) return true
      return false
    },
    getDate(time) {
      if (!time) return ''
      return utils.date('cndate', 0, new Date(time * 1000))
    },
    countDownStartCb() {
      this.renewView()
    },
    padding: utils.padding,
    formateTime: utils.formateTime,
    formateNums: utils.formateNums,
    formateMoney: utils.formateMoney,
    // 调用父组件方法
    emit(fn, params) {
      if (!fn) return
      this.$emit('receive', fn, params || [])
    },
    // 提示信息
    message(msg, type) {
      this.emit('message', [msg, type])
    },
    // 确认操作
    confirm(msg, fn, title) {
      this.emit('confirm', [msg, fn, title])
    },
    myNotify(show, msg, btn, fn) {
      this.emit('myNotify', [show, msg, btn, fn])
    },
    getMinHeight(arr) {
      const a = []
      for (let i = 0; i < arr.length; i++) {
        a.push(parseInt(arr[i]._height) + parseInt(arr[i]._top))
      }
      return Math.min.apply(null, a)
    },
    getMinIndex(val) {
      const arrIndex = []
      for (let i = 0; i < this.columns.length; i++) {
        const its = this.columns[i].items
        const height = its[its.length - 1]._height
        const top = its[its.length - 1]._top
        if (parseInt(height) + parseInt(top) === val) {
          arrIndex.push(i)
        }
      }
      return arrIndex
    },
    calcItemSize(workid) {
      const w = this.works[workid] ? this.works[workid].width : this.maxThumbWidth
      const h = this.works[workid] ? this.works[workid].height : this.maxThumbHeight
      let width, height
      if (w < this.maxThumbWidth && h < this.maxThumbHeight) {
        width = w
        height = h
      } else {
        if (w > h) {
          width = this.maxThumbWidth
          height = Math.round(width * h / w)
        } else {
          height = this.maxThumbHeight
          width = Math.round(height * w / h)
        }
      }
      const left = (this.cardWidth - width) / 2
      const top = (this.cardWidth - height) / 2
      return { width: width, height: height, left: left, top: top }
    },
    renderItems(items, refresh = false) {
      if (refresh) {
        this.data.columns = []
        this.data.horizWidth = 0
      }
      if (!items.length) return
      // 初始化列、列的第一个元素
      let idx = 0
      if (this.columns.length < this.maxColumns || refresh) {
        for (let i = 0; i < this.maxColumns; i++) {
          if (!this.columns[i] && items[idx]) {
            const itemSize = this.calcItemSize(items[idx].workid)
            items[idx]._top = 0
            items[idx]._padding_top = itemSize.top
            items[idx]._padding_left = itemSize.left
            items[idx]._raw_width = itemSize.width
            items[idx]._raw_height = itemSize.height
            items[idx]._height = this.cardWidth + this.fixHeight
            this.columns.push({ items: [items[idx]] })
            idx++
          }
        }
      }
      // 对剩余元素的判断，应该放到哪一列
      for (var index = idx; index < items.length; index++) {
        // 找到高度最小的一列，可能有多个
        const tmp = []
        for (let i = 0; i < this.columns.length; i++) {
          const its = this.columns[i].items
          tmp.push({
            _height: its[its.length - 1]._height,
            _top: its[its.length - 1]._top
          })
        }
        // 获取最矮的列高度
        const minHeight = this.getMinHeight(tmp)
        // 获取最矮列的索引
        let minHeightIdx = 0
        const arrIndex = this.getMinIndex(minHeight)
        // 出现高度一样的，取索引最小的
        if (arrIndex.length > 0) {
          minHeightIdx = Math.min.apply(null, arrIndex)
        }
        // 设置属性
        const itemSize = this.calcItemSize(items[index].workid)
        items[index]._top = minHeight
        items[index]._padding_top = itemSize.top
        items[index]._padding_left = itemSize.left
        items[index]._raw_width = itemSize.width
        items[index]._raw_height = itemSize.height
        items[index]._height = this.cardWidth + this.fixHeight
        this.columns[minHeightIdx].items.push(items[index])
      }
    },
    calcSize() {
      if (this.state.platform.type === 'desktop' && this.isPop) {
        this.container_width = 632
        this.container_height = 632
      } else {
        this.container_width = utils.width()
        this.container_height = utils.height()
      }
      this.width = this.container_width - 3
      this.height = this.container_height - 95 - this.headHeight
    },
    init(backTop) {
      if (backTop) this.backTop()
      this.dt[this.mod] = utils.deepClone(this.schema)
    },
    update() {
      this.$forceUpdate()
    },
    updateData(updateHash) {
      if (updateHash && !this.config.pop) this.emit('setLocationHash')
      if (!this.itemNums) {
        this.init()
        this.getData('down')
      } else {
        this.update()
      }
    },
    getAvatar(item) {
      const ver = Math.round(new Date(this.users[item.userid].avatar_at) / 1000)
      if (this.users[item.userid] && this.users[item.userid].avatar) return service.getCosUrl(this.users[item.userid].avatar, 'avatar', ver) || ''
    },
    getTotal(item) {
      if (this.mod === 'publish' || this.mod === 'preorder') return item.sell_nums
      if (this.mod === 'compound') return item.compound_nums
      if (this.mod === 'paradrop') return item.paradrop_nums
      if (this.mod === 'calendar') return item.total
    },
    // 移除专辑作品
    removeAlbumWork(item) {
      const params = {}
      params.seriesid = this.params.seriesid
      params.workid = item.workid
      service.actionDelete('series_work', params, (dt, type) => {
        if (type === 'success') {
          this.removeItem(params.workid)
        } else {
          this.message(dt, type)
        }
      }, true)
    },
    showActionSheetx(item) {
      let btn = ''
      if (this.mod === 'series' && this.params.seriesid) {
        if ((this.params.userid && this.params.userid === this.loginUserId) || (this.loginUserId && this.loginUserId < 10000)) btn = { title: '移除', cb: () => { this.removeAlbumWork(item) } }
      }
      this.emit('showActionSheet', [item.userid, 'work', item, (id, data, dt, type) => {
        if (type === 'success') {
          this.view.nft.renew = utils.time('time')
          this.deleteItems()
          if (this.mod === 'series' && (data.public === 0 || data.check === 0 || data.flag === 0)) {
            this.removeItem(id)
          }
        }
      }, btn])
    },
    showActionSheet(publish) {
      if (!publish) return
      const nft = this.nfts[publish.publishid] || {}
      if (!nft.nftid) return
      let item, item_type
      const cb = (id, data, dt, type) => {
        // TODO:
        console.log(id, data, dt, type)
      }
      const show = () => {
        const actions = {
          show: true,
          title: publish.name + ' #' + this.padding(nft.sn, 4),
          btns: []
        }
        if ((nft.userid && nft.userid === this.loginUserId) || (this.loginUserId && this.loginUserId < 10000)) {
          actions.btns.push({ title: '设为' + (nft.public ? '私密' : '公开'), cb: () => { this.actionOperate('nft', nft, nft.nftid, { public: nft.public ? 0 : 1 }, cb) } })
        }
        if (item.donate) {
          const time = utils.time('time')
          const donateLockSeconds = item.donate_open_at - time
          if (donateLockSeconds > 0) {
            actions.btns.push({ title: '转赠(' + utils.getCountDown(donateLockSeconds) + '后)', cb: () => {
              this.message(utils.getCountDown(donateLockSeconds) + '后可转赠', 'error')
            } })
          } else {
            actions.btns.push({ title: '转赠', cb: () => { this.itemOperate('donate', nft.nftid, item, item_type) } })
          }
        }
        actions.btns.push({ title: '查看', cb: () => { this.openWorkViewer(nft.userid, nft.workid, nft.publishid) } })
        this.view.actionSheet = actions
      }
      service.get('item', nft.itemid, (dt, type) => {
        if (type === 'success' && dt && dt.data && dt.data.count) {
          item = dt.data.items[0]
          item_type = dt.data.item_types[item.item_typeid] || {}
          show()
        }
      }, true, { userid: this.loginUserId })
    },
    actionOperate(table, item, id, data, cb) {
      this.emit('actionOperate', [table, item, id, data, cb])
    },
    // 物品操作
    itemOperate(act, nftid, item, item_type) {
      if (!act) return
      this.getTradeState(() => {
        if (act === 'donate' && this.tradeState.status !== 4) {
          if (this.tradeState.status === 3) {
            this.emit('alert', ['请先设置交易密码', () => { this.emit('goto', ['account']) }])
          } else if (this.tradeState.status === 1 || this.tradeState.status === 0 || this.tradeState.status === -1) {
            this.emit('alert', ['请先通过实名认证', () => { this.emit('goto', ['account']) }])
          } else {
            this.message('请重新登录', 'login')
          }
          return
        }
        this.view.itemOperate.data = { nftid: nftid, itemid: item.itemid, item: item, item_type: item_type }
        this.view.itemOperate.type = 'donateNFT'
        this.view.itemOperate.show = true
        this.view.itemOperate.fn = null
      })
    },
    getTradeState(cb) {
      if (this.tradeState.userid === this.loginUserId) return cb && cb()
      this.emit('getTradeState', [this.loginUserId, '', (ret) => {
        this.tradeState = ret || {}
        cb && cb()
      }])
    },
    loadData(loaded, direction) {
      if (this.config.show || this.view.user.show || this.view.praise.show || this.view.favor.show || this.view.footprint.show || this.view.index.show || this.view.download.show || this.view.seriesWorks.show) {
        this.renew = direction === 'renew'
        if (direction === 'renew') {
          direction = 'down'
          this.data.currentPage = 0
          this.data.cursorValue = 0
          this.data.cursorValueUp = 0
          this.data.cursorValueDown = 0
          this.data.cursorSkipUp = 0
          this.data.cursorSkipDown = 0
          this.data.skip = 0
          this.data.noUpPage = false
          this.data.noDownPage = false
        }
        this.getData(direction, () => {
          this.renew = false
          loaded('done')
        })
      }
    },
    openWorkViewer(userid, workid, publishid) {
      const data = {
        userid: userid,
        workid: workid,
        publishid: publishid
      }
      this.emit('openWorkViewer', [{ data: data }])
    },
    // 分页读取数据
    async getData(direction, cb) {
      direction = direction || 'down'
      if (!this.config.show && !this.view.user.show && !this.view.praise.show && !this.view.favor.show && !this.view.footprint.show && !this.view.index.show && !this.view.download.show && !this.view.seriesWorks.show) return cb && cb()
      if (direction === 'up' && this.data.noUpPage) return cb && cb()
      if (direction === 'down' && this.data.noDownPage) return cb && cb()
      if (this.loading[this.mod]) return cb && cb()
      this.loading[this.mod] = true
      let table = 'publish'
      const params = {
        'sort_field': 'sell_at',
        'sort': 'desc',
        'limit': this.limit,
        'skip': direction === 'up' ? this.data.cursorSkipUp : this.data.cursorSkipDown,
        'cursor_value': direction === 'up' ? this.data.cursorValueUp : this.data.cursorValueDown,
        'direction': direction
      }
      const catalogid = utils.getInt(this.config.catalogid)
      // 今日23:59:59
      const time = utils.time('date', 1)
      if (catalogid) params.catalogid = catalogid
      if (this.mod === 'publish' || this.mod === 'index') {
        params.trade = 2
        params.between_field = 'sell_at'
        params.between_value = '0,' + time
      }
      if (this.mod === 'preorder') params.preorder = 1
      if (this.mod === 'paradrop') {
        params.paradrop = 1
        params.sort_field = 'paradrop_at'
        params.between_field = 'paradrop_at'
        params.between_value = '0,' + time
      }
      if (this.mod === 'compound') {
        params.compound = 1
        params.sort_field = 'compound_at'
        params.between_field = 'compound_at'
        params.between_value = '0,' + time
      }
      if (this.mod === 'calendar') {
        params.sort = 'asc'
        params.between_field = 'sell_at'
        params.between_value = 'tomorrow,0'
      }
      if (this.mod === 'user' || this.mod === 'userPrivate') {
        table = 'nft'
        params.sort_field = 'nftid'
        this.userid = this.view.user.userid
        params.userid = this.view.user.userid
        if (this.mod === 'user') params.public = 1
        if (this.mod === 'userPrivate') params.public = 0
      }
      // 暂未使用
      // if (this.mod === 'praise' || this.mod === 'favor' || this.mod === 'footprint' || this.mod === 'download' || this.mod === 'series') {
      //   params.mod = this.mod
      //   if (this.mod === 'series') {
      //     this.seriesid = this.view.seriesWorks.seriesid
      //     params.seriesid = this.seriesid
      //   }
      // }
      // console.error('params', this.mod, params, direction, this.data.currentPage, this.data, direction === 'up', this.data.cursorValueUp, this.data.cursorValueDown)
      service.listGet(table, params, (res, type) => {
        if (type === 'success') {
          let refresh = false
          if (direction === 'up') {
            refresh = true
            this.data.cursorValueUp = res.data.cursor_value_up
            this.data.cursorSkipUp = res.data.cursor_skip_up
            if (!this.data.cursorValueDown && !utils.isEmpty(res.data.cursor_value_down)) this.data.cursorValueDown = res.data.cursor_value_down
            this.data.noUpPage = utils.isEmpty(this.data.cursorValueUp)
            this.data.items = res.data.items.concat(this.data.items)
          } else if (direction === 'down') {
            if ((res.data.count === this.limit && this.data.currentPage >= this.remainPages) || this.renew) {
              if (res.data.count === this.limit && this.data.currentPage >= this.remainPages) {
                this.init(true)
                this.showUp = true
              } else {
                this.init()
              }
              refresh = true
            }
            this.data.currentPage++
            if (!this.data.cursorValueUp && !utils.isEmpty(res.data.cursor_value_up)) this.data.cursorValueUp = res.data.cursor_value_up
            this.data.cursorValueDown = res.data.cursor_value_down
            this.data.cursorSkipDown = res.data.cursor_skip_down
            this.data.noDownPage = utils.isEmpty(this.data.cursorValueDown)
            this.data.items = this.data.items.concat(res.data.items)
          }
          if (res.data.users) {
            for (var userid in res.data.users) {
              this.users[userid] = res.data.users[userid]
            }
          }
          if (res.data.series) {
            for (var seriesid in res.data.series) {
              this.series[seriesid] = res.data.series[seriesid]
            }
          }
          if (res.data.works) {
            for (var workid in res.data.works) {
              this.works[workid] = res.data.works[workid]
            }
          }
          if (res.data.nfts) {
            for (var nftid in res.data.nfts) {
              this.nfts[nftid] = res.data.nfts[nftid]
            }
          }
          this.data.skip = utils.getInt(res.data.skip)
          if (this.mod === 'series') this.emit('setTotal', [res.data.total])
          this.getFavorState(res.data)
          if (direction === 'up') {
            this.renderItems(this.data.items, true)
          } else {
            this.renderItems(res.data.items, refresh)
          }
        } else {
          this.message(res, type)
        }
        cb && cb()
        this.loading[this.mod] = false
        if (this.view.user.show) this.view.user.loading = false
        if (this.view.seriesWorks.show) this.view.seriesWorks.loading = false
        if (this.view.download.show) this.view.download.loading = false
        if (this.view.footprint.show) this.view.footprint.loading = false
        if (this.view.index.show) this.view.index.loading = false
        if (this.view.favor.show) this.view.favor.loading = false
        if (this.view.praise.show) this.view.praise.loading = false
      }, false, false)
    },
    // 喜欢
    praiseIt(workid) {
      if (!workid) return
      if (this.mod === 'praise' && this.state.praiseState[workid]) this.removeItem(workid)
      let praise_count = this.state.praiseCount[workid] || 0
      const praise = () => {
        if (this.state.praiseState[workid]) {
          this.state.praiseState[workid] = 0
          praise_count--
          service.decCount(this.loginUserId, 'work_praise')
        } else {
          this.state.praiseState[workid] = 1
          praise_count++
          service.incCount(this.loginUserId, 'work_praise')
        }
        praise_count = Math.max(0, praise_count)
        this.state.praiseCount[workid] = praise_count
        this.update()
      }
      praise()
      service.get('work_praise', workid, (dt, type) => {
        if (type !== 'success') {
          // 失败回滚
          praise()
          this.message(dt, type)
        }
      }, true)
    },
    // 收藏
    favorIt(workid) {
      if (!workid) return
      let albumTip = false
      const favor = () => {
        if (this.state.favorState[workid]) {
          this.state.favorState[workid] = 0
          this.state.favorCount[workid]--
          service.decCount(this.loginUserId, 'work_favor')
        } else {
          this.state.favorState[workid] = 1
          this.state.favorCount[workid]++
          service.incCount(this.loginUserId, 'work_favor')
          albumTip = true
        }
        this.state.favorCount[workid] = Math.max(0, this.state.favorCount[workid])
        console.log(this.state.favorCount, workid)
        this.update()
      }
      favor()
      service.get('work_favor', workid, (dt, type) => {
        if (type !== 'success') {
          // 失败回滚
          favor()
          this.message(dt, type)
        } else if (type === 'success' && albumTip) {
          this.myNotify(true, '恭喜，收藏成功', '加入专辑', () => {
            this.myNotify(false)
            this.view.albumSelector.workid = workid
            this.view.albumSelector.show = true
          })
        }
      }, true)
    },
    // 获取收藏状态
    getFavorState(data) {
      if (!data) return
      if (data.favor) {
        for (var workid in data.favor) {
          this.state.favorCount[workid] = data.favor[workid]
        }
      }
      const items = data.items
      if (Array.isArray(items)) {
        const ids = []
        for (var i in items) {
          if (items[i].workid && data.favor && data.favor[items[i].workid] && typeof this.state.favorState[items[i].workid] === 'undefined') {
            ids.push(items[i].workid)
          }
        }
        if (ids.length) {
          const params = { ids: ids, 'type': 'favor' }
          service.actionGet('work_count', params, (res, type) => {
            if (type === 'success' && res.data && res.data.favor) {
              for (const k in res.data.favor) {
                this.state.favorState[k] = res.data.favor[k]
              }
              this.update()
            }
          }, true)
        }
      }
    },
    // 获取收藏状态
    getFavorStatex(data) {
      if (!data) return
      if (data.favor) {
        for (var publishid in data.favor) {
          this.favorCount[publishid] = data.favor[publishid]
        }
      }
      const items = data.items
      if (Array.isArray(items)) {
        const ids = []
        for (var i in items) {
          if (data.favor[items[i].publishid] && items[i].publishid && typeof this.favorState[items[i].publishid] === 'undefined') {
            ids.push(items[i].publishid)
          }
        }
        if (ids.length) {
          const params = { ids: ids, 'type': 'favor' }
          service.actionGet('album_count', params, (res, type) => {
            if (type === 'success' && res.data && res.data.favor) {
              for (const k in res.data.favor) {
                this.favorState[k] = res.data.favor[k]
              }
              this.update()
            }
          }, true)
        }
      }
    }
  }
}
