<template>
  <el-dialog
    ref="favorDialog"
    :visible.sync="config.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="!isDesktop"
    :show-close="false"
    :top="marginTop"
    custom-class="pop_page noscroll favorDialog"
  >
    <div slot="title" class="header">
      <div class="left">
        <el-button v-if="!isDesktop" class="tap-btn iconfont my-back size20" @click="closePop(config)" />
        &nbsp;
      </div>
      <div class="middle">
        我的收藏
      </div>
      <div class="float-right align-right">
        <el-button v-if="isDesktop" class="tap-btn iconfont my-close size20" @click="closePop(config)" />
        &nbsp;
      </div>
    </div>
    <div v-show="config.show" v-finger:swipe="onSwipe" class="my-container">
      <div class="tabs my-tabs bg-20" :class="{'headless': !config.showHead}" style="padding: 2px;">
        <el-tabs v-model="config.type">
          <el-tab-pane v-for="(type, idx) in config.types" :key="idx" :label="type[1]" :name="type[0]" />
        </el-tabs>
      </div>
      <thread-list
        v-show="config.show && config.type === 'thread'"
        ref="favor-thread-list"
        mod="favor"
        :subtract-height="subtractHeight"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :is-pop="true"
        @receive="handle"
      />
      <works-list
        v-show="config.show && config.type === 'works'"
        ref="favor-works-list"
        mod="favor"
        :subtract-height="subtractHeight"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :is-pop="true"
        @receive="handle"
      />
      <album-list
        v-show="config.show && config.type === 'album'"
        ref="favor-album-list"
        mod="favor"
        :subtract-height="subtractHeight"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :is-pop="true"
        @receive="handle"
      />
    </div>
  </el-dialog>
</template>
<script>
import { mixins } from '@/mixins/common'
import worksList from '@/components/web/worksList'
import threadList from '@/components/web/threadList'
import albumList from '@/components/web/albumList'
export default {
  components: {
    worksList,
    threadList,
    albumList
  },
  mixins: [mixins],
  data() {
    return {
      scrollTop: 0,
      subtractHeight: this.state.platform.type === 'desktop' ? 40 : 80
    }
  },
  computed: {
    config() {
      return this.view.favor
    }
  },
  // watch: {
  //   'view.favor.show': {
  //     handler() {
  //       if (this.view.favor.show) this.updateData()
  //     }
  //   }
  // },
  mounted() {
    if (!this.loginUserId) {
      this.emit('login')
      return
    }
    this.updateData()
  },
  methods: {
    updateData() {
      this.callSub('favor-' + this.config.type + '-list', 'updateData')
    },
    onSwipe(evt) {
      this.switchTab(this.config, evt.direction)
    },
    handle(act, data) {
      if (act === 'setScrollTop') {
        return
      } else if (act === 'goto') {
        this.goto(...data)
      } else if (act === 'update') {
        this.update()
      } else {
        this.emit(act, data)
      }
    }
  }
}
</script>
