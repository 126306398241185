<template>
  <el-dialog
    ref="profileDialog"
    :visible.sync="config.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="!isDesktop"
    :show-close="false"
    :top="marginTop"
    :custom-class="isDesktop ? 'pop_page noscroll' : 'pop_page noscroll headless'"
  >
    <div v-if="isDesktop" slot="title" class="header">
      <div class="left">
        &nbsp;
      </div>
      <div class="middle">
        个人设置
      </div>
      <div class="float-right align-right">
        <el-button class="tap-btn iconfont my-close size20" @click="closePop(config)" />
        &nbsp;
      </div>
    </div>
    <div v-show="config.show" class="my-container">
      <div class="my-body first">
        <div class="header-card" :class="{'large': user.background}" :style="cardStyle">
          <div v-if="!isDesktop" class="header">
            <div class="left">
              <el-button v-if="!isDesktop" class="tap-btn iconfont my-back size20" @click="closePop(config)" />
              &nbsp;
            </div>
            <div class="middle">
              个人设置
            </div>
            <div class="float-right align-right">
              <el-button v-if="isDesktop" class="tap-btn iconfont my-close size20" @click="closePop(config)" />
              &nbsp;
            </div>
          </div>
          <div class="center">
            <span v-if="!user.avatar" class="el-avatar el-avatar--circle noavatar xlarge">
              <i class="iconfont my-man" />
            </span>
            <el-avatar v-if="user.avatar" :size="64" :src="getAvatar()" />
          </div>
          <div class="center set-btns">
            <input ref="avatar-uploader" type="file" accept="image/*" style="display:none;" @change="upload('avatar')">
            <input ref="background-uploader" type="file" accept="image/*" style="display:none;" @change="upload('background')">
            <el-button class="opacity-bg" @click="openLocalImages('avatar')">
              设置头像
            </el-button>
            <el-button class="opacity-bg" @click="openLocalImages('background')">
              设置背景
            </el-button>
          </div>
        </div>
        <div class="my-block">
          <div class="my-row round-top4" @click="setNickname()">
            <el-row>
              <el-col :span="8">昵称</el-col>
              <el-col :span="16" class="right">
                {{ user.nickname || '未设置' }}
                <i class="iconfont my-right" />
              </el-col>
            </el-row>
          </div>
          <div class="my-row round-bottom4" @click="setIntro()">
            <el-row>
              <el-col :span="6">简介</el-col>
              <el-col :span="18" class="right">
                <div class="text intro">{{ getSign(user.signature) }}</div>
                <div class="arrow"><i class="iconfont my-right" /></div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="my-block">
          <div class="my-row round-top4" @click="selectSex()">
            <el-row>
              <el-col :span="8">性别</el-col>
              <el-col :span="16" class="right">
                {{ user.sex === 0 ? '女' : (user.sex === 1 ? '男' : '保密') }}
                <i class="iconfont my-right" />
              </el-col>
            </el-row>
          </div>
          <div class="my-row" @click="selectDate">
            <el-row>
              <el-col :span="8">生日</el-col>
              <el-col :span="16" class="right">
                {{ user.birth_year ? (user.birth_year + '-') : '' }}{{ user.birth_month && user.birth_day ? (user.birth_month + '-' + user.birth_day) : '未设置' }}
                <i class="iconfont my-right" />
              </el-col>
            </el-row>
          </div>
          <div class="my-row round-bottom4" @click="setArea">
            <el-row>
              <el-col :span="8">所在地</el-col>
              <el-col :span="16" class="right">
                {{ user.city || '未设置' }}
                <i class="iconfont my-right" />
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import '@/styles/picker.css'
import DatePicker from 'hg-datepicker'
import { mixins } from '@/mixins/common'
import utils from '@/js/utils'
import conf from '@/js/conf/conf'
import service from '@/js/service'
import COSSDK from '@/js/sdk/cosSDK'
export default {
  components: {},
  mixins: [mixins],
  data() {
    return {
      worksHost: conf.hosts().worksHost,
      COSSDK: null,
      token: {},
      picker: null,
      user: {}
    }
  },
  computed: {
    cardStyle() {
      const style = {}
      let bg = this.getBackground()
      // if (!bg) bg = this.worksHost + utils.getDefaultBg(this.user.userid || 0) + '/background'
      if (!bg) bg = utils.getDefaultBg(this.user.userid || 0)
      if (bg) {
        style['background-image'] = 'url(' + bg + ')'
        style['background-position'] = 'center center'
        style['background-size'] = '100% auto'
        style['background-repeat'] = 'no-repeat'
      }
      return style
    },
    config() {
      return this.view.profile
    }
  },
  // watch: {
  //   'view.profile.show': {
  //     handler() {
  //       if (this.view.profile.show && !this.user.userid) this.loadData()
  //     }
  //   }
  // },
  mounted() {
    if (!this.loginUserId) {
      this.emit('login')
      return
    }
    this.loadData()
    this.picker = new DatePicker({
      type: 'date',
      start: [1960, 1, 1],
      end: [2010, 12, 31],
      hasSuffix: 'no',
      onOk: (arr) => {
        const data = {
          birth_year: arr[0] || '',
          birth_month: arr[1] || '',
          birth_day: arr[2] || ''
        }
        this.user.birth_year = data.birth_year
        this.user.birth_month = data.birth_month
        this.user.birth_day = data.birth_day
        this.updateProfile('', data)
      }
    })
  },
  beforeDestroy() {
    this.picker.destroy()
    this.picker = null
  },
  methods: {
    handle(act, data) {
      this.emit(act, data)
    },
    getSign(content) {
      if (!content) return ''
      content = content.replace(/\n+/ig, '\n')
      if (content.slice(0, 2) === '\n') content = content.slice(2)
      if (content.slice(-2) === '\n') content = content.slice(0, -2)
      return content
    },
    getAvatar() {
      const ver = Math.round(new Date(this.user.avatar_at) / 1000)
      if (this.user.avatar) return service.getCosUrl(this.user.avatar, 'avatar', ver) || ''
    },
    getBackground() {
      const ver = Math.round(new Date(this.user.background_at) / 1000)
      if (this.user.background) return service.getCosUrl(this.user.background, 'background', ver) || ''
    },
    selectDate() {
      if (this.isDesktop) {
        this.setBirth()
        return
      }
      if (this.picker) {
        this.picker.set({ value: [this.user.birth_year || 2000, this.user.birth_month || 1, this.user.birth_day || 1] })
        this.picker.show()
      }
    },
    update() {
      this.$forceUpdate()
    },
    // 打开本地图片
    openLocalImages(type) {
      setTimeout(() => {
        this.$refs[type + '-uploader'].click()
      }, 100)
    },
    upload(type) {
      const dom = this.$refs[type + '-uploader']
      if (!dom.files.length) {
        return
      }
      this.emit('loading', [true, '上传中...'])
      const file = dom.files[0]
      const key = 'public/' + type + '/' + utils.caclUserPath(this.loginUserId) + '.png'
      // 上传文件
      this.COSSDK = new COSSDK()
      this.COSSDK.putObject({
        Bucket: conf.cosBucket.bucket,
        Region: conf.cosBucket.region,
        Key: key,
        Body: file
      }, (err, data) => {
        if (!err && data && data.statusCode === 200 && data.Location) {
          this.user[type] = 'cos://' + key.replace('public/' + type + '/', '')
          const datetime = utils.date('datetime')
          if (type === 'avatar') {
            this.user.avatar_at = datetime
            this.token = service.getToken()
            this.token.avatar = this.getAvatar()
            this.token.avatarUrl = this.getAvatar()
            window.localStorage.setItem('token', JSON.stringify(this.token))
          } else {
            this.user.background_at = datetime
          }
          this.updateProfile(type, '', () => {
            this.emit('loading', [false])
            this.view.user.renew = datetime
          })
        } else {
          this.message('太火爆了，请稍后重试', 'error')
          this.emit('loading', [false])
        }
      })
    },
    // 更新个人资料
    updateProfile(type, body, cb) {
      body = body || {}
      if (type) body[type] = this.user[type]
      service.put('user_profile', this.loginUserId, body, (dt, status) => {
        this.update()
        if (status === 'success') {
          this.emit('getToken')
          this.message('设置成功', status)
        } else {
          this.message(dt, status)
        }
        cb && cb()
      }, true)
    },
    setIntro() {
      this.view.pop.title = '修改个人简介'
      this.view.pop.placeholder = ''
      this.view.pop.content = this.user.signature || ''
      this.view.pop.classname = ''
      this.view.pop.maxlength = 240
      this.view.pop.rows = 3
      this.view.pop.type = ''
      this.view.pop.data = {}
      this.view.pop.show = true
      this.view.pop.fn = () => {
        if (!utils.checkNow('str2-0-120', this.view.pop.content, true)) {
          this.view.pop.loading = false
          return this.message('个人简介在120个汉字以内', 'error')
        }
        if (this.view.pop.content !== this.user.signature) {
          service.updateUser({ 'signature': this.view.pop.content }, (dt, type) => {
            if (type === 'success') {
              this.view.pop.fn = null
              this.view.pop.show = false
              this.user.signature = dt.signature
              this.view.user.renew = utils.time('time')
              this.message('修改成功', type)
            } else {
              this.message(dt, type)
            }
            this.view.pop.loading = false
          })
        } else {
          this.view.pop.loading = false
          this.view.pop.fn = null
          this.view.pop.show = false
        }
      }
    },
    setNickname() {
      this.view.pop.title = '修改昵称'
      this.view.pop.placeholder = ''
      this.view.pop.content = this.user.nickname || ''
      this.view.pop.classname = ''
      this.view.pop.maxlength = 18
      this.view.pop.rows = 1
      this.view.pop.type = ''
      this.view.pop.data = {}
      this.view.pop.show = true
      this.view.pop.fn = () => {
        if (!utils.checkNow('nickname', this.view.pop.content, true)) {
          this.view.pop.loading = false
          return this.message('昵称为6~18位字符，包含字母、数字、下划线、汉字', 'error')
        }
        if (this.view.pop.content !== this.user.nickname) {
          service.updateUser({ 'nickname': this.view.pop.content }, (dt, type) => {
            if (type === 'success') {
              this.view.pop.fn = null
              this.view.pop.show = false
              this.message('修改成功', type)
              this.token = service.getToken()
              this.token.nickname = dt.nickname
              this.user.nickname = dt.nickname
              window.localStorage.setItem('token', JSON.stringify(this.token))
            } else {
              this.message(dt, type)
            }
            this.view.pop.loading = false
          })
        } else {
          this.view.pop.loading = false
          this.view.pop.fn = null
          this.view.pop.show = false
        }
      }
    },
    setBirth() {
      this.view.pop.title = '生日'
      this.view.pop.type = 'date'
      this.view.pop.data = { year: this.user.birth_year, month: this.user.birth_month, day: this.user.birth_day }
      this.view.pop.show = true
      this.view.pop.fn = (birth) => {
        const data = {}
        data.birth_year = birth.year || ''
        data.birth_month = birth.month || ''
        data.birth_day = birth.day || ''
        this.user.birth_year = birth.year || ''
        this.user.birth_month = birth.month || ''
        this.user.birth_day = birth.day || ''
        this.updateProfile('', data, () => {
          this.user.birth_year = data.birth_year
          this.user.birth_month = data.birth_month
          this.user.birth_day = data.birth_day
          this.goto('profile')
        })
      }
    },
    setArea() {
      this.view.pop.title = '所在地'
      this.view.pop.type = 'area'
      this.view.pop.data = { province: this.user.province, city: this.user.city, district: this.user.district }
      this.view.pop.show = true
      this.view.pop.fn = (area) => {
        const data = {}
        data.province = area.province.value || ''
        data.city = area.city.value || ''
        data.district = area.area.value || ''
        this.user.city = data.city
        this.updateProfile('', data, () => {
          this.user.city = data.city
          this.goto('profile')
        })
      }
    },
    selectSex() {
      const actions = {
        show: true,
        title: '选择性别',
        btns: [
          {
            title: '男', cb: () => {
              this.user.sex = 1
              this.updateProfile('sex')
            }
          },
          {
            title: '女', cb: () => {
              this.user.sex = 0
              this.updateProfile('sex')
            }
          },
          {
            title: '保密', cb: () => {
              this.user.sex = 2
              this.updateProfile('sex')
            }
          }
        ]
      }
      this.view.actionSheet = actions
    },
    loadData() {
      if (!this.loginStatus) return this.message('', 'login')
      this.getUserProfile(this.loginUserId)
    },
    getUserProfile(userid) {
      service.get('user_profile', userid, (ret, type) => {
        if (type === 'success' && ret.data) {
          this.user = ret.data
          this.setCount()
        } else {
          this.message(ret, type)
        }
      }, true)
    },
    // 设置计数器(新手任务)
    setCount() {
      const user = this.user
      if (user.avatar) service.setCount(user.userid, 'avatar', 1)
      if (user.background) service.setCount(user.userid, 'background', 1)
      if (user.signature) service.setCount(user.userid, 'signature', 1)
      if (user.sex) service.setCount(user.userid, 'sex', 1)
      if (user.birth_month && user.birth_day) service.setCount(user.userid, 'birth', 1)
      if (user.city) service.setCount(user.userid, 'city', 1)
      if (user.username) service.setCount(user.userid, 'username', 1)
      if (user.verify_status === 2) service.setCount(user.userid, 'verify', 1)
      if (user.chain_account.status === 2) service.setCount(user.userid, 'chain_account', 1)
      if (user.password) service.setCount(user.userid, 'password', 1)
      if (user.pay_password) service.setCount(user.userid, 'pay_password', 1)
    }
  }
}
</script>
