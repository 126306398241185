<template>
  <el-dialog
    ref="downloadDialog"
    :visible.sync="config.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="!isDesktop"
    :show-close="false"
    :top="marginTop"
    custom-class="pop_page noscroll downloadDialog"
  >
    <div slot="title" class="header">
      <div class="left">
        <el-button v-if="!isDesktop" class="tap-btn iconfont my-back size20" @click="closePop(config)" />
        &nbsp;
      </div>
      <div class="middle">
        我的下载
      </div>
      <div class="float-right align-right">
        <el-button v-if="isDesktop" class="tap-btn iconfont my-close size20" @click="closePop(config)" />
        &nbsp;
      </div>
    </div>
    <div v-show="config.show" v-finger:swipe="onSwipe" class="my-container">
      <works-list
        v-show="config.show && config.type === 'works'"
        ref="download-works-list"
        mod="download"
        :subtract-height="subtractHeight"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :is-pop="true"
        @receive="handle"
      />
    </div>
  </el-dialog>
</template>
<script>
import { mixins } from '@/mixins/common'
import worksList from '@/components/web/worksList'
export default {
  components: {
    worksList
  },
  mixins: [mixins],
  data() {
    return {
      scrollTop: 0,
      subtractHeight: 40
    }
  },
  computed: {
    config() {
      return this.view.download
    }
  },
  // watch: {
  //   'view.download.show': {
  //     handler() {
  //       if (this.view.download.show) this.updateData()
  //     }
  //   }
  // },
  mounted() {
    if (!this.loginUserId) {
      this.emit('login')
      return
    }
    this.updateData()
  },
  methods: {
    updateData() {
      this.callSub('download-works-list', 'updateData')
    },
    onSwipe(evt) {
      if (evt.direction === 'Left' || evt.direction === 'Right') this.goto()
    },
    handle(act, data) {
      if (act === 'setScrollTop') {
        return
      } else if (act === 'goto') {
        this.goto(...data)
      } else if (act === 'update') {
        this.update()
      } else {
        this.emit(act, data)
      }
    }
  }
}
</script>
