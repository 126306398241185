<template>
  <div>
    <el-tag
      v-for="tag in tags"
      :key="tag"
      closable
      :disable-transitions="false"
      size="mini"
      @close="handleClose(tag)"
    >
      {{ tag }}
    </el-tag>
    <el-input
      v-if="inputVisible"
      ref="saveTagInput"
      v-model="inputValue"
      size="mini"
      class="input-new-tag"
      @keyup.enter.native="handleInputConfirm"
      @blur="handleInputConfirm"
    />
    <el-button v-else class="button-new-tag" size="mini" @click="showInput">+</el-button>
  </div>
</template>
<style>
.el-tag {
  height: 28px;
  line-height: 28px!important;
  padding: 0 8px;
  margin-right: 8px;
}
.button-new-tag {
  margin-bottom: 5px;
}
.input-new-tag {
  width: 56px;
}
.input-new-tag .el-input__inner {
  padding: 0 6px!important;
  height: 28px;
}
</style>

<script>
export default {
  props: {
    tags: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      inputVisible: false,
      inputValue: ''
    }
  },
  methods: {
    handleClose(tag) {
      this.tags.splice(this.tags.indexOf(tag), 1)
    },
    showInput() {
      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    handleInputConfirm() {
      if (this.inputValue && this.tags.indexOf(this.inputValue) === -1) {
        this.tags.push(this.inputValue.substr(0, 12))
      }
      this.inputVisible = false
      this.inputValue = ''
    }
  }
}
</script>
