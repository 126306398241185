<template>
  <div v-show="colors && colors.length && !emptyOption">
    <div :style="paletteStyle">
      <ul class="color-list">
        <li v-if="emptyOption">
          <div v-if="emptyOption !== 'true'" title="无" :class="{selected: !selectedColor}" class="noColor" @click.stop="selectColor(0, '');" @contextmenu.prevent="selectColor(1, '')" />
        </li>
        <li v-for="(color, index) in colors" :key="color" @mouseenter="setHoverColor(color)" @mouseleave="setHoverColor('')">
          <div :style="{background: color}" :title="color.toUpperCase()" :class="{selected: selectedColor === color}" @click.stop="selectColor(0, color)" @contextmenu.prevent="selectColor(1, color)">
            <span v-if="hoverColor === color && canRemove" class="btn-del" title="移除" @click.stop="removeColor(index, color)">-</span>
          </div>
        </li>
        <li v-for="(color, index) in mine" v-show="color" :key="'m' + index" @mouseenter="setPrePickColor(index, color)" @mouseleave="setPrePickColor('', '')">
          <div :style="{background: color}" :title="color.toUpperCase()" :class="{selected: selectedColor === color}" @click.stop="selectColor(0, color)" @contextmenu.prevent="selectColor(1, color)">
            <span v-if="prePickColor === color && prePickColorIdx === index" class="btn-pick" title="取色">
              <color-picker v-model="mine[index]" @change="selectColor(0, mine[index])" />
            </span>
          </div>
        </li>
      </ul>
      <input :id="inputId" v-model="selectedColor" :name="inputId" type="hidden">
    </div>
  </div>
</template>
<script>
module.exports = {
  props: ['view', 'state', 'paletteColors', 'emptyOption', 'canRemove', 'inputId', 'default', 'isDesktop'],
  data: function() {
    return {
      mine: [],
      selectedColor: this.default,
      colors: this.paletteColors || [],
      prePickColorIdx: 0,
      prePickColor: '',
      hoverColor: ''
    }
  },
  watch: {},
  computed: {
    paletteStyle() {
      if (this.isDesktop) {
        return { width: '100%', height: Math.min(Math.ceil(this.colors.length / 10) * 27, 134) + 8 + 'px', 'overflow-x': 'hidden', 'overflow-y': 'auto' }
      } else {
        return { width: this.colors.length * 27 + 12 + 'px', 'height': '30px', margin: '2px 5px 5px 0px' }
      }
    }
  },
  mounted: function() {
    if (!this.colors || !this.colors.length) {
      this.$emit('receive', 'usePalette', [])
    }
    if (this.default === '' && this.colors.length) {
      this.default = this.colors[0]
    }
    let i
    for (i = 0; i < this.colors.length; i++) {
      if (this.colors[i] === this.default) {
        this.$emit('input', this.selectedColor)
      }
    }
  },
  methods: {
    setPrePickColor: function(idx, color) {
      this.prePickColorIdx = idx
      this.prePickColor = color
    },
    setHoverColor: function(color) {
      this.hoverColor = color
    },
    removeColor: function(index, color) {
      this.colors.splice(index, 1)
      this.$emit('receive', 'removeColor', color)
    },
    setColors: function(colors) {
      if (colors) {
        this.colors = colors
      } else {
        this.$emit('receive', 'usePalette', [])
      }
    },
    selectColor: function(idx, color) {
      this.selectedColor = color || ''
      // this.$emit('input', this.selectedColor)
      this.$emit('receive', 'pick', [idx, color])
    }
  }
}
</script>
<style >
.color-list {
  list-style-type: none;
  padding: 0;
  display: block;
  float: left;
  margin: 4px 0px;
  width: 100%;
}
.color-list + * {
  clear: both;
}
.color-list > li {
  float: left;
  padding: 1px;
  position: relative;
}
.color-list > li > div {
  display: block;
  height: 25px;
  width: 25px;
  background: #ffffff;
  border: 1px solid #000000;
}
.color-list > li > div.noColor:after, .color-list > li > div.noColor.selected:after {
  content: "";
  background: red;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  display: inline-block;
  position: absolute;
  top: 12px;
  left: -2px;
  width: 26px;
  height: 2px;
}
.color-list > li > div:hover {
  border: 1px solid #eeeeee;
}
.color-list > li > div.selected, .color-list > li > div.noColor.selected {
  border: 1px solid #eeeeee;
}
.btn-del {
  position: absolute;
  left: -1px;
  top: -1px;
  width: 10px;
  height: 10px;
  background: #FF0000;
  border-radius: 10px;
  color: #eeeeee;
  font-size: 6px;
  line-height: 6px;
  text-align: center;
  cursor: pointer;
}

.btn-pick {
  position: absolute;
  bottom: -1px;
  right: -1px;
  width: 10px;
  height: 10px;
  background: rgb(24, 144, 255);
  border-radius: 10px;
  color: #eeeeee;
  font-size: 6px;
  line-height: 6px;
  text-align: center;
  cursor: pointer;
}
.btn-pick .m-colorPicker .colorBtn {
  width: 8px!important;
  height: 8px!important;
  border-radius: 10px;
  top: 1px;
  position: relative;
  cursor: pointer;
  color: #FFFFFF;
  font-size: 6px;
  line-height: 6px;
  text-align: center;
  background-color: rgb(24, 144, 255)!important;
}
.btn-pick .m-colorPicker .colorBtn:after {
  content: "+";
}
</style>

