import PullTo from 'vue-pull-to'
import emptyImage from '@/assets/empty.png'
import icon from '@/assets/icon.png'
import utils from '@/js/utils'
import service from '@/js/service'
export const mixins = {
  components: {
    PullTo
  },
  props: {
    view: {
      type: Object,
      default() {
        return {}
      }
    },
    state: {
      type: Object,
      default() {
        return {}
      }
    },
    db: {
      type: Object,
      default() {
        return {}
      }
    },
    file: {
      type: Object,
      default() {
        return {}
      }
    },
    mod: {
      type: String,
      default() {
        return 'new'
      }
    },
    params: {
      type: Object,
      default() {
        return {}
      }
    },
    thumbSize: {
      type: Number,
      default: 380
    },
    pageSize: {
      type: Number,
      default: 15
    },
    loginStatus: {
      type: Boolean,
      default: false
    },
    loginUserId: {
      type: Number,
      default: 0
    },
    subtractHeight: {
      type: Number,
      default: 0
    }
  },
  data() {
    const schema = {
      scrollTop: 0,
      // 瀑布流各列数据
      columns: [],
      items: [],
      initMessageTypeItem: false,
      chatItems: [],
      currentPage: 0,
      cursorValue: 0,
      cursorValueUp: 0,
      cursorValueDown: 0,
      cursorSkipUp: 0,
      cursorSkipDown: 0,
      skip: 0,
      noUpPage: false,
      noDownPage: false,
      placeholder: '',
      rows: 1,
      content: '',
      deleteMod: false,
      deleteIds: {}
    }
    let container_width, container_height
    if (this.state.platform.type === 'desktop' && this.view.message.pop) {
      container_width = 632
      container_height = 632
    } else {
      container_width = utils.width()
      container_height = utils.height()
    }
    const data = {
      icon: icon,
      // 可用宽度
      container_width: container_width,
      container_height: container_height,
      width: container_width - 3,
      height: container_height - 95,
      renew: false,
      fixHeight: 70,
      // 达到保留页数时，重新渲染列表
      remainPages: 100,
      userid: 0,
      dialogId: 0,
      showUp: false,
      emptyImage: emptyImage,
      schema: schema,
      dt: {
        'chat': utils.deepClone(schema),
        'dialog': utils.deepClone(schema),
        'social': utils.deepClone(schema),
        'system': utils.deepClone(schema)
      },
      loading: {
        'chat': false,
        'dialog': false,
        'social': false,
        'system': false
      }
    }
    return data
  },
  computed: {
    isDesktop() {
      return this.state.platform.type === 'desktop'
    },
    isMobile() {
      return this.state.platform.type === 'mobile'
    },
    reqId() {
      return this.state.reqId
    },
    maxColumns() {
      return Math.max(Math.floor(this.width / (this.thumbSize + 10)), 1)
    },
    columnWidth() {
      return Math.round(this.width / this.maxColumns)
    },
    cardWidth() {
      return this.columnWidth - 10
    },
    // 瀑布流高度
    flowHeight() {
      let height = 0
      for (let i = 0; i < this.columns.length; i++) {
        const its = this.columns[i].items
        const colHeight = its[its.length - 1]._height + its[its.length - 1]._top
        if (colHeight > height) height = colHeight
      }
      return height
    },
    headHeight() {
      return this.config.showHead ? 40 : 0
    },
    listStyle() {
      let fixHeight = this.data.rows > 1 ? 50 : 0
      if (this.mod === 'chat') fixHeight = fixHeight + (this.isDesktop ? 30 : 70)
      if (this.subtractHeight) return { 'height': 'calc(100% - ' + this.subtractHeight + 'px)', 'margin-top': '5px' }
      return { 'height': 'calc(100% - ' + (5 + this.headHeight + fixHeight) + 'px)', 'margin-top': (5 + this.headHeight) + 'px' }
    },
    config() {
      return this.view.message
    },
    users() {
      return this.state.users
    },
    data() {
      return this.dt[this.mod]
    },
    items() {
      return this.data.items
    },
    itemNums() {
      return this.data.items.length
    },
    columns() {
      return this.data ? this.data.columns : []
    },
    followState() {
      return this.state.followState || {}
    },
    limit() {
      const limit = this.pageSize
      if (this.mod === 'chat') return limit * 5
      if (!this.isMobile) return limit * 2
      return limit
    }
  },
  watch: {
    // 'view.message.show': {
    //   handler(show) {
    //     this.data.canDelete = false
    //     this.data.deleteMod = false
    //     this.data.deleteIds = {}
    //     this.update()
    //     if (this.config.type === this.mod && show) {
    //       if (!this.itemNums) this.getData('down', true)
    //       this.deleteItems()
    //     }
    //   }
    // },
    'view.message.type': {
      handler() {
        this.data.canDelete = false
        this.data.deleteMod = false
        this.data.deleteIds = {}
        this.update()
        if (this.config.type === this.mod && this.config.show) {
          if (!this.config.pop) this.emit('setLocationHash')
          this.deleteItems()
          if (!this.itemNums) this.getData('down', true)
        }
      }
    },
    'loginUserId': {
      handler() {
        this.init()
      }
    },
    'view.chat.show': {
      handler(show) {
        this.update()
        if (this.mod === 'chat' && show) this.updateData()
      }
    },
    'view.chat.type': {
      handler() {
        if (this.mod === 'chat' && this.view.chat.show) this.updateData(true)
      }
    },
    'view.chat.dialogId': {
      handler() {
        if (this.mod === 'chat') this.init()
      }
    },
    'view.chat.userid': {
      handler() {
        if (this.mod === 'chat') this.init()
      }
    }
  },
  mounted() {
    if (!this.loginUserId) {
      this.emit('login')
      return
    }
    this.calcSize()
    const container = this.scrollContainer()
    if (container) container.onscroll = () => { this.data.scrollTop = container.scrollTop }
    window.addEventListener('resize', this.onResize)
    if (this.config.type === this.mod && this.config.show) this.emit('getUnread')
    if (((this.config.type === this.mod && this.config.show) || (this.view.chat.show && this.mod === 'chat')) && !this.itemNums) return this.getData('down', true)
  },
  methods: {
    longTap(id) {
      if (this.data.deleteMod) {
        this.emit('cancelDelete')
      } else {
        this.data.deleteMod = true
        this.data.deleteIds[id] = true
        this.emit('setDeleteData', [this.data.deleteMod, this.data.deleteIds])
        this.update()
      }
    },
    select(id) {
      if (this.data.deleteIds[id]) {
        delete this.data.deleteIds[id]
      } else {
        this.data.deleteIds[id] = true
      }
      this.emit('setDeleteData', [this.data.deleteMod, this.data.deleteIds])
      this.update()
    },
    cancelDelete() {
      this.data.deleteMod = false
      this.data.deleteIds = {}
      this.update()
    },
    // 转场
    goto(mod, dt, type) {
      if (type) {
        this.config.type = type
        return
      }
      this.emit('goto', [mod, dt])
    },
    linkTo(item, type) {
      if (type) {
        this.config.type = type
        return
      } else if (this.mod === 'dialog') {
        this.emit('goto', ['chat', { dialogId: item.id, userid: item.user.userid, nickname: item.user.nickname }])
        return
      } else if (this.mod === 'system') {
        return
      }
      if (item.data.is_first) {
        this.goto('thread', { type: 'thread', threadid: item.data.thread_id })
      } else {
        this.goto('reply', { threadid: item.data.thread_id, postid: item.data.reply_post_id })
      }
    },
    // 逻辑删除
    deleteItems() {
      let deleteIds = {}
      if (this.mod === 'dialog') deleteIds = this.state.deleteDialogMessage
      if (this.mod === 'social') deleteIds = this.state.deleteSocialMessage
      if (this.mod === 'system') deleteIds = this.state.deleteSystemMessage
      const ids = []
      for (var id in deleteIds) {
        ids.push(parseInt(id))
      }
      if (ids.length) {
        const items = []
        for (var i in this.data.items) {
          if (ids.indexOf(this.data.items[i].id) < 0) items.push(this.data.items[i])
        }
        this.data.items = items
        if (this.mod === 'dialog') this.state.deleteDialogMessage = {}
        if (this.mod === 'social') this.state.deleteSocialMessage = {}
        if (this.mod === 'system') this.state.deleteSystemMessage = {}
        this.renderItems(this.data.items, true)
      }
      this.data.canDelete = false
      this.data.deleteMod = false
      this.data.deleteIds = {}
      this.update()
    },
    // 移除
    removeItem(id) {
      for (var i in this.data.items) {
        if (this.data.items[i].id === id) {
          this.data.items.splice(i, 1)
        }
      }
      this.renderItems(this.data.items, true)
    },
    // 设置属性
    setItem(id, key, value) {
      for (var i in this.data.items) {
        if (this.data.items[i].id === id) {
          this.data.items[i][key] = value
          return this.data.items[i]
        }
      }
    },
    scrollContainer() {
      const main = document.getElementById('message-' + this.mod + '-container')
      if (main) {
        const scroll = main.getElementsByClassName('scroll-container')
        if (scroll.length) return scroll[0]
      }
    },
    onResize() {
      if (!this.config.show && !this.view.chat.show) return
      this.calcSize()
      // this.backTop()
      // this.data.scrollTop = 0
      this.renderItems(this.data.items, true)
    },
    backTop() {
      let y = 0
      if (this.mod === 'chat') y = 9999999
      const container = this.scrollContainer()
      if (container) {
        setTimeout(() => {
          container.scrollTo({ left: 0, top: y, behavior: 'smooth' })
        }, 100)
      }
    },
    renewView() {
      this.backTop()
      this.loadData(() => {}, 'renew')
    },
    showItem(item) {
      if (item._top <= this.data.scrollTop - this.height && item._top + item._height >= this.data.scrollTop - this.height) return true
      if (item._top >= this.data.scrollTop - this.height && item._top <= this.data.scrollTop + this.height + this.height) return true
      return false
    },
    formateTime: utils.formateTime,
    formateNums: utils.formateNums,
    // 调用父组件方法
    emit(fn, params) {
      if (!fn) return
      this.$emit('receive', fn, params || [])
    },
    // 提示信息
    message(msg, type) {
      this.emit('message', [msg, type])
    },
    // 确认操作
    confirm(msg, fn, title) {
      this.emit('confirm', [msg, fn, title])
    },
    getMinHeight(arr) {
      const a = []
      for (let i = 0; i < arr.length; i++) {
        a.push(parseInt(arr[i]._height) + parseInt(arr[i]._top))
      }
      return Math.min.apply(null, a)
    },
    getMinIndex(val) {
      const arrIndex = []
      for (let i = 0; i < this.columns.length; i++) {
        const its = this.columns[i].items
        const height = its[its.length - 1]._height
        const top = its[its.length - 1]._top
        if (parseInt(height) + parseInt(top) === val) {
          arrIndex.push(i)
        }
      }
      return arrIndex
    },
    calcItemSize(w, h) {
      let height = Math.round(this.cardWidth * h / w)
      height = Math.min(height, Math.round(this.height * 2 / 5))
      const width = Math.round(height * w / h)
      return { width: width, height: height }
    },
    addMessageTypeItem(items) {
      if (this.mod !== 'dialog' || (this.mod === 'dialog' && this.initMessageTypeItem)) return items
      const typeItems = [{
        unreadCount: this.state.unread.system || 0,
        summary: '',
        user: {
          type: 'system',
          userid: 0,
          nickname: '服务通知'
        }
      }, {
        unreadCount: this.state.unread.social || 0,
        summary: '',
        user: {
          type: 'social',
          userid: 0,
          nickname: '互动消息'
        }
      }]
      items = typeItems.concat(items)
      return items
    },
    renderItems(items, refresh = false) {
      if (refresh) this.data.columns = []
      if (!this.initMessageTypeItem || refresh) items = this.addMessageTypeItem(items)
      if (!items.length) return
      // 初始化列、列的第一个元素
      let idx = 0
      if (this.columns.length < this.maxColumns || refresh) {
        for (let i = 0; i < this.maxColumns; i++) {
          if (!this.columns[i] && items[idx]) {
            items[idx]._top = 0
            items[idx]._raw_width = this.thumbSize
            items[idx]._raw_height = 0
            items[idx]._height = items[idx]._raw_height + this.fixHeight
            this.columns.push({ items: [items[idx]] })
            idx++
          }
        }
      }
      // 对剩余元素的判断，应该放到哪一列
      for (var index = idx; index < items.length; index++) {
        // 找到高度最小的一列，可能有多个
        const tmp = []
        for (let i = 0; i < this.columns.length; i++) {
          const its = this.columns[i].items
          tmp.push({
            _height: its[its.length - 1]._height,
            _top: its[its.length - 1]._top
          })
        }
        // 获取最矮的列高度
        const minHeight = this.getMinHeight(tmp)
        // 获取最矮列的索引
        let minHeightIdx = 0
        const arrIndex = this.getMinIndex(minHeight)
        // 出现高度一样的，取索引最小的
        if (arrIndex.length > 0) {
          minHeightIdx = Math.min.apply(null, arrIndex)
        }
        // 设置属性
        items[index]._top = minHeight
        items[index]._raw_width = this.thumbSize
        items[index]._raw_height = 0
        items[index]._height = items[index]._raw_height + this.fixHeight
        this.columns[minHeightIdx].items.push(items[index])
      }
    },
    calcSize() {
      if (this.state.platform.type === 'desktop' && this.view.message.pop) {
        this.container_width = 632
        this.container_height = 632
      } else {
        this.container_width = utils.width()
        this.container_height = utils.height()
      }
      this.width = this.container_width - 3
      this.height = this.container_height - 95 - this.headHeight
    },
    init(backTop) {
      if (backTop) this.backTop()
      this.dt[this.mod] = utils.deepClone(this.schema)
      this.emit('setDeleteData', [false, []])
    },
    update() {
      this.$forceUpdate()
    },
    updateData(updateHash) {
      if (updateHash && !this.config.pop) this.emit('setLocationHash')
      if (!this.itemNums || (this.mod === 'chat' && (this.userid !== this.view.chat.userid || this.dialogId !== this.view.chat.dialogId))) {
        this.getData('down', true)
      } else {
        this.update()
      }
    },
    getAvatar(item) {
      if (!item || !item.avatar) return ''
      const ver = item.avatar_at ? Math.round(new Date(item.avatar_at) / 1000) : ''
      return service.getCosUrl(item.avatar, 'avatar', ver) || ''
    },
    getContent(item, split) {
      if (!item || !item.summary) return ''
      return utils.fomateContent(item.summary, split)
    },
    moreRows(yes) {
      this.data.rows = (yes || this.data.content) ? 3 : 1
    },
    send() {
      const dialogId = this.view.chat.dialogId || 0
      const message = this.data.content
      const recipientUserId = dialogId > 0 ? 0 : this.view.chat.userid || 0
      if (!utils.checkNow('str2-1-225', message, true)) return this.message('请输入内容', 'error')
      service.sendMessage(dialogId, message, false, (res, type) => {
        if (type === 'success') {
          this.data.content = ''
          this.data.rows = 1
          if (recipientUserId && this.dialogId === 0 && res.dialogId) {
            this.view.chat.dialogId = res.dialogId
            if (!this.config.pop) this.emit('setLocationHash')
          }
          this.renewView()
          service.incCount(this.loginUserId, 'chat')
        } else {
          this.message(res, type)
        }
      }, recipientUserId)
    },
    loadData(loaded, direction) {
      if (this.config.show || this.view.chat.show) {
        this.renew = direction === 'renew'
        if (direction === 'renew') {
          direction = 'down'
          this.data.currentPage = 0
          this.data.cursorValue = 0
          this.data.cursorValueUp = 0
          this.data.cursorValueDown = 0
          this.data.cursorSkipUp = 0
          this.data.cursorSkipDown = 0
          this.data.skip = 0
          this.data.noUpPage = false
          this.data.noDownPage = false
          this.emit('getUnread')
        }
        this.getData(direction, direction === 'renew', () => {
          this.renew = false
          loaded('done')
        })
      }
    },
    // 分页读取数据
    async getData(direction, init, cb) {
      direction = direction || 'down'
      if (!this.config.show && !this.view.chat.show) return cb && cb()
      if (direction === 'up' && this.data.noUpPage) return cb && cb()
      if (direction === 'down' && this.data.noDownPage) return cb && cb()
      if (this.loading[this.mod]) return cb && cb()
      if (init) this.init()
      this.loading[this.mod] = true
      const params = {
        'sort_field': 'updated_at',
        'sort': 'desc',
        'limit': this.limit,
        'skip': direction === 'up' ? this.data.cursorSkipUp : this.data.cursorSkipDown,
        'cursor_value': direction === 'up' ? this.data.cursorValueUp : this.data.cursorValueDown,
        'direction': direction,
        'count_total': 1
      }
      if (this.mod) {
        params.mod = this.mod
      } else {
        params.parentid = 0
      }
      params.userid = this.loginUserId
      let source = ''
      if (this.mod === 'chat') {
        source = 'dialog_message'
        this.userid = parseInt(this.view.chat.userid)
        this.dialogId = parseInt(this.view.chat.dialogId)
        params.dialog_id = this.dialogId
        if (!this.dialogId) {
          params.dialog_userid = this.userid
        }
      } else if (this.mod === 'dialog') {
        source = 'dialog'
      } else if (this.mod === 'social') {
        source = 'notifications'
        params.types = 'related,replied,liked'
        // filter.types = filter.type + ',rewarded,threadrewarded,receiveredpacket,threadrewardedexpired'
      } else if (this.mod === 'system') {
        source = 'notifications'
        params.types = 'system'
        // filter.types = filter.type + ',withdrawal'
      }
      if (!source) {
        this.loading[this.mod] = false
        return cb && cb()
      }
      // console.error('params', this.mod, params, direction, this.data.currentPage, this.data, direction === 'up', this.data.cursorValueUp, this.data.cursorValueDown)
      service.listGet(source, params, (res, type) => {
        if (type === 'success') {
          let refresh = false
          if (direction === 'up') {
            refresh = true
            this.data.cursorValueUp = res.data.cursor_value_up
            this.data.cursorSkipUp = res.data.cursor_skip_up
            if (!this.data.cursorValueDown && !utils.isEmpty(res.data.cursor_value_down)) this.data.cursorValueDown = res.data.cursor_value_down
            this.data.noUpPage = utils.isEmpty(this.data.cursorValueUp)
          } else if (direction === 'down') {
            if ((res.data.count === this.limit && this.data.currentPage >= this.remainPages) || this.renew) {
              if (res.data.count === this.limit && this.data.currentPage >= this.remainPages) {
                this.init(true)
                this.showUp = true
              } else {
                this.init()
              }
              refresh = true
            }
            this.data.currentPage++
            if (!this.data.cursorValueUp && !utils.isEmpty(res.data.cursor_value_up)) this.data.cursorValueUp = res.data.cursor_value_up
            this.data.cursorValueDown = res.data.cursor_value_down
            this.data.cursorSkipDown = res.data.cursor_skip_down
            this.data.noDownPage = utils.isEmpty(this.data.cursorValueDown)
          }
          this.data.skip = utils.getInt(res.data.skip)
          const items = res.data.items
          if (items.length) {
            for (var idx in items) {
              if (this.mod === 'chat') {
                items[idx].summary = (items[idx].message_text) ? (items[idx].message_text.message_text || '') : ''
                items[idx].time = this.formateTime(items[idx].created_at)
                items[idx].user = res.data.users[items[idx].user_id] || {}
                this.view.chat.dialogId = res.data.dialog_id
              } else if (this.mod === 'dialog') {
                const message = res.data.messages[items[idx].dialog_message_id]
                items[idx].summary = (message && message.message_text) ? message.message_text.message_text : ''
                items[idx].time = (message && message.created_at) ? this.formateTime(message.created_at) : ''
                if (items[idx].sender_user_id === this.loginUserId) {
                  items[idx].user = res.data.users[items[idx].recipient_user_id] || {}
                  items[idx].unreadCount = items[idx].sender_read_at ? 0 : 1
                } else {
                  items[idx].user = res.data.users[items[idx].sender_user_id] || {}
                  items[idx].unreadCount = items[idx].recipient_read_at ? 0 : 1
                }
              } else if (this.mod === 'system') {
                items[idx].user = {
                  userid: 0,
                  avatar: '',
                  nickname: items[idx].data.title
                }
                items[idx].summary = items[idx].data.content
                items[idx].time = this.formateTime(items[idx].created_at)
              } else {
                items[idx].user = res.data.users[items[idx].data.user_id] || {}
                const acts = {
                  'liked': '点赞',
                  'replied': '回复',
                  'related': '@'
                }
                let target = ''
                if (items[idx].type !== 'related') {
                  target = ''
                  if (items[idx].data.is_first) {
                    target = '的主题'
                  } else {
                    target = '的评论'
                  }
                }
                items[idx].summary = '<span class="act">' + acts[items[idx].type] + '了你' + target + '</span> ' + (items[idx].data.thread_title || items[idx].data.post_content)
                items[idx].time = this.formateTime(items[idx].created_at)
                items[idx].unreadCount = 0
              }
            }
          }
          this.data.items = this.data.items.concat(items)
          if (this.mod === 'chat') {
            this.data.chatItems = items.reverse().concat(this.data.chatItems)
            this.update()
            this.backTop()
          } else {
            this.renderItems(items, refresh)
          }
        } else {
          this.message(res, type)
        }
        cb && cb()
        this.loading[this.mod] = false
      }, true, false)
    },
    // 获取关注状态
    getFollowState(items) {
      if (!items) return
      const ids = []
      for (var i in items) {
        const userid = items[i].userid
        if (typeof this.followState[userid] === 'undefined' && parseInt(userid) !== this.loginUserId) {
          ids.push(userid)
        }
      }
      if (ids.length) {
        service.batchGet('user_follow', ids, (res, type) => {
          if (type === 'success' && res.data) {
            for (const userid in res.data) {
              this.followState[userid] = res.data[userid]
            }
            this.update()
          }
        })
      }
    },
    // 关注
    follow(userid) {
      this.followState[userid] = 1
      this.update()
      this.emit('follow', [userid, (res, type) => {
        if (type === 'success') {
          this.followState[userid] = res.isMutual
        } else {
          // 回滚
          this.followState[userid] = -1
          this.message(res, type)
        }
        this.update()
      }])
    },
    // 取消关注
    unfollow(userid) {
      this.followState[userid] = -1
      this.update()
      this.emit('unfollow', [userid, (res, type) => {
        if (type === 'success') {
          this.followState[userid] = -1
        } else {
          // 回滚
          this.followState[userid] = 0
          this.message(res, type)
        }
        this.update()
      }])
    }
  }
}
