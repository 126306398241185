<template>
  <div style="width:100%;height:100%;">
    <pull-to :id="'album-' + mod + '-container'" :style="listStyle" class="my-list" :top-load-method="(loaded) => loadData(loaded, showUp ? 'up' : 'renew')" :bottom-load-method="(loaded) => loadData(loaded, 'down')" @infinite-scroll="getData('down')">
      <div class="flow-container">
        <div v-for="(column, index) in columns" :key="index" class="column" :style="{'width': columnWidth + 'px' }">
          <div v-for="(item, i) in column.items" :key="i" class="item">
            <div class="card" :style="{ 'width': cardWidth + 'px'}">
              <div>
                <div class="top">
                  <div class="left" @click="emit('goto', ['albumWorks', { albumid: item.albumid }])">
                    <span v-if="!item.check" class="tag bg-red white">封</span>
                    <!-- <span v-if="item.original" class="tag">原</span> <span v-if="item.best" class="tag">荐</span> -->
                    {{ item.name }} <i class="iconfont my-right size14" />
                  </div>
                  <div class="right">
                    <el-button class="tap-btn" @click="showActionSheet(item)">
                      <i class="iconfont my-dot-more-v" />
                    </el-button>
                  </div>
                </div>
                <div class="horiz-scroll">
                  <div v-if="data.totals[item.albumid]" v-finger:touch-start="onTouchStart" v-finger:touch-end="onTouchEnd" class="scroll-works" :style="{'width': (item._raw_width + ((data.totals[item.albumid] && data.totals[item.albumid]) > 5 ? 40 : 10)) + 'px', 'height': (maxThumbHeight + 50) + 'px' }">
                    <div v-for="(work, idx) in data.works[item.albumid]" :key="idx" class="bg-28 round8" :style="{ width: maxThumbWidth + 'px', height: (maxThumbHeight + 40) + 'px', margin: '0 5px'}">
                      <div :style="{ width: maxThumbWidth + 'px', height: maxThumbHeight + 'px', 'padding-top': work._height < maxThumbHeight ? (maxThumbHeight - work._height) / 2 + 'px' : ''}">
                        <img :src="worksHost + 'work/action/download/gridy/size/8/workid/' + work.workid + '/' + work.name + '.png?ver=' + work.update_at" :style="{ width: work._width + 'px', height: work._height + 'px'}" :class="{'round-top': maxThumbHeight - 16 < work._height}" @click="openViewer(0, work.workid)">
                      </div>
                      <div class="ellipsis align-center padding-top8 size12" :style="{ width: maxThumbWidth + 'px' }">
                        {{ work.name }}
                      </div>
                    </div>
                    <div v-if="(data.totals[item.albumid] && data.totals[item.albumid]) > 5" class="more" :style="{ 'height': (maxThumbHeight + 40) + 'px', 'line-height': (maxThumbHeight + 40) + 'px' }" @click="emit('goto', ['albumWorks', { albumid: item.albumid }])">
                      <i class="iconfont my-right color-cc" />
                    </div>
                  </div>
                  <div v-if="!data.totals[item.albumid]" class="empty" style="padding-top: 80px;">
                    <i class="iconfont my-empty" />
                    <br><span class="gray">空专辑</span>
                  </div>
                </div>
                <div class="foot">
                  <div class="left" @click="emit('goto', ['user', { userid: item.userid } ])">
                    <span v-if="!users[item.userid].avatar" class="el-avatar el-avatar--circle noavatar small">
                      <i class="iconfont my-man" />
                    </span>
                    <el-avatar v-if="users[item.userid].avatar" :size="24" :src="getAvatar(item)" />
                  </div>
                  <div class="center ellipsis" style="width:calc(100% - 120px);" @click="emit('goto', ['user', { userid: item.userid } ])">
                    {{ (users[item.userid]) ? users[item.userid].nickname : view.anonymity }}
                  </div>
                  <div class="right">
                    <el-button class="tap-btn" @click="() => favorIt(item.albumid)">
                      <i class="iconfont" :class="{ 'my-star' : favorCount[item.albumid], 'my-blank-star' : !favorCount[item.albumid] }" />
                    </el-button>
                  </div>
                  <div class="right">
                    <span>{{ favorState[item.albumid] && favorState[item.albumid] > 0 ? formateNums(favorState[item.albumid]) : '' }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="itemNums && !data.noDownPage" class="tip load" @click="getData('down')"><i class="iconfont my-dot-more" /></div>
      <div v-if="flowHeight > height && data.noDownPage" class="tip">已显示全部内容</div>
      <el-empty v-if="(!itemNums && !loading[mod])" :image="emptyImage" description="这里空空的" />
    </pull-to>
    <div v-if="data.scrollTop > 200" class="go-top">
      <el-button class="tap-btn" @click="backTop()">
        <i class="iconfont my-top" />
      </el-button>
    </div>
  </div>
</template>
<script>
import { mixins } from '@/mixins/album'
export default {
  mixins: [mixins],
  methods: {
    onTouchStart() {
      this.swipeStart = new Date()
      this.emit('setLockSwipe', [true])
    },
    onTouchEnd() {
      this.swipeEnd = new Date()
      if (this.swipeEnd - this.swipeStart > 200) {
        setTimeout(() => {
          this.emit('setLockSwipe', [false])
        }, 100)
      } else {
        this.emit('setLockSwipe', [false])
      }
    }
  }
}
</script>
