<template>
  <div style="width:100%;height:100%;">
    <pull-to :id="'order-' + mod + '-container'" :style="listStyle" class="my-list" :top-load-method="(loaded) => loadData(loaded, showUp ? 'up' : 'renew')" :bottom-load-method="(loaded) => loadData(loaded, 'down')" @infinite-scroll="getData('down')">
      <div class="flow-container">
        <div v-for="(column, index) in columns" :key="index" class="column" :style="{'width': columnWidth + 'px' }">
          <div v-for="(item, i) in column.items" :key="i" class="item">
            <div class="card" :style="{ 'width': cardWidth + 'px', height: '98px' }">
              <div v-finger:long-tap="() => longTap(item.brick_orderid)" class="order">
                <div class="left" @click="() => openDetail(item.brick_orderid)">
                  <img v-if="item.image && item.diy" :src="getOrderImage(item)" class="round4" :style="imgStyle(data.works[item.workid])">
                  <img v-if="item.image && !item.diy && item.suites && item.suites.length" :src="cdnHost + item.suites[0].image + '?v=1.06'" class="round4" :style="imgStyle(data.works[item.workid])">
                  <img v-if="!item.image && item.workid" :src="worksHost + 'work/action/download/gridy/size/8/workid/' + item.workid + '/' + item.workid + '.png?ver=' + item.update_at" class="round4" :style="imgStyle(data.works[item.workid])">
                </div>
                <div class="summary float-left" style="width: calc(100% - 130px);">
                  <div @click="() => openDetail(item.brick_orderid)">
                    <el-row class="padding-top4 padding-bottom4 ellipsis">
                      {{ item.name }} <span class="color-99 size12">{{ formateTime(item.add_at) }}</span>
                    </el-row>
                    <el-row class="padding-bottom4">
                      <div class="color-99">{{ item.info }}</div>
                    </el-row>
                  </div>
                  <div class="padding-top4" @click="() => openDetail(item.brick_orderid)">
                    <el-col v-if="item.amount" :span="12">
                      <span class="yellow">
                        ¥{{ formateMoney(item.amount) }}
                      </span>
                    </el-col>
                    <el-col :span="item.amount ? 12 : 24">
                      <div v-if="item.pay_type !== -1" class="acts">
                        <span v-if="item.pay_status < 0" class="color-99">{{ item.pay_status === -1 ? '支付失败' : (item.pay_status === -2 ? '已取消' : (item.pay_status === -3 ? '已过期' : '')) }}</span>
                        <span v-if="item.pay_status === 0" class="blue" @click="payIt(item)">立即支付</span>
                      </div>
                      <div v-if="item.pay_type === -1 || (item.pay_type !== -1 && item.pay_status === 1)" class="acts">
                        <span v-if="item.pay_status && !item.delivery_status" class="white">待发货</span>
                        <span v-if="item.pay_status && item.delivery_status && !item.receipt_status" class="blue">待收货</span>
                        <span v-if="item.pay_status && item.delivery_status && item.receipt_status" class="green">已完成</span>
                        <!-- <span v-if="item.pay_status && item.delivery_status && item.receipt_status && !item.is_comment" class="yellow">待评价</span>
                        <span v-if="item.pay_status && item.delivery_status && item.receipt_status && item.is_comment" class="green">已完成</span> -->
                      </div>
                    </el-col>
                  </div>
                </div>
                <div v-if="data.deleteMod" class="select-btn padding-top4" @click="select(item.brick_orderid)">
                  <i class="iconfont my-ok" :class="{ gray: !data.deleteIds[item.brick_orderid], blue: data.deleteIds[item.brick_orderid]}" />
                </div>
              </div>
              <!-- <span v-if="item.type === 1 && orderTypes[item.type]" class="tag" style="position: relative;top: -25px;left: 5px;">{{ orderTypes[item.type] }}</span> -->
            </div>
          </div>
        </div>
      </div>
      <div v-if="!loading[mod] && itemNums && !data.noDownPage" class="tip load" @click="getData('down')"><i class="iconfont my-dot-more" /></div>
      <div v-if="flowHeight > height && data.noDownPage" class="tip">已显示全部内容</div>
      <el-empty v-if="(!itemNums && !loading[mod])" :image="emptyImage" description="这里空空的" />
    </pull-to>
    <div v-if="data.scrollTop > 200" class="go-top">
      <el-button class="tap-btn" @click="backTop()">
        <i class="iconfont my-top" />
      </el-button>
    </div>
  </div>
</template>
<script>
import { mixins } from '@/mixins/order'
export default {
  mixins: [mixins]
}
</script>
