<template>
  <div class="bottombar" :style="bottombarStyle">
    <div v-if="state.showScenes" :style="{'width': state.play ? '100vw!important' : 'calc(100vw - 42px)!important'}" style="height: 84px;text-align: left;border-top: 1px solid var(--color-1b);" class="objs-div">
      <el-row>
        <el-button v-if="!state.play" :disabled="scenes.length <= 1" class="iconfont my-play" title="播放" @click="emit('startPlay')" />
        <el-button v-if="state.play" :disabled="scenes.length <= 1" class="iconfont my-stop" title="停止" @click="emit('stopPlay')" />
        <el-popover v-model="animationSetShow" placement="top-start" offset="4" trigger="click">
          播放帧率：{{ rate }}帧/秒
          <el-slider v-model="rate" :min="1" :max="24" :show-tooltip="false" />
          播放次数 &nbsp;
          <el-radio-group v-model="loop">
            <el-radio :label="1">1次</el-radio>
            <el-radio :label="2">2次</el-radio>
            <el-radio :label="0">永久</el-radio>
          </el-radio-group>
          <el-button slot="reference" :disabled="scenes.length <= 1" class="iconfont my-remain" title="动画设置" />
        </el-popover>
        <el-button class="iconfont my-scenes-add" title="添加场景" @click="emit('addScene')" />
        <el-button :disabled="!scenes.length" class="iconfont my-copyparse" title="拷贝" @click="emit('copyScene', [state.sceneIdx])" />
        <el-popconfirm v-if="scenes.length > 1" title="确定删除该场景吗？" cancel-button-type="Primary" placement="top" @confirm="emit('delScene', [state.sceneIdx])">
          <el-button slot="reference" class="iconfont my-delete" title="删除" />
        </el-popconfirm>
      </el-row>
      <el-row class="padding4" style="border-top: 1px solid var(--color-1b);overflow-x: auto;">
        <div :style="{'width': scenes.length * 46 + 'px'}">
          <div v-for="(scene, idx) in scenes" :key="scene.id" :class="state.sceneIdx === idx ? 'select' : ''">
            <div class="obj-img" @click="emit('selectScene', [idx])">
              <scenes-preview
                :key="'scenes-preview:' + state.sceneId"
                :idx="idx"
                :file="file"
              />
            </div>
          </div>
        </div>
      </el-row>
    </div>
    <div v-show="!state.play" :style="toolbarStyle">
      <div style="width:400px;margin: 0 auto;">
        <el-dropdown v-if="file && file.type === 0" trigger="click" placement="top-start" @command="(e) => emit('setPalette', [e])">
          <el-button class="iconfont my-palette" :disabled="!btnStatus.setPalette" title="对象调色" />
          <el-dropdown-menu slot="dropdown" class="my-dropdown">
            <el-dropdown-item v-if="curObj.type === 'image'" command=""> 原色 <span class="float-right align-right" :class="curObj.paletteId === '' ? 'iconfont my-check' : ''" /></el-dropdown-item>
            <el-dropdown-item v-if="curObj.type === 'image'" command="64"> 精简色 <span class="float-right align-right" :class="curObj.paletteId === '64' ? 'iconfont my-check' : ''" /></el-dropdown-item>
            <!-- <el-dropdown-item v-if="curObj.type === 'image'" command="32"> 32色 <span class="float-right align-right" :class="curObj.paletteId === '32' ? 'iconfont my-check' : ''" /></el-dropdown-item>
            <el-dropdown-item v-if="curObj.type === 'image'" command="16"> 16色 <span class="float-right align-right" :class="curObj.paletteId === '16' ? 'iconfont my-check' : ''" /></el-dropdown-item>
            <el-dropdown-item v-if="curObj.type === 'image'" command="8"> 8色 <span class="float-right align-right" :class="curObj.paletteId === '8' ? 'iconfont my-check' : ''" /></el-dropdown-item> -->
            <el-dropdown-item v-for="item in palette.opts" v-show="item.value !== 'default'" :key="item.value" :command="item.value">
              {{ item.label }}
              <span class="float-right align-right" :class="curObj.paletteId === item.value ? 'iconfont my-check' : ''" />
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown trigger="click" placement="top-start" @command="(e) => emit('setFillShape', [e])">
          <el-button class="iconfont my-shape" :disabled="!btnStatus.gridShape" title="网格形状" />
          <el-dropdown-menu slot="dropdown" class="my-dropdown">
            <el-dropdown-item command="square"><i class="iconfont my-square-shape" /> 方形 <span class="float-right align-right" :class="curObj.fillShape === 'square' ? 'iconfont my-check' : ''" /></el-dropdown-item>
            <el-dropdown-item command="circle"> <i class="iconfont my-circle-shape" /> 圆形 <span class="float-right align-right" :class="curObj.fillShape === 'circle' ? 'iconfont my-check' : ''" /></el-dropdown-item>
            <!-- <el-dropdown-item command="triangle"> <i class="iconfont my-triangle-shape" /> 三角形 <span class="float-right align-right" :class="curObj.fillShape === 'triangle' ? 'iconfont my-check' : ''" /></el-dropdown-item> -->
            <el-dropdown-item command=""> <i class="iconfont my-square-full" /> 默认 <span class="float-right align-right" :class="curObj.fillShape === '' ? 'iconfont my-check' : ''" /></el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button class="iconfont my-colorfilter" :disabled="!btnStatus.colorFilter" title="色相/饱和度" @click="openColorFilter()" />
        <el-button class="iconfont my-denoise" :disabled="!btnStatus.denoise" title="降噪" @click="openDenoise" />
        <el-popover v-model="mngColorShow" placement="top-start" offset="4" popper-class="padding4" width="260" trigger="click">
          <div class="my-title-bar padding-left8 padding-bottom4" style="border-bottom: 1px solid var(--color-22);">
            对象颜色 <span v-if="state.objColors.count">({{ state.objColors.count > 128 ? '128+' : state.objColors.count }})</span>
            <el-button :type="state.colorOrder === 'desc' ? 'light' : ''" class="iconfont my-sort-desc padding4 float-right" title="倒序" @click="toggleColorOrder" />
            <el-button class="iconfont my-denoise padding4 margin-right4 float-right" :disabled="!btnStatus.denoise" title="降噪" @click="openDenoise()" />
          </div>
          <div class="my-colors">
            <div :style="{ width: '100%', height: '206px', 'overflow-x': 'hidden', 'overflow-y': 'auto'}">
              <el-row v-for="(color, idx) in state.objColors.colors" :key="'in_' + idx">
                <el-col :span="3"><div class="preview" :title="color[0].toUpperCase()" :style="{ background: color[0] }" /></el-col>
                <el-col :span="7" :title="color[0].toUpperCase()">
                  <span v-if="editColor.idx !== idx" @click="toggleColorEditer(idx, true)">{{ color[0].toUpperCase() }}</span>
                  <el-input v-if="editColor.idx === idx" v-model.trim="editColor.newColor" type="input" maxlength="7" onkeyup="this.value = this.value.replace(/[^#0-9a-fA-F.]/g,'')" @blur="changeColor(idx)" />
                </el-col>
                <el-col :span="4" title="更换颜色">
                  <i class="iconfont my-change main-color" @click="(event) => {emit('toggleColorPicker', [event, 'change-color', [color[0].toUpperCase()]])}" />
                </el-col>
                <el-col :span="6" title="颜色数量">{{ color[1] }}</el-col>
                <el-col :span="4" title="去除颜色" class="align-right">
                  <el-button :disabled="state.objColors.count <= 2" class="iconfont my-delete padding4 margin0" title="删除" @click="emit('deleteObjColor', [color[0]])" />
                </el-col>
              </el-row>
            </div>
          </div>
          <el-button slot="reference" class="iconfont my-color-off" :disabled="!btnStatus.mngColors" title="颜色管理" />
        </el-popover>
        <!-- <el-popconfirm title="确定清除对象吗？" cancel-button-type="Primary" placement="top" @confirm="emit('clear')">
          <el-button slot="reference" class="iconfont my-clear" :disabled="!btnStatus.clear" title="清除对象" />
        </el-popconfirm> -->
        <el-popconfirm title="确定恢复图像？" cancel-button-type="Primary" placement="top" @confirm="emit('restore')">
          <el-button slot="reference" class="iconfont my-restore" :disabled="!btnStatus.restore" title="恢复图像" />
        </el-popconfirm>
        <el-dropdown trigger="click" placement="top-start" size="medium" @command="(e) => emit('rotate', [e])">
          <el-button class="iconfont my-rotate-left" :disabled="!btnStatus.rotate" title="旋转" />
          <el-dropdown-menu slot="dropdown" class="my-dropdown">
            <el-dropdown-item :command="'w'" class="iconfont my-rotate-right"> 顺时针旋转90°</el-dropdown-item>
            <el-dropdown-item :command="'i'" class="iconfont my-rotate-left"> 逆时针旋转90°</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown trigger="click" placement="top-start" @command="(e) => emit('flip', [e])">
          <el-button class="iconfont my-flip-h" :disabled="!btnStatus.flip" title="翻转" />
          <el-dropdown-menu slot="dropdown" class="my-dropdown">
            <el-dropdown-item :command="'h'" class="iconfont my-flip-h"> 水平翻转</el-dropdown-item>
            <el-dropdown-item :command="'v'" class="iconfont my-flip-v"> 垂直翻转</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown trigger="click" placement="top-start" @command="(e) => emit('setLayer', [e])">
          <el-button class="iconfont my-lay-down" title="排列" :disabled="!btnStatus.layTop && !btnStatus.layBottom && !btnStatus.layUp && !btnStatus.layDown" />
          <el-dropdown-menu slot="dropdown" class="my-dropdown">
            <el-dropdown-item :command="'top'" class="iconfont my-lay-top" :disabled="!btnStatus.layTop"> 置于顶层</el-dropdown-item>
            <el-dropdown-item :command="'bottom'" class="iconfont my-align-bottom" :disabled="!btnStatus.layBottom"> 置于底层</el-dropdown-item>
            <el-dropdown-item :command="'+'" class="iconfont my-lay-up" :disabled="!btnStatus.layUp"> 上移一层</el-dropdown-item>
            <el-dropdown-item :command="'-'" class="iconfont my-lay-down" :disabled="!btnStatus.layDown"> 下移一层</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown trigger="click" placement="top-start" @command="(e) => emit('align', [e])">
          <el-button class="iconfont my-align-left" title="对齐" :disabled="!btnStatus.alignLeft && !btnStatus.alignCenter && !btnStatus.alignRight && !btnStatus.alignTop && !btnStatus.alignMiddle && !btnStatus.alignBottom && !btnStatus.alignSpaceH && !btnStatus.alignSpaceV" />
          <el-dropdown-menu slot="dropdown" class="my-dropdown">
            <el-dropdown-item :command="'left'" class="iconfont my-align-left" :disabled="!btnStatus.alignLeft"> 左对齐</el-dropdown-item>
            <el-dropdown-item :command="'center'" class="iconfont my-align-center" :disabled="!btnStatus.alignCenter"> 水平居中</el-dropdown-item>
            <el-dropdown-item :command="'right'" class="iconfont my-align-right" :disabled="!btnStatus.alignRight"> 右对齐</el-dropdown-item>
            <el-dropdown-item :command="'top'" class="iconfont my-align-top" :disabled="!btnStatus.alignTop"> 顶对齐</el-dropdown-item>
            <el-dropdown-item :command="'middle'" class="iconfont my-align-middle" :disabled="!btnStatus.alignMiddle"> 垂直居中</el-dropdown-item>
            <el-dropdown-item :command="'bottom'" class="iconfont my-align-bottom" :disabled="!btnStatus.alignBottom"> 底对齐</el-dropdown-item>
            <el-dropdown-item :command="'spaceH'" class="iconfont my-align-space-h" :disabled="!btnStatus.alignSpaceH"> 水平等间距</el-dropdown-item>
            <el-dropdown-item :command="'spaceV'" class="iconfont my-align-space-v" :disabled="!btnStatus.alignSpaceV"> 垂直等间距</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button class="iconfont my-group" :disabled="!btnStatus.group" title="组合" @click="emit('group')" />
        <el-button class="iconfont my-ungroup" :disabled="!btnStatus.ungroup" title="取消组合" @click="emit('ungroup')" />
        <el-button class="iconfont my-merge" :disabled="!btnStatus.merge" title="合并" @click="emit('merge')" />
        <!-- <el-button class="iconfont my-eye-off" :disabled="!btnStatus.hide" title="隐藏" @click="() => emit('hideSelectObjs')" />
        <el-button :disabled="!btnStatus.lock" class="iconfont my-lock" title="锁定" @click="() => emit('lockSelectObjs')" />
        <el-button :disabled="!btnStatus.unlock" class="iconfont my-unlock" title="解锁" @click="() => emit('unlockSelectObjs')" />
        <el-button class="iconfont my-copyparse" :disabled="!btnStatus.copy" title="复制" @click="() => emit('copyAndParse', [state.objIdx])" />
        <el-button class="iconfont my-delete" :disabled="!btnStatus.del" title="删除" @click="() => emit('delete', [false])" /> -->
      </div>
    </div>
    <div v-if="!isDesktop" v-show="!state.play && state.showPalette && !view.resource.show" style="width: calc(100vw - 42px);height: 40px;overflow-x: auto;text-align: left;border-top: 1px solid var(--color-1b);">
      <palette ref="palette" v-model="state.color[0]" :view="view" :state="state" :can-remove="false" :palette-id="curPaletteId" @receive="handle" />
    </div>
  </div>
</template>
<script>
import { mixins } from '@/mixins/common'
// import conf from '@/js/conf/conf'
import palette from '@/components/palette'
import scenesPreview from '@/components/scenesPreview'
export default {
  components: {
    palette,
    scenesPreview
  },
  mixins: [mixins],
  props: {
    tmp: {
      type: Object,
      default() {
        return {}
      }
    },
    curObj: {
      type: Object,
      default() {
        return {}
      }
    },
    palette: {
      type: Object,
      default() {
        return {}
      }
    },
    curPaletteId: {
      type: String,
      default() {
        return 'base'
      }
    },
    editColor: {
      type: Object,
      default() {
        return {}
      }
    },
    btnStatus: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    const filter = {
      // 色调
      hue: 0,
      // 饱和度
      saturation: 0,
      // 色明度
      value: 0,
      // 亮度
      brightness: 0,
      // 对比度
      contrast: 0
    }
    return { animationSetShow: false, mngColorShow: false, rate: 12, loop: 0, opts: { filter: filter }}
  },
  computed: {
    // 右侧操作栏宽度
    rightSideWidth() {
      return this.view.rightSide ? (this.view.rightSideWidth + this.view.rightToggleWidth) : this.view.rightToggleWidth
    },
    bottombarStyle() {
      let style = {}
      if (!this.isDesktop) {
        style = { 'width': this.state.play ? '100vw!important' : '', 'left': this.state.play ? '' : (this.view.rightToolBar ? '' : '40px') }
      }
      return style
    },
    toolbarStyle() {
      let style = 'height: 40px;overflow-x: auto;text-align: left;padding-top: 3px;border-top: 1px solid var(--color-1b);'
      if (this.isDesktop) {
        style = style + 'width: calc(100vw - ' + this.rightSideWidth + 'px);'
      } else {
        style = style + 'width: calc(100vw - 42px);'
      }
      return style
    }
  },
  watch: {
    'rate': {
      handler() {
        this.emit('setRateAndLoop', [this.rate, this.loop])
      }
    },
    'loop': {
      handler() {
        this.emit('setRateAndLoop', [this.rate, this.loop])
      }
    },
    'mngColorShow': {
      handler() {
        if (this.mngColorShow) {
          this.getObjColors()
        }
      }
    }
  },
  methods: {
    // 编辑颜色代码
    toggleColorEditer(i, edit) {
      this.editColor['color'] = (this.state.objColors.colors[i] && this.state.objColors.colors[i][0]) ? this.state.objColors.colors[i][0] : ''
      this.editColor['newColor'] = this.editColor['color']
      this.editColor['idx'] = i
      if (!edit) {
        this.editColor.color = ''
        this.editColor.newColor = ''
        this.editColor.idx = -1
      }
    },
    // 更换颜色
    changeColor(i) {
      const color = this.editColor['color'] || ''
      const newColor = this.editColor['newColor'] || ''
      if (/^#([0-9a-f]{6})$/i.test(newColor)) {
        this.emit('changeObjColor', [color, newColor])
      } else {
        if (this.state.objColors.colors[i] && this.state.objColors.colors[i][0]) this.state.objColors.colors[i][0] = color
      }
      setTimeout(() => {
        this.toggleColorEditer(i, false)
      }, 100)
    },
    setColors(palColors) {
      this.$refs['palette'] && this.$refs['palette'].setColors(palColors)
    },
    openDenoise() {
      const fn = () => {
        this.emit('denoise')
      }
      this.emit('openPop', [{ title: '降噪', type: 'denoise', fn: fn }])
    },
    openColorFilter() {
      const fn = () => {
        this.emit('colorFilter')
      }
      this.emit('openPop', [{ title: '色相/饱和度', type: 'colorFilter', fn: fn }])
    },
    toggleColorOrder() {
      if (this.state.colorOrder === 'asc') {
        this.state.colorOrder = 'desc'
      } else {
        this.state.colorOrder = 'asc'
      }
      this.getObjColors(this.state.colorOrder)
    },
    // 获取对象颜色表
    getObjColors(orderby = 'asc') {
      this.emit('getObjColors', orderby)
    }
  }
}
</script>
