<template>
  <el-dialog
    ref="cropDialog"
    :title="title"
    :visible.sync="config.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="!isDesktop"
    :show-close="false"
    :top="marginTop"
    custom-class="pop_page large headless cropDialog"
  >
    <span v-if="isDesktop" slot="title">
      <span class="el-dialog__title">{{ title || '裁剪图片' }}</span>
      <i class="iconfont my-close title-btn" title="关闭" @click="close()" />
    </span>
    <div v-finger:double-tap="submit" :style="cropperStyle" @dblclick="submit">
      <img ref="image" :src="imgFile">
    </div>
    <div slot="footer" class="footer bg-22" style="height: 125px;position: absolute;" :style="{ height: (data.diyType === 'brick' ? '55' : '125') + 'px' }" :class="{'padding-left12 padding-right12': isDesktop}">
      <!-- <el-row>
        <el-button icon="el-icon-zoom-in" @click="cropperzoom(0.05)" />
        <el-button icon="el-icon-zoom-out" @click="cropperzoom(-0.05)" />
        <el-button icon="el-icon-refresh-left" @click="cropperRotate(-90)" />
        <el-button icon="el-icon-refresh-right" @click="cropperRotate(90)" />
      </el-row> -->
      <el-row v-if="data.diyType !== 'brick'" class="padding-top12 padding-bottom12 margin-bottom8" style="overflow-x: auto;">
        <el-radio-group v-model="curRatioid" :style="{'width': '255px'}" @input="select">
          <el-radio-button v-show="data.diyType !== 'brick'" label="none">
            原图
          </el-radio-button>
          <!-- <el-radio-button label="free">
            自由裁剪
          </el-radio-button> -->
          <el-radio-button v-for="ratio in canvasRatio" :key="ratio.id" :label="ratio.id">
            <i class="iconfont size16 margin-right8" :class="ratio.icon" />{{ ratio.label }}
          </el-radio-button>
        </el-radio-group>
      </el-row>
      <el-row>
        <el-col :span="6" class="align-left" :class="{'padding-left8': !isDesktop}">
          <el-button class="tap-btn" @click="close()">
            <i class="iconfont my-close" />
          </el-button>
        </el-col>
        <el-col :span="12" class="size16">
          <br v-if="!(data.diyType === 'brick' && !data.cols && !data.rows)">
          <el-radio-group v-if="data.diyType === 'brick' && !data.cols && !data.rows" v-model="curRatioid" :style="{'width': '120px'}" @input="select">
            <el-radio-button v-for="ratio in canvasRatio" :key="ratio.id" :label="ratio.id">
              <i class="iconfont size16" :class="ratio.icon" />
            </el-radio-button>
          </el-radio-group>
        </el-col>
        <el-col :span="6" class="align-right" :class="{'padding-right8': !isDesktop}">
          <el-button class="tap-btn" @click="submit()">
            <i class="iconfont my-check" />
          </el-button>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>
<script>
import { mixins } from '@/mixins/common'
import utils from '@/js/utils'
import conf from '@/js/conf/conf'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
import blankImage from '@/assets/blank.png'
export default {
  mixins: [mixins],
  props: {},
  data() {
    let width, height, curRatioid
    if (this.state.platform.type === 'desktop') {
      width = 632
      height = 500
      curRatioid = '1-1'
    } else {
      width = utils.width()
      height = utils.height() - 125
      curRatioid = '1-1'
    }
    return {
      cropWidth: 256,
      cropHeight: 256,
      width: width,
      height: height,
      canvasRatio: utils.deepClone(conf.canvasRatio),
      myCropper: null,
      blankImage: blankImage,
      imgFile: blankImage,
      scaleX: 1,
      scaleY: 1,
      curRatioid: curRatioid
    }
  },
  computed: {
    config() {
      return this.view.cropImage
    },
    data() {
      return this.config.data
    },
    title() {
      return this.config.title
    },
    cropperStyle() {
      return { 'width': this.width + 'px', 'height': this.height + 'px', 'overflow': 'hidden', 'background': 'var(--color-00)', 'text-align': 'center' }
    }
  },
  watch: {
    'curRatioid': {
      handler() {
        if (this.myCropper) {
          this.setRatio()
        } else {
          this.init()
        }
      }
    }
  },
  mounted() {
    if (this.data.diyType === 'brick') {
      if (this.state.platform.type === 'desktop') {
        this.height = 580
      } else {
        this.height = utils.height() - 45
      }
    }
    if (this.data.cols && this.data.rows) this.curRatioid = this.calcRatioId(this.data.cols, this.data.rows)
    this.initCropper()
  },
  methods: {
    calcImageSize(w, h, maxWidth, maxHeight) {
      let width, height
      if (w < maxWidth && h < maxHeight) {
        return { width: w, height: h }
      } else if (w / h > maxWidth / maxHeight) {
        width = maxWidth
        height = Math.round(width * h / w)
      } else {
        height = maxHeight
        width = Math.round(height * w / h)
      }
      return { width: width, height: height }
    },
    initCropper() {
      if (this.data.file.size > 1024 * 1024) this.loading(true, '加载中...')
      setTimeout(() => {
        const image = this.$refs.image
        this.imgFile = window.URL.createObjectURL(this.data.file)
        if (image) {
          image.onload = () => {
            const size = this.calcImageSize(image.width, image.height, this.width, this.height)
            image.style.width = size.width + 'px'
            image.style.height = size.height + 'px'
            image.style['margin-top'] = (this.height - size.height) / 2 + 'px'
            this.init()
            setTimeout(() => {
              this.loading(false)
            }, 100)
          }
        }
      }, 100)
    },
    loading(show, tip) {
      this.emit('loading', [!!show, tip])
    },
    close() {
      if (this.$refs.image) this.$refs.image.src = blankImage
      this.closePop(this.config)
      setTimeout(() => {
        this.destroyCropper()
        this.closePop(this.config)
      }, 100)
    },
    init() {
      if (this.curRatioid === 'none' || !this.$refs.image) {
        this.destroyCropper()
        return
      }
      const ratio = this.calcRatio()
      this.myCropper = new Cropper(this.$refs.image, {
        viewMode: 1,
        dragMode: 'move',
        background: false,
        autoCrop: true,
        autoCropArea: 0.8,
        aspectRatio: ratio
      })
    },
    calcRatio() {
      let ratio = NaN
      const arr = this.curRatioid.split('-')
      if (arr.length === 2) {
        ratio = arr[0] / arr[1]
      }
      return ratio
    },
    setRatio() {
      if (this.curRatioid === 'none') {
        this.destroyCropper()
        return
      }
      this.myCropper.setAspectRatio(this.calcRatio())
    },
    destroyCropper() {
      if (this.myCropper) {
        this.myCropper.destroy()
        this.myCropper = null
      }
    },
    calcRatioId(cols, rows) {
      for (const i in conf.canvasRatio) {
        if (conf.canvasRatio[i].cols / conf.canvasRatio[i].rows === cols / rows) {
          return conf.canvasRatio[i].id
        }
      }
      return ''
    },
    select() {
      delete this.data.cols
      delete this.data.rows
    },
    submit() {
      // this.loading(true, '请稍候...')
      let cropCanvas, file, img, width, height, brickSizeId, ratioid
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      let suiteMod = false
      if (this.curRatioid === 'none') {
        width = this.$refs.image.width
        height = this.$refs.image.height
        file = this.data.file
        ratioid = this.calcRatioId(width, height)
        if (width < conf.state.limitCols && height < conf.state.limitRows) brickSizeId = width + '-' + height
        canvas.width = width
        canvas.height = height
        ctx.drawImage(this.$refs.image, 0, 0, width, height)
        img = canvas.toDataURL('image/png')
      } else {
        cropCanvas = this.myCropper.getCroppedCanvas({ imageSmoothingEnabled: false })
        const size = utils.calcImageSize(cropCanvas.width, cropCanvas.height, 512, 512)
        canvas.width = size.width
        canvas.height = size.height
        ctx.drawImage(cropCanvas, 0, 0, cropCanvas.width, cropCanvas.height, 0, 0, canvas.width, canvas.height)
        width = canvas.width
        height = canvas.height
        img = canvas.toDataURL('image/png')
        ratioid = this.curRatioid
        if (this.data.cols && this.data.rows) {
          // 使用传递的cols、rows参数
          ratioid = this.calcRatioId(this.data.cols, this.data.rows)
          brickSizeId = this.data.cols + '-' + this.data.rows
          // 设为套装模式
          suiteMod = true
        }
      }
      if (this.data.diyType === 'brick') {
        const colorfyId = this.data.colorfyId || ''
        this.emit('openBrickyViewer', [{ data: { isAi: this.data.isAi, suiteMod: suiteMod, colorfyId: colorfyId, ratioid: ratioid, brickSizeId: brickSizeId, width: width, height: height, img: img, file: file, diyType: this.data.diyType, imageUrl: this.data.imageUrl || '', name: this.data.file.name }, fn: this.config.fn }])
      } else {
        this.emit('openGridyViewer', [{ data: { ratioid: ratioid, brickSizeId: brickSizeId, width: width, height: height, img: img, file: file, diyType: this.data.diyType, imageUrl: this.data.imageUrl || '', name: this.data.file.name }, fn: this.config.fn }])
      }
      // setTimeout(this.close, 1000)
    },
    // 缩放
    cropperzoom(val) {
      this.myCropper.zoom(val)
    },
    // 重置
    cropperReset() {
      this.myCropper.reset()
      this.scaleX = 1
      this.scaleY = 1
    },
    // 移动
    croppermove(val1, val2) {
      this.myCropper.move(val1, val2)
    },
    // 旋转
    cropperRotate(val) {
      this.myCropper.rotate(val)
    },
    // 绝对角度旋转
    cropperrotateTo(val) {
      this.myCropper.rotateTo(val)
    },
    // X轴翻转
    cropperScaleX() {
      this.scaleX = -this.scaleX
      if (this.myCropper.getImageData().rotate === -90 || this.myCropper.getImageData().rotate === 90) {
        this.myCropper.scaleY(this.scaleX)
      } else {
        this.myCropper.scaleX(this.scaleX)
      }
    },
    // y轴翻转
    cropperscaleY() {
      // console.log(this.myCropper.getImageData().rotate)
      this.scaleY = -this.scaleY
      if (this.myCropper.getImageData().rotate === -90 || this.myCropper.getImageData().rotate === 90) {
        this.myCropper.scaleX(this.scaleY)
      } else {
        this.myCropper.scaleY(this.scaleY)
      }
    }
  }
}
</script>
