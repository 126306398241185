/**
 * @author funfly
 * @mail 389193@qq.com
 * @date 2023-2-20
 * @copyright Gridy.Art
 */
// 默认调色板 paletteId： 基本 base 拼图 brickfy、 拼图人像 humane、 肤色 skine、灰色 gray
const defaultPaletteId = ''
// 网格形状 fillShape： 方形 square、 圆形 circle、 三角形 triangle
const defaultFillShape = 'square'
export default {
  schema: {
    file: {
      // 草稿箱id为自增
      id: 'e05b45c8-12b0-4b13-975e-261c81bee45a',
      // 数据库fileid为uuid，判断唯一性
      fileId: 'e05b45c8-12b0-4b13-975e-261c81bee45a',
      // 来源；0=原生创作; 1=二次创作; 2=导图创作
      origin: 0,
      refFileId: '',
      refWorkid: 0,
      // 文件类型type：["百格画","0"],["拼图","1"],["其他","2"]
      type: 0,
      name: '未命名',
      info: '',
      catalogid: 0,
      tags: [],
      // fillShape: defaultFillShape,
      // paletteId: defaultPaletteId,
      // palette: '',
      animation: false,
      rate: 1,
      loop: 0,
      // readOnly: false,
      // brickfy: true,
      formate: 'png',
      gridSize: 8,
      // refs: 0,
      // del 必须是字符串，不然本地草稿箱搜索不出来
      del: '0',
      delTime: 0,
      // creator: '',
      // createUid: 0,
      createTime: '',
      updateTime: '',
      // sync: false,
      // syncTime: '',
      workid: 0,
      threadid: 0,
      postid: 0,
      userid: 0,
      public: 0,
      publish: false,
      publishTime: '',
      save: false,
      saveTime: '',
      canvas: {
        aspectFactor: '1-1',
        cols: 96,
        rows: 96,
        showGrid: false,
        gridColor: '#242424',
        showBg: false,
        bgColor: '#3b3b3b',
        scenes: []
      }
    },
    scene: {
      id: 'e35c272e-da6a-475f-b577-6fd299cd1e63',
      name: '默认场景',
      info: '',
      // creator: '',
      // fillShape: defaultFillShape,
      // paletteId: defaultPaletteId,
      // palette: '',
      userid: 0,
      refSceneId: '',
      createTime: '',
      updateTime: '',
      animate: {
        duration: 100,
        type: ''
      },
      objs: []
    },
    obj: {
      id: 'cc4fa439-f093-4233-9e56-a33fb5e91d75',
      refSceneId: '',
      refObjectId: '',
      originUrl: '',
      originImage: '',
      thumbImage: '',
      // refUrl: '',
      // loadUrl: '',
      // gridSize: 8,
      name: '默认对象',
      info: '',
      // creator: '',
      userid: 0,
      createTime: '',
      updateTime: '',
      animate: {
        duration: 1000,
        type: ''
      },
      cols: 0,
      rows: 0,
      del: false,
      show: true,
      lock: false,
      resizable: true,
      draggable: true,
      gridsfy: true,
      brickfy: false,
      anti: false,
      flip: { vertical: false, horizontal: false },
      fillShape: defaultFillShape,
      paletteId: defaultPaletteId,
      palette: '',
      colors: '',
      x: 0,
      y: 0,
      z: 99,
      // 对象类型type：图片 image、组合 group、文本 txt、按钮 button、网页、webpage
      type: '',
      txt: '',
      data: [],
      objs: []
    }
  }
}
