<template>
  <div class="my-container selector">
    <el-drawer :visible.sync="view.albumSelector.show" direction="btt" size="33%" :show-close="false" custom-class="selector">
      <span slot="title">
        <span class="el-el-drawer__header">选择专辑</span>
        <el-button class="title-btn txt-btn size16" :class="{blue: albumid}" @click="addAlbum">
          确定
        </el-button>
      </span>
      <album-options
        ref="album-selector"
        mod="selector"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        @receive="handle"
      />
    </el-drawer>
  </div>
</template>
<script>
import albumOptions from '@/components/web/albumOptions'
import { mixins } from '@/mixins/common'
import service from '@/js/service'
export default {
  components: {
    albumOptions
  },
  mixins: [mixins],
  data() {
    const data = {}
    data.albumid = 0
    data.name = ''
    return data
  },
  methods: {
    handle(act, data) {
      if (act === 'setAlbum') {
        this.albumid = data[0]
        this.name = data[1]
      } else {
        this.emit(act, data)
      }
    },
    addAlbum() {
      if (!this.albumid) return this.message('请选择或创建一个专辑', 'error')
      service.post('album_work', { albumid: this.albumid, workid: this.view.albumSelector.workid }, (dt, type) => {
        if (type === 'success') {
          this.message('已加入' + this.name, type)
          this.view.albumSelector.show = false
        } else {
          this.message(dt, type)
        }
      }, true)
    }
  }
}
</script>
