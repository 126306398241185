<template>
  <div ref="voxelViewerDiv" :style="voxelStyle" />
</template>
<script>
import { mixins } from '@/mixins/common'
import utils from '@/js/utils'
import conf from '@/js/conf/conf'
import GRIDY from '@/js/sdk/GridySDK'
import download from 'downloadjs'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { DragControls } from 'three/examples/jsm/controls/DragControls.js'
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js'
/* eslint-disable */
export default {
  mixins: [mixins],
  props: {
    width: {
      type: Number,
      default() {
        return 300
      }
    },
    height: {
      type: Number,
      default() {
        return 300
      }
    },
    skyStyleid: {
      type: Number,
      default() {
        return 0
      }
    },
    fixpos: {
      type: Number,
      default() {
        return 0
      }
    }
  },
  computed: {
    voxelStyle() {
      const style = {
        width: this.width + 'px',
        height: this.height + 'px',
        overflow: 'hidden',
        background: '#1b1b1b',
        'text-align': 'center'
      }
      return style
    },
    cols() {
      return this.work.canvas ? this.work.canvas.cols : 0
    },
    rows() {
      return this.work.canvas ? this.work.canvas.rows : 0
    }
  },
  watch: {},
  mounted() {
    this.GRIDY = new GRIDY()
    this.inited = false
    // realVoxel false：贴图 true：体素模型
    // this.realVoxel = this.isDesktop
    this.realVoxel = false
    // 是否显示边框
    this.showcubeBorder = true
    this.tshirtGlbs = ['../glb/tshirt_0.glb', '../glb/tshirt_1.glb']
    this.skyBoxTextures = [
      [
        '../sky/sky.jpg',
        '../sky/sky.jpg',
        '../sky/sky_back.png',
        '../sky/sky_back.png',
        '../sky/sky.jpg',
        '../sky/sky.jpg'
      ],
      [
        '../sky0/ft.jpg',
        '../sky0/bk.jpg',
        '../sky0/up.jpg',
        '../sky0/dn.jpg',
        '../sky0/rt.jpg',
        '../sky0/lf.jpg'
      ],
      [
        '../sky1/ft.jpg',
        '../sky1/bk.jpg',
        '../sky1/up.jpg',
        '../sky1/dn.jpg',
        '../sky1/rt.jpg',
        '../sky1/lf.jpg'
      ],
      [
        '../room/ft.jpg',
        '../room/bk.jpg',
        '../room/up.jpg',
        '../room/dn.jpg',
        '../room/rt.jpg',
        '../room/lf.jpg'
      ],
      [
        '../meadow/ft.jpg',
        '../meadow/bk.jpg',
        '../meadow/up.jpg',
        '../meadow/dn.jpg',
        '../meadow/rt.jpg',
        '../meadow/lf.jpg'
      ]
    ]
    this.skyBoxTextureIdx = 1
    this.paperNum = 0
    this.partCols = 0
    this.partRows = 0
    this.partWidth = 0
    this.frameId = 'blackBlack'
    this.tshirtIdx = 0
    this.diySide = 0
    this.diyType = ''
    this.diyColor = '#FFFFFF'
    this.interact = true
    this.work = {}
    this.canvasEl = null
    this.showBorder = true
    this.bgColor = 0x1b1b1b
    this.initThree = false
    this.maxSize = 64
    this.renderer = null
    this.camera = null
    this.scene = null
    this.light = null
    this.controls = null
    this.dragControls = null
    this.geometry = null
    this.transform = null
    this.merged = null
    this.mergeMesh = null
    this.materialRight = null
    this.materialLeft = null
    this.materialTop = null
    this.materialBottom = null
    this.materialFront = null
    this.materialBack = null
    this.group = null
    this.diy = null
    this.cubes = null
    this.cube = null
    this.box = null
    this.gridHelper = null
    this.showHelper = false
    this.boxEl = {}
    this.skyGeometry = null
    this.textureCube = null
    this.shader = null
    this.skyMaterial = null
    this.skybox = null
  },
  beforeDestroy() {
    this.GRIDY = null
    if (this.renderer) {
      this.renderer.dispose()
      this.renderer.renderLists.dispose()
      // 注释掉避免闪白屏
      // this.renderer.forceContextLoss()
      this.renderer.context = null
      this.container.removeChild(this.renderer.domElement)
      this.renderer.domElement = null
      this.renderer = null
    }
    this.inited = false
    this.showBorder = true
    this.bgColor = null
    this.initThree = false
    this.maxSize = 64
    this.camera = null
    this.scene = null
    this.light = null
    this.controls = null
    this.dragControls = null
    this.geometry = null
    this.transform = null
    this.merged = null
    this.mergeMesh = null
    this.materialRight = null
    this.materialLeft = null
    this.materialTop = null
    this.materialBottom = null
    this.materialFront = null
    this.materialBack = null
    this.group = this.diy = null
    if (this.cubes) this.cubes.clear()
    this.cubes = null
    this.cube = null
    this.box = null
    this.gridHelper = null
    this.showHelper = false
    this.boxEl = {}
    this.skyGeometry = null
    this.textureCube = null
    this.shader = null
    this.skyMaterial = null
    this.skybox = null
    window.onresize = null
  },
  methods: {
    setWork(work) {
      this.work = work
    },
    // 设置Diy模式
    setDiyMod(type, data = {}) {
      this.diyType = type
      if (typeof data.wallId !== 'undefined') this.wallId = data.wallId
      if (typeof data.frameId !== 'undefined') this.frameId = data.frameId
      if (typeof data.side !== 'undefined') this.diySide = data.side
      if (typeof data.color !== 'undefined') this.diyColor = data.color
      if (typeof data.tshirtIdx !== 'undefined') this.tshirtIdx = data.tshirtIdx
      if (typeof data.paperNum !== 'undefined') this.paperNum = data.paperNum
      if (typeof data.partCols !== 'undefined') this.partCols = data.partCols
      if (typeof data.partRows !== 'undefined') this.partRows = data.partRows
      if (typeof data.partWidth !== 'undefined') this.partWidth = data.partWidth
    },
    borderToggle(showBorder) {
      this.showBorder = showBorder
    },
    // 获取盒子模型
    getBox(id) {
      const el = this.$refs[id]
      if (el) {
        return el.getBoundingClientRect()
      } else {
        return { x: 0, y: 0, left: 0, top: 0, right: 0, bottom: 0, width: 0, height: 0 }
      }
    },
    getTexture(canvas, pos = 'back') {
      const canvasTmp = document.createElement('canvas')
      const ctx = canvasTmp.getContext('2d')
      if (pos === 'top') {
        canvasTmp.width = canvas.width
        canvasTmp.height = 1
        ctx.drawImage(canvas, 0, 8, canvasTmp.width, canvasTmp.height, 0, 0, canvasTmp.width, canvasTmp.height)
      } else if (pos === 'bottom') {
        canvasTmp.width = canvas.width
        canvasTmp.height = 1
        ctx.drawImage(canvas, 0, canvas.height - 8, canvasTmp.width, canvasTmp.height, 0, 0, canvasTmp.width, canvasTmp.height)
      } else if (pos === 'left') {
        canvasTmp.width = 1
        canvasTmp.height = canvas.height
        ctx.drawImage(canvas, 8, 0, canvasTmp.width, canvasTmp.height, 0, 0, canvasTmp.width, canvasTmp.height)
      } else if (pos === 'right') {
        canvasTmp.width = 1
        canvasTmp.height = canvas.height
        ctx.drawImage(canvas, canvas.width - 8, 0, canvasTmp.width, canvasTmp.height, 0, 0, canvasTmp.width, canvasTmp.height)
      } else if (pos === 'back') {
        canvasTmp.height = canvas.height
        canvasTmp.width = canvas.width
        ctx.translate(canvasTmp.width, 0)
        ctx.scale(-1, 1)
        ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height)
      }
      let texture
      if (pos === 'front') {
        if (this.diyType === 'paper') {
          canvasTmp.width = this.partWidth
          canvasTmp.height = this.partWidth
          const x = (this.paperNum % this.partCols) * this.partWidth
          const y = Math.floor(this.paperNum / this.partCols) * this.partWidth
          ctx.drawImage(canvas, x, y, this.partWidth, this.partWidth, 0, 0, canvasTmp.width, canvasTmp.height)
          texture = new THREE.CanvasTexture(canvasTmp)
        } else {
          texture = new THREE.CanvasTexture(canvas)
        }
      } else {
        texture = new THREE.CanvasTexture(canvasTmp)
      }
      texture.needUpdate = true
      return texture
    },
    // 获取T恤贴图
    getTshirtTexture(frontImage, backImage) {
      const textTureWidth = 320
      const textTureHeight = 480
      let frontSize = {}
      let backSize = {}
      if (frontImage) {
        frontSize = utils.calcImageSize(frontImage.width, frontImage.height, textTureWidth, textTureHeight)
      }
      if (backImage) {
        backSize = utils.calcImageSize(backImage.width, backImage.height, textTureWidth, textTureHeight)
      }
      const canv = document.createElement('canvas')
      canv.width = 3300
      canv.height = 3300
      const ctx = canv.getContext('2d')
      ctx.clearRect(0, 0, canv.width, canv.height)
      this.GRIDY.drawRect(ctx, 0, 0, canv.width, canv.height, this.diyColor)
      let frontFixX = 0
      let backFixX = 0
      if (this.tshirtIdx) {
        frontFixX = 70
        backFixX = 30
        if (frontImage) ctx.drawImage(frontImage, 1950 + frontFixX + (textTureWidth - frontSize.width) / 2, 1750 + (textTureHeight - frontSize.height) / 2, frontSize.width, frontSize.height)
        if (backImage) ctx.drawImage(backImage, 670 + backFixX + (textTureWidth - backSize.width) / 2, 1750 + (textTureHeight - backSize.height) / 2, backSize.width, backSize.height)
      } else {
        if (frontImage) ctx.drawImage(frontImage, 670 + frontFixX + (textTureWidth - frontSize.width) / 2, 1750 + (textTureHeight - frontSize.height) / 2, frontSize.width, frontSize.height)
        if (backImage) ctx.drawImage(backImage, 1950 + backFixX + (textTureWidth - backSize.width) / 2, 1750 + (textTureHeight - backSize.height) / 2, backSize.width, backSize.height)
      }
      const texture = new THREE.CanvasTexture(canv)
      texture.flipY = false
      texture.offset.x = 0.1
      texture.offset.y = 0.1
      texture.repeat.set(0.7, 0.7)
      return texture
    },
    // 加载并渲染T恤
    drawTshirt(frontImage, backImage) {
      const loader = new GLTFLoader()
      loader.load(this.tshirtGlbs[this.tshirtIdx], (gltf) => {
        const tshirt = gltf.scene
        tshirt.traverse((o) => {
          if (o.isMesh) {
            o.material.map = this.getTshirtTexture((this.diySide === 0 || this.diySide === 2) ? frontImage : '', (this.diySide === 1 || this.diySide === 2) ? backImage : '')
          }
        })
        this.diy.add(tshirt)
      })
      this.camera.position.set(0, 0, this.isDesktop ? 21 : 28)
    },
    drawIt(canvas, cb) {
      canvas = canvas || this.canvasEl
      if (!canvas) return
      let frameConf = {}
      if (this.diyType === 'brick') {
        frameConf = conf.frameDt[this.frameId] ? utils.deepClone(conf.frameDt[this.frameId]) : {}
        if (frameConf.border) frameConf.border = eval(frameConf.border.replace('#', '0x'))
        if (frameConf.shadow) frameConf.shadow = eval(frameConf.shadow.replace('#', '0x'))
      }
      if (this.cubes) this.cubes.clear()
      if (this.diy) this.diy.clear()
      if (this.diyType === 'tshirt') {
        this.drawTshirt(canvas, canvas)
      } else {
        // const texture = new THREE.CanvasTexture(canvas)
        // texture.needUpdate = true
        this.materialFront = new THREE.MeshBasicMaterial({ color: 0xeeeeee, map: this.getTexture(canvas, 'front'), transparent: this.diyType !== 'brick' && this.diyType !== 'paper' })
        if (this.diyType === 'brick'|| this.diyType === 'paper') {
          this.materialBack = new THREE.MeshBasicMaterial({ color: frameConf.shadow || 0xeaeaea })
        } else {
          this.materialBack = new THREE.MeshBasicMaterial({ color: frameConf.border || 0xeeeeee, map: this.getTexture(canvas, 'back'), transparent: true })
        }
        if (this.diyType === 'paper') {
          this.materialBottom = new THREE.MeshBasicMaterial({ color: 0xdddddd })
          this.materialTop = new THREE.MeshBasicMaterial({ color: 0xdddddd })
          this.materialLeft = new THREE.MeshBasicMaterial({ color: 0xdddddd })
          this.materialRight = new THREE.MeshBasicMaterial({ color: 0xdddddd })
        } else {
          this.materialBottom = new THREE.MeshBasicMaterial({ color: frameConf.border || 0xeeeeee, map: this.getTexture(canvas, 'bottom'), transparent: true })
          this.materialTop = new THREE.MeshBasicMaterial({ color: frameConf.border || 0xeeeeee, map: this.getTexture(canvas, 'top'), transparent: true })
          this.materialLeft = new THREE.MeshBasicMaterial({ color: frameConf.border || 0xeeeeee, map: this.getTexture(canvas, 'left'), transparent: true })
          this.materialRight = new THREE.MeshBasicMaterial({ color: frameConf.border || 0xeeeeee, map: this.getTexture(canvas, 'right'), transparent: true })
        }
        let width, height
        if (this.diyType === 'paper') {
          width = this.work.canvas.rows
          height = this.work.canvas.rows
        } else if (this.diyType === 'originImage') {
          width = this.work.canvas.cols
          height = this.work.canvas.rows
        } else {
          width = canvas.width / 16
          height = canvas.height / 16
        }
        this.maxSize = Math.max(width, height)
        let deps = Math.max(this.maxSize / 50, 1)
        if (this.diyType === 'brick' && this.showBorder) {
          deps = 1
          const borderSize = 1
          // 渲染拼图边框
          const materialBorder = new THREE.MeshBasicMaterial({ color: frameConf.border || 0xeeeeee })
          const materialBorder2 = new THREE.MeshBasicMaterial({ color: frameConf.shadow || 0xeaeaea })
          const borderTopBox = new THREE.BoxGeometry(canvas.width / 16 + borderSize * 2, borderSize, deps * 2)
          const borderTopMesh = new THREE.Mesh(borderTopBox, [materialBorder, materialBorder, materialBorder, materialBorder2, materialBorder, materialBorder])
          borderTopMesh.position.set(0, height / 2 + borderSize / 2, 0.5)
          const borderBottomBox = new THREE.BoxGeometry(canvas.width / 16 + borderSize * 2, borderSize, deps * 2)
          const borderBottomMesh = new THREE.Mesh(borderBottomBox, [materialBorder, materialBorder, materialBorder2, materialBorder, materialBorder, materialBorder])
          borderBottomMesh.position.set(0, -height / 2 - borderSize / 2, 0.5)
          const borderLeftBox = new THREE.BoxGeometry(borderSize, canvas.height / 16, deps * 2)
          const borderLeftMesh = new THREE.Mesh(borderLeftBox, [materialBorder, materialBorder2, materialBorder, materialBorder, materialBorder, materialBorder])
          borderLeftMesh.position.set(width / 2 + borderSize / 2, 0, 0.5)
          const borderRightBox = new THREE.BoxGeometry(borderSize, canvas.height / 16, deps * 2)
          const borderRightMesh = new THREE.Mesh(borderRightBox, [materialBorder2, materialBorder, materialBorder, materialBorder, materialBorder, materialBorder])
          borderRightMesh.position.set(-width / 2 - borderSize / 2, 0, 0.5)
          if (this.cubes && frameConf.border) this.cubes.add(borderTopMesh)
          if (this.cubes && frameConf.border) this.cubes.add(borderBottomMesh)
          if (this.cubes && frameConf.border) this.cubes.add(borderLeftMesh)
          if (this.cubes && frameConf.border) this.cubes.add(borderRightMesh)
        } else if (this.diyType === 'paper') {
          deps = 0.1
        }
        this.box = new THREE.BoxGeometry(width, height, deps)
        this.cube = new THREE.Mesh(this.box, [this.materialRight, this.materialLeft, this.materialTop, this.materialBottom, this.materialFront, this.materialBack])
        if (this.showcubeBorder && this.diyType !== 'brick') {
          // 几何体box作为EdgesGeometry参数创建一个新的几何体
          var edges = new THREE.EdgesGeometry(this.box)
          // 线框，不显示中间的斜线
          var edgesMaterial = new THREE.LineBasicMaterial({ color: 0x1E90FF, transparent: true, opacity: 0.2 })
          var line = new THREE.LineSegments(edges,edgesMaterial)
          if (this.cubes) this.cubes.add(this.cube, line)
        } else {
          if (this.cubes) this.cubes.add(this.cube)
        }
        this.camera.position.set(0, 0, this.maxSize * 2 * (this.isDesktop ? 1.05 : 1.5))
      }
      this.initHelper()
      if (this.controls) this.controls.enableRotate = this.interact
      if (this.controls) this.controls.autoRotate = false
      // if (this.controls) this.controls.autoRotate = this.interact
      if (this.dragControls) this.dragControls.enabled = !this.interact
      cb && cb()
    },
    draw(canvas, cb) {
      this.GRIDY.setFile(this.work)
      if (!this.realVoxel) {
        if (this.initThree) {
          this.drawIt(canvas, cb)
          return
        }
        this.initThree = true
        if (!this.group) this.group = new THREE.Group()
        this.scene.add(this.group)
        if (!this.diy) this.diy = new THREE.Group()
        this.diy.position.set(0, 1.5 + this.fixpos, 0)
        this.group.add(this.diy)
        if (!this.cubes) this.cubes = new THREE.Group()
        this.cubes.position.set(0, 3.5 + this.fixpos + (this.diyType === 'paper' ? -8 : 0), 0)
        this.group.add(this.cubes)
        this.drawIt(canvas, cb)
        return
      }
      // 体素模型
      const cubeSize = 1.0
      const cols = this.work.canvas.cols
      const rows = this.work.canvas.rows
      const sceneData = this.GRIDY.getSceneData(0)
      const data = sceneData.imageData
      const numberOfObjects = cols * rows
      // 是否合并模型
      this.mergeVertices = false
      let geometryArray = []
      let materialArray = []
      this.geometry = new THREE.BoxGeometry(cubeSize, cubeSize, cubeSize)
      var materialTmpArr = {}
      this.transform = new THREE.Object3D()
      for (var i = 0; i < numberOfObjects; i++) {
        var y = Math.floor(i / cols)
        var x = i - y * cols
        if (data[i]) {
          var pos = [x - Math.round(cols / 2), -y + Math.round(rows / 2), 0]
          var color = data[i].replace('#', '0x')
          var geometryTmp = this.geometry.clone()
          this.transform.position.set(...pos)
          this.transform.updateMatrix()
          geometryTmp.applyMatrix4(this.transform.matrix)
          geometryArray.push(geometryTmp)
          if (!materialTmpArr[color]) {
            // eslint-disable-next-line
            materialTmpArr[color] = new THREE.MeshLambertMaterial({ color: eval(color) })
          }
          materialArray.push(materialTmpArr[color])
        }
      }
      // 合并模型
      this.merged = BufferGeometryUtils.mergeBufferGeometries(geometryArray, true)
      this.mergeMesh = new THREE.Mesh(this.merged, materialArray)
      this.mergeMesh.geometry.deleteAttribute('normal')
      this.mergeMesh.geometry.deleteAttribute('uv')
      if (this.mergeVertices) {
        this.mergeMesh.geometry = BufferGeometryUtils.mergeVertices(this.mergeMesh.geometry)
      }
      this.mergeMesh.geometry.computeVertexNormals()
      this.scene.add(this.mergeMesh)
      geometryArray = null
      materialArray = null
      cb && cb()
    },
    launch(cb) {
      if (this.inited) {
        cb && cb()
      } else {
        this.init(cb)
        this.animate()
      }
    },
    init(cb, count) {
      count = count || 0
      if (count > 10) return
      this.container = this.$refs.voxelViewerDiv
      if (!this.container) {
        setTimeout(() => {
          count++
          this.init(cb, count)
        }, 100)
        return
      }
      this.boxEl = this.getBox('voxelViewerDiv')
      this.initRender()
      this.initScene()
      this.initSkybox()
      this.initHelper()
      this.initCamera()
      this.initLight()
      this.initControls()
      this.animate()
      this.inited = true
      window.onresize = this.onResize
      cb && cb()
    },
    initRender() {
      this.renderer = new THREE.WebGLRenderer({ antialias: true })
      this.renderer.setClearColor(this.bgColor)
      this.renderer.setPixelRatio(window.devicePixelRatio)
      this.renderer.setSize(this.boxEl.width, this.boxEl.height)
      // 开启阴影效果
      this.renderer.shadowMap.enabled = true
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap
      this.renderer.domElement.id = 'voxelCanvas'
      this.container.appendChild(this.renderer.domElement)
    },
    initCamera() {
      this.camera = new THREE.PerspectiveCamera(45, this.boxEl.width / this.boxEl.height, 0.1, 1000)
      this.camera.position.set(0, 10, this.maxSize * 3)
      this.camera.lookAt(new THREE.Vector3(0, 0, 0))
      this.scene.add(this.camera)
    },
    // TODO: 支持阴影效果（光线未生效）
    initLight() {
      const light = new THREE.PointLight(0xFFFF00)
      light.position.set(25, 25, 25)
      this.scene.add(light)
    },
    initScene() {
      this.scene = new THREE.Scene()
    },
    initSkybox() {
      // this.skyGeometry = new THREE.BoxGeometry(8192,8192,8192)
      this.skyGeometry = new THREE.BoxGeometry(4096,4096,4096)
      // this.skyGeometry = new THREE.BoxGeometry(2048,2048,2048)
      // this.skyGeometry = new THREE.BoxGeometry(1024,1024,1024)
      this.textureCube = new THREE.CubeTextureLoader().load(this.skyBoxTextures[this.skyStyleid || this.skyBoxTextureIdx])
      this.shader = THREE.ShaderLib.cube
      this.shader.uniforms.tCube.value = this.textureCube
      this.skyMaterial = new THREE.ShaderMaterial({
        fragmentShader: this.shader.fragmentShader,
        vertexShader: this.shader.vertexShader,
        uniforms: this.shader.uniforms,
        depthWrite: false,
        blending: THREE.NoBlending,
        side: THREE.BackSide
      })
      this.skybox = new THREE.Mesh(this.skyGeometry,this.skyMaterial)
      this.skybox.name = "skybox"
      this.scene.add(this.skybox)
    },
    initHelper() {
      if (this.gridHelper) this.scene.remove(this.gridHelper)
      if (!this.interact || !this.showHelper) return
      this.gridHelper = new THREE.GridHelper(this.maxSize, Math.round(this.maxSize / 2), 0x333333, 0x333333)
      this.gridHelper.position.y = Math.round(-this.rows / 2) - 5
      this.scene.add(this.gridHelper)
    },
    initControls() {
      this.controls = new OrbitControls(this.camera, this.renderer.domElement)
      this.dragControls = new DragControls([this.group], this.camera, this.renderer.domElement)
      // 交互模式下，不可拖拽
      this.dragControls.enabled = !this.interact
      // 如果使用animate方法时，将此函数删除
      this.controls.addEventListener('change', this.render)
      // 禁止旋转
      this.controls.enableRotate = this.interact
      // 使动画循环使用时阻尼或自转 意思是否有惯性
      this.controls.enableDamping = true
      // 动态阻尼系数 就是鼠标拖拽旋转灵敏度
      this.controls.dampingFactor = 0.25
      // 是否可以缩放
      this.controls.enableZoom = true
      // 缩放范围
      this.controls.minZoom = 0.1
      this.controls.maxZoom = 300
      // 是否自动旋转
      // this.controls.autoRotate = this.interact
      this.controls.autoRotate = false
      this.controls.autoRotateSpeed = 0.75
      // 设置相机距离原点的最近距离
      this.controls.minDistance = 10
      // 设置相机距离原点的最远距离
      this.controls.maxDistance = 2000
      // 是否开启右键拖拽
      this.controls.enablePan = true
      // 限制只能上下旋转
      // this.controls.minPolarAngle = 0
      // this.controls.maxPolarAngle = Math.PI
      // 限制只能左右旋转
      // this.controls.minPolarAngle = Math.PI / 2
      // this.controls.maxPolarAngle = Math.PI / 2
    },
    render() {
      if (this.renderer) this.renderer.render(this.scene, this.camera)
    },
    onResize() {
      this.box = this.getBox('voxelViewerDiv')
      this.camera.aspect = this.boxEl.width / this.boxEl.height
      this.camera.updateProjectionMatrix()
      this.render()
      this.renderer.setSize(this.boxEl.width, this.boxEl.height)
    },
    animate() {
      this.render()
      if (this.controls) this.controls.update()
      requestAnimationFrame(this.animate)
    },
    exportScenes(binary, cb) {
      this.exportGLTF([this.realVoxel ? this.mergeMesh : this.cubes], binary, cb)
    },
    initCubesPos() {
      if (this.cubes) this.cubes.position.set(0, 3.5 + this.fixpos + (this.diyType === 'paper' ? -8 : 0), 0)
    },
    zoomIn() {
      const minY = this.isDesktop? -8 : 0
      this.initCubesPos()
      const pos = this.cubes.position
      const position = this.camera.position
      position.x = 0
      position.y = 0
      const minZ = position.z / 2.5
      let n = 0
      let factor = 1
      const run = (z) => {
        if (z > minZ) {
          setTimeout(() => {
            position.z = z - factor
            if (pos.y > minY) pos.y = pos.y - 1
            n++
            if (n > minZ / 2 ) {
              factor = 2
            } else if (n > minZ) {
              factor = 3
            }
            this.camera.lookAt(position)
            run(position.z)
          }, 1)
        }
      }
      run(position.z)
    },
    exportGLTF(input, binary, cb) {
      if (typeof binary === 'undefined') binary = true
      const gltfExporter = new GLTFExporter()
      const options = {
        trs: false,
        onlyVisible: true,
        binary: binary,
        maxTextureSize: 4096
      }
      gltfExporter.parse(
        input,
        (result) => {
          if (result instanceof ArrayBuffer) {
            this.saveArrayBuffer(result, cb)
          } else {
            const output = JSON.stringify(result, null, 2)
            this.saveString(output, cb)
          }
        },
        function(error) {
          console.log('An error happened during gltfExporter parsing', error)
        },
        options
      )
    },
    saveString(text, cb) {
      const filename = (this.work.name || '未命名') + '-百格画_' + utils.date('str') + '.gltf'
      download(new Blob([text], { type: 'text/plain' }), filename, 'text/plain')
      cb && cb(this.work.workid, 'gltf')
    },
    saveArrayBuffer(buffer, cb) {
      const filename = (this.work.name || '未命名') + '-百格画_' + utils.date('str') + '.glb'
      download(new Blob([buffer], { type: 'text/plain' }), filename, 'application/octet-stream')
      cb && cb(this.work.workid, 'glb')
    }
  }
}
</script>
