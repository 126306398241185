<template>
  <div>
    <canvas ref="previewCanvas" :style="previewStyle" />
  </div>
</template>
<script>
export default {
  name: 'ScenesPreview',
  props: {
    file: {
      type: Object,
      default() {
        return {}
      }
    },
    idx: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 40
    }
  },
  computed: {
    canvas() {
      return this.file.canvas || {}
    },
    scenes() {
      return this.file.canvas.scenes || {}
    },
    objs() {
      return this.file.canvas.scenes[this.idx] ? this.file.canvas.scenes[this.idx].objs || [] : []
    },
    previewStyle() {
      if (!this.canvas.cols || !this.canvas.rows) {
        return {}
      }
      let width
      let height
      let mt = 0
      let ml = 0
      if (this.canvas.cols < this.size && this.canvas.rows < this.size) {
        width = this.canvas.cols
        height = this.canvas.rows
        mt = (this.size - height) / 2
        ml = (this.size - width) / 2
      } else {
        if (this.canvas.cols > this.canvas.rows) {
          width = this.size
          height = this.canvas.rows * width / this.canvas.cols
          mt = (this.size - height) / 2
        } else {
          height = this.size
          width = this.canvas.cols * height / this.canvas.rows
          ml = (this.size - width) / 2
        }
      }
      return {
        'width': width + 'px',
        'height': height + 'px',
        'margin-top': mt + 'px',
        'margin-left': ml + 'px'
      }
    }
  },
  watch: {
    'file': {
      handler(val) {
        this.draw()
      },
      deep: true
    }
  },
  mounted() {
    this.previewCanvas = this.$refs.previewCanvas
    this.previewCtx = this.previewCanvas.getContext('2d')
    this.draw()
  },
  methods: {
    // 清除画布
    clearCanvas(id, w, h) {
      if (!this[id + 'Canvas'] || !this[id + 'Ctx']) {
        return
      }
      w = w || this[id + 'Canvas'].width
      h = h || this[id + 'Canvas'].height
      this[id + 'Canvas'].width = w
      this[id + 'Canvas'].height = h
      this[id + 'Ctx'].clearRect(0, 0, w, h)
    },
    drawBg() {
      if (this.canvas.showBg && this.canvas.bgColor) {
        this.previewCtx.fillStyle = this.canvas.bgColor
        this.previewCtx.fillRect(0, 0, this.canvas.cols, this.canvas.rows)
      }
    },
    draw() {
      if (!this.canvas.cols || !this.canvas.rows) {
        return
      }
      this.clearCanvas('preview', this.canvas.cols, this.canvas.rows)
      this.drawBg()
      Object.keys(this.objs).reverse().map((idx) => {
        const obj = this.objs[idx]
        if (obj.show) {
          if (obj.type === 'group') {
            this.drawSubObjs(obj.objs, obj.x, obj.y)
            return
          }
          let x, y
          let i = 0
          const len = obj.data.length
          for (i = 0; i < len; i++) {
            y = Math.floor(i / obj.cols)
            x = Math.floor(i - y * obj.cols)
            if (obj.data[i]) {
              this.previewCtx.fillStyle = obj.data[i]
              this.previewCtx.fillRect(x + obj.x, y + obj.y, 1, 1)
            }
          }
        }
      })
    },
    // 绘制子对象
    drawSubObjs(objs, offsetX, offsetY) {
      Object.keys(objs).reverse().map((i) => (this.drawSubObj(objs[i], offsetX, offsetY)))
    },
    // 绘制特定子对象
    drawSubObj(obj, offsetX, offsetY) {
      if (!obj.show) {
        return
      }
      let x, y
      let i = 0
      const len = obj.data.length
      for (i = 0; i < len; i++) {
        y = Math.floor(i / obj.cols)
        x = Math.floor(i - y * obj.cols)
        if (obj.data[i]) {
          this.previewCtx.fillStyle = obj.data[i]
          this.previewCtx.fillRect(x + obj.x + offsetX, y + obj.y + offsetY, 1, 1)
        }
      }
    }
  }
}
</script>
