<template>
  <div class="my-container">
    <el-drawer :visible.sync="view.sift.show" direction="btt" :show-close="true" custom-class="sift opts">
      <div class="main">
        <el-row v-if="config.type === 'post'" style="padding: 0 20px;">
          <el-col v-for="item in catalogs" :key="item.id" :span="6" class="align-center" style="height:40px;">
            <el-button :class="{ 'on': sift.catalogid === item.id }" @click="select(item.id, item.label)">
              {{ getName(item.id, item.label) }}
            </el-button>
          </el-col>
        </el-row>
        <el-row v-if="config.type === 'aiart'">
          <el-col :span="4" class="title">风格</el-col>
          <el-col :span="20">
            <el-button :class="{ 'on': sift.styleid === '0' }" @click="selectStyle('0', '全部')">
              全部
            </el-button>
            <el-button v-for="item in styles" :key="item[0]" :class="{ 'on': sift.styleid === item[0] }" @click="selectStyle(...item)">
              {{ item[1] }}
            </el-button>
          </el-col>
        </el-row>
        <el-row v-if="config.type === 'aiart'">
          <el-col :span="4" class="title">来源</el-col>
          <el-col :span="20">
            <el-button v-for="(type, idx) in viewConfig.originTypes" :key="idx" :name="type[0]" :class="{ 'on': sift.origin === type[0] }" @click="sift.origin = type[0]">
              {{ type[1] }}
            </el-button>
          </el-col>
        </el-row>
        <el-row v-if="config.type !== 'post' && config.type !== 'aiart'">
          <el-col :span="4" class="title">类目</el-col>
          <el-col :span="20">
            <el-button v-for="item in catalogs" :key="item.id" :class="{ 'on': sift.catalogid === item.id }" @click="select(item.id, item.label)">
              {{ getName(item.id, item.label) }}
            </el-button>
          </el-col>
        </el-row>
        <el-row v-if="config.type !== 'post'">
          <el-col :span="4" class="title">筛选</el-col>
          <el-col :span="20">
            <el-button v-for="(type, idx) in viewConfig.types" :key="idx" :name="type[0]" :class="{ 'on': sift.type === type[0] }" @click="selectType(idx, type[0])">
              {{ type[1] }}
            </el-button>
          </el-col>
        </el-row>
        <el-row v-if="config.type !== 'post' && config.type !== 'aiart'">
          <el-col :span="4" class="title">排序</el-col>
          <el-col :span="20">
            <!-- <el-button v-for="(type, idx) in viewConfig.sortTypes" :key="idx" :name="type[0]" :class="{ 'on': sift.sortType === type[0] }" @click="sift.sortType = type[0]">
              {{ type[1] }}
            </el-button> -->
            <el-button v-for="(type, idx) in sorts" :key="idx" :name="type[0]" :class="{ 'on': sift.sort === type[0] }" @click="sift.sort = type[0]">
              {{ type[1] }}
            </el-button>
          </el-col>
        </el-row>
      </div>
      <div class="btn-opts">
        <el-button class="btn-cancel" @click="view.sift.show = false">
          取消
        </el-button>
        <el-button type="light" class="btn-ok" @click="submit()">
          确定
        </el-button>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import conf from '@/js/conf/conf'
import utils from '@/js/utils'
import { mixins } from '@/mixins/common'
export default {
  components: {},
  mixins: [mixins],
  data() {
    const data = {}
    const styles = [
      ['000', '不限风格'],
      ['201', '动漫风格'],
      ['301', '游戏卡通'],
      ['114', '科幻风格'],
      ['113', '赛博朋克'],
      ['102', '概念艺术'],
      ['115', '黑暗艺术'],
      ['112', '黑白素描'],
      ['110', '2.5D人像'],
      ['111', '肖像画'],
      ['109', '印象派'],
      ['107', '插图']
    ]
    data.styles = styles
    data.sift = {
      type: 'new',
      typeid: '0',
      catalogid: '0',
      catalogName: '',
      styleid: '0',
      styleName: '',
      sort: 'desc',
      sortType: 'update',
      origin: '0'
    }
    data.sorts = [['desc', '倒序'], ['asc', '正序']]
    return data
  },
  computed: {
    config() {
      return this.view.sift
    },
    viewConfig() {
      return this.view[this.config.type] || {}
    },
    catalogs() {
      if (this.config.type === 'works' || this.config.type === 'album') {
        return utils.deepClone(conf.catalogs)
      } else if (this.config.type === 'zone' || this.config.type === 'post') {
        return utils.deepClone(conf.categories)
      } else {
        return {}
      }
    }
  },
  watch: {
    'view.sift.show': {
      handler() {
        if (this.config.show && this.config.type) {
          this.sift.type = this.viewConfig.type
          this.sift.typeid = this.viewConfig.typeid
          this.sift.sort = this.viewConfig.sort
          this.sift.sortType = this.viewConfig.sortType
          this.sift.origin = this.viewConfig.origin
          if (this.config.type === 'aiart') {
            this.sift.styleName = this.viewConfig.styleName
            this.sift.styleid = this.viewConfig.styleid
          } else {
            this.sift.catalogid = this.viewConfig.catalogid
            this.sift.catalogName = this.viewConfig.catalogName
          }
        }
      }
    }
  },
  methods: {
    getName(id, name) {
      return id === 0 ? (this.config.type === 'post' ? '默认' : '全部') : name
    },
    selectType(typeid, type) {
      this.sift.typeid = typeid
      this.sift.type = type
    },
    select(id, name) {
      this.sift.catalogid = id
      this.sift.catalogName = this.getName(id, name)
    },
    selectStyle(id, name) {
      this.sift.styleid = id
      this.sift.styleName = this.getName(id, name)
    },
    submit() {
      this.viewConfig.type = this.sift.type
      this.viewConfig.typeid = this.sift.typeid
      this.viewConfig.sort = this.sift.sort
      this.viewConfig.sortType = this.sift.sortType
      this.viewConfig.origin = this.sift.origin
      if (this.config.type === 'aiart') {
        this.viewConfig.styleName = this.sift.styleName
        this.viewConfig.styleid = this.sift.styleid
      } else {
        this.viewConfig.catalogid = this.sift.catalogid
        this.viewConfig.catalogName = this.sift.catalogName
      }
      this.view.sift.show = false
    }
  }
}
</script>
