/**
 * @author funfly
 * @mail 389193@qq.com
 * @date 2023-7-4
 * @copyright Gridy.Art
 */
// 几何图案
const geoObjs = [
  {
    'id': '20BAF7E7-2507-45C7-937A-2994442F4916',
    'refSceneId': '98CCD4ED-DA10-4A07-A788-DC8B65AE1D0A',
    'refObjectId': '1624D9CC-9B81-4A11-A4C0-9D7F43347D9A',
    'originUrl': '',
    'originImage': '',
    'thumbImage': '',
    'name': '1',
    'info': '',
    'userid': 0,
    'createTime': '',
    'updateTime': '',
    'animate': {
      'duration': 1000,
      'type': ''
    },
    'cols': 32,
    'rows': 32,
    'del': false,
    'show': true,
    'lock': false,
    'resizable': true,
    'draggable': true,
    'gridsfy': true,
    'brickfy': false,
    'anti': false,
    'flip': {
      'vertical': false,
      'horizontal': false
    },
    'fillShape': '',
    'paletteId': '',
    'palette': '',
    'colors': {},
    'x': 0,
    'y': 0,
    'z': 80,
    'type': '',
    'txt': '',
    'data': [
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#72349D',
      '#72349D',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#F9CD00',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#FFFFFF',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#2980C4',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#2980C4',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#FFFFFF',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#F9CD00',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#72349D',
      '#72349D',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#72349D',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#72349D',
      '#72349D',
      '#72349D',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFFFFF',
      '#FFFFFF',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#FFFFFF',
      '#FFFFFF',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#2980C4',
      '#2980C4',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#72349D',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#72349D',
      '#72349D',
      '#72349D',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#00A73C',
      '#00A73C',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#72349D'
    ],
    'objs': [],
    'gridSize': 8,
    'from': 'copy'
  },
  {
    'id': 'BCB757E4-9BBC-4882-9BEE-A5A9F5C49E62',
    'refSceneId': '',
    'refObjectId': '',
    'originUrl': '',
    'originImage': '',
    'thumbImage': '',
    'name': '2',
    'info': '',
    'userid': 0,
    'createTime': '',
    'updateTime': '',
    'animate': {
      'duration': 1000,
      'type': ''
    },
    'cols': 32,
    'rows': 32,
    'del': false,
    'show': true,
    'lock': false,
    'resizable': true,
    'draggable': true,
    'gridsfy': true,
    'brickfy': false,
    'anti': false,
    'flip': {
      'vertical': false,
      'horizontal': false
    },
    'fillShape': '',
    'paletteId': '',
    'palette': '',
    'colors': {},
    'x': 0,
    'y': 0,
    'z': 79,
    'type': '',
    'txt': '',
    'data': [
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#D92817',
      '#D92817',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#D92817',
      '#D92817',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#F9CD00',
      '#F9CD00',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#F9CD00',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#F9CD00',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#F9CD00',
      '#F9CD00',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#D92817',
      '#D92817',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#D92817',
      '#D92817',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#F9CD00',
      '#F9CD00',
      '#584C9D',
      '#584C9D',
      '#584C9D',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C'
    ],
    'objs': [],
    'gridSize': 8
  },
  {
    'id': '4C63556E-6A93-40BC-9725-FDB32A04C9BF',
    'refSceneId': '1DA7042C-6D64-43BF-AE75-D9B4E17DF8CA',
    'refObjectId': '7FDC2B67-810B-421C-88F6-313628E7535C',
    'originUrl': '',
    'originImage': '',
    'thumbImage': '',
    'name': '3',
    'info': '',
    'userid': 0,
    'createTime': '',
    'updateTime': '',
    'animate': {
      'duration': 1000,
      'type': ''
    },
    'cols': 32,
    'rows': 32,
    'del': false,
    'show': true,
    'lock': false,
    'resizable': true,
    'draggable': true,
    'gridsfy': true,
    'brickfy': false,
    'anti': false,
    'flip': {
      'vertical': false,
      'horizontal': false
    },
    'fillShape': '',
    'paletteId': '',
    'palette': '',
    'colors': '',
    'x': 0,
    'y': 0,
    'z': 78,
    'type': '',
    'txt': '',
    'data': [
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817'
    ],
    'objs': [],
    'gridSize': 8,
    'from': 'copy'
  },
  {
    'id': '0086A04F-1B80-43F1-91E4-DC8D6F1BC2FF',
    'refSceneId': '1DA7042C-6D64-43BF-AE75-D9B4E17DF8CA',
    'refObjectId': 'BE05ED47-7346-429A-9A4E-BD6461ED2CBB',
    'originUrl': '',
    'originImage': '',
    'thumbImage': '',
    'name': '4',
    'info': '',
    'userid': 0,
    'createTime': '',
    'updateTime': '',
    'animate': {
      'duration': 1000,
      'type': ''
    },
    'cols': 32,
    'rows': 32,
    'del': false,
    'show': true,
    'lock': false,
    'resizable': true,
    'draggable': true,
    'gridsfy': true,
    'brickfy': false,
    'anti': false,
    'flip': {
      'vertical': true,
      'horizontal': true
    },
    'fillShape': '',
    'paletteId': '',
    'palette': '',
    'colors': {},
    'x': 0,
    'y': 0,
    'z': 77,
    'type': '',
    'txt': '',
    'data': [
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#FFC12A',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#FFC12A',
      '#FFC12A',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#2980C4',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#2980C4',
      '#2980C4',
      '#D92817',
      '#D92817',
      '#D92817',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#FFC12A',
      '#FFC12A',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#FFC12A',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#2980C4',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#FFC12A',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#FFC12A',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#2980C4',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#D92817',
      '#D92817',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#D92817',
      '#D92817',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#2980C4',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#D92817',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#D92817',
      '#D92817',
      '#D92817',
      '#FFC12A',
      '#FFC12A',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#2980C4',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#D92817',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#00A73C',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A',
      '#FFC12A'
    ],
    'objs': [],
    'gridSize': 8,
    'from': 'copy'
  }
]
export default {
  geoObjs: geoObjs
}
