<template>
  <div :id="'album-' + mod + '-container'" class="flow-container">
    <div class="horiz-scroll" :style="{'overflow-x': isMobile ? '' : 'hidden'}">
      <div v-finger:touch-start="onTouchStart" v-finger:touch-end="onTouchEnd" class="scroll-works" :style="{'width': (data.horizWidth + 45) + 'px', 'height': (maxThumbHeight + 50) + 'px' }">
        <div v-for="(item, i) in data.items" :key="i" @click="emit('goto', ['albumWorks', { albumid: item.albumid }])">
          <div v-for="(work, idx) in data.works[item.albumid]" :key="idx" class="bg-28 round8" :style="{ width: maxThumbWidth + 'px', height: (maxThumbHeight + 40) + 'px', margin: '0 5px'}">
            <div :style="{ width: maxThumbWidth + 'px', height: maxThumbHeight + 'px', 'padding-left': work._width < maxThumbWidth ? (maxThumbWidth - work._width) / 2 + 'px' : '', 'padding-top': work._height < maxThumbHeight ? (maxThumbHeight - work._height) / 2 + 'px' : ''}">
              <img :src="worksHost + 'work/action/download/gridy/size/8/workid/' + work.workid + '/' + work.name + '.png?ver=' + work.update_at" :style="{ width: work._width + 'px', height: work._height + 'px'}" :class="{'round-top': maxThumbHeight - 16 < work._height}">
            </div>
            <div class="ellipsis align-center padding-top8 size12" :style="{ width: maxThumbWidth + 'px' }">
              《{{ item.name }}》
            </div>
          </div>
        </div>
        <div v-if="data.items.length > 2 && isMobile" class="more float-right" :style="{ 'height': (maxThumbHeight + 40) + 'px', 'line-height': (maxThumbHeight + 40) + 'px' }" @click="emit('goto', ['album', { type: 'best' }])">
          <i class="iconfont my-right color-cc" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mixins } from '@/mixins/album'
export default {
  mixins: [mixins],
  methods: {
    onTouchStart() {
      this.swipeStart = new Date()
      this.emit('setLockSwipe', [true])
    },
    onTouchEnd() {
      this.swipeEnd = new Date()
      if (this.swipeEnd - this.swipeStart > 200) {
        setTimeout(() => {
          this.emit('setLockSwipe', [false])
        }, 100)
      } else {
        this.emit('setLockSwipe', [false])
      }
    }
  }
}
</script>
