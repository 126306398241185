<template>
  <el-dialog
    title="登录"
    :visible.sync="view.login.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :custom-class="platform.type !== 'desktop' ? 'pop_page fullscreen header_40' : ''"
    :fullscreen="platform.type !== 'desktop'"
    class="my-dialog"
    width="350px"
  >
    <span slot="title">
      <span class="el-dialog__title">{{ login.mod === 'active' ? '设置昵称' : (login.mod === 'wechat' ? '微信登录' : (login.mod === 'username' ? '用户名登录' : '手机号登录/注册')) }}</span>
      <i v-if="login.mod !== 'active'" class="iconfont my-close title-btn" title="关闭" @click="view.login.show = false" />
    </span>
    <el-card shadow="never">
      <div class="login">
        <el-card v-if="login.mod !== 'active'" v-loading="login.loging" shadow="never" class="login-card" :style="{ 'height': platform.type !== 'desktop' ? '100vh': '' }" element-loading-text="登录中..." element-loading-background="rgba(0, 0, 0, 0.8)">
          <el-form v-if="login.mod !== 'wechat'" label-width="0px" style="width: 280px;margin: 0 auto;padding-left: 12px;">
            <img src="@/assets/icon.png" title="Gridy.Art 百格画" width="64" style="margin:12px 0 12px 95px;">
            <el-form-item v-if="login.mod === 'username'" label="">
              <el-input v-model="login.username" class="my-from-item" maxlength="18" placeholder="请输入用户名" clearable />
            </el-form-item>
            <el-form-item v-if="login.mod === 'username'" label="">
              <el-input v-model="login.password" class="my-from-item" maxlength="20" type="password" placeholder="请输入密码" clearable />
            </el-form-item>
            <el-form-item v-if="login.mod === 'phone'" label="">
              <el-input v-model="login.phone" class="my-from-item" maxlength="11" placeholder="请输入手机号" clearable onkeyup="this.value = this.value.replace(/[^\d.]/g,'')" @blur="login.phone = login.phone.replace(/[^\d.]/g,'')" />
            </el-form-item>
            <el-form-item v-if="login.mod === 'phone'" class="sms-item" label="">
              <el-input v-model="login.code" class="my-from-item" maxlength="6" placeholder="请输入验证码" clearable onkeyup="this.value = this.value.replace(/[^\d.]/g,'')" @blur="login.code = login.code.replace(/[^\d.]/g,'')" />
              <div class="sms-send" :class="{'is-disabled': !login.phone || state.countDownNum}" @click="sendDzqSMS()">
                {{ state.countDownNum ? state.countDownNum : '获取验证码' }}
              </div>
            </el-form-item>
            <el-form-item>
              <input type="hidden" name="type" value="mobilebrowser_username_login">
              <el-button size="small" type="primary" class="login-btn" @click="userLogin">登录{{ login.mod === 'phone' ? '/注册' : '' }}</el-button>
              <!-- <el-button v-if="login.mod !== 'phone'" size="small" type="primary" class="reg-btn" @click="login.mod = 'phone'">注册新用户</el-button> -->
            </el-form-item>
          </el-form>
          <div v-if="login.mod === 'wechat'" class="qrcode">
            <div class="img"><img v-if="qrcode" :src="qrcode"></div>
            <div v-if="expire" class="cover" />
            <div v-if="expire" class="info icon" @click="() => genQrcode()">
              <i class="iconfont my-update" />
            </div>
          </div>
          <div v-if="login.mod === 'wechat'" class="txt top">
            {{ expire ? '二维码已过期，请点击刷新' : '请使用微信，扫码登录' }}
          </div>
          <div v-show="true" class="txt">
            其他登录方式
          </div>
          <div v-show="true" class="txt icon">
            <!-- <i v-if="login.mod !== 'wechat'" class="iconfont my-wechat" title="微信登录" @click="() => (login.mod = 'wechat')" /> -->
            <i v-if="login.mod !== 'phone'" class="iconfont my-phone" title="手机登录" @click="() => (login.mod = 'phone')" />
            <i v-if="login.mod !== 'username'" class="iconfont my-username" title="用户名登录" @click="() => (login.mod = 'username')" />
          </div>
          <div class="txt">
            <el-checkbox v-model="login.checked" /> 同意
            《<el-button class="txt-btn" @click="openPopPage({ 'type': 'agreement' })">用户协议</el-button>》和《<el-button class="txt-btn" @click="openPopPage({ 'type': 'privacy' })">隐私政策</el-button>》
          </div>
        </el-card>
        <el-card v-if="login.mod === 'active'" v-loading="login.loging" shadow="never" class="login-card" :style="{ 'height': platform.type !== 'desktop' ? '100vh': '' }" element-loading-text="提交中..." element-loading-background="rgba(0, 0, 0, 0.8)">
          <el-form label-width="10px" style="width: 280px;margin: 0 auto;">
            <div class="center set-btns" style="margin: 20px 0;">
              <span v-if="!token.avatar" class="el-avatar el-avatar--circle noavatar xlarge">
                <i class="iconfont my-man" />
              </span>
              <el-avatar v-if="token.avatar" :size="64" :src="getAvatar()" />
              <br><br><el-button @click="openLocalImages('avatar')">设置头像</el-button>
              <input ref="avatar-uploader" type="file" accept="image/*" style="display:none;" @change="upload('avatar')">
            </div>
            <el-form-item label="">
              <el-input v-model="login.nickname" class="my-from-item" maxlength="18" placeholder="请输入昵称" clearable />
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" class="login-btn" @click="setNickname">提交</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </div>
    </el-card>
    <span slot="footer" class="dialog-footer" />
  </el-dialog>
</template>
<script>
import { mixins } from '@/mixins/common'
import utils from '@/js/utils'
import conf from '@/js/conf/conf'
import service from '@/js/service'
import COSSDK from '@/js/sdk/cosSDK'
export default {
  name: 'MyLogin',
  mixins: [mixins],
  data() {
    const data = {}
    data.COSSDK = null
    data.platform = utils.platform()
    data.mainHost = conf.hosts().mainHost
    data.tabNames = utils.deepClone(conf.tabNames)
    data.catalogs = utils.deepClone(conf.catalogs)
    data.sto = null
    data.token = {}
    data.sessionToken = null
    data.qrcode = null
    data.reqTimes = 0
    data.expire = false
    return data
  },
  computed: {
    login() {
      return this.view.login
    }
  },
  watch: {
    'view.login.mod': {
      handler(mod) {
        if (mod === 'wechat') {
          this.genQrcode()
        } else {
          this.initQrCode()
        }
      }
    },
    'view.login.show': {
      handler(show) {
        if (!show) {
          this.initQrCode()
        } else if (this.view.login.mod === 'wechat') {
          this.genQrcode()
        }
      }
    }
  },
  mounted() {
    this.token = service.getToken()
  },
  methods: {
    getAvatar() {
      const ver = Math.round(new Date(this.token.avatar_at) / 1000)
      if (this.token.avatar) return service.getCosUrl(this.token.avatar, 'avatar', ver) || ''
    },
    // 打开本地图片
    openLocalImages(type) {
      setTimeout(() => {
        this.$refs[type + '-uploader'].click()
      }, 100)
    },
    upload(type) {
      const dom = this.$refs[type + '-uploader']
      if (!dom.files.length) {
        return
      }
      this.emit('traceEvent', ['upload_avatar'])
      this.emit('loading', [true, '上传中...'])
      const file = dom.files[0]
      const key = 'public/' + type + '/' + utils.caclUserPath(this.token.uid) + '.png'
      // 上传文件
      this.COSSDK = new COSSDK()
      this.COSSDK.putObject({
        Bucket: conf.cosBucket.bucket,
        Region: conf.cosBucket.region,
        Key: key,
        Body: file
      }, (err, data) => {
        if (!err && data && data.statusCode === 200 && data.Location) {
          const url = 'cos://' + key.replace('public/' + type + '/', '')
          const datetime = utils.date('datetime')
          if (type === 'avatar') {
            this.token = service.getToken()
            this.token.avatar_at = datetime
            this.token.avatar = url
            this.token.avatarUrl = url
            window.localStorage.setItem('token', JSON.stringify(this.token))
            this.update()
          }
          this.updateProfile({ avatar: url }, () => {
            this.emit('loading', [false])
            this.update()
          })
        } else {
          this.message('太火爆了，请稍后重试', 'error')
          this.emit('loading', [false])
        }
      })
    },
    // 更新个人资料
    updateProfile(body, cb) {
      body = body || {}
      service.put('user_profile', this.token.uid, body, (dt, status) => {
        if (status === 'success') {
          this.message('设置成功', status)
          this.update()
        } else {
          this.message(dt, status)
        }
        cb && cb()
      }, true)
    },
    // 发送DZQ验证码
    sendDzqSMS() {
      if (!utils.checkNow('mobile', this.login.phone, true)) return this.message('请输入正确的手机号码', 'error')
      this.emit('sendDzqSMS', ['login', this.login.phone])
    },
    // 用户名登录
    userLogin() {
      setTimeout(() => {
        if (this.login.mod === 'phone') return this.smsLogin()
        // console.error('this.login.username', this.login.username)
        if (!utils.checkNow('username', this.login.username, true)) return this.message('用户名为6~18位字符，包含字母、数字、下划线', 'error')
        if (!utils.checkNow('strongpassword', this.login.password, true)) return this.message('密码为8~20位字符，必须包含字母、数字和特殊字符', 'error')
        if (!this.login.checked) return this.message('请阅读并同意《用户协议》和《隐私政策》', 'error')
        this.login.loging = true
        this.emit('traceEvent', ['login_username'])
        service.login(this.login.username, this.login.password, this.userLoginSuccess)
      }, 200)
    },
    setNickname() {
      if (!utils.checkNow('nickname', this.login.nickname, true)) return this.message('昵称为6~18位字符，包含字母、数字、下划线、汉字', 'error')
      service.setNickname(this.login.nickname, (dt, type) => {
        if (type === 'success') {
          this.token = service.getToken()
          delete this.token.isMissNickname
          this.token.nickname = this.login.nickname
          window.localStorage.setItem('token', JSON.stringify(this.token))
          this.userLoginSuccess('昵称已设置', type)
        } else {
          this.message(dt, type)
        }
      })
    },
    // 手机号登录/注册
    smsLogin() {
      if (this.login.mod !== 'phone') return
      if (!utils.checkNow('mobile', this.login.phone, true)) return this.message('请输入正确的手机号码', 'error')
      if (!utils.checkNow('str-6-6', this.login.code, true)) return this.message('请输入正确的验证码', 'error')
      if (!this.login.checked) return this.message('请阅读并同意《用户协议》和《隐私政策》', 'error')
      this.login.loging = true
      const inviteCode = window.localStorage.getItem('inviteid')
      window.localStorage.setItem('inviteid', '')
      this.emit('traceEvent', ['login_sms'])
      service.smsLogin(this.login.phone, this.login.code, inviteCode, (dt, type) => {
        if (type === 'active') {
          this.token = service.getToken()
          // 激活账户：填写昵称
          this.login.mod = 'active'
          this.login.loging = false
        } else {
          this.userLoginSuccess(dt, type)
        }
      })
    },
    genQrcode() {
      this.initQrCode()
      service.qrcode((res) => {
        if (res.status === 200 && res.data && res.data.Code === 0) {
          this.sessionToken = res.data.Data.sessionToken
          this.qrcode = res.data.Data.base64Img
          this.sto = setTimeout(this.loopQrLogin, 9000)
        } else {
          this.initQrCode()
        }
      })
    },
    loopQrLogin() {
      this.reqTimes++
      if (this.reqTimes < 60) {
        this.qrLogin()
        this.sto = setTimeout(this.loopQrLogin, 3000)
      } else {
        this.expire = true
      }
    },
    qrLogin() {
      if (this.sessionToken) {
        service.qrlogin(this.sessionToken, this.userLoginSuccess)
      } else {
        this.initQrCode()
      }
    },
    userLoginSuccess(msg, type) {
      this.initQrCode()
      this.message(msg, type)
      if (type === 'success') {
        this.emit('getUnread')
        this.emit('setCookie')
        this.emit('traceEvent', ['login_success'])
        setTimeout(() => {
          this.view.login.show = false
          this.login.mod = 'phone'
          // 每次登录都重载
          // window.location.reload()
        }, 200)
      }
      this.login.loging = false
    },
    initQrCode() {
      this.sessionToken = null
      this.qrcode = null
      clearTimeout(this.sto)
      this.expire = false
      this.reqTimes = 0
    }
  }
}
</script>
