import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
import main from '@/components/main'
export const constantRoutes = [
  {
    path: '/',
    name: 'Gridy.Art',
    component: main,
    meta: { title: 'Gridy.Art', icon: 'GridyArt', affix: true }
  }
]

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()
// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
