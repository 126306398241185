<template>
  <el-dialog
    :title="tabNames[view.myPop.tab]"
    :visible.sync="view.myPop.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :top="marginTop"
    class="my-dialog"
    width="916px"
  >
    <span slot="title">
      <span class="el-dialog__title">{{ tabNames[view.myPop.tab] }}</span>
      <i class="iconfont my-close title-btn" title="关闭" @click="() => (view.myPop.show = false)" />
    </span>
    <el-tabs v-if="view.myPop.mod === 'tab'" v-model="view.myPop.tab" type="border-card" class="my-tab" tab-position="left" style="width:896px;height: calc(100vh * 0.6);">
      <!-- <el-tab-pane v-if="view.myPop.group === 'help'" :key="13" name="faq">
        <span slot="label"><i class="iconfont my-faq" /> <br>帮助</span>
      </el-tab-pane> -->
      <el-tab-pane v-if="view.myPop.group === 'about'" :key="14" name="hotkeys">
        <span slot="label"><i class="iconfont my-keyboard" /> <br>快捷键</span>
        <div class="list-scroll" :style="scrollStyle">
          <el-col :span="24" class="txt bold bg">
            {{ hotkeys[0].text }} 快捷键
          </el-col>
          <el-col :span="24">
            <el-col v-for="(item, idx) in hotkeys[0].sub" :key="idx" :span="6">
              <el-button size="mini" type="icon">{{ item.hotkey }}</el-button>
              <span class="txt">{{ item.text }}</span>
            </el-col>
          </el-col>
          <el-col v-for="(item, index) in hotkeys[1].sub" :key="index" :span="24">
            <el-col :span="24" class="txt bold bg">
              {{ item.text }} 快捷键
            </el-col>
            <el-col :span="24">
              <el-col v-for="(subItem, idx) in item.sub" :key="idx" :span="8">
                <el-col v-if="subItem.hotkey" :span="24">
                  <el-button size="large" type="icon">{{ subItem.hotkey }}</el-button>
                  <span class="txt">{{ subItem.text }}</span>
                </el-col>
                <el-col v-if="subItem.sub.length" :span="24">
                  <el-col v-for="(it, i) in subItem.sub" :key="i" :span="24">
                    <el-button size="large" type="icon">{{ it.hotkey }}</el-button>
                    <span class="txt">{{ it.text }}</span>
                  </el-col>
                </el-col>
              </el-col>
            </el-col>
          </el-col>
          <el-col :span="24" class="txt bold bg">
            {{ hotkeys[2].text }} 快捷键
          </el-col>
          <el-col :span="24">
            <el-col v-for="(item, idx) in hotkeys[2].sub" :key="idx" :span="12">
              <el-button size="small" type="icon">{{ item.hotkey }}</el-button>
              <span class="txt">{{ item.text }}</span>
            </el-col>
          </el-col>
        </div>
      </el-tab-pane>
    </el-tabs>
    <span slot="footer" class="dialog-footer">
      <!-- <el-button size="mini" @click="view.myPop.show = false">取 消</el-button>
      <el-button size="mini" type="primary" @click="view.myPop.show = false">确 定</el-button> -->
    </span>
  </el-dialog>
</template>

<script>
import { mixins } from '@/mixins/common'
import utils from '@/js/utils'
import conf from '@/js/conf/conf'
export default {
  components: {},
  mixins: [mixins],
  props: {
    db: {
      type: Object,
      default() {
        return {}
      }
    },
    hotkeys: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    const data = {}
    data.platform = utils.platform()
    data.ver = conf.ver
    data.mainHost = conf.hosts().mainHost
    data.downloadUrl = conf.hosts().downloadUrl
    data.tabNames = utils.deepClone(conf.tabNames)
    data.catalogs = utils.deepClone(conf.catalogs)
    data.rankOpts = utils.deepClone(conf.rankOpts)
    return data
  },
  computed: {
    scrollStyle() {
      return {
        width: '100%',
        height: 'calc(100vh * 0.6 - 8px)'
      }
    }
  },
  methods: {
    setMyPop(k, v) {
      this.view.myPop[k] = v
    },
    // 执行操作命令
    handle(act, data) {
      this[act].apply(null, data)
    }
  }
}
</script>
