import conf from '@/js/conf/conf'
import PullTo from 'vue-pull-to'
import emptyImage from '@/assets/empty.png'
import utils from '@/js/utils'
import service from '@/js/service'
export const mixins = {
  components: {
    PullTo
  },
  props: {
    view: {
      type: Object,
      default() {
        return {}
      }
    },
    state: {
      type: Object,
      default() {
        return {}
      }
    },
    db: {
      type: Object,
      default() {
        return {}
      }
    },
    file: {
      type: Object,
      default() {
        return {}
      }
    },
    mod: {
      type: String,
      default() {
        return 'new'
      }
    },
    params: {
      type: Object,
      default() {
        return {}
      }
    },
    thumbSize: {
      type: Number,
      default: 168
    },
    pageSize: {
      type: Number,
      default: 12
    },
    loginStatus: {
      type: Boolean,
      default: false
    },
    loginUserId: {
      type: Number,
      default: 0
    },
    subtractHeight: {
      type: Number,
      default: 0
    },
    isPop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const catalogs = utils.deepClone(conf.catalogs)
    const schema = {
      scrollTop: 0,
      // 瀑布流各列数据
      columns: [],
      items: [],
      horizWidth: 0,
      currentPage: 0,
      cursorValue: 0,
      cursorValueUp: 0,
      cursorValueDown: 0,
      cursorSkipUp: 0,
      cursorSkipDown: 0,
      skip: 0,
      noUpPage: false,
      noDownPage: false
    }
    let container_width, container_height
    if (this.state.platform.type === 'desktop' && this.isPop) {
      container_width = 632
      container_height = 632
    } else {
      container_width = utils.width()
      container_height = utils.height()
    }
    let horizThumbSize = 160
    if (this.state.platform.type !== 'mobile' && this.mod === 'index') {
      // 非移动端首页时，横向铺满
      horizThumbSize = Math.floor((utils.width() - (this.state.platform.type === 'desktop' ? 24 : 12)) / this.pageSize - 10)
    }
    const data = {
      worksHost: conf.hosts().worksHost,
      container_width: container_width,
      container_height: container_height,
      width: container_width - 3,
      height: container_height - 95,
      // 水平局部时限制缩略图尺寸和水平宽度
      maxThumbWidth: horizThumbSize,
      maxThumbHeight: horizThumbSize,
      renew: false,
      fixHeight: 70,
      // 达到保留页数时，重新渲染列表
      remainPages: 100,
      showUp: false,
      userid: 0,
      emptyImage: emptyImage,
      catalogs: catalogs,
      schema: schema,
      dt: {
        'new': utils.deepClone(schema),
        'hot': utils.deepClone(schema),
        'best': utils.deepClone(schema),
        'user': utils.deepClone(schema),
        'userPrivate': utils.deepClone(schema),
        'praise': utils.deepClone(schema),
        'favor': utils.deepClone(schema),
        'footprint': utils.deepClone(schema),
        'download': utils.deepClone(schema),
        'index': utils.deepClone(schema)
      },
      loading: {
        'new': false,
        'hot': false,
        'best': false,
        'user': false,
        'userPrivate': false,
        'praise': false,
        'favor': false,
        'footprint': false,
        'download': false,
        'index': false
      }
    }
    return data
  },
  computed: {
    isDesktop() {
      return this.state.platform.type === 'desktop'
    },
    isMobile() {
      return this.state.platform.type === 'mobile'
    },
    reqId() {
      return this.state.reqId
    },
    thumbWidth() {
      if (this.isMobile) {
        return this.thumbSize
      } else {
        return this.thumbSize * 1.5
      }
    },
    maxColumns() {
      return Math.max(Math.floor(this.width / (this.thumbWidth + 10)), 1)
    },
    columnWidth() {
      return Math.round(this.width / this.maxColumns)
    },
    cardWidth() {
      return this.columnWidth - 10
    },
    // 瀑布流高度
    flowHeight() {
      let height = 0
      for (let i = 0; i < this.columns.length; i++) {
        const its = this.columns[i].items
        const colHeight = its[its.length - 1]._height + its[its.length - 1]._top
        if (colHeight > height) height = colHeight
      }
      return height
    },
    headHeight() {
      return (!this.isDesktop && this.config.showHead) ? 40 : 0
    },
    listStyle() {
      if (this.subtractHeight) return { 'height': 'calc(100% - ' + this.subtractHeight + 'px)', 'margin-top': '5px' }
      return { 'height': 'calc(100% - ' + (95 + this.headHeight) + 'px)', 'margin-top': (45 + this.headHeight) + 'px' }
    },
    config() {
      return this.view.aiart
    },
    users() {
      return this.state.users
    },
    data() {
      return this.dt[this.mod]
    },
    itemNums() {
      return this.data.items.length
    },
    columns() {
      return this.data ? this.data.columns : []
    },
    limit() {
      let limit = this.pageSize
      // 非首页及非移动端时分页数量X2
      if (!this.isMobile && this.mod !== 'index') limit = limit * 2
      return limit
    }
  },
  watch: {
    'view.praise.show': {
      handler() {
        if (this.view.praise.show && this.mod === 'praise' && this.view.praise.type === 'aiart') this.updateData()
      }
    },
    'view.praise.type': {
      handler() {
        if (this.view.praise.show && this.mod === 'praise' && this.view.praise.type === 'aiart') this.updateData(true)
      }
    },
    'view.favor.show': {
      handler() {
        if (this.view.favor.show && this.mod === 'favor' && this.view.favor.type === 'aiart') this.updateData()
      }
    },
    'view.favor.type': {
      handler() {
        if (this.view.favor.show && this.mod === 'favor' && this.view.favor.type === 'aiart') this.updateData(true)
      }
    },
    'view.footprint.show': {
      handler() {
        if (this.view.footprint.show && this.mod === 'footprint' && this.view.footprint.type === 'aiart') this.updateData()
      }
    },
    'view.footprint.type': {
      handler() {
        if (this.view.footprint.show && this.mod === 'footprint' && this.view.footprint.type === 'aiart') this.updateData(true)
      }
    },
    'view.index.show': {
      handler() {
        if (this.view.index.show && this.mod === 'index') this.updateData()
      }
    },
    'view.download.show': {
      handler() {
        if (this.view.download.show && this.mod === 'download' && this.view.download.type === 'aiart') this.updateData()
      }
    },
    'view.download.type': {
      handler() {
        if (this.view.download.show && this.mod === 'download' && this.view.download.type === 'aiart') this.updateData(true)
      }
    },
    'view.user.show': {
      handler() {
        if (this.view.user.show && ((this.mod === 'user' && this.view.user.type === 'aiart') || (this.mod === 'userPrivate' && this.view.user.type === 'aiartPrivate'))) {
          if ((this.view.user.userid && this.userid !== this.view.user.userid) || !this.itemNums) {
            if (this.userid !== this.view.user.userid) this.init()
            this.getData('down')
          }
          this.update()
        }
      }
    },
    'view.user.type': {
      handler() {
        if (this.view.user.show && ((this.mod === 'user' && this.view.user.type === 'aiart') || (this.mod === 'userPrivate' && this.view.user.type === 'aiartPrivate'))) {
          if (!this.view.user.pop) this.emit('setLocationHash')
          if ((this.view.user.userid && this.userid !== this.view.user.userid) || !this.itemNums) {
            if (this.userid !== this.view.user.userid) this.init()
            this.getData('down')
          }
          this.update()
        }
      }
    },
    'view.aiart.show': {
      handler(show) {
        if (this.config.type === this.mod && show) {
          if (!this.itemNums) this.getData('down')
          this.update()
          this.deleteItems()
        }
      }
    },
    'view.aiart.type': {
      handler() {
        if (this.config.type === this.mod && this.config.show) {
          if (!this.config.pop) this.emit('setLocationHash')
          this.deleteItems()
          if (!this.itemNums) this.getData('down')
        }
      }
    },
    'view.aiart.sortType': {
      handler() {
        if (this.config.type === this.mod && this.config.show) {
          this.init()
          if (!this.config.pop) this.emit('setLocationHash')
          if (!this.itemNums) this.getData('down')
        }
      }
    },
    'view.aiart.sort': {
      handler() {
        if (this.config.type === this.mod && this.config.show) {
          this.init()
          if (!this.config.pop) this.emit('setLocationHash')
          if (!this.itemNums) this.getData('down')
        }
      }
    },
    'view.aiart.origin': {
      handler() {
        if (this.config.type === this.mod && this.config.show) {
          this.init()
          if (!this.config.pop) this.emit('setLocationHash')
          if (!this.itemNums) this.getData('down')
        }
      }
    },
    'view.aiart.catalogid': {
      handler() {
        if (this.config.type === this.mod && this.config.show) {
          this.init()
          if (!this.config.pop) this.emit('setLocationHash')
          if (!this.itemNums) this.getData('down')
        }
      }
    },
    'view.aiart.styleid': {
      handler() {
        if (this.config.type === this.mod && this.config.show) {
          this.init()
          if (!this.config.pop) this.emit('setLocationHash')
          if (!this.itemNums) this.getData('down')
        }
      }
    },
    'view.aiart.renew': {
      handler() {
        if (this.mod === 'hot' || this.mod === 'best' || this.mod === 'new') this.updateItems()
        if (this.view.aiart.renew && this.view.aiart.renew.toString().substr(0, 9) === 'renewView' && this.config.type === this.mod) {
          this.renewView()
        }
      }
    }
    // ,
    // 'data.scrollTop': {
    //   handler() {
    //     if ((this.view.user.show && ((this.mod === 'user' && this.view.user.type === 'aiart') || (this.mod === 'userPrivate' && this.view.user.type === 'aiartPrivate')))) {
    //       this.emit('setScrollTop', [this.data.scrollTop])
    //     }
    //   }
    // }
  },
  mounted() {
    this.calcSize()
    const container = this.scrollContainer()
    if (container) container.onscroll = () => { this.data.scrollTop = container.scrollTop }
    window.addEventListener('resize', this.onResize)
    if (this.config.show && !this.itemNums) return this.getData('down')
  },
  methods: {
    // 更新数据
    updateItems() {
      for (var i in this.data.items) {
        const data = this.state.updateWork[this.data.items[i].paintid]
        if (data) {
          for (const k in data) {
            this.data.items[i][k] = data[k]
          }
        }
      }
      this.renderItems(this.data.items, true)
    },
    // 逻辑删除作品
    deleteItems() {
      let deleteIds = []
      if (this.mod === 'hot') deleteIds = this.state.deleteHotPaint
      if (this.mod === 'best') deleteIds = this.state.deleteBestPaint
      if (this.mod === 'new') deleteIds = this.state.deletePaint
      const ids = []
      for (var id in deleteIds) {
        ids.push(parseInt(id))
      }
      if (ids.length) {
        const items = []
        for (var i in this.data.items) {
          if (ids.indexOf(this.data.items[i].paintid) < 0) items.push(this.data.items[i])
        }
        this.data.items = items
        if (this.mod === 'hot') this.state.deleteHotPaint = {}
        if (this.mod === 'best') this.state.deleteBestPaint = {}
        if (this.mod === 'new') this.state.deletePaint = {}
        this.renderItems(this.data.items, true)
      }
    },
    // 移除作品
    removeItem(id) {
      for (var i in this.data.items) {
        if (this.data.items[i].paintid === id) {
          this.data.items.splice(i, 1)
        }
      }
      this.renderItems(this.data.items, true)
    },
    // 设置主题属性
    setItem(paintid, key, value) {
      for (var i in this.data.items) {
        if (this.data.items[i].paintid === paintid) {
          this.data.items[i][key] = value
          return this.data.items[i]
        }
      }
    },
    scrollContainer() {
      const main = document.getElementById('aiart-' + this.mod + '-container')
      if (main) {
        const scroll = main.getElementsByClassName('scroll-container')
        if (scroll.length) return scroll[0]
      }
    },
    onResize() {
      if (!this.config.show && !this.view.user.show && !this.view.praise.show && !this.view.favor.show && !this.view.footprint.show && !this.view.index.show && !this.view.download.show) return
      this.calcSize()
      // this.backTop()
      // this.data.scrollTop = 0
      this.renderItems(this.data.items, true)
    },
    backTop() {
      const container = this.scrollContainer()
      if (container) {
        setTimeout(() => {
          container.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
        }, 100)
      }
    },
    renewView() {
      this.backTop()
      this.loadData(() => {}, 'renew')
    },
    showItem(item) {
      if (item._top <= this.data.scrollTop - this.height && item._top + item._height >= this.data.scrollTop - this.height) return true
      if (item._top >= this.data.scrollTop - this.height && item._top <= this.data.scrollTop + this.height + this.height) return true
      return false
    },
    formateTime: utils.formateTime,
    formateNums: utils.formateNums,
    // 调用父组件方法
    emit(fn, params) {
      if (!fn) return
      this.$emit('receive', fn, params || [])
    },
    // 提示信息
    message(msg, type) {
      this.emit('message', [msg, type])
    },
    // 确认操作
    confirm(msg, fn, title) {
      this.emit('confirm', [msg, fn, title])
    },
    getMinHeight(arr) {
      const a = []
      for (let i = 0; i < arr.length; i++) {
        a.push(parseInt(arr[i]._height) + parseInt(arr[i]._top))
      }
      return Math.min.apply(null, a)
    },
    getMinIndex(val) {
      const arrIndex = []
      for (let i = 0; i < this.columns.length; i++) {
        const its = this.columns[i].items
        const height = its[its.length - 1]._height
        const top = its[its.length - 1]._top
        if (parseInt(height) + parseInt(top) === val) {
          arrIndex.push(i)
        }
      }
      return arrIndex
    },
    calcItemSize(w, h) {
      // 水平布局
      if (this.mod === 'index') {
        this.data.horizWidth = this.data.horizWidth + this.maxThumbWidth + 10
        return this.calcHorizItemSize(w, h)
      }
      let height = Math.round(this.cardWidth * h / w)
      height = Math.min(height, Math.round(this.height * 3 / 5))
      const width = Math.round(height * w / h)
      return { width: width, height: height }
    },
    // 计算水平布局的ItemSize
    calcHorizItemSize(w, h) {
      if (w < this.maxThumbWidth && h < this.maxThumbHeight) return { width: w, height: h }
      let width, height
      if (w > h) {
        width = this.maxThumbWidth
        height = Math.round(width * h / w)
      } else {
        height = this.maxThumbHeight
        width = Math.round(height * w / h)
      }
      return { width: width, height: height }
    },
    renderItems(items, refresh = false) {
      if (refresh) {
        this.data.columns = []
        this.data.horizWidth = 0
      }
      if (!items.length) return
      // 初始化列、列的第一个元素
      let idx = 0
      if (this.columns.length < this.maxColumns || refresh) {
        for (let i = 0; i < this.maxColumns; i++) {
          if (!this.columns[i] && items[idx]) {
            const itemSize = this.calcItemSize(items[idx].width, items[idx].height)
            items[idx]._image = this.getPaint(items[idx])
            items[idx]._top = 0
            items[idx]._raw_width = itemSize.width
            items[idx]._raw_height = itemSize.height
            items[idx]._height = items[idx]._raw_height + this.fixHeight
            this.columns.push({ items: [items[idx]] })
            idx++
          }
        }
      }
      // 对剩余元素的判断，应该放到哪一列
      for (var index = idx; index < items.length; index++) {
        // 找到高度最小的一列，可能有多个
        const tmp = []
        for (let i = 0; i < this.columns.length; i++) {
          const its = this.columns[i].items
          tmp.push({
            _height: its[its.length - 1]._height,
            _top: its[its.length - 1]._top
          })
        }
        // 获取最矮的列高度
        const minHeight = this.getMinHeight(tmp)
        // 获取最矮列的索引
        let minHeightIdx = 0
        const arrIndex = this.getMinIndex(minHeight)
        // 出现高度一样的，取索引最小的
        if (arrIndex.length > 0) {
          minHeightIdx = Math.min.apply(null, arrIndex)
        }
        // 设置属性
        const itemSize = this.calcItemSize(items[index].width, items[index].height)
        items[index]._image = this.getPaint(items[index])
        items[index]._top = minHeight
        items[index]._raw_width = itemSize.width
        items[index]._raw_height = itemSize.height
        items[index]._height = items[index]._raw_height + this.fixHeight
        this.columns[minHeightIdx].items.push(items[index])
      }
    },
    calcSize() {
      if (this.state.platform.type === 'desktop' && this.isPop) {
        this.container_width = 632
        this.container_height = 632
      } else {
        this.container_width = utils.width()
        this.container_height = utils.height()
      }
      this.width = this.container_width - 3
      this.height = this.container_height - 95 - this.headHeight
      let horizThumbSize = 160
      if (this.state.platform.type !== 'mobile' && this.mod === 'index') {
        // 非移动端首页时，横向铺满
        horizThumbSize = Math.floor((this.width - (this.state.platform.type === 'desktop' ? 24 : 12)) / this.pageSize - 10)
      }
      this.maxThumbWidth = horizThumbSize
      this.maxThumbHeight = horizThumbSize
    },
    init(backTop) {
      if (backTop) this.backTop()
      this.dt[this.mod] = utils.deepClone(this.schema)
    },
    update() {
      this.$forceUpdate()
    },
    updateData(updateHash) {
      if (updateHash && !this.config.pop) this.emit('setLocationHash')
      if (!this.itemNums) {
        this.init()
        this.getData('down')
      } else {
        this.update()
      }
    },
    getPaint(item) {
      if (!item || !item.images || !item.images[0]) return ''
      return service.getCosUrl(item.images[0], 'paint', item.add_at) || ''
    },
    getAvatar(item) {
      const ver = Math.round(new Date(this.users[item.userid].avatar_at) / 1000)
      if (this.users[item.userid] && this.users[item.userid].avatar) return service.getCosUrl(this.users[item.userid].avatar, 'avatar', ver) || ''
    },
    showActionSheet(item) {
      this.emit('showActionSheet', [item.userid, 'aiart', item, (id, data, dt, type) => {
        if (type === 'success') {
          this.view.aiart.renew = utils.time('time')
          this.deleteItems()
          if ((this.mod === 'user' || this.mod === 'userPrivate') && (data.public === 0 || data.public === 1 || data.check === 0 || data.flag === 0 || data.flag === 1)) {
            this.removeItem(id)
          }
        }
      }, ''])
    },
    loadData(loaded, direction) {
      if (this.config.show || this.view.user.show || this.view.praise.show || this.view.favor.show || this.view.footprint.show || this.view.index.show || this.view.download.show) {
        this.renew = direction === 'renew'
        if (direction === 'renew') {
          direction = 'down'
          this.data.currentPage = 0
          this.data.cursorValue = 0
          this.data.cursorValueUp = 0
          this.data.cursorValueDown = 0
          this.data.cursorSkipUp = 0
          this.data.cursorSkipDown = 0
          this.data.skip = 0
          this.data.noUpPage = false
          this.data.noDownPage = false
        }
        this.getData(direction, () => {
          this.renew = false
          loaded('done')
        })
      }
    },
    openAiPaint(item) {
      this.emit('openAiPaint', [{ paint: item.ai_params }])
    },
    openGridyViewer(id, item) {
      if (item.ai_status === 2 && item.images.length) {
        const images = []
        const names = []
        for (const i in item.images) {
          images.push(service.getCosUrl(item.images[i], 'paint', item.add_at))
          names.push(item.name)
        }
        this.emit('openGridyViewer', [{ data: { images: images, names: names, idx: 0, type: 'imagesViewer' }}])
      }
    },
    // 分页读取数据
    async getData(direction, cb) {
      direction = direction || 'down'
      if (!this.config.show && !this.view.user.show && !this.view.praise.show && !this.view.favor.show && !this.view.footprint.show && !this.view.index.show && !this.view.download.show) return cb && cb()
      if (direction === 'up' && this.data.noUpPage) return cb && cb()
      if (direction === 'down' && this.data.noDownPage) return cb && cb()
      if (this.loading[this.mod]) return cb && cb()
      this.loading[this.mod] = true
      const params = {
        'sort_field': 'update_at',
        'sort': 'desc',
        'limit': this.limit,
        'skip': direction === 'up' ? this.data.cursorSkipUp : this.data.cursorSkipDown,
        'cursor_value': direction === 'up' ? this.data.cursorValueUp : this.data.cursorValueDown,
        'direction': direction
      }
      if (this.config.sortType === 'create') {
        params.sort_field = 'add_at'
      } else {
        params.sort_field = 'update_at'
      }
      if (this.config.sort === 'asc') {
        params.sort = 'asc'
      } else {
        params.sort = 'desc'
      }
      if (this.config.origin !== '') {
        params.origin = this.config.origin
      }
      const catalogid = utils.getInt(this.config.catalogid)
      if (catalogid) params.catalogid = catalogid
      if (this.config.styleid !== '0') params.styleid = this.config.styleid
      if (this.mod === 'hot') params.hot = 1
      if (this.mod === 'best') params.best = 1
      if (this.mod === 'user' || this.mod === 'userPrivate') {
        this.userid = this.view.user.userid
        params.userid = this.view.user.userid
        if (this.mod === 'user') params.public = 1
        if (this.mod === 'userPrivate') params.public = 0
      }
      if (this.mod === 'praise' || this.mod === 'favor' || this.mod === 'footprint' || this.mod === 'download') {
        params.mod = this.mod
      }
      // console.error('params', this.mod, params, direction, this.data.currentPage, this.data, direction === 'up', this.data.cursorValueUp, this.data.cursorValueDown)
      service.listGet('paint', params, (res, type) => {
        if (type === 'success') {
          let refresh = false
          if (direction === 'up') {
            refresh = true
            this.data.cursorValueUp = res.data.cursor_value_up
            this.data.cursorSkipUp = res.data.cursor_skip_up
            if (!this.data.cursorValueDown && !utils.isEmpty(res.data.cursor_value_down)) this.data.cursorValueDown = res.data.cursor_value_down
            this.data.noUpPage = utils.isEmpty(this.data.cursorValueUp)
            this.data.items = res.data.items.concat(this.data.items)
          } else if (direction === 'down') {
            if ((res.data.count === this.limit && this.data.currentPage >= this.remainPages) || this.renew) {
              if (res.data.count === this.limit && this.data.currentPage >= this.remainPages) {
                this.init(true)
                this.showUp = true
              } else {
                this.init()
              }
              refresh = true
            }
            this.data.currentPage++
            if (!this.data.cursorValueUp && !utils.isEmpty(res.data.cursor_value_up)) this.data.cursorValueUp = res.data.cursor_value_up
            this.data.cursorValueDown = res.data.cursor_value_down
            this.data.cursorSkipDown = res.data.cursor_skip_down
            this.data.noDownPage = utils.isEmpty(this.data.cursorValueDown)
            this.data.items = this.data.items.concat(res.data.items)
          }
          if (res.data.users) {
            for (var userid in res.data.users) {
              this.users[userid] = res.data.users[userid]
            }
          }
          this.data.skip = utils.getInt(res.data.skip)
          // this.getPraiseState(res.data)
          if (direction === 'up') {
            this.renderItems(this.data.items, true)
          } else {
            this.renderItems(res.data.items, refresh)
          }
        } else {
          this.message(res, type)
        }
        cb && cb()
        this.loading[this.mod] = false
        if (this.view.user.show) this.view.user.loading = false
        if (this.view.download.show) this.view.download.loading = false
        if (this.view.footprint.show) this.view.footprint.loading = false
        if (this.view.index.show) this.view.index.loading = false
        if (this.view.favor.show) this.view.favor.loading = false
        if (this.view.praise.show) this.view.praise.loading = false
      }, false, false)
    },
    // 喜欢
    praiseIt(paintid) {
      if (!paintid) return
      if (this.mod === 'praise' && this.state.praiseState[paintid]) this.removeItem(paintid)
      let praise_count = this.state.praiseCount[paintid] || 0
      const praise = () => {
        if (this.state.praiseState[paintid]) {
          this.state.praiseState[paintid] = 0
          praise_count--
          service.decCount(this.loginUserId, 'paint_praise')
        } else {
          this.state.praiseState[paintid] = 1
          praise_count++
          service.incCount(this.loginUserId, 'paint_praise')
        }
        praise_count = Math.max(0, praise_count)
        this.state.praiseCount[paintid] = praise_count
        this.update()
      }
      praise()
      service.get('paint_praise', paintid, (dt, type) => {
        if (type !== 'success') {
          // 失败回滚
          praise()
          this.message(dt, type)
        }
      }, true)
    },
    // 取消收藏
    unfavorIt(paintid) {
      if (!paintid) return
      if (this.mod === 'favor') this.removeItem(paintid)
      service.decCount(this.loginUserId, 'paint_favor')
      service.get('paint_favor', paintid, '', true)
    },
    // 获取赞的状态
    getPraiseState(data) {
      if (!data) return
      if (data.praise) {
        for (var paintid in data.praise) {
          this.state.praiseCount[paintid] = data.praise[paintid]
        }
      }
      const items = data.items
      if (Array.isArray(items)) {
        const ids = []
        for (var i in items) {
          if (data.praise[items[i].paintid] && items[i].paintid && typeof this.state.praiseState[items[i].paintid] === 'undefined') {
            ids.push(items[i].paintid)
          }
        }
        if (ids.length) {
          const params = { ids: ids, 'type': 'praise' }
          service.actionGet('paint_count', params, (res, type) => {
            if (type === 'success' && res.data && res.data.praise) {
              for (const k in res.data.praise) {
                this.state.praiseState[k] = res.data.praise[k]
              }
              this.update()
            }
          }, true)
        }
      }
    }
  }
}
