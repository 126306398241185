<template>
  <el-dialog
    ref="inviteDialog"
    :visible.sync="config.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="!isDesktop"
    :show-close="false"
    :top="marginTop"
    :custom-class="isDesktop ? 'pop_page noscroll' : 'pop_page noscroll headless'"
  >
    <div v-if="isDesktop" slot="title" class="header">
      <div class="left">
        &nbsp;
      </div>
      <div class="middle">
        我的邀请
      </div>
      <div class="float-right align-right">
        <el-button class="tap-btn iconfont my-close size20" @click="closePop(config)" />
        &nbsp;
      </div>
    </div>
    <div v-show="config.show" class="my-container">
      <div ref="header-card" class="header-card" :style="style">
        <div v-if="!isDesktop" class="header">
          <div class="left">
            <el-button class="tap-btn" @click="closePop(config)">
              <i class="iconfont my-back" />
            </el-button>
          </div>
          <div class="middle">
            我的邀请
          </div>
          <div class="right" />
        </div>
        <div v-if="scrollTop < 100" class="my-row album-info" style="padding-top:30px;">
          <el-row>
            <div>
              <span v-if="!user.avatar" class="el-avatar el-avatar--circle noavatar xlarge">
                <i class="iconfont my-man" />
              </span>
              <el-avatar v-if="user.avatar" :size="64" :src="getAvatar()" />
            </div>
          </el-row>
          <el-row>
            {{ user.nickname }}
          </el-row>
        </div>
        <div class="padding-top8 padding-left8">{{ total ? '已邀请' + total + '人' : '' }} </div>
      </div>
      <users-list
        v-show="config.show"
        ref="users-invite"
        mod="invite"
        :view="view"
        :state="state"
        :subtract-height="subtractHeight"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        @receive="handle"
      />
    </div>
    <div slot="footer" class="footer padding-top8" style="position: absolute;">
      <el-button type="light" class="btn-ok clipboard" style="max-width:200px;" :data-clipboard-text="' ' + user.nickname + ' 邀请您加入 Gridy.Art ，这里的精彩只有你能懂！ ' + mainHost + '#/?inviteid=' + loginUserId" @click="invite">
        邀请好友
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import utils from '@/js/utils'
import conf from '@/js/conf/conf'
import service from '@/js/service'
import usersList from '@/components/web/usersList'
import { mixins } from '@/mixins/common'
export default {
  components: {
    usersList
  },
  mixins: [mixins],
  data() {
    const data = {
      mainHost: conf.hosts().mainHost,
      worksHost: conf.hosts().worksHost,
      scrollTop: 0,
      subtractHeight: 210,
      total: 0,
      user: {}
    }
    return data
  },
  computed: {
    style() {
      const style = {}
      let bg = this.getBackground()
      // if (!bg) bg = this.worksHost + utils.getDefaultBg(this.user.userid || 0) + '/background'
      if (!bg) bg = utils.getDefaultBg(this.user.userid || 0)
      if (bg && this.scrollTop < 100 && !this.view.invite.loading) {
        style['background-image'] = 'url(' + bg + ')'
        style['background-position'] = 'center center'
        style['background-size'] = '100% auto'
        style['background-repeat'] = 'no-repeat'
      } else {
        style['padding-top'] = '30px'
      }
      return style
    },
    config() {
      return this.view.invite
    }
  },
  // watch: {
  //   'view.invite.show': {
  //     handler() {
  //       if (this.view.invite.show) {
  //         this.updateData()
  //         this.update()
  //         this.view.invite.loading = false
  //         if (this.loginUserId && this.loginUserId !== this.user.userid) {
  //           this.getUser()
  //         }
  //       }
  //     }
  //   },
  //   'view.user.renew': {
  //     handler() {
  //       this.user = {}
  //     }
  //   }
  // },
  mounted() {
    this.view.invite.loading = false
    if (!this.loginUserId) {
      this.emit('login')
      return
    }
    this.getUser()
    this.updateData()
  },
  methods: {
    updateData() {
      this.callSub('users-invite', 'updateData')
    },
    handle(act, data) {
      if (act === 'setTotal') {
        this.setTotal(...data)
      } else if (act === 'setScrollTop') {
        this.setScrollTop(...data)
      } else if (act === 'update') {
        this.update()
      } else {
        this.emit(act, data)
      }
    },
    setTotal(total) {
      this.total = total || 0
    },
    getElementHeight(refid) {
      return this.$refs[refid] ? parseInt(window.getComputedStyle(this.$refs[refid]).height) : 0
    },
    getAvatar() {
      const ver = Math.round(new Date(this.user.avatar_at) / 1000)
      if (this.user.avatar) return service.getCosUrl(this.user.avatar, 'avatar', ver) || ''
    },
    getBackground() {
      const ver = Math.round(new Date(this.user.background_at) / 1000)
      if (this.user.background) return service.getCosUrl(this.user.background, 'background', ver) || ''
    },
    update() {
      this.$forceUpdate()
      setTimeout(() => {
        const cardHeight = this.getElementHeight('header-card')
        this.subtractHeight = cardHeight + 50
      }, 10)
    },
    // 邀请
    invite() {
      // eslint-disable-next-line
      var clipboard = new ClipboardJS('.clipboard')
      clipboard.on('success', (e) => {
        e.clearSelection()
        this.message('链接复制成功，请粘贴邀请好友吧', 'success')
        clipboard.destroy()
      })
    },
    getUser() {
      if (this.view.invite.loading) return
      this.view.invite.loading = true
      this.emit('getUser', [this.loginUserId, (user) => {
        if (user) this.user = user
        this.view.invite.loading = false
        this.update()
      }])
    }
  }
}
</script>
