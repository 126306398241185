<template>
  <el-dialog
    ref="orderDialog"
    :visible.sync="config.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="!isDesktop"
    :show-close="false"
    :top="marginTop"
    custom-class="pop_page noscroll orderDialog"
  >
    <div slot="title" class="header">
      <div class="left">
        <el-button v-if="!isDesktop" class="tap-btn iconfont my-back size20" @click="closePop(config)" />
        &nbsp;
      </div>
      <div class="middle">
        我的订单
      </div>
      <div class="float-right align-right">
        <el-button v-if="deleteMod" :class="{'tap-btn': canDelete, 'txt-btn': !canDelete}" @click="deleteItems()">
          <i v-if="canDelete" class="iconfont my-delete blue size20" />
          <span v-if="!canDelete" class="blue">取消</span>
        </el-button>
        <el-button v-if="isDesktop" class="tap-btn iconfont my-close size20" @click="closePop(config)" />
        &nbsp;
      </div>
    </div>
    <div v-show="config.show" class="my-container">
      <div class="tabs my-tabs bg-20" style="padding:2px;">
        <el-tabs v-model="config.type">
          <el-tab-pane v-for="(type, idx) in config.types" :key="idx" :label="type[1]" :name="type[0]" />
        </el-tabs>
      </div>
      <order-list
        v-show="config.show && config.type === 'all'"
        ref="order-all"
        mod="all"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :subtract-height="subtractHeight"
        @receive="handle"
      />
      <order-list
        v-show="config.show && config.type === 'paying'"
        ref="order-paying"
        mod="paying"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :subtract-height="subtractHeight"
        @receive="handle"
      />
      <order-list
        v-show="config.show && config.type === 'paid'"
        ref="order-paid"
        mod="paid"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :subtract-height="subtractHeight"
        @receive="handle"
      />
      <order-list
        v-show="config.show && config.type === 'receive'"
        ref="order-receive"
        mod="receive"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :subtract-height="subtractHeight"
        @receive="handle"
      />
      <order-list
        v-show="config.show && config.type === 'comment'"
        ref="order-comment"
        mod="comment"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :subtract-height="subtractHeight"
        @receive="handle"
      />
    </div>
  </el-dialog>
</template>
<script>
import orderList from '@/components/web/orderList'
import { mixins } from '@/mixins/common'
import service from '@/js/service'
export default {
  components: {
    orderList
  },
  mixins: [mixins],
  data() {
    return {
      subtractHeight: this.state.platform.type === 'desktop' ? 40 : 80,
      canDelete: false,
      deleteMod: false,
      deleteIds: {}
    }
  },
  computed: {
    config() {
      return this.view.order
    }
  },
  watch: {
    // 'view.order.show': {
    //   handler() {
    //     this.init()
    //   }
    // },
    'view.order.type': {
      handler() {
        this.init()
      }
    }
  },
  mounted() {
    if (!this.loginUserId) {
      this.emit('login')
      return
    }
    this.init()
  },
  methods: {
    handle(act, data) {
      if (act === 'setDeleteData') {
        this.deleteMod = data[0]
        this.deleteIds = data[1]
        this.canDelete = this.deleteMod && Object.keys(this.deleteIds).length > 0
        this.update()
      } else if (act === 'cancelDelete') {
        this.cancelDelete()
      } else {
        this.emit(act, data)
      }
    },
    init() {
      this.canDelete = false
      this.deleteMod = false
      this.deleteIds = {}
    },
    cancelDelete() {
      this.$refs['order-' + this.config.type].cancelDelete()
      this.init()
    },
    deleteItems() {
      if (this.canDelete) {
        const ids = []
        for (const id in this.deleteIds) {
          ids.push(id)
        }
        if (this.config.type === 'all' || this.config.type === 'paying') this.state.deletePayingBrickorder = this.deleteIds
        if (this.config.type === 'all' || this.config.type === 'paid') this.state.deletePaidBrickorder = this.deleteIds
        if (this.config.type === 'all' || this.config.type === 'receive') this.state.deleteReceiveBrickorder = this.deleteIds
        if (this.config.type === 'all' || this.config.type === 'comment') this.state.deleteCommentBrickorder = this.deleteIds
        this.state.deleteAllBrickorder = this.deleteIds
        this.$refs['order-' + this.config.type].deleteItems()
        service.batchDelete('brick_order', ids, (dt, type) => {}, true)
        this.init()
      } else {
        this.cancelDelete()
      }
    }
  }
}
</script>
