import device from 'current-device'
const setFontSize = (minFontSize = 12, maxFontSize = 14) => {
  if (device.desktop()) return
  const set = () => {
    var dom = document.documentElement || document.body
    var w = dom.clientWidth
    var h = dom.clientHeight
    var s = Math.min(w, h)
    if (s > 750) s = 7.5 * 50
    const fontSize = Math.max(minFontSize, Math.min(12 * (s / 320), maxFontSize))
    dom.style.fontSize = `${fontSize}px`
  }
  set()
  window.addEventListener('resize', set, false)
}
const getFontSize = (minFontSize = 12, maxFontSize = 14) => {
  if (device.desktop()) return
  var dom = document.documentElement || document.body
  var w = dom.clientWidth
  var h = dom.clientHeight
  var s = Math.min(w, h)
  if (s > 750) s = 7.5 * 50
  return Math.max(minFontSize, Math.min(12 * (s / 320), maxFontSize))
}
export default { setFontSize, getFontSize }
