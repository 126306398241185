<template>
  <el-dialog
    ref="topicDetailDialog"
    :visible.sync="config.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="!isDesktop"
    :show-close="false"
    :top="marginTop"
    custom-class="pop_page noscroll topicDetailDialog"
  >
    <div slot="title" class="header">
      <div class="left">
        <el-button v-if="!isDesktop" class="tap-btn iconfont my-back size20" @click="closePop(config)" />
        &nbsp;
      </div>
      <div class="middle">
        {{ topic.content ? '#' + topic.content + '#' : '' }}
      </div>
      <div class="float-right align-right">
        <el-button class="tap-btn" @click="shareIt">
          <i class="iconfont clipboard my-share size20" :data-clipboard-text="' 快来围观 ' + (topic.content ? '#' + topic.content + '#' : '') + ' ' + mainHost + '#/?mod=topicDetail&topicid=' + topic.id" />
        </el-button>
        <el-button v-if="isDesktop" class="tap-btn iconfont my-close size20" @click="closePop(config)" />
      </div>
    </div>
    <div v-show="config.show" class="my-container margin-top12">
      <div class="topic-info bg-28 round8">
        <el-row>
          {{ topic.content ? '#' + topic.content + '#' : '' }}
        </el-row>
        <el-row class="color-cc size14">
          <el-col :span="6">
            热度：{{ topic.view_count || 0 }}
          </el-col>
          <el-col :span="6">
            主题：{{ topic.thread_count || 0 }}
          </el-col>
        </el-row>
      </div>
      <thread-list
        v-show="config.show"
        ref="topicDetail-thread"
        mod="topic"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :subtract-height="subtractHeight"
        :is-pop="true"
        @receive="handle"
      />
    </div>
  </el-dialog>
</template>
<script>
import threadList from '@/components/web/threadList'
import { mixins } from '@/mixins/common'
import service from '@/js/service'
import conf from '@/js/conf/conf'
export default {
  components: {
    threadList
  },
  mixins: [mixins],
  data() {
    const data = {}
    data.topic = {}
    data.mainHost = conf.hosts().mainHost
    data.subtractHeight = 80
    return data
  },
  computed: {
    config() {
      return this.view.topicDetail
    }
  },
  // watch: {
  //   'view.topicDetail.show': {
  //     handler() {
  //       if (this.view.topicDetail.show) {
  //         this.update()
  //         this.view.topicDetail.loading = false
  //         if (this.config.topicid && this.config.topicid !== this.topic.id) {
  //           this.getTopic(true)
  //         }
  //       }
  //     }
  //   }
  // },
  mounted() {
    this.view.topicDetail.loading = false
    this.getTopic(true)
  },
  methods: {
    handle(act, data) {
      this.emit(act, data)
    },
    renewView() {
      const el = this.$refs['topicDetail-thread']
      if (el) {
        el.renewView()
      } else {
        setTimeout(this.renewView, 100)
      }
    },
    getTopic(renew) {
      if (this.view.topicDetail.loading) return
      this.view.topicDetail.loading = true
      service.get('topics', this.config.topicid, (dt) => {
        if (dt && dt.data && dt.data.items) {
          this.topic = dt.data.items[this.config.topicid] || {}
        }
        this.view.topicDetail.loading = false
        this.update()
      })
      if (renew) this.renewView()
    },
    // 分享
    shareIt() {
      // eslint-disable-next-line
      var clipboard = new ClipboardJS('.clipboard')
      clipboard.on('success', (e) => {
        e.clearSelection()
        this.message('链接复制成功，请粘贴分享', 'success')
        clipboard.destroy()
      })
    }
  }
}
</script>
