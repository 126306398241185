<template>
  <el-dialog
    title="发布作品"
    :visible.sync="view.myPublish.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :class="{'my-dialog': isDesktop}"
    :fullscreen="!isDesktop"
    :top="marginTop"
  >
    <span slot="title">
      <span class="el-dialog__title">发布作品</span>
      <i class="iconfont my-close title-btn size20" title="关闭" @click="view.myPublish.show = false" />
    </span>
    <el-card v-loading="uploading" shadow="never" class="login-card padding-top8" element-loading-text="提交中..." element-loading-background="rgba(0, 0, 0, 0.8)">
      <el-form label-width="82px" style="max-width: 400px;margin: 0 auto;">
        <el-col :span="24">
          <el-form-item label="目录：" class="my-el-form-item">
            <el-select v-model="file.catalogid" style="width: 260px;" placeholder="请选择...">
              <el-option
                v-for="item in catalogs"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="23">
          <el-form-item label="名称：">
            <el-input v-model="file.name" style="width: 260px;" class="my-from-item" name="name" placeholder="请输入作品名称" clearable />
          </el-form-item>
        </el-col>
        <el-col :span="23">
          <el-form-item label="简介：">
            <el-input v-model="file.info" type="textarea" class="my-from-item" name="info" :autosize="{ minRows: 7, maxRows:7 }" :rows="8" style="width: 260px;" :maxlength="1000" placeholder="请输入作品介绍" />
          </el-form-item>
        </el-col>
        <el-col :span="23">
          <el-form-item label="标签：" class="my-el-form-item">
            <tags :tags="file.tags" />
          </el-form-item>
        </el-col>
        <el-col :span="23">
          <el-form-item label="展示：" class="my-el-form-item">
            <el-checkbox v-model="tmp.show">公开展示</el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="23">
          <el-form-item label="分享：" class="my-el-form-item">
            <el-checkbox v-model="tmp.share">分享到圈子</el-checkbox>
          </el-form-item>
        </el-col>
      </el-form>
    </el-card>
    <span slot="footer" class="dialog-footer">
      <el-button class="margin-bottom12 btn-ok" type="light" style="width:200px;" @click="() => publishWork()">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { mixins } from '@/mixins/common'
import utils from '@/js/utils'
import conf from '@/js/conf/conf'
import tags from '@/components/tags'
export default {
  components: {
    tags
  },
  mixins: [mixins],
  props: {
    db: {
      type: Object,
      default() {
        return {}
      }
    },
    tmp: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    const data = {}
    data.apiHost = conf.hosts().apiHost
    data.worksHost = conf.hosts().worksHost
    data.catalogs = utils.deepClone(conf.catalogs)
    data.uploading = false
    this.file.catalogid = this.file.catalogid || this.tmp.catalogid
    if (!this.file.tags || !utils.isArray(this.file.tags) || !this.file.tags.length) {
      this.file.tags = this.tmp.tags
    }
    return data
  },
  methods: {
    publishWork() {
      this.file.public = this.tmp.show ? 1 : 0
      this.file.publish = true
      this.file.publishTime = utils.date('datetime')
      this.tmp.catalogid = this.file.catalogid
      this.tmp.tags = this.file.tags
      this.emit('saveRemote', [this.file, false, this.tmp.share, this.view.myPublish.fn])
      this.view.myPublish.show = false
    }
  }
}
</script>
