<template>
  <el-dialog
    ref="userDialog"
    :visible.sync="config.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="!isDesktop"
    :show-close="false"
    :top="marginTop"
    :custom-class="isDesktop ? 'pop_page noscroll' : 'pop_page noscroll headless'"
  >
    <div v-if="isDesktop" slot="title" class="header">
      <div class="left">
        &nbsp;
      </div>
      <div class="middle">
        <span v-if="!(loginUserId && config.userid === loginUserId)">个人主页</span>
        <span v-if="loginUserId && config.userid === loginUserId">{{ title }}</span>
      </div>
      <div class="float-right align-right">
        <el-button v-if="!(loginUserId && config.userid === loginUserId)" class="tap-btn" @click="showActionSheet">
          <i class="iconfont my-dot-more" />
        </el-button>
        <el-button class="tap-btn iconfont my-close size20" @click="close" />
        &nbsp;
      </div>
    </div>
    <div v-show="config.show" class="my-container">
      <div v-if="config.mng !== 'yes'">
        <div v-if="(isDesktop && largeMod) || !isDesktop" ref="header-card" class="header-card" :style="cardStyle">
          <div v-if="!isDesktop" class="header">
            <div class="left">
              <el-button v-if="!config.hideBack" class="tap-btn" @click="close">
                <i class="iconfont my-back" />
              </el-button>
              <span v-if="config.hideBack">&nbsp;</span>
            </div>
            <div class="middle">
              <span v-if="largeMod || config.userid === loginUserId">{{ (config.userid !== loginUserId || isDesktop) ? '个人主页' : '我的' }}</span>
              <div v-if="!largeMod && config.userid !== loginUserId" class="user">
                <div class="l">
                  <span v-if="!user.avatar" class="el-avatar el-avatar--circle noavatar small">
                    <i class="iconfont my-man" />
                  </span>
                  <el-avatar v-if="user.avatar" :size="24" :src="getAvatar()" />
                </div>
                <div class="m">
                  {{ user.nickname || view.anonymity }}
                </div>
                <div class="r">
                  <el-button v-if="(followState[config.userid] === 0)" class="tap-btn on opacity-bg" @click="unfollow(config.userid)">已关注</el-button>
                  <el-button v-if="(followState[config.userid] === 1)" class="tap-btn on opacity-bg" @click="unfollow(config.userid)">互相关注</el-button>
                  <el-button v-if="(followState[config.userid] !== 0 && followState[config.userid] !== 1)" class="tap-btn opacity-bg" @click="follow(config.userid)">关注</el-button>
                </div>
              </div>
            </div>
            <div class="right">
              <el-button class="tap-btn" @click="moreAction">
                <i class="iconfont my-dot-more" />
              </el-button>
            </div>
          </div>
          <div v-if="largeMod" class="my-row user-info">
            <div class="avatar" @click="setProfile()">
              <span v-if="!(user.avatar && !config.loading)" class="el-avatar el-avatar--circle noavatar xlarge">
                <i class="iconfont my-man" />
              </span>
              <el-avatar v-if="user.avatar && !config.loading" :size="64" :src="getAvatar()" />
              <!-- <span v-if="isVip" class="iconfont my-vip vip-tag opacity-bg yellow" /> -->
            </div>
            <div class="info">
              <span class="name" @click="setProfile()">{{ user.nickname || view.anonymity }}</span>
              <span v-if="isVip || config.userid === loginUserId" class="addition opacity-bg iconfont my-vip color-ee" :class="{'yellow': isVip}" title="VIP会员" @click="goto('vip')" />
              <span v-if="user.is_creator" class="addition opacity-bg">创作者</span>
              <span v-if="user.is_publisher" class="addition opacity-bg">发行机构</span>
              <br>
              <el-button class="txt-btn" @click="alert('共 ' + (user.liked_count || 0) + ' 个点赞')">{{ user.liked_count || 0 }} 点赞</el-button>
              <el-button class="txt-btn" @click="goto('relation', { type: 'follow', userid: config.userid })">{{ user.follow_count || 0 }} 关注</el-button>
              <el-button class="txt-btn" @click="goto('relation', { type: 'fans', userid: config.userid })">{{ user.fans_count || 0 }} 粉丝</el-button>
              <el-button v-if="config.userid === loginUserId && guests" class="txt-btn" @click="goto('relation', { type: 'guest', userid: config.userid })">{{ guests }} 访问</el-button>
            </div>
            <div v-if="isDesktop && largeMod && config.userid !== loginUserId" class="btns padding-top24 padding-right4 float-right">
              <el-button class="tap-btn opacity-bg margin-right12" style="width:110px;" @click="emit('goto', ['chat', { userid: config.userid, nickname: user.nickname }])">私信</el-button>
              <el-button v-if="(followState[config.userid] === 0)" style="width:110px;" class="tap-btn opacity-bg" @click="unfollow(config.userid)">已关注</el-button>
              <el-button v-if="(followState[config.userid] === 1)" style="width:110px;" class="tap-btn opacity-bg" @click="unfollow(config.userid)">互相关注</el-button>
              <el-button v-if="(followState[config.userid] !== 0 && followState[config.userid] !== 1)" style="width:110px;" class="tap-btn opacity-bg" @click="follow(config.userid)">关注</el-button>
            </div>
          </div>
          <div v-if="largeMod && user.signature" class="my-row padding-bottom4 margin-left10 margin-right10">
            <span v-html="getSign(user.signature)" />
          </div>
          <div v-if="!isDesktop && largeMod && config.userid !== loginUserId" class="my-row btns padding-top4 padding-bottom4">
            <el-button class="tap-btn opacity-bg margin-left10" @click="emit('goto', ['chat', { userid: config.userid, nickname: user.nickname }])">私信</el-button>
            <el-button v-if="(followState[config.userid] === 0)" class="tap-btn opacity-bg" @click="unfollow(config.userid)">已关注</el-button>
            <el-button v-if="(followState[config.userid] === 1)" class="tap-btn opacity-bg" @click="unfollow(config.userid)">互相关注</el-button>
            <el-button v-if="(followState[config.userid] !== 0 && followState[config.userid] !== 1)" class="tap-btn opacity-bg" @click="follow(config.userid)">关注</el-button>
          </div>
        </div>
        <div v-if="!isDesktop && largeMod && config.userid === loginUserId" ref="user-btns" class="user-btns padding-top4">
          <div class="bg-22 padding8 margin-top2">
            <el-row>
              <el-col :span="4">
                <el-badge :value="formateNums(state.unread.total)" :hidden="!state.unread.total">
                  <el-button class="tap-btn" @click="goto('message')">
                    <i class="iconfont my-message" />
                  </el-button>
                  <br> 消息
                </el-badge>
              </el-col>
              <el-col :span="4">
                <el-button class="tap-btn" @click="goto('task')">
                  <i class="iconfont my-task" />
                </el-button>
                <br> 任务
              </el-col>
              <el-col :span="4">
                <el-button class="tap-btn" @click="goto('sign')">
                  <i class="iconfont my-sign" />
                </el-button>
                <br> 签到
              </el-col>
              <el-col :span="4">
                <el-button class="tap-btn" @click="goto('items')">
                  <i class="iconfont my-items" />
                </el-button>
                <br> 物品
              </el-col>
              <!-- <el-col :span="5">
                <el-button class="tap-btn" @click="goto('nft')">
                  <i class="iconfont my-nft" />
                </el-button>
                <br> 数藏
              </el-col> -->
              <el-col :span="4">
                <el-button class="tap-btn" @click="goto('wallet')">
                  <i class="iconfont my-worth" />
                </el-button>
                <br> 晶币
              </el-col>
              <!-- <el-col :span="5">
                <el-button class="tap-btn" @click="goto('order')">
                  <i class="iconfont my-orders" />
                </el-button>
                <br> 订单
              </el-col> -->
              <el-col :span="4">
                <el-button class="tap-btn" @click="goto('services')">
                  <i class="iconfont my-circle-grid" />
                </el-button>
                <br> 全部
              </el-col>
            </el-row>
            <!-- <el-row>
              <el-col :span="5">
                <el-button class="tap-btn" @click="goto('draft')">
                  <i class="iconfont my-material" />
                </el-button>
                <br> 草稿
              </el-col>
              <el-col :span="5">
                <el-button class="tap-btn" @click="goto('download')">
                  <i class="iconfont my-down-round" />
                </el-button>
                <br> 下载
              </el-col>
              <el-col :span="5">
                <el-button class="tap-btn" @click="goto('favor')">
                  <i class="iconfont my-blank-star" />
                </el-button>
                <br> 收藏
              </el-col>
              <el-col :span="5">
                <el-button class="tap-btn" @click="goto('praise')">
                  <i class="iconfont my-praise-blank" />
                </el-button>
                <br> 点赞
              </el-col>
              <el-col :span="5">
                <el-button class="tap-btn" @click="goto('footprint')">
                  <i class="iconfont my-footprint" />
                </el-button>
                <br> 足迹
              </el-col>
              <el-col :span="5">
                <el-button class="tap-btn" @click="goto('invite')">
                  <i class="iconfont my-invite" />
                </el-button>
                <br> 邀请
              </el-col>
            </el-row> -->
          </div>
        </div>
      </div>
      <div class="tabs my-tabs" :class="{'bg-20' : isDesktop, 'flex' : !isDesktop}" :style="{'padding' : isDesktop ? '3px' : '', 'padding-top': (!isDesktop && config.mng === 'yes') ? '0px' : '' }">
        <el-col :span="isDesktop ? 18 : 19" class="padding-left8">
          <el-menu v-if="loginUserId && config.userid === loginUserId" :default-active="config.type" menu-trigger="click" :unique-opened="true" mode="horizontal" @select="handleSelect">
            <el-menu-item index="thread">主题</el-menu-item>
            <el-submenu index="worksMenu">
              <template slot="title">作品</template>
              <el-menu-item index="works">公开</el-menu-item>
              <el-menu-item index="worksPrivate">私密</el-menu-item>
            </el-submenu>
            <el-submenu index="albumMenu">
              <template slot="title">专辑</template>
              <el-menu-item index="album">公开</el-menu-item>
              <el-menu-item index="albumPrivate">私密</el-menu-item>
            </el-submenu>
            <el-submenu index="nftMenu">
              <template slot="title">藏品</template>
              <el-menu-item index="nft">公开</el-menu-item>
              <el-menu-item index="nftPrivate">私密</el-menu-item>
            </el-submenu>
          </el-menu>
          <el-menu v-if="!(loginUserId && config.userid === loginUserId)" :default-active="config.type" menu-trigger="click" :unique-opened="true" mode="horizontal" @select="handleSelect">
            <el-menu-item index="thread">主题</el-menu-item>
            <el-menu-item index="works">作品</el-menu-item>
            <el-menu-item index="album">专辑</el-menu-item>
            <el-menu-item index="nft">藏品</el-menu-item>
          </el-menu>
        </el-col>
        <el-col :span="isDesktop ? 6 : 5" class="btns align-right" :class="{'padding-top4': !isDesktop, 'padding-top2': isDesktop}">
          <el-button class="tap-btn margin-right4 bg-22" style="width: 30px;" @click="handleAdd">
            <i class="iconfont my-square-add color-ee" />
          </el-button>
          <el-button v-if="config.mng !== 'yes'" class="tap-btn bg-22" style="width: 30px;" @click="toggleHead">
            <i v-if="!largeMod" class="iconfont my-open-down color-ee" />
            <i v-if="largeMod" class="iconfont my-close-up color-ee" />
          </el-button>
        </el-col>
      </div>
      <thread-list
        v-show="config.show && config.type === 'thread'"
        ref="thread-list"
        mod="user"
        :subtract-height="subtractHeight"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :show-footbar="showFootbar"
        :is-pop="true"
        @receive="handle"
      />
      <works-list
        v-show="config.show && config.type === 'works'"
        ref="works-list"
        mod="user"
        :subtract-height="subtractHeight"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :show-footbar="showFootbar"
        :is-pop="true"
        @receive="handle"
      />
      <works-list
        v-show="config.show && config.type === 'worksPrivate'"
        ref="works-private-list"
        mod="userPrivate"
        :subtract-height="subtractHeight"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :show-footbar="showFootbar"
        :is-pop="true"
        @receive="handle"
      />
      <album-list
        v-show="config.show && config.type === 'album'"
        ref="album-list"
        mod="user"
        :subtract-height="subtractHeight"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :show-footbar="showFootbar"
        :is-pop="true"
        @receive="handle"
      />
      <album-list
        v-show="config.show && config.type === 'albumPrivate'"
        ref="album-private-list"
        mod="userPrivate"
        :subtract-height="subtractHeight"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :show-footbar="showFootbar"
        :is-pop="true"
        @receive="handle"
      />
      <nft-list
        v-show="config.show && config.type === 'nft'"
        ref="nft-list"
        mod="user"
        :subtract-height="subtractHeight"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :show-footbar="showFootbar"
        :is-pop="true"
        @receive="handle"
      />
      <nft-list
        v-show="config.show && config.type === 'nftPrivate'"
        ref="nft-private-list"
        mod="userPrivate"
        :subtract-height="subtractHeight"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :show-footbar="showFootbar"
        :is-pop="true"
        @receive="handle"
      />
    </div>
    <div solt="footer">
      <foot-bar
        v-if="showFootbar"
        ref="footBar"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        @receive="handle"
      />
    </div>
  </el-dialog>
</template>
<script>
import conf from '@/js/conf/conf'
import utils from '@/js/utils'
import service from '@/js/service'
import worksList from '@/components/web/worksList'
import threadList from '@/components/web/threadList'
import albumList from '@/components/web/albumList'
import nftList from '@/components/web/nftList'
import { mixins } from '@/mixins/common'
import footBar from '@/components/web/footBar'
export default {
  components: {
    worksList,
    threadList,
    albumList,
    nftList,
    footBar
  },
  mixins: [mixins],
  props: {
    showFootbar: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      worksHost: conf.hosts().worksHost,
      swipeLock: false,
      hideHead: false,
      scrollTop: 0,
      subtractHeight: 180,
      guests: 0,
      user: {}
    }
  },
  computed: {
    isVip() {
      return this.user.groupid > 10 && new Date(this.user.expired_at) > new Date()
    },
    title() {
      if (this.config.mng !== 'yes') return '个人主页'
      const types = { 'thread': '主题', 'works': '作品', 'album': '专辑', 'nft': '藏品' }
      const k = this.config.type.replace('Private', '')
      return types[k] ? ('我的' + (this.config.type.split('Private').length > 1 ? '私密' : '') + types[k]) : '个人主页'
    },
    largeMod() {
      return !this.hideHead
    },
    cardStyle() {
      const style = {}
      let bg = this.getBackground()
      // if (!bg) bg = this.worksHost + utils.getDefaultBg(this.user.userid || 0) + '/background'
      if (!bg) bg = utils.getDefaultBg(this.user.userid || 0)
      if (bg && this.largeMod && !this.view.user.loading) {
        style['background-image'] = 'url(' + bg + ')'
        style['background-position'] = 'center center'
        style['background-size'] = '100% auto'
        style['background-repeat'] = 'no-repeat'
        if (this.isDesktop) {
          style['padding-top'] = '10px'
          style['border-radius'] = '4px'
        }
      } else {
        style['padding-top'] = '30px'
      }
      return style
    },
    config() {
      return this.view.user
    },
    publicTypes() {
      const types = []
      if (this.config.types.length) {
        for (const i in this.config.types) {
          if (!this.config.types[i][2]) types.push(this.config.types[i])
        }
      }
      return types
    }
  },
  watch: {
    // 'view.user.show': {
    //   handler() {
    //     if (this.view.user.show) {
    //       this.updateData()
    //       this.autoAcceptTask()
    //       this.view.user.loading = false
    //       if (this.config.userid && this.config.userid !== this.user.userid) {
    //         this.hideHead = false
    //         this.config.type = this.config.type || 'thread'
    //         this.config.typeid = this.config.typeid || 0
    //         this.guests = 0
    //         this.getUser(true)
    //       }
    //       this.update()
    //     }
    //   }
    // },
    // 'view.user.userid': {
    //   handler() {
    //     if (this.view.user.show) {
    //       this.addFootprint()
    //       if (this.config.userid && this.config.userid !== this.user.userid) {
    //         this.hideHead = false
    //         this.config.type = this.config.type || 'thread'
    //         this.config.typeid = this.config.typeid || 0
    //         this.guests = 0
    //         this.getUser(true)
    //       }
    //     }
    //   }
    // },
    'view.user.renew': {
      handler() {
        this.getUser(true)
      }
    }
  },
  mounted() {
    this.view.user.userid = this.view.user.userid || this.loginUserId
    if (!this.view.user.userid) {
      this.emit('login')
      return
    }
    this.addFootprint()
    if (this.view.user.userid && this.view.user.userid === this.loginUserId) {
      this.autoAcceptTask()
    }
    this.view.user.type = this.view.user.type || 'thread'
    this.view.user.typeid = this.view.user.typeid || 0
    this.hideHead = false
    this.view.user.loading = false
    this.guests = 0
    this.getUser(true)
  },
  methods: {
    updateData() {
      this.callSub(utils.snakeCase(this.config.type) + '-list', 'updateData')
    },
    toggleHead() {
      this.hideHead = !this.hideHead
      this.update()
    },
    handleSelect(key, path) {
      this.config.type = path[path.length - 1]
    },
    handleAdd() {
      const actions = {
        show: true,
        title: '请选择',
        btns: []
      }
      actions.btns = actions.btns.concat([
        { title: '创作像素画', cb: () => { this.goto('editer') } },
        { title: '定制拼图', cb: () => { this.emit('openPopPage', [{ 'type': 'toBrickfy' }]) } },
        // { title: 'AI像素画', cb: () => { this.emit('openPopPage', [{ 'type': 'toPixel' }]) } },
        // { title: 'AI动漫', cb: () => { this.emit('openPopPage', [{ 'type': 'toCartoon' }]) } },
        // { title: 'AI作画', cb: () => { this.emit('openAiPaint', []) } },
        { title: '发布主题', cb: () => { this.openPost({}) } },
        { title: '新建专辑', cb: () => { this.emit('openResourceMng', [{ 'table': 'album', 'act': 'add' }]) } }
      ])
      this.view.actionSheet = actions
    },
    close() {
      this.closePop(this.config)
      if (this.view.mod === 'editer') this.goto('index')
    },
    // 转场
    goto(mod, dt) {
      if (mod === 'user') return
      this.emit('goto', [mod, dt])
    },
    handle(act, data) {
      if (act === 'setLockSwipe') {
        this.swipeLock = data[0]
      } else if (act === 'setScrollTop') {
        this.setScrollTop(...data)
      } else if (act === 'goto') {
        this.goto(...data)
      } else if (act === 'update') {
        this.update()
      } else {
        this.emit(act, data)
      }
    },
    onSwipeTab(evt) {
      if (this.swipeLock) return
      const config = utils.deepClone(this.config)
      if (!(this.loginUserId && config.userid === this.loginUserId)) {
        config.types = this.publicTypes
      }
      const newConfig = this.switchTab(config, evt.direction)
      this.config.type = newConfig.type
    },
    setScrollTop(scrollTop) {
      this.scrollTop = scrollTop
      this.update()
    },
    moreAction() {
      if (this.loginUserId && this.config.userid === this.loginUserId) return this.goto('setup')
      this.showActionSheet()
    },
    showActionSheet() {
      const actions = {
        show: true,
        title: '请选择',
        btns: []
      }
      const userid = this.user.userid
      actions.btns.push({ title: '私信', cb: () => {
        this.emit('goto', ['chat', { userid: userid, nickname: this.user.nickname }])
      } })
      if (this.followState[userid] !== 0 && this.followState[userid] !== 1) {
        actions.btns.push({ title: '关注', cb: () => {
          this.follow(userid)
        } })
      } else {
        actions.btns.push({ title: '取消关注', cb: () => {
          this.unfollow(userid)
        } })
      }
      actions.btns = actions.btns.concat([
        { title: '广告垃圾', cb: () => { this.emit('reports', [userid, 0, '广告垃圾', this.loginUserId]) } },
        { title: '违规内容', cb: () => { this.emit('reports', [userid, 0, '违规内容', this.loginUserId]) } },
        { title: '恶意灌水', cb: () => { this.emit('reports', [userid, 0, '恶意灌水', this.loginUserId]) } },
        { title: '重复发帖', cb: () => { this.emit('reports', [userid, 0, '重复发帖', this.loginUserId]) } },
        { title: '其他理由', cb: () => { this.emit('setReportReason', [userid, 0, this.loginUserId]) } }
      ])
      this.view.actionSheet = actions
    },
    getSign(content) {
      if (!content) return ''
      return utils.fomateContent(content)
    },
    getAvatar() {
      const ver = Math.round(new Date(this.user.avatar_at) / 1000)
      if (this.user.avatar) return service.getCosUrl(this.user.avatar, 'avatar', ver) || ''
    },
    getBackground() {
      const ver = Math.round(new Date(this.user.background_at) / 1000)
      if (this.user.background) return service.getCosUrl(this.user.background, 'background', ver) || ''
    },
    formateTime: utils.formateTime,
    formateNums: utils.formateNums,
    getElementHeight(refid) {
      return this.$refs[refid] ? parseInt(window.getComputedStyle(this.$refs[refid]).height) : 0
    },
    setProfile() {
      if (this.config.userid && this.config.userid === this.loginUserId) this.goto('profile')
    },
    update() {
      this.$forceUpdate()
      setTimeout(() => {
        const cardHeight = this.getElementHeight('header-card')
        const userBtnsHeight = this.getElementHeight('user-btns')
        this.subtractHeight = cardHeight + userBtnsHeight + 40
        if (this.showFootbar) this.subtractHeight = this.subtractHeight + 50
      }, 10)
    },
    renewView() {
      this.callSub(this.config.type + '-list', 'renewView')
    },
    callSub(ref, fn) {
      const el = this.$refs[ref]
      if (el && el[fn]) {
        el[fn]()
      } else {
        setTimeout(() => {
          this.callSub(ref, fn)
        }, 100)
      }
    },
    // 关注
    follow(userid) {
      this.followState[userid] = 1
      this.update()
      this.$refs['thread-list'].update()
      this.emit('follow', [userid, (res, type) => {
        if (type === 'success') {
          this.followState[userid] = res.isMutual
        } else {
          // 回滚
          this.followState[userid] = -1
          this.message(res, type)
        }
        this.update()
        this.$refs['thread-list'].update()
      }])
    },
    // 取消关注
    unfollow(userid) {
      this.followState[userid] = -1
      this.update()
      this.$refs['thread-list'].update()
      this.emit('unfollow', [userid, (res, type) => {
        if (type === 'success') {
          this.followState[userid] = -1
        } else {
          // 回滚
          this.followState[userid] = 0
          this.message(res, type)
        }
        this.update()
        this.$refs['thread-list'].update()
      }])
    },
    getUser(renew) {
      if (this.view.user.loading) return
      this.view.user.loading = true
      this.emit('getUser', [this.config.userid || this.loginUserId, (user) => {
        if (user) {
          this.user = user
          this.config.userid = user.userid
          this.setCount()
        }
        this.view.user.loading = false
        this.update()
      }])
      if (renew) this.renewView()
    },
    // 自动接受任务
    autoAcceptTask() {
      this.emit('autoAcceptTask', [(dt, type) => {
        if (type === 'success' && dt && dt.data && dt.data.count) this.view.task.init = utils.time('time')
        // TODO: 显示未完成的任务数
        this.update()
      }])
    },
    addFootprint() {
      service.get('home_view', this.config.userid, (dt, type) => {
        if (type === 'success') this.guests = dt.data.guests
      })
    },
    // 设置计数器(新手任务)
    setCount() {
      const user = this.user
      if (user.userid !== this.loginUserId || !this.loginUserId) return
      if (user.avatar) service.setCount(user.userid, 'avatar', 1)
      if (user.background) service.setCount(user.userid, 'background', 1)
      if (user.signature) service.setCount(user.userid, 'signature', 1)
      if (user.username) service.setCount(user.userid, 'username', 1)
    }
  }
}
</script>
