<template>
  <vue-draggable-resizable
    v-show="obj.show && drawed"
    :active.sync="state.activeObj[idx + '_' + parentIdx]"
    :grid="[state.gridSize, state.gridSize]"
    :x="x"
    :y="y"
    :z="z"
    :w="width"
    :h="height"
    :min-width="state.gridSize"
    :min-height="state.gridSize"
    :parent="false"
    :resizable="resizable"
    :draggable="draggable"
    class="my-obj"
    :lock-aspect-ratio="state.lockAspectRatio"
    :disable-user-select="true"
    class-name-handle="my-handle"
    @dragging="onDragging"
    @dragstop="onDrag"
    @resizing="onResizing"
    @resizestop="onResize"
    @deactivated="ondeactivated"
  >
    <!-- <div slot="br"><span id="resizeBr" class="iconfont my-drag-resize" /></div> -->
    <canvas v-if="obj.type !== 'group'" v-show="false" ref="thumbCanvas" class="thumbCanvas" />
    <canvas v-if="obj.type !== 'group'" v-show="!obj.gridsfy && !canEdit" ref="originCanvas" class="originCanvas" :style="objStyle" />
    <canvas v-if="obj.type !== 'group'" v-show="obj.gridsfy && !canEdit" ref="gridCanvas" class="gridCanvas" :style="objStyle" />
    <el-input v-if="obj.type === 'txt'" v-show="canEdit" ref="txt-input" v-model="obj.txt.content" :class="txtObjClass" :style="objStyle" type="text" placeholder="请输入..." maxlength="16" @change="debounceDrawTxt" />
    <div v-if="obj.type === 'group'">
      <obj
        v-for="(o, i) in obj.objs"
        v-show="obj.show"
        :key="'sub-obj:' + sceneId + ':' + obj.id + ':' + o.id"
        :ref="'sub-obj:' + sceneId + ':' + obj.id + ':' + o.id"
        :state="state"
        :view="view"
        :file="file"
        :parent-id="obj.id"
        :parent-idx="idx"
        :idx="i"
        :obj="o"
        :canvas-cols="canvasCols"
        :canvas-rows="canvasRows"
        :canvas-bg-color="canvasBgColor"
        :grid-color="gridColor"
        :is-sub="true"
        :file-type="fileType"
        @receive="handleTop"
      />
    </div>
    <div
      v-show="!canEdit"
      id="objWorkArea"
      ref="objWorkArea"
      v-finger:press-move="(e) => handle(e)"
      v-finger:touch-start="(e) => handle(e)"
      v-finger:touch-end="mouseup"
      v-finger:double-tap="dblclick"
      :class="objClass"
      :style="objStyle"
      @mousemove="mousemove"
      @mousedown="mousedown"
      @mouseup="mouseup"
      @mouseout="(e) => mouseup(e, true)"
      @dblclick="dblclick"
      @contextmenu.prevent="handleRightClick"
    />
    <div
      v-if="obj.lock"
      class="obj-lock"
      title="解锁"
      :style="{'margin-top': (height - 40) / 2 + 'px', 'margin-left': (width - 40) / 2 + 'px'}"
      @click.stop="obj.lock = false"
    >
      <span class="iconfont my-unlock" />
    </div>
  </vue-draggable-resizable>
</template>

<script>
import utils from '@/js/utils'
import conf from '@/js/conf/conf'
import GRIDY from '@/js/sdk/GridySDK'
export default {
  name: 'Obj',
  props: {
    state: {
      type: Object,
      default() {
        return {}
      }
    },
    view: {
      type: Object,
      default() {
        return {}
      }
    },
    file: {
      type: Object,
      default() {
        return {}
      }
    },
    obj: {
      type: Object,
      default() {
        return {}
      }
    },
    parentIdx: {
      type: Number,
      default: -1
    },
    parentId: {
      type: String,
      default: ''
    },
    idx: {
      type: Number,
      default: 0
    },
    fileType: {
      type: [Number, String],
      default: 0
    },
    canvasCols: {
      type: Number,
      default: 0
    },
    canvasRows: {
      type: Number,
      default: 0
    },
    canvasBgColor: {
      type: String,
      default: ''
    },
    gridColor: {
      type: String,
      default: ''
    },
    isSub: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      GRIDY: null,
      box: {},
      continueDrawing: false,
      startEdit: false,
      originImage: new Image(),
      loadOriginImage: false,
      haveOriginImage: false,
      haveThumbImage: false,
      editTxt: false,
      drawed: false,
      initPalette: false,
      curColor: '',
      filled: {},
      fillTimes: 0,
      maxColorNums: 128,
      palette: utils.deepClone(conf.palette),
      x: 0,
      y: 0
    }
  },
  computed: {
    isDesktop() {
      return this.state.platform.type === 'desktop'
    },
    // 文本对象class属性
    txtObjClass() {
      return 'obj_txt grid_size_' + this.state.gridSize
    },
    // 当前对象样式
    objStyle() {
      return {
        position: 'absolute',
        top: 0,
        left: 0,
        width: this.width + 'px',
        height: this.height + 'px'
      }
    },
    // 是否显示对象边框
    showObjBorder() {
      return ['freeSelect', 'fill', 'batchFill', 'erase', 'batchErase', 'brush', 'spray', 'txt', 'line', 'ellipse', 'rectangle', 'triangle', 'star', 'heart', 'pick'].indexOf(this.state.act) >= 0
    },
    // 当前对象class属性
    objClass() {
      // 非编辑模式或非创作模式时不显示边框
      if (this.view.mod !== 'editer') return 'my-obj'
      if (this.select && !this.state.play) {
        if (this.state.selectNums === 1 || (this.state.selectNums > 1 && this.showObjBorder)) {
          return 'my-obj-border'
        }
      }
      return 'my-obj'
    },
    // z轴数值
    z() {
      return this.obj.z
    },
    // 宽度
    width() {
      return this.obj.cols * this.state.gridSize || this.state.gridSize
    },
    // 高度
    height() {
      return this.obj.rows * this.state.gridSize || this.state.gridSize
    },
    // 是否被选中
    select() {
      let k
      if (this.parentId) {
        k = 'sub-obj:' + this.sceneId + ':' + this.parentId + ':' + this.obj.id
      } else {
        k = 'obj:' + this.sceneId + ':' + this.obj.id
      }
      return !!this.state.selectObjs[k]
    },
    // 是否可以改变尺寸
    resizable() {
      return (this.select && this.obj.type !== 'group' && this.state.selectNums === 1 && this.state.act === 'resize')
    },
    // 是否可拖动
    draggable() {
      // 组合对象，在选择select、freeSelect、resize工具下，不可拖动
      if (this.obj.type === 'group' && (this.state.act === 'select' || this.state.act === 'freeSelect' || this.state.act === 'resize')) {
        return false
      }
      // 组合内的子对象在使用select工具时可以拖动
      if (this.isSub) {
        return this.obj.draggable && !this.obj.lock && (this.state.act === 'select' || this.state.act === 'resize')
      }
      const arr = ['freeSelect', 'hand', 'fill', 'batchFill', 'erase', 'batchErase', 'brush', 'spray', 'line', 'ellipse', 'rectangle', 'triangle', 'star', 'heart']
      if (!this.obj.draggable || this.obj.lock || arr.indexOf(this.state.act) >= 0) {
        return false
      }
      return true
    },
    // 可以在对象内触发选择事件(default select freeSelect 使用 startFreeSelect 进行选择)
    canSelect() {
      return ['pick', 'resize', 'fill', 'batchFill', 'erase', 'batchErase', 'brush', 'spray', 'txt'].indexOf(this.state.act) >= 0
    },
    // 允许在对象内触发多选
    canMultiSelect() {
      return this.state.act === 'pick' || this.state.act === 'resize'
    },
    // 是否可编辑文本
    canEdit() {
      return this.editTxt
    },
    sceneIdx() {
      return this.state.sceneIdx
    },
    sceneId() {
      return this.state.sceneId
    }
  },
  watch: {
    'obj.show'(show) {
      this.emit('initFreeSelector', true)
      if (!this.obj.show) {
        this.unselectObj()
        this.emit('deactive', [this.idx, this.parentIdx])
      }
    },
    'obj.lock'() {
      this.emit('initFreeSelector', true)
      if (this.obj.lock) {
        this.unselectObj()
        this.emit('deactive', [this.idx, this.parentIdx])
      }
    },
    'obj.x'() {
      this.x = this.obj.x * this.state.gridSize
    },
    'obj.y'() {
      this.y = this.obj.y * this.state.gridSize
    },
    'state.act'() {
      if (this.select && this.obj.type !== 'group' && this.state.act === 'resize') {
        this.debounceActive()
      }
    }
  },
  created() {
    this.x = this.obj.x * this.state.gridSize
    this.y = this.obj.y * this.state.gridSize
  },
  mounted() {
    this.GRIDY = new GRIDY()
    // 更新父对象（组合）信息
    if (this.obj.type === 'group') {
      this.draw()
      return
    }
    this.objWorkArea = this.$refs.objWorkArea
    this.originCanvas = this.$refs.originCanvas
    this.originCtx = this.originCanvas.getContext('2d')
    this.thumbCanvas = this.$refs.thumbCanvas
    this.thumbCtx = this.thumbCanvas.getContext('2d')
    this.gridCanvas = this.$refs.gridCanvas
    this.gridCtx = this.gridCanvas.getContext('2d')
    if (this.obj.type === 'txt') {
      this.drawTxt()
      return
    }
    setTimeout(this.draw, 10)
  },
  methods: {
    // 双击编辑文本
    dblclick(event) {
      if (!this.state.editTxtLock && this.obj.type === 'txt' && (this.state.act === 'default' || this.state.act === 'select' || this.state.act === 'txt' || this.state.act === 'resize')) {
        this.editTxt = true
        this.state.editTxt = true
        // 设置输入框文字颜色
        this.$refs['txt-input'].$refs.input.style.setProperty('color', this.obj.txt.fontColor, 'important')
      }
    },
    // 右击
    handleRightClick(event) {
      if (this.state.act === 'freeSelect' && this.state.freeSelector.partSelect) {
        this.emit('initFreeSelector', true)
      }
      if ((this.state.act === 'default' || this.state.act === 'select' || this.state.act === 'freeSelect' || this.state.act === 'pick' || this.state.act === 'resize')) {
        this.emit('selectOneObj', [this.obj.id, this.parentId, this.idx, this.parentIdx])
      }
    },
    // 转发事件
    handleTop(act, data) {
      if (this.obj.type === 'group' && act === 'select' && this.state.act === 'default') {
        // 组合对象转发子对象select事件时，如果使用 default 指针工具，则选择当前组合对象
        this.emit(act, [data[1], '', data[3], -1, data[4]])
      } else {
        this.emit(act, data)
      }
    },
    // 选择对象
    handleSelect(event) {
      if (!this.canSelect || !this.obj.show) {
        return
      }
      let multiSelect = false
      if (this.canMultiSelect && event && event.shiftKey) {
        multiSelect = true
      }
      this.emit('select', [this.obj.id, this.parentId, this.idx, this.parentIdx, multiSelect])
    },
    // 对齐
    align(mod) {
      this.emit('align', mod)
    },
    // 设置图层
    setLayer(act) {
      this.emit('setLayer', act)
    },
    // 调用父组件方法
    emit(fn, params) {
      if (!fn) return
      this.$emit('receive', fn, params || [])
    },
    setHistory(fn, params) {
      this.emit('setHistory', [fn, params])
    },
    // 绘制缩略图并获取数据
    getThumbDt(cols, rows, cb) {
      const getDt = () => {
        this.thumbWidth = cols || this.obj.cols
        this.thumbHeight = rows || this.obj.rows
        this.GRIDY.drawImage('', this.originCanvas, this.thumbWidth, this.thumbHeight)
        this.thumbDt = this.GRIDY.getPixelData()
        this.haveThumbImage = true
        cb && cb(this.thumbDt)
      }
      if (!this.haveOriginImage) {
        this.createOrigin(false, getDt)
      } else {
        getDt()
      }
    },
    debounceSetThumbDt() {
      utils.debounce(() => this.setThumbDt(), 1, 'setThumbDt')()
    },
    // 保存缩略图数据
    setThumbDt() {
      this.thumbDt = {
        width: this.obj.cols,
        height: this.obj.rows,
        data: this.obj.data
      }
    },
    // 完成图形绘制
    stopDrawShape(color) {
      this.obj.gridsfy = true
      this.GRIDY.setFile(this.file)
      this.GRIDY.replaceImage(this.sceneIdx, this.idx, this.parentIdx, this.state.act === 'line' ? this.thumbCanvas : this.originCanvas, this.obj.name, () => {
        this.draw()
        this.setHistory('stopDrawShape')
      }, { repalce: true, replaceColor: color || '', type: 'shape' })
    },
    // 翻转原图
    flip(mod) {
      // 清除画布
      this.clearCanvas('origin', this.originCanvas.width, this.originCanvas.height)
      // 位移来做镜像翻转
      if (mod === 'v') {
        // 上下镜像翻转
        this.originCtx.translate(0, this.originCanvas.height)
        this.originCtx.scale(1, -1)
      } else {
        // 左右镜像翻转
        this.originCtx.translate(this.originCanvas.width, 0)
        this.originCtx.scale(-1, 1)
      }
      this.originCtx.drawImage(this.originImage, 0, 0, this.originCanvas.width, this.originCanvas.height)
    },
    // 绘制直线
    drawLine(ctx, x1, y1, x2, y2, color, lineWidth) {
      ctx = ctx && this[ctx] ? this[ctx] : this.gridCtx
      this.GRIDY.drawLine(ctx, x1, y1, x2, y2, color, lineWidth)
      this.haveOriginImage = true
    },
    // 绘制椭圆 x,y 是中心点 a 是横半轴 b 是纵半轴
    drawEllipse(ctx, x, y, a, b, color, type) {
      ctx = ctx && this[ctx] ? this[ctx] : this.gridCtx
      this.GRIDY.drawEllipse(ctx, x, y, a, b, color, type)
      this.haveOriginImage = true
    },
    // 绘制圆形 del
    drawCircle(ctx, x, y, r, start, end, color, type) {
      ctx = ctx && this[ctx] ? this[ctx] : this.gridCtx
      this.GRIDY.drawCircle(ctx, x, y, r, start, end, color, type)
      this.haveOriginImage = true
    },
    // 绘制矩形
    drawRectangle(ctx, x, y, w, h, color, type) {
      ctx = ctx && this[ctx] ? this[ctx] : this.gridCtx
      this.GRIDY.drawRect(ctx, x, y, w, h, color, type)
      this.haveOriginImage = true
    },
    // 绘制三角形
    drawTriangle(ctx, x1, y1, x2, y2, x3, y3, color, type) {
      ctx = ctx && this[ctx] ? this[ctx] : this.gridCtx
      this.GRIDY.drawTriangle(ctx, x1, y1, x2, y2, x3, y3, color, type)
      this.haveOriginImage = true
    },
    // 绘制五角星
    drawStar(ctx, r, x, y, rot, color, type) {
      ctx = ctx && this[ctx] ? this[ctx] : this.gridCtx
      this.GRIDY.drawStar(ctx, r, x, y, rot, color, type)
      this.haveOriginImage = true
    },
    // 绘制文本对象
    drawTxtObj() {
      const txt = this.obj.txt
      txt.fontColor = txt.fontColor || '#000000'
      const cav = this.originCanvas
      const ctx = this.originCtx
      this.haveOriginImage = true
      // 预设宽度: 100格
      cav.width = 100 * this.state.gridSize
      cav.height = this.obj.rows * this.state.gridSize
      ctx.font = txt.fontWeight + ' ' + txt.fontSize * this.state.gridSize + 'px ' + txt.fontFamily
      ctx.textAlign = txt.textAlign
      ctx.textBaseline = txt.textBaseline
      const words = txt.content.split('')
      // 测量宽度
      const width = Math.ceil((ctx.measureText(txt.content).width / this.state.gridSize) + words.length + 2) * this.state.gridSize
      // 重算列数
      this.obj.cols = width / this.state.gridSize
      // 重置画布
      cav.width = this.obj.cols * this.state.gridSize
      cav.height = this.obj.rows * this.state.gridSize
      ctx.clearRect(0, 0, cav.width, cav.height)
      // 写入文本
      ctx.font = txt.fontWeight + ' ' + txt.fontSize * this.state.gridSize + 'px ' + txt.fontFamily
      ctx.textAlign = txt.textAlign
      ctx.textBaseline = txt.textBaseline
      ctx.fillStyle = txt.fontColor
      let i
      let x = this.state.gridSize
      const y = this.state.gridSize * 3
      for (i = 0; i < words.length; i++) {
        ctx.fillText(words[i], x, y)
        x = x + ctx.measureText(words[i]).width + this.state.gridSize
      }
      this.GRIDY.drawImage('', this.originCanvas, this.obj.cols, this.obj.rows)
      const imageData = this.GRIDY.getPixelData()
      this.obj.colors = imageData.colors
      this.obj.data = imageData.data
      this.obj.thumbImage = this.GRIDY.getBase64()
      this.drawObj()
      this.drawed = true
    },
    // 清除画布
    clearCanvas(id, w, h) {
      if (!this[id + 'Canvas'] || !this[id + 'Ctx']) return
      w = w || this[id + 'Canvas'].width
      h = h || this[id + 'Canvas'].height
      this[id + 'Canvas'].width = w
      this[id + 'Canvas'].height = h
      this[id + 'Ctx'].clearRect(0, 0, w, h)
    },
    // 设置组合对象的位置和尺寸
    resetGroupBoxSize() {
      if (this.isSub) {
        this.emit('resetGroupBoxSize', [this.idx, this.parentIdx])
      }
    },
    // 计算子对象图层
    calcSubLayer() {
      if (!this.obj.objs) return
      let i = 0
      for (i in this.obj.objs) {
        this.obj.objs[i].z = this.state.maxLayers - i
      }
    },
    // 翻转对象
    flipObj(mod) {
      if (this.obj.type === 'group') {
        this.callSubObjs('flipObj', [mod])
        return
      }
      if (mod === 'v') {
        this.obj.flip.vertical = !this.obj.flip.vertical
        // this.flip('v')
        this.GRIDY.flipV(this.obj)
        this.draw()
      } else {
        this.obj.flip.horizontal = !this.obj.flip.horizontal
        // this.flip('h')
        this.GRIDY.flipH(this.obj)
        this.draw()
      }
      this.setHistory('flipObj', [mod])
    },
    // 旋转对象
    rotateObj(mod) {
      if (this.obj.type === 'group') {
        this.callSubObjs('rotateObj', [mod])
        return
      }
      if (mod === 'w') {
        this.GRIDY.rotateW(this.obj)
        this.draw()
      } else {
        this.GRIDY.rotateI(this.obj)
        this.draw()
      }
      this.setHistory('rotateObj', [mod])
    },
    // 绘制子对象
    drawSubObjs() {
      if (!this.obj.objs) {
        return
      }
      Object.keys(this.obj.objs).map((i) => (this.drawSubObj(i)))
      this.calcSubLayer()
    },
    // 绘制特定子对象
    drawSubObj(idx) {
      if (!this.obj.objs || !this.obj.objs[idx]) return
      this.callSubObj(this.obj.objs[idx].id, 'draw', [])
    },
    // 重新绘制
    reDraw(saveThumbDt) {
      // console.error('reDraw', saveThumbDt)
      if (this.thumbDt && this.thumbDt.data) {
        this.obj.data = this.thumbDt.data
        this.obj.colors = this.thumbDt.colors || {}
        this.obj.cols = this.thumbDt.width
        this.obj.rows = this.thumbDt.height
      }
      this.draw(saveThumbDt)
    },
    // 绘制对象
    draw(saveThumbDt = true) {
      if (saveThumbDt) {
        this.setThumbDt()
      }
      this.drawed = true
      if (this.obj.type === 'group') {
        this.drawSubObjs()
        return
      }
      if (!this.obj.cols || !this.obj.rows) return
      this.x = this.obj.x * this.state.gridSize
      this.y = this.obj.y * this.state.gridSize
      this.drawObj({ gridSize: this.state.gridSize })
      this.state.drawStartTime = 0
      this.createOrigin()
    },
    drawObj(options = {}) {
      this.gridCanvas.width = this.width
      this.gridCanvas.height = this.height
      this.GRIDY.setFile(this.file)
      this.GRIDY.drawObj(this.gridCanvas, this.sceneIdx, this.idx, this.parentIdx, options)
    },
    // 创建原图
    createOrigin(force, cb) {
      this.haveOriginImage = true
      if (this.obj.type === 'group') {
        this.callSubObjs('createOrigin', [])
        return
      }
      if (!this.obj.cols || !this.obj.rows) {
        return
      }
      if (!force && (this.obj.originImage || this.obj.thumbImage)) {
        this.GRIDY.drawImageBase64(this.originCanvas, this.obj.originImage || this.obj.thumbImage, this.width, this.height, cb)
      } else {
        this.GRIDY.drawObj(this.originCanvas, this.sceneIdx, this.idx, this.parentIdx, { gridSize: this.state.gridSize, fillShape: 'none' })
      }
      cb && cb(true)
    },
    debounceActive() {
      utils.debounce(this.onActive, 100, 'activeTimer')()
    },
    // 激活对象
    onActive() {
      this.emit('active', [this.idx, this.parentIdx])
    },
    // 剪切
    cut() {
      this.emit('cut', [this.idx, this.parentIdx])
    },
    // 黏贴
    parse() {
      this.emit('parse', [this.idx, this.parentIdx])
    },
    // 拷贝对象
    copy() {
      this.emit('copy', [this.idx, this.parentIdx])
    },
    // 删除对象
    delete() {
      this.emit('delete', [this.idx, this.parentIdx])
    },
    // 清除并绘制对象
    clearSubObj() {
      // 先保存数据
      this.setThumbDt()
      this.obj.data = []
      this.draw()
      this.setHistory('clearSubObj')
    },
    // 清除对象
    clear() {
      if (this.obj.type === 'group') {
        this.callSubObjs('clear')
        return
      }
      this.clearSubObj()
    },
    // 恢复对象
    restore() {
      if (this.obj.type === 'group') {
        this.callSubObjs('restore')
        return
      }
      this.obj.paletteId = ''
      this.GRIDY.reloadImage(this.sceneIdx, this.idx, this.parentIdx, this.obj.brickSizeId ? 'origin' : 'thumb', (status) => {
        if (!status) {
          this.obj.data = []
        }
        this.draw()
        this.setHistory('restore')
      })
    },
    // 设置网格大小
    reloadImage() {
      if (this.obj.lock || this.obj.type === 'group') return
      this.GRIDY.reloadImage(this.sceneIdx, this.idx, this.parentIdx, this.obj.brickSizeId ? 'origin' : 'thumb', () => {
        this.GRIDY.autoCanvasSize()
        this.draw()
      }, { brickSizeId: this.obj.brickSizeId, paletteId: this.obj.paletteId })
    },
    // 设置网格形状
    setFillShape(shape) {
      if (this.obj.lock) return
      if (this.obj.type === 'group') {
        this.callSubObjs('setFillShape', [shape])
        return
      }
      this.obj.fillShape = shape || ''
      this.draw()
      this.setHistory('setFillShape', [shape])
    },
    // 设置网格大小
    setBricksize(sizeId) {
      if (this.obj.lock) return
      if (this.obj.type === 'group') {
        this.callSubObjs('setBricksize', [sizeId])
        return
      }
      this.obj.brickSizeId = sizeId
      this.reloadImage()
      this.setHistory('setBricksize', [sizeId])
    },
    setPalette(paletteId) {
      if (this.obj.lock) return
      if (this.obj.type === 'group') {
        this.callSubObjs('setPalette', [paletteId])
        return
      }
      this.obj.paletteId = paletteId
      this.GRIDY.setFile(this.file)
      this.GRIDY.setObjPalette(this.sceneIdx, this.idx, this.parentIdx, paletteId)
      this.draw()
      this.setHistory('setPalette', [paletteId])
    },
    // 删除颜色
    deleteObjColor(color) {
      if (!this.obj.palette) {
        this.obj.palette = {}
      }
      this.obj.palette[color] = !this.obj.palette[color]
      if (this.obj.type === 'group') {
        this.callSubObjs('deleteObjColor', [color])
        return
      }
      this.GRIDY.deleteObjColor(this.sceneIdx, this.idx, this.parentIdx, color)
      this.draw()
      this.emit('getObjColors', [this.state.colorOrder])
      this.setHistory('deleteObjColor', [color])
    },
    // 调用全部子对象方法
    callSubObjs(fn, params) {
      if (!this.obj.objs || !fn) return
      Object.values(this.obj.objs).map((obj) => {
        this.callSubObj(obj.id, fn, params)
      })
    },
    // 调用子对象方法
    callSubObj(subId, fn, params) {
      if (!subId || !fn) return
      params = params || []
      this.$refs['sub-obj:' + this.sceneId + ':' + this.obj.id + ':' + subId][0][fn](...params)
    },
    // 合并
    merge() {
      this.emit('merge', [this.idx, this.parentIdx])
    },
    // 组合
    group() {
      this.emit('group', [this.idx, this.parentIdx])
    },
    // 取消组合
    ungroup() {
      this.emit('ungroup', [this.idx, this.parentIdx])
    },
    // 连续处理
    continueHandle(event) {
      if (this.obj.lock || this.state.act === 'freeSelect' || this.obj.type === 'group' || (!this.startEdit && !this.continueDrawing)) {
        return
      }
      this.getPos('gridCanvas', event.clientX, event.clientY)
      const x = this.actPos.x
      const y = this.actPos.y
      const curColor = this.state.color[this.state.colorIdx]
      this.curColor = curColor
      if (this.state.act === 'fill') {
        this.fillGridColor(x, y, curColor)
      } else if (this.state.act === 'erase') {
        this.fillGridColor(x, y, '')
      } else if (this.state.act === 'brush') {
        this.handleBrush(x, y, curColor)
      } else if (this.state.act === 'spray') {
        this.handleSpray(x, y, curColor)
      }
    },
    // 笔刷
    handleBrush(x, y, curColor) {
      const n = Math.ceil(Math.random() * 4)
      const offset = this.obj.cols - x - 1
      this.fillGridColor(x, y, curColor)
      if (offset) {
        this.fillGridColor(x + 1, y, curColor)
      }
      if (x) {
        this.fillGridColor(x - 1, y, curColor)
      }
      this.fillGridColor(x, y + 1, curColor)
      this.fillGridColor(x, y - 1, curColor)
      if (n !== 1) {
        if (offset) {
          this.fillGridColor(x + 1, y - 2, curColor)
        }
        this.fillGridColor(x - 1, y + 2, curColor)
      }
      if (n !== 2) {
        if (offset > 1) {
          this.fillGridColor(x + 2, y - 1, curColor)
        }
        if (x > 1) {
          this.fillGridColor(x - 2, y + 1, curColor)
        }
      }
      if (n !== 3) {
        if (offset > 1) {
          this.fillGridColor(x + 2, y + 1, curColor)
        }
        if (x > 1) {
          this.fillGridColor(x - 2, y - 1, curColor)
        }
      }
      if (n !== 4) {
        if (offset) {
          this.fillGridColor(x + 1, y + 2, curColor)
        }
        if (x) {
          this.fillGridColor(x - 1, y - 2, curColor)
        }
      }
    },
    // 喷涂
    handleSpray(x, y, curColor) {
      const now = Date.now()
      if (now - this.sprayTime < 100) {
        return
      }
      this.sprayTime = now
      const offset = this.obj.cols - x - 1
      this.fillGridColor(x, y + 1, curColor)
      this.fillGridColor(x, y - 1, curColor)
      this.fillGridColor(x, y - 3, curColor)
      this.fillGridColor(x, y + 3, curColor)
      if (x) {
        this.fillGridColor(x - 1, y, curColor)
        this.fillGridColor(x - 1, y + 2, curColor)
        this.fillGridColor(x - 1, y - 2, curColor)
      }
      if (x > 1) {
        this.fillGridColor(x - 2, y + 1, curColor)
        this.fillGridColor(x - 2, y - 1, curColor)
        this.fillGridColor(x - 2, y - 3, curColor)
        this.fillGridColor(x - 2, y + 3, curColor)
      }
      if (x > 2) {
        this.fillGridColor(x - 3, y + 2, curColor)
        this.fillGridColor(x - 3, y, curColor)
        this.fillGridColor(x - 3, y - 2, curColor)
      }
      if (offset) {
        this.fillGridColor(x + 1, y, curColor)
        this.fillGridColor(x + 1, y - 2, curColor)
        this.fillGridColor(x + 1, y + 2, curColor)
      }
      if (offset > 1) {
        this.fillGridColor(x + 2, y + 1, curColor)
        this.fillGridColor(x + 2, y - 3, curColor)
        this.fillGridColor(x + 2, y + 3, curColor)
        this.fillGridColor(x + 2, y - 1, curColor)
      }
      if (offset > 2) {
        this.fillGridColor(x + 3, y - 2, curColor)
        this.fillGridColor(x + 3, y, curColor)
        this.fillGridColor(x + 3, y + 2, curColor)
      }
    },
    getPosColor(pos) {
      this.getPos('gridCanvas', pos.x, pos.y)
      return this.getGridDt(this.actPos.x, this.actPos.y).color
    },
    mousedown(event, rightClick) {
      if (!this.isDesktop) return
      this.continueDrawing = true
      this.handle(event, rightClick)
    },
    mousemove(event) {
      if (this.continueDrawing) {
        this.continueHandle(event)
      }
    },
    mouseup(event, silence = false) {
      // 适配触摸模式
      if (event.touches && event.touches[0]) {
        event.clientX = event.touches[0].clientX
        event.clientY = event.touches[0].clientY
        event.button = 0
      }
      if (!this.continueDrawing && event.type === 'mouseup') return
      this.continueDrawing = false
      this.startEdit = false
      this.box = {}
      if (['fill', 'batchFill', 'erase', 'batchErase', 'brush', 'spray'].indexOf(this.state.act) >= 0) {
        this.createOrigin(true)
        if (!silence) this.setHistory(event.type)
      }
    },
    // 处理
    handle(event, rightClick) {
      // 适配触摸模式
      if (event.touches && event.touches[0]) {
        event.clientX = event.touches[0].clientX
        event.clientY = event.touches[0].clientY
        event.button = 0
      }
      if (!(this.select && this.state.selectNums === 1)) this.handleSelect(event)
      if (this.obj.lock || this.state.act === 'freeSelect' || this.obj.type === 'group') {
        return
      }
      this.state.colorIdx = rightClick ? 1 : event.button === 2 ? 1 : 0
      const curColor = this.state.color[this.state.colorIdx]
      this.curColor = curColor
      if (this.state.act === 'pick') {
        this.getPos('gridCanvas', event.clientX, event.clientY)
        const gridDt = this.getGridDt(this.actPos.x, this.actPos.y)
        this.emit(this.state.act, [this.state.colorIdx, gridDt.color || this.canvasBgColor || '#FFFFFF'])
        return
      } else if (this.state.act === 'fill' || this.state.act === 'erase' || this.state.act === 'brush' || this.state.act === 'spray') {
        this.sprayTime = Date.now() - 100
        this.startEdit = true
        this.continueHandle(event)
      } else if (this.state.act === 'batchFill') {
        this.getPos('gridCanvas', event.clientX, event.clientY)
        this.filled = {}
        this.fillTimes = 0
        this.batchFill(this.actPos.x, this.actPos.y, curColor, this.getGridColor(this.actPos.x, this.actPos.y).color)
        this.setThumbDt()
        this.reDraw()
      } else if (this.state.act === 'batchErase') {
        this.getPos('gridCanvas', event.clientX, event.clientY)
        this.filled = {}
        this.fillTimes = 0
        this.batchFill(this.actPos.x, this.actPos.y, '', this.getGridColor(this.actPos.x, this.actPos.y).color)
        this.setThumbDt()
        this.reDraw()
      }
    },
    // 颜色替换
    changeColor(color, newColor) {
      if ((color || newColor) && color !== newColor) {
        this.GRIDY.changeObjColor(this.sceneIdx, this.idx, this.parentIdx, color, newColor)
        this.emit('getObjColors', [this.state.colorOrder])
        this.draw()
        this.setHistory('changeColor', [color, newColor])
      }
    },
    // 降噪
    denoise(denoiseFactor, mergerFactor, quantizeFactor) {
      this.GRIDY.objDenoise(this.sceneIdx, this.idx, this.parentIdx, denoiseFactor)
      this.GRIDY.mergeObjColor(this.sceneIdx, this.idx, this.parentIdx, mergerFactor)
      this.GRIDY.quantizeObjColor(this.sceneIdx, this.idx, this.parentIdx, quantizeFactor)
      this.state.quantizeFactor = 0
      this.draw()
      this.setHistory('denoise', [denoiseFactor, mergerFactor, quantizeFactor])
    },
    // 色相/饱和度
    colorFilter(filter) {
      this.GRIDY.objColorFilter(this.sceneIdx, this.idx, this.parentIdx, filter)
      this.draw()
      this.setHistory('colorFilter', [filter])
    },
    // 移动对象
    move(direction) {
      if (direction === 'left') {
        this.obj.x--
      } else if (direction === 'right') {
        this.obj.x++
      } else if (direction === 'up') {
        this.obj.y--
      } else if (direction === 'down') {
        this.obj.y++
      }
      this.setHistory('move', [direction])
      setTimeout(this.resetGroupBoxSize, 100)
    },
    // 拖动中
    onDragging(x, y) {
      this.x = x / this.state.gridSize
      this.y = y / this.state.gridSize
      this.state.draggingObj = true
    },
    // 拖动
    onDrag(x, y) {
      this.state.draggingObj = false
      x = x / this.state.gridSize
      y = y / this.state.gridSize
      if (this.obj.x === x && this.obj.y === y) return
      this.x = x
      this.y = y
      this.obj.x = x
      this.obj.y = y
      this.setHistory('onDrag', [x, y])
      // 解决 resetGroupBoxSize 的尺寸 bug
      setTimeout(this.resetGroupBoxSize, 100)
    },
    // 缩放时
    onResizing(x, y, width, height) {
      this.state.resizeingObj = true
      if (this.gridCanvas) {
        this.gridCanvas.style.width = width + 'px'
        this.gridCanvas.style.height = height + 'px'
        this.objWorkArea.style.width = width + 'px'
        this.objWorkArea.style.height = height + 'px'
        this.state.resizing = true
      }
    },
    // 缩放
    onResize(x, y, width, height) {
      this.getThumbDt(Math.floor(width / this.state.gridSize), Math.floor(height / this.state.gridSize), () => {
        this.reDraw(false)
        this.resetGroupBoxSize()
        this.setHistory('onResize', [x, y, width, height])
        this.obj.x = x / this.state.gridSize
        this.obj.y = y / this.state.gridSize
        this.state.resizeingObj = false
      })
    },
    setSize(mod, val) {
      val = Math.abs(parseFloat(val))
      this.obj.cols = parseInt(this.obj.cols)
      this.obj.rows = parseInt(this.obj.rows)
      if (!val) return
      if (mod === 'scale') {
        this.obj.cols = Math.ceil(this.obj.cols * val)
        this.obj.rows = Math.ceil(this.obj.rows * val)
      } else if (mod === 'incRows') {
        this.obj.rows = this.obj.rows + val
      } else if (mod === 'decRows') {
        this.obj.rows = this.obj.rows - val
      } else if (mod === 'incCols') {
        this.obj.cols = this.obj.cols + val
      } else if (mod === 'decCols') {
        this.obj.cols = this.obj.cols - val
      }
      if (this.obj.type === 'group') {
        this.callSubObjs('setSize', [mod, val])
        return
      }
      this.getThumbDt(this.obj.cols, this.obj.rows, () => {
        this.reDraw(false)
        this.resetGroupBoxSize()
        this.setHistory('setSize', [mod, val])
      })
    },
    // 显示原图开关
    toggleGridsfy(status) {
      if (typeof status === 'boolean') {
        this.obj.gridsfy = status
      } else {
        this.obj.gridsfy = !this.obj.gridsfy
      }
    },
    // 绘制文本
    drawTxt(setCache) {
      // 去除全部空格
      this.obj.txt.content = utils.trim(this.obj.txt.content, true)
      if (!this.obj.txt.content) {
        this.delete()
        return
      }
      this.editTxt = false
      this.state.editTxt = false
      // 限制8个汉字或16个字符
      this.obj.txt.content = utils.getString(this.obj.txt.content, 8)
      this.drawTxtObj(this.obj.txt)
      if (setCache) {
        this.setHistory('drawTxt')
      }
    },
    // 绘制文本
    debounceDrawTxt() {
      utils.debounce(() => this.drawTxt(true), 100, 'drawTxtTimer')()
    },
    ondeactivated() {
      if (this.editTxt) {
        this.debounceDrawTxt()
      }
    },
    // 取消选中
    unselectObj() {
      this.emit('unselectObj', [this.obj.id, this.parentId, this.idx, this.parentIdx])
    },
    // 防抖取消选中 ?? del?
    debounceUnselectObj() {
      utils.debounce(this.unselectObj, 100, 'unselectObjTimer')()
    },
    // 获取当前坐标
    getPos(canvasId, x, y) {
      const box = this[canvasId].getBoundingClientRect()
      this.actPos = {
        x: Math.floor((x - box.left) / this.state.gridSize),
        y: Math.floor((y - box.top) / this.state.gridSize)
      }
      return this.actPos
    },
    // 获取网格颜色
    getGridColor(x, y) {
      const idx = y * this.obj.cols + x
      return {
        idx: idx,
        color: this.obj.data[idx]
      }
    },
    // 获取网格数据
    getGridDt(x, y) {
      const gridColor = this.getGridColor(x, y)
      return {
        x: x,
        y: y,
        idx: gridColor.idx,
        color: gridColor.color || ''
      }
    },
    // 单个填色
    fillGridColor(x, y, color) {
      this.GRIDY.drawGrid(this.gridCtx, x, y, color, this.state.gridSize, this.obj.fillShape, true)
      const idx = y * this.obj.cols + x
      this.obj.data[idx] = color
    },
    // 计算颜色索引
    calcIdx(x, y) {
      return y * this.obj.cols + x
    },
    // 批量填色 (控制填充次数在65536次以内)
    batchFill(x, y, color, oldColor, times) {
      try {
        times = times || 0
        times++
        const idx = this.calcIdx(x, y)
        if (x < 0 || y < 0 || x >= this.obj.cols || y >= this.obj.rows || this.obj.data[idx] === color || this.filled[idx] || this.fillTimes > 65536) {
          return
        }
        this.fillTimes++
        this.filled[idx] = true
        if (oldColor === this.obj.data[idx]) {
          this.obj.data[idx] = color
          this.batchFill(x + 1, y, color, oldColor, times)
          this.batchFill(x - 1, y, color, oldColor, times)
          this.batchFill(x, y - 1, color, oldColor, times)
          this.batchFill(x, y + 1, color, oldColor, times)
        } else {
          return
        }
      } catch (err) {
        // eslint-disable-next-line
      }
    }
  }
}
</script>
