<template>
  <div :id="'users-' + mod + '-container'" class="flow-container my-list discover">
    <div v-for="(column, index) in columns" :key="index" class="column" :style="{'width': columnWidth + 'px' }">
      <div v-for="(item, i) in column.items" :key="i" class="item">
        <div class="card" :style="{ 'width': cardWidth + 'px', 'height': (item._height - 5) + 'px' }">
          <div class="user" @click="emit('goto', ['user', { userid: item.userid } ])">
            <div>
              <span v-if="!item.avatar" class="el-avatar el-avatar--circle noavatar large">
                <i class="iconfont my-man" />
              </span>
              <el-avatar v-if="item.avatar" :size="42" :src="getAvatar(item)" />
            </div>
            <div class="ellipsis">{{ item.nickname || view.anonymity }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mixins } from '@/mixins/users'
export default {
  mixins: [mixins]
}
</script>
