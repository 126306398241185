<template>
  <div v-show="config.show" class="my-container discover">
    <div v-show="!isDesktop && config.showHead" class="header my-header">
      <div class="left">
        &nbsp;
      </div>
      <div class="middle">
        <el-button-group>
          <el-button size="small" :type="'primary'" @click="goto('zone')">圈子</el-button>
          <el-button size="small" :type="'plain'">发现</el-button>
        </el-button-group>
      </div>
      <div class="right" />
    </div>
    <pull-to :id="'discover-container'" :ref="'discover-container'" :style="style" :top-load-method="(loaded) => renewView(loaded)">
      <div class="margin-top4 bg-22 round4">
        <div class="my-row bg-22 round4">
          <el-row>
            <el-col :span="8"><b>热门话题</b></el-col>
            <el-col :span="16" class="right">
              <el-button class="tap-btn box24" @click="goto('topics', { type: 'hot' })">
                <i class="iconfont my-right" />
              </el-button>
            </el-col>
          </el-row>
        </div>
        <topics-item
          v-show="config.show"
          ref="topics-discover"
          mod="discover"
          class="no-card-bg"
          :view="view"
          :state="state"
          :login-status="loginStatus"
          :login-user-id="loginUserId"
          :top-pull="false"
          :bottom-pull="false"
          :thumb-size="isDesktop ? 180 : 120"
          :page-size="12"
          :fix-height="30"
          :is-page="true"
          @receive="handle"
        />
      </div>
      <div class="active-users round4 margin-top8">
        <div class="my-row bg-22 round4">
          <el-row>
            <el-col :span="8"><b>活跃用户</b></el-col>
            <el-col :span="16" class="right">
              <el-button class="tap-btn box24" @click="goto('users', { type: 'active' })">
                <i class="iconfont my-right" />
              </el-button>
            </el-col>
          </el-row>
        </div>
        <users-item
          v-show="config.show"
          ref="users-discover"
          mod="discover"
          class="no-card-bg"
          :view="view"
          :state="state"
          :login-status="loginStatus"
          :login-user-id="loginUserId"
          :top-pull="false"
          :bottom-pull="false"
          :page-size="8"
          :column-nums="isDesktop ? 8 : 4"
          :fix-height="90"
          @receive="handle"
        />
      </div>
      <div class="my-row bg-22 round4">
        <el-row>
          <el-col :span="8"><b>推荐主题</b></el-col>
          <el-col :span="16" class="right">
            <el-button class="tap-btn box24" @click="goto('zone', { type: 'best' })">
              <i class="iconfont my-right" />
            </el-button>
          </el-col>
        </el-row>
      </div>
      <thread-item
        v-show="config.show"
        ref="thread-discover"
        mod="discover"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :top-pull="false"
        :bottom-pull="false"
        @receive="handle"
      />
    </pull-to>
  </div>
</template>
<script>
import PullTo from 'vue-pull-to'
import topicsItem from '@/components/web/topicsItem'
import threadItem from '@/components/web/threadItem'
import usersItem from '@/components/web/usersItem'
import { mixins } from '@/mixins/common'
export default {
  components: {
    PullTo,
    topicsItem,
    threadItem,
    usersItem
  },
  mixins: [mixins],
  data() {
    const data = {}
    return data
  },
  computed: {
    style() {
      return { 'width': '100%', 'height': 'calc(100% - ' + (this.isDesktop ? 45 : 90) + 'px)' }
    },
    config() {
      return this.view.discover
    }
  },
  methods: {
    scrollContainer() {
      const main = document.getElementById('discover-container')
      if (main) {
        const scroll = main.getElementsByClassName('scroll-container')
        if (scroll.length) return scroll[0]
      }
    },
    backTop() {
      const container = this.scrollContainer()
      if (container) {
        setTimeout(() => {
          container.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
        }, 100)
      }
    },
    renewView(loaded) {
      this.backTop()
      this.callSub('topics-discover', 'renewView')
      this.callSub('users-discover', 'renewView')
      this.callSub('thread-discover', 'renewView')
      loaded('done')
    },
    callSub(ref, fn) {
      const el = this.$refs[ref]
      if (el && el[fn]) {
        el[fn]()
      } else {
        setTimeout(() => {
          this.callSub(ref, fn)
        }, 100)
      }
    },
    handle(act, data) {
      this.emit(act, data)
    }
  }
}
</script>
