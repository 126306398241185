import COS from 'cos-js-sdk-v5'
import service from '@/js/service'
var main = function() {
  this.cos = new COS({
    getAuthorization: (options, callback) => {
      service.actionPost('token', { type: 'tencent', path: options.Key || options.Query.prefix }, (dt, type) => {
        if (type === 'success' && dt && dt.data && dt.data.credentials) {
          callback({
            TmpSecretId: dt.data.credentials.tmpSecretId,
            TmpSecretKey: dt.data.credentials.tmpSecretKey,
            XCosSecurityToken: dt.data.credentials.sessionToken,
            StartTime: dt.data.startTime,
            ExpiredTime: dt.data.expiredTime
          })
        } else {
          console.error(dt || '获取COS秘钥失败')
        }
      }, true)
    }
  })
}
main.prototype = {
  putObject: function(options, callback) {
    this.cos.putObject(options, callback)
  },
  sliceUploadFile: function(options, callback) {
    this.cos.sliceUploadFile(options, callback)
  }
}
export default main
