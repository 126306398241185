<template>
  <el-dialog
    ref="topicsDialog"
    :visible.sync="config.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="!isDesktop"
    :show-close="false"
    :top="marginTop"
    custom-class="pop_page noscroll topicsDialog"
  >
    <div slot="title" class="header">
      <div class="left">
        <el-button v-if="!isDesktop" class="tap-btn iconfont my-back size20" @click="closePop(config)" />
        &nbsp;
      </div>
      <div class="middle">
        <div class="tabs my-tabs" style="width:240px;">
          <el-tabs v-model="config.type">
            <el-tab-pane v-for="(type, idx) in config.types" :key="idx" :label="type[1]" :name="type[0]" />
          </el-tabs>
        </div>
      </div>
      <div class="float-right align-right">
        <el-button v-if="isDesktop" class="tap-btn iconfont my-close size20" @click="closePop(config)" />
        &nbsp;
      </div>
    </div>
    <div v-show="config.show" class="my-container topics">
      <topics-list
        v-show="config.show && config.type === 'new'"
        ref="topics-new"
        mod="new"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :subtract-height="5"
        @receive="handle"
      />
      <topics-list
        v-show="config.show && config.type === 'hot'"
        ref="topics-hot"
        mod="hot"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :subtract-height="5"
        @receive="handle"
      />
      <topics-list
        v-show="config.show && config.type === 'best'"
        ref="topics-best"
        mod="best"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :subtract-height="5"
        @receive="handle"
      />
    </div>
  </el-dialog>
</template>
<script>
import topicsList from '@/components/web/topicsList'
import { mixins } from '@/mixins/common'
export default {
  components: {
    topicsList
  },
  mixins: [mixins],
  data() {
    return {}
  },
  computed: {
    config() {
      return this.view.topics
    }
  },
  methods: {
    handle(act, data) {
      this.emit(act, data)
    }
  }
}
</script>
