<template>
  <el-dialog
    ref="setupDialog"
    :visible.sync="config.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="!isDesktop"
    :show-close="false"
    :top="marginTop"
    custom-class="pop_page noscroll setupDialog"
  >
    <div slot="title" class="header">
      <div class="left">
        <el-button v-if="!isDesktop" class="tap-btn iconfont my-back size20" @click="closePop(config)" />
        &nbsp;
      </div>
      <div class="middle">
        设置
      </div>
      <div class="float-right align-right">
        <el-button v-if="isDesktop" class="tap-btn iconfont my-close size20" @click="closePop(config)" />
        &nbsp;
      </div>
    </div>
    <div v-show="config.show" class="my-container">
      <div class="my-body">
        <div class="my-block">
          <div class="my-row round-top4" @click="userGoto('profile', { 'from': 'setup' })">
            个人设置
          </div>
          <div class="my-row round-bottom4" @click="userGoto('account')">
            账号设置
          </div>
        </div>
        <div class="my-block">
          <div class="my-row round-top4" @click="openPopPage({ 'type': 'agreement' })">
            用户协议
          </div>
          <div class="my-row round-bottom4" @click="openPopPage({ 'type': 'privacy' })">
            隐私政策
          </div>
        </div>
        <div class="my-block">
          <div class="my-row round-top4" @click="openPopPage({ 'type': 'feedback' })">
            用户反馈
          </div>
          <div class="my-row" @click="openPopPage({ 'type': 'aboutUs' })">
            关于我们
          </div>
          <div class="my-row round-bottom4" @click="openPopPage({ 'type': 'checkUpdate' })">
            检查更新
          </div>
        </div>
        <div v-if="loginUserId" class="center">
          <el-popconfirm title="确定退出登录吗？" cancel-button-type="Primary" placement="bottom" @confirm="logout()">
            <el-button slot="reference">
              退出登录
            </el-button>
          </el-popconfirm>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { mixins } from '@/mixins/common'
export default {
  components: {},
  mixins: [mixins],
  computed: {
    config() {
      return this.view.setup
    }
  },
  methods: {
    handle(act, data) {
      this.emit(act, data)
    },
    logout() {
      this.emit('logout')
      this.goto('works')
    }
  }
}
</script>
