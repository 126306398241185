<template>
  <el-dialog :title="title" :visible.sync="config.show" :fullscreen="platform.type !== 'desktop'" :top="marginTop" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" custom-class="pop_page resourceMngDialog">
    <div slot="title" class="header">
      <div class="left">
        <el-button v-if="!isDesktop" class="tap-btn iconfont my-back size20" @click="closePop(config)" />
        &nbsp;
      </div>
      <div class="middle">
        {{ title }}
      </div>
      <div class="float-right align-right">
        <el-button v-if="isDesktop" class="tap-btn iconfont my-close size20" @click="closePop(config)" />
        &nbsp;
      </div>
    </div>
    <div :class="{'my-container': platform.type !== 'desktop'}">
      <div class="my-block">
        <el-form ref="formWidget" v-loading="config.loading" class="my-form" label-width="80px" :inline="false" :model="form" :rules="rules" element-loading-background="rgba(0, 0, 0, 0.6)">
          <span v-if="table ==='album'">
            <el-form-item label="目录：" class="my-el-form-item">
              <el-select v-model="form.catalogid" style="width: 240px;" placeholder="请选择...">
                <el-option
                  v-for="item in catalogs"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="名称：">
              <el-input v-model="form.name" style="width: 240px;" class="my-from-item" name="name" placeholder="请输入专辑名称" clearable />
            </el-form-item>
            <el-form-item label="简介：">
              <el-input v-model="form.info" type="textarea" class="my-from-item" name="info" :autosize="{ minRows: 7, maxRows:7 }" :rows="8" :style="{'width': isDesktop ? '460px' : '240px'}" placeholder="请输入专辑介绍" />
            </el-form-item>
            <el-form-item label="标签：" class="my-el-form-item">
              <tags :tags="form.tags" />
            </el-form-item>
          </span>
          <span v-if="table ==='bankcard'">
            <el-form-item v-if="act !== 'view'" label="手机号" prop="phone">
              <el-input v-model.trim="form.phone" type="input" maxlength="11" :placeholder="'请输入手机号 ' + (config.data.mobile || '') " clearable onkeyup="this.value = this.value.replace(/[^\d.]/g,'')" @blur="form.phone = form.phone.replace(/[^\d.]/g,'')" />
            </el-form-item>
            <el-form-item v-if="act !== 'view'" label="验证码" prop="code" class="sms-item">
              <el-input v-model.trim="form.code" type="input" maxlength="6" placeholder="请输入验证码" onkeyup="this.value = this.value.replace(/[^\d.]/g,'')" @blur="form.code = form.code.replace(/[^\d.]/g,'')" />
              <div class="sms-send" :class="{'is-disabled': !form.phone || state.countDownNum}" @click="sendSMS()">
                {{ state.countDownNum ? state.countDownNum : '获取验证码' }}
              </div>
            </el-form-item>
            <el-form-item label="开户银行" prop="bankname">
              <el-input v-model.trim="form.bankname" type="input" maxlength="30" :placeholder="'请输入银行名称'" :disabled="act === 'view'" clearable />
            </el-form-item>
            <el-form-item label="银行卡号" prop="bankcard">
              <el-input v-model.trim="form.bankcard" type="input" maxlength="30" :placeholder="'请输入银行卡号'" :disabled="act === 'view'" clearable />
            </el-form-item>
            <el-form-item label="开户姓名" prop="realname">
              <el-input v-model.trim="form.realname" type="input" maxlength="18" :placeholder="'请输入开户姓名'" :disabled="act === 'view'" clearable />
            </el-form-item>
            <el-form-item label="证件号码" prop="idcard">
              <el-input v-model.trim="form.idcard" type="input" maxlength="18" :placeholder="'请输入身份证号码'" :disabled="act === 'view'" clearable />
            </el-form-item>
            <el-form-item label="备注">
              <el-input v-model.trim="form.note" type="input" maxlength="30" :placeholder="'请输入备注信息'" :disabled="act === 'view'" clearable />
            </el-form-item>
          </span>
          <span v-if="table ==='address'">
            <el-form-item label="姓名" prop="realname">
              <el-input v-model.trim="form.realname" type="input" maxlength="18" :placeholder="'请输入姓名'" :disabled="act === 'view'" clearable />
            </el-form-item>
            <el-form-item label="性别" prop="sex">
              <el-select v-if="act !== 'view'" v-model="form.sex" placeholder="请选择性别">
                <el-option label="女" :value="0" />
                <el-option label="男" :value="1" />
                <el-option label="保密" :value="2" />
              </el-select>
              <span v-if="act === 'view'" class="margin-left8 color-ff">{{ form.sex === 0 ? '女' : (form.sex === 1 ? '男' : '保密') }}</span>
            </el-form-item>
            <el-form-item v-if="act !== 'view'" label="手机号" prop="phone">
              <el-input v-model.trim="form.phone" type="input" maxlength="11" :placeholder="'请输入手机号 ' + (config.data.mobile || '') " clearable onkeyup="this.value = this.value.replace(/[^\d.]/g,'')" @blur="form.phone = form.phone.replace(/[^\d.]/g,'')" />
            </el-form-item>
            <el-form-item label="所在地区" prop="area">
              <v-distpicker v-if="act !== 'view'" :province="form.province" :city="form.city" :area="form.district" @change="onChange" />
              <span v-if="act === 'view'" class="margin-left8 color-ff">{{ form.province + ' ' + form.city + ' ' + form.district }}</span>
            </el-form-item>
            <el-form-item label="详细地址" prop="address">
              <el-input v-model.trim="form.address" type="input" maxlength="100" :placeholder="'请输入详细地址'" :disabled="act === 'view'" clearable />
            </el-form-item>
          </span>
          <el-form-item v-if="table === 'bankcard' || table === 'address'" label="设为默认">
            <el-switch v-if="act !== 'view'" v-model="form.isdefault" />
            <span v-if="act === 'view'" class="margin-left8 color-ff">{{ form.isdefault ? '是': '否' }}</span>
          </el-form-item>
          <el-form-item label="">
            <el-button v-if="act !== 'view'" class="submit" @click="submitForm('formWidget')">
              提交
            </el-button>
            <el-button v-if="act === 'view'" class="submit" @click="config.act = 'edit'">
              编辑
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { mixins } from '@/mixins/common'
import utils from '@/js/utils'
import conf from '@/js/conf/conf'
import service from '@/js/service'
import VDistpicker from 'v-distpicker'
import tags from '@/components/tags'
export default {
  components: {
    VDistpicker,
    tags
  },
  mixins: [mixins],
  props: {},
  data() {
    const form = {
      catalogid: 0,
      name: '',
      info: '',
      tags: [],
      phone: '',
      code: '',
      password: '',
      repassword: '',
      username: '',
      nickname: '',
      sex: 1,
      checked: 0,
      bankname: '',
      realname: '',
      idcard: '',
      bankcard: '',
      address: '',
      note: '',
      area: {},
      province: '',
      city: '',
      district: '',
      isdefault: 1
    }
    const rules = {
      phone: [{ trigger: 'blur', validator: this.validPhone }],
      code: [{ trigger: 'blur', validator: this.validCode }],
      username: [{ trigger: 'blur', validator: this.validUsername }],
      password: [{ trigger: 'blur', validator: this.validPassword }],
      repassword: [{ trigger: 'blur', validator: this.validRepassword }],
      nickname: [{ trigger: 'blur', validator: this.validNickname }],
      bankname: [{ trigger: 'blur', validator: this.validBankname }],
      realname: [{ trigger: 'blur', validator: this.validRealname }],
      idcard: [{ trigger: 'blur', validator: this.validIdcard }],
      bankcard: [{ trigger: 'blur', validator: this.validBankcard }],
      checked: [{ trigger: 'blur', validator: this.validChecked }],
      address: [{ trigger: 'blur', validator: this.validAddress }],
      area: [{ trigger: 'blur', validator: this.validArea }]
    }
    const data = {}
    data.imageSize = 120
    data.worksHost = conf.hosts().worksHost
    data.catalogs = utils.deepClone(conf.catalogs)
    data.platform = utils.platform()
    data.form = form
    data.rules = rules
    data.resource = {}
    data.countDownNum = 0
    data.countDownIt = null
    return data
  },
  computed: {
    config() {
      return this.view.resourceMng
    },
    table() {
      return this.config.table
    },
    act() {
      return this.config.act
    },
    id() {
      return this.config.id
    },
    keyfield() {
      return this.config.keyfield || this.table + 'id'
    },
    title() {
      return this.view.resourceActs[this.act] + this.view.resourceNames[this.table]
    }
  },
  watch: {
    // 'view.resourceMng.show': {
    //   handler() {
    //     this.resetForm()
    //     if (this.view.resourceMng.show) {
    //       this.getResource()
    //       this.update()
    //     }
    //   }
    // },
    'resource': {
      handler() {
        this.initFormData()
      }
    }
  },
  mounted() {
    this.getResource()
  },
  methods: {
    resetForm() {
      if (this.$refs['formWidget']) this.$refs['formWidget'].resetFields()
    },
    validPhone(rule, value, cb) {
      if (!utils.checkNow('mobile', value, true)) {
        cb(new Error('请输入正确的手机号码'))
      } else {
        let checkMobile = value + ''
        checkMobile = checkMobile.slice(0, 3) + '****' + checkMobile.slice(-4)
        if (this.config.data.mobile && this.config.data.mobile !== checkMobile) {
          cb(new Error('请输入手机号 ' + this.config.data.mobile))
        } else {
          cb()
        }
      }
    },
    validCode(rule, value, cb) {
      if (!utils.checkNow('str-6-6', value, true)) {
        cb(new Error('请输入验证码'))
      } else {
        cb()
      }
    },
    validChecked(rule, value, cb) {
      if (!value) {
        cb(new Error('请阅读并同意《用户协议》和《隐私条款》'))
      } else {
        cb()
      }
    },
    validPassword(rule, value, cb) {
      if (this.table === 'setPayPassword') {
        if (!utils.checkNow('paypassword', value, true)) {
          cb(new Error('交易密码为6位数字'))
        } else {
          cb()
        }
      } else {
        if (!utils.checkNow('strongpassword', value, true)) {
          cb(new Error('8~20位字符，必须包含字母、数字和特殊字符'))
        } else {
          cb()
        }
      }
    },
    validRepassword(rule, value, cb) {
      if (!value) {
        cb(new Error('请输入确认密码'))
      } else if (value !== this.form.password) {
        cb(new Error('两次输入的密码不一致'))
      } else {
        cb()
      }
    },
    validUsername(rule, value, cb) {
      if (!utils.checkNow('username', value, true)) {
        cb(new Error('6~18位字符，包含字母、数字、下划线'))
      } else {
        cb()
      }
    },
    validNickname(rule, value, cb) {
      if (!utils.checkNow('nickname', value, true)) {
        cb(new Error('6~18位字符，包含字母、数字、下划线、汉字'))
      } else {
        cb()
      }
    },
    validRealname(rule, value, cb) {
      if (!utils.checkNow('str2-4-18', value, true)) {
        cb(new Error('请输入正确的开户姓名'))
      } else {
        cb()
      }
    },
    validIdcard(rule, value, cb) {
      if (!utils.checkNow('idcard', value, true)) {
        cb(new Error('请输入正确的身份证号码'))
      } else {
        cb()
      }
    },
    validBankname(rule, value, cb) {
      if (!utils.checkNow('str2-4-30', value, true)) {
        cb(new Error('请输入正确的银行名称'))
      } else {
        cb()
      }
    },
    validBankcard(rule, value, cb) {
      if (!utils.checkNow('bankcard', value, true)) {
        cb(new Error('请输入正确的银行卡号'))
      } else {
        cb()
      }
    },
    validAddress(rule, value, cb) {
      if (!value) {
        cb(new Error('请输入详细地址'))
      } else {
        cb()
      }
    },
    validArea(rule, value, cb) {
      if (!value.province || !value.city || !value.area) {
        cb(new Error('请选择所在地区'))
      } else {
        cb()
      }
    },
    handle(act, data) {
      this.emit(act, data)
    },
    getDate(time) {
      if (!time) return ''
      return utils.date('datetime', 0, new Date(time * 1000))
    },
    formateTime: utils.formateTime,
    formateNums: utils.formateNums,
    formateMoney: utils.formateMoney,
    // 获取资源信息
    getResource(cb) {
      this.resource = {}
      if ((this.act === 'view' || this.act === 'edit') && this.id) {
        service.get(this.table, this.id, (dt, type) => {
          if (type === 'success' && dt && dt.data && dt.data.count) {
            this.resource = dt.data.items[0]
          }
          this.update()
          cb && cb(this.resource)
        }, this.act === 'edit', this.act === 'edit' ? { userid: this.loginUserId } : '', false)
      }
    },
    initFormData() {
      if (this.table === 'bankcard') {
        this.form.bankname = this.resource.bank
        this.form.realname = this.resource.name
        this.form.idcard = this.resource.idcard
        this.form.bankcard = this.resource.account
        this.form.note = this.resource.note
        this.form.isdefault = !!this.resource.isdefault
      } else if (this.table === 'address') {
        this.form.realname = this.resource.name
        this.form.sex = this.resource.sex
        this.form.phone = this.resource.phone
        this.form.province = this.resource.province
        this.form.city = this.resource.city
        this.form.district = this.resource.district
        this.form.address = this.resource.address
        this.form.isdefault = !!this.resource.isdefault
      } else if (this.table === 'album') {
        this.form.catalogid = this.resource.catalogid
        this.form.name = this.resource.name
        this.form.info = this.resource.info
        this.form.tags = this.resource.tags || []
      }
    },
    // type 验证类别；0=注册; 1=登录; 2=实名认证; 3=二次认证; 4=添加银行卡; 5=修改银行卡; 6=绑定手机号认证
    sendSMS() {
      this.validPhone('', this.form.phone, (err) => {
        if (err) {
          this.message(err.message, 'error')
        } else {
          let type = 3
          if (this.table === 'bankcard') type = 4
          this.emit('sendSMS', [type, this.form.phone])
        }
      })
    },
    onChange(data) {
      this.form.area = data
    },
    submitForm(formName) {
      const cb = (dt, type) => {
        if (type === 'success') {
          let consumeMsg = ''
          if (dt.data.consumeNums) consumeMsg = '，本次消费 ' + dt.data.consumeNums + ' 个晶钻，签到和做任务可以获得晶钻'
          this.message(((this.act === 'edit' ? '修改' : '添加') + '成功') + consumeMsg, type)
          this.view.resourceSelecter.renew = utils.time('time')
          this.view.resource.renew = utils.time('time')
          if (this.table === 'album') {
            this.view.user.renew = utils.time('time')
          }
          this.closePop(this.config)
        } else {
          this.message(dt, type)
        }
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {}
          if (this.table === 'bankcard') {
            params.phone = this.form.phone
            params.code = this.form.code
            params.bank = this.form.bankname
            params.name = this.form.realname
            params.idcard = this.form.idcard
            params.account = this.form.bankcard
            params.note = this.form.note
            params.isdefault = this.form.isdefault ? 1 : 0
          } else if (this.table === 'address') {
            params.name = this.form.realname
            params.sex = this.form.sex
            params.phone = this.form.phone
            params.province = this.form.area.province.value
            params.city = this.form.area.city.value
            params.district = this.form.area.area.value
            params.address = this.form.address
            params.zip = this.form.area.area.code || this.form.area.city.code || this.form.area.province.code
            params.isdefault = this.form.isdefault ? 1 : 0
          } else if (this.table === 'album') {
            params.catalogid = this.form.catalogid
            params.name = this.form.name
            params.info = this.form.info
            params.tags = this.form.tags ? this.form.tags.join(',') : ''
          } else {
            params = this.form
          }
          if (this.config.id && this.act === 'edit') {
            service.put(this.table, this.config.id, params, cb, true)
          } else if (this.act === 'add') {
            service.post(this.table, params, cb, true)
          }
        }
      })
    }
  }
}
</script>
